import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, TextInput, Platform, FlatList, Image, RefreshControl } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import 'moment/locale/it';
import DateTimePicker from '@react-native-community/datetimepicker';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { MaskedTextInput } from "react-native-mask-text";
import moment from 'moment';
import 'moment/locale/it';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class OrariOperatoreScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            operatore: this.props.route.params.operatore,
            orariActive: true,
            lunediOpen: true,
            lunediStart: '',
            lunediEnd: '',
            lunediIsSecond: false,
            lunediSecondStart: '',
            lunediSecondEnd: '',
            martediOpen: true,
            martediStart: '',
            martediEnd: '',
            martediIsSecond: false,
            martediSecondStart: '',
            martediSecondEnd: '',
            mercolediOpen: true,
            mercolediStart: '',
            mercolediEnd: '',
            mercolediIsSecond: false,
            mercolediSecondStart: '',
            mercolediSecondEnd: '',
            giovediOpen: true,
            giovediStart: '',
            giovediEnd: '',
            giovediIsSecond: false,
            giovediSecondStart: '',
            giovediSecondEnd: '',
            venerdiOpen: true,
            venerdiStart: '',
            venerdiEnd: '',
            venerdiIsSecond: false,
            venerdiSecondStart: '',
            venerdiSecondEnd: '',
            sabatoOpen: true,
            sabatoStart: '',
            sabatoEnd: '',
            sabatoIsSecond: false,
            sabatoSecondStart: '',
            sabatoSecondEnd: '',
            domenicaOpen: true,
            domenicaStart: '',
            domenicaEnd: '',
            domenicaIsSecond: false,
            domenicaSecondStart: '',
            domenicaSecondEnd: '',
            lunghezzaSlot: '',
            ferieActive: false,
            ferieStart: '',
            ferieEnd: '',
            loadingFerie: false,
            listFerieActive: false,
            loadingPage: true,
            listFerie: [],
            loadingFerieList: true
        }
    }

    componentDidMount() {
        this.getOrariOperatore()
        this.getFerie()
    }

    getOrariOperatore = async () => {
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', this.state.operatore.id);
        axios.post('https://www.visitreporter.com/app/get_orari_operatore.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                const lunediJson = json.filter(e => e.giorno == "lunedi")[0]
                this.setState({
                    lunediOpen: (lunediJson.aperto == "1" || lunediJson.aperto == 1) ? true : false,
                    lunediStart: (lunediJson.start !== '' && lunediJson.start !== null) ? `${lunediJson.start.split(':')[0]}:${lunediJson.start.split(':')[1]}` : '',
                    lunediEnd: (lunediJson.end !== '' && lunediJson.end !== null) ? `${lunediJson.end.split(':')[0]}:${lunediJson.end.split(':')[1]}` : '',
                    lunediIsSecond: (lunediJson.second == "1" || lunediJson.second == 1) ? true : false,
                    lunediSecondStart: (lunediJson.start_second !== '' && lunediJson.start_second !== null) ? `${lunediJson.start_second.split(':')[0]}:${lunediJson.start_second.split(':')[1]}` : '',
                    lunediSecondEnd: (lunediJson.end_second !== '' && lunediJson.end_second !== null) ? `${lunediJson.end_second.split(':')[0]}:${lunediJson.end_second.split(':')[1]}` : '',
                    lunghezzaSlot: (lunediJson.slot !== '' && lunediJson.slot !== null) ? lunediJson.slot : ''
                })

                const martediJson = json.filter(e => e.giorno == "martedi")[0]
                this.setState({
                    martediOpen: (martediJson.aperto == "1" || martediJson.aperto == 1) ? true : false,
                    martediStart: (martediJson.start !== '' && martediJson.start !== null) ? `${martediJson.start.split(':')[0]}:${martediJson.start.split(':')[1]}` : '',
                    martediEnd: (martediJson.end !== '' && martediJson.end !== null) ? `${martediJson.end.split(':')[0]}:${martediJson.end.split(':')[1]}` : '',
                    martediIsSecond: (martediJson.second == "1" || martediJson.second == 1) ? true : false,
                    martediSecondStart: (martediJson.start_second !== '' && martediJson.start_second !== null) ? `${martediJson.start_second.split(':')[0]}:${martediJson.start_second.split(':')[1]}` : '',
                    martediSecondEnd: (martediJson.end_second !== '' && martediJson.end_second !== null) ? `${martediJson.end_second.split(':')[0]}:${martediJson.end_second.split(':')[1]}` : ''
                })

                const mercolediJson = json.filter(e => e.giorno == "mercoledi")[0]
                this.setState({
                    mercolediOpen: (mercolediJson.aperto == "1" || mercolediJson.aperto == 1) ? true : false,
                    mercolediStart: (mercolediJson.start !== '' && mercolediJson.start !== null) ? `${mercolediJson.start.split(':')[0]}:${mercolediJson.start.split(':')[1]}` : '',
                    mercolediEnd: (mercolediJson.end !== '' && mercolediJson.end !== null) ? `${mercolediJson.end.split(':')[0]}:${mercolediJson.end.split(':')[1]}` : '',
                    mercolediIsSecond: (mercolediJson.second == "1" || mercolediJson.second == 1) ? true : false,
                    mercolediSecondStart: (mercolediJson.start_second !== '' && mercolediJson.start_second !== null) ? `${mercolediJson.start_second.split(':')[0]}:${mercolediJson.start_second.split(':')[1]}` : '',
                    mercolediSecondEnd: (mercolediJson.end_second !== '' && mercolediJson.end_second !== null) ? `${mercolediJson.end_second.split(':')[0]}:${mercolediJson.end_second.split(':')[1]}` : ''
                })

                const giovediJson = json.filter(e => e.giorno == "giovedi")[0]
                this.setState({
                    giovediOpen: (giovediJson.aperto == "1" || giovediJson.aperto == 1) ? true : false,
                    giovediStart: (giovediJson.start !== '' && giovediJson.start !== null) ? `${giovediJson.start.split(':')[0]}:${giovediJson.start.split(':')[1]}` : '',
                    giovediEnd: (giovediJson.end !== '' && giovediJson.end !== null) ? `${giovediJson.end.split(':')[0]}:${giovediJson.end.split(':')[1]}` : '',
                    giovediIsSecond: (giovediJson.second == "1" || giovediJson.second == 1) ? true : false,
                    giovediSecondStart: (giovediJson.start_second !== '' && giovediJson.start_second !== null) ? `${giovediJson.start_second.split(':')[0]}:${giovediJson.start_second.split(':')[1]}` : '',
                    giovediSecondEnd: (giovediJson.end_second !== '' && giovediJson.end_second !== null) ? `${giovediJson.end_second.split(':')[0]}:${giovediJson.end_second.split(':')[1]}` : ''
                })

                const venerdiJson = json.filter(e => e.giorno == "venerdi")[0]
                this.setState({
                    venerdiOpen: (venerdiJson.aperto == "1" || venerdiJson.aperto == 1) ? true : false,
                    venerdiStart: (venerdiJson.start !== '' && venerdiJson.start !== null) ? `${venerdiJson.start.split(':')[0]}:${venerdiJson.start.split(':')[1]}` : '',
                    venerdiEnd: (venerdiJson.end !== '' && venerdiJson.end !== null) ? `${venerdiJson.end.split(':')[0]}:${venerdiJson.end.split(':')[1]}` : '',
                    venerdiIsSecond: (venerdiJson.second == "1" || venerdiJson.second == 1) ? true : false,
                    venerdiSecondStart: (venerdiJson.start_second !== '' && venerdiJson.start_second !== null) ? `${venerdiJson.start_second.split(':')[0]}:${venerdiJson.start_second.split(':')[1]}` : '',
                    venerdiSecondEnd: (venerdiJson.end_second !== '' && venerdiJson.end_second !== null) ? `${venerdiJson.end_second.split(':')[0]}:${venerdiJson.end_second.split(':')[1]}` : ''
                })

                const sabatoJson = json.filter(e => e.giorno == "sabato")[0]
                this.setState({
                    sabatoOpen: (sabatoJson.aperto == "1" || sabatoJson.aperto == 1) ? true : false,
                    sabatoStart: (sabatoJson.start !== '' && sabatoJson.start !== null) ? `${sabatoJson.start.split(':')[0]}:${sabatoJson.start.split(':')[1]}` : '',
                    sabatoEnd: (sabatoJson.end !== '' && sabatoJson.end !== null) ? `${sabatoJson.end.split(':')[0]}:${sabatoJson.end.split(':')[1]}` : '',
                    sabatoIsSecond: (sabatoJson.second == "1" || sabatoJson.second == 1) ? true : false,
                    sabatoSecondStart: (sabatoJson.start_second !== '' && sabatoJson.start_second !== null) ? `${sabatoJson.start_second.split(':')[0]}:${sabatoJson.start_second.split(':')[1]}` : '',
                    sabatoSecondEnd: (sabatoJson.end_second !== '' && sabatoJson.end_second !== null) ? `${sabatoJson.end_second.split(':')[0]}:${sabatoJson.end_second.split(':')[1]}` : ''
                })

                const domenicaJson = json.filter(e => e.giorno == "domenica")[0]
                this.setState({
                    domenicaOpen: (domenicaJson.aperto == "1" || domenicaJson.aperto == 1) ? true : false,
                    domenicaStart: (domenicaJson.start !== '' && domenicaJson.start !== null) ? `${domenicaJson.start.split(':')[0]}:${domenicaJson.start.split(':')[1]}` : '',
                    domenicaEnd: (domenicaJson.end !== '' && domenicaJson.end !== null) ? `${domenicaJson.end.split(':')[0]}:${domenicaJson.end.split(':')[1]}` : '',
                    domenicaIsSecond: (domenicaJson.second == "1" || domenicaJson.second == 1) ? true : false,
                    domenicaSecondStart: (domenicaJson.start_second !== '' && domenicaJson.start_second !== null) ? `${domenicaJson.start_second.split(':')[0]}:${domenicaJson.start_second.split(':')[1]}` : '',
                    domenicaSecondEnd: (domenicaJson.end_second !== '' && domenicaJson.end_second !== null) ? `${domenicaJson.end_second.split(':')[0]}:${domenicaJson.end_second.split(':')[1]}` : ''
                })

                setTimeout(() => {
                    this.setState({ loadingPage: false })
                }, 500);
            } else {
                this.setState({ loadingPage: false })
            }
        }).catch((error) => {
            alert("Attenzione! Un problema non permette l'apertura della pagina. Riprova più tardi")
            this.handleGoBack()
            console.error(error);
        })
    }

    handleSaveOrari = () => {
        let errorCount = 0;

        if (this.state.lunediOpen) {
            if (this.state.lunediIsSecond) {
                if (this.state.lunediStart == '' || this.state.lunediEnd == '' || this.state.lunediSecondStart == '' || this.state.lunediSecondEnd == '') {
                    errorCount = errorCount + 1;
                }
            } else {
                if (this.state.lunediStart == '' || this.state.lunediEnd == '') {
                    errorCount = errorCount + 1;
                }
            }
        }

        if (this.state.martediOpen) {
            if (this.state.martediIsSecond) {
                if (this.state.martediStart == '' || this.state.martediEnd == '' || this.state.martediSecondStart == '' || this.state.martediSecondEnd == '') {
                    errorCount = errorCount + 1;
                }
            } else {
                if (this.state.martediStart == '' || this.state.martediEnd == '') {
                    errorCount = errorCount + 1;
                }
            }
        }

        if (this.state.mercolediOpen) {
            if (this.state.mercolediIsSecond) {
                if (this.state.mercolediStart == '' || this.state.mercolediEnd == '' || this.state.mercolediSecondStart == '' || this.state.mercolediSecondEnd == '') {
                    errorCount = errorCount + 1;
                }
            } else {
                if (this.state.mercolediStart == '' || this.state.mercolediEnd == '') {
                    errorCount = errorCount + 1;
                }
            }
        }

        if (this.state.giovediOpen) {
            if (this.state.giovediIsSecond) {
                if (this.state.giovediStart == '' || this.state.giovediEnd == '' || this.state.giovediSecondStart == '' || this.state.giovediSecondEnd == '') {
                    errorCount = errorCount + 1;
                }
            } else {
                if (this.state.giovediStart == '' || this.state.giovediEnd == '') {
                    errorCount = errorCount + 1;
                }
            }
        }

        if (this.state.venerdiOpen) {
            if (this.state.venerdiIsSecond) {
                if (this.state.venerdiStart == '' || this.state.venerdiEnd == '' || this.state.venerdiSecondStart == '' || this.state.venerdiSecondEnd == '') {
                    errorCount = errorCount + 1;
                }
            } else {
                if (this.state.venerdiStart == '' || this.state.venerdiEnd == '') {
                    errorCount = errorCount + 1;
                }
            }
        }

        if (this.state.sabatoOpen) {
            if (this.state.sabatoIsSecond) {
                if (this.state.sabatoStart == '' || this.state.sabatoEnd == '' || this.state.sabatoSecondStart == '' || this.state.sabatoSecondEnd == '') {
                    errorCount = errorCount + 1;
                }
            } else {
                if (this.state.sabatoStart == '' || this.state.sabatoEnd == '') {
                    errorCount = errorCount + 1;
                }
            }
        }

        if (this.state.domenicaOpen) {
            if (this.state.domenicaIsSecond) {
                if (this.state.domenicaStart == '' || this.state.domenicaEnd == '' || this.state.domenicaSecondStart == '' || this.state.domenicaSecondEnd == '') {
                    errorCount = errorCount + 1;
                }
            } else {
                if (this.state.domenicaStart == '' || this.state.domenicaEnd == '') {
                    errorCount = errorCount + 1;
                }
            }
        }

        if (this.state.lunghezzaSlot == '') {
            errorCount = errorCount + 1;
        }

        if (errorCount > 0) {
            alert("Attenzione! Compila correttamente tutti gli slot orari")
        } else {
            this.handleSendSaveOrari()
        }
    }

    handleSendSaveOrari = async () => {
        this.setState({ loadingActive: true })
        const orariList = [];
        orariList.push({
            giorno: "lunedi",
            aperto: (this.state.lunediOpen) ? 1 : 0,
            start: this.state.lunediStart,
            end: this.state.lunediEnd,
            start_second: this.state.lunediSecondStart,
            end_second: this.state.lunediSecondEnd,
            second: (this.state.lunediIsSecond) ? 1 : 0,
        })
        orariList.push({
            giorno: "martedi",
            aperto: (this.state.martediOpen) ? 1 : 0,
            start: this.state.martediStart,
            end: this.state.martediEnd,
            start_second: this.state.martediSecondStart,
            end_second: this.state.martediSecondEnd,
            second: (this.state.martediIsSecond) ? 1 : 0,
        })
        orariList.push({
            giorno: "mercoledi",
            aperto: (this.state.mercolediOpen) ? 1 : 0,
            start: this.state.mercolediStart,
            end: this.state.mercolediEnd,
            start_second: this.state.mercolediSecondStart,
            end_second: this.state.mercolediSecondEnd,
            second: (this.state.mercolediIsSecond) ? 1 : 0,
        })
        orariList.push({
            giorno: "giovedi",
            aperto: (this.state.giovediOpen) ? 1 : 0,
            start: this.state.giovediStart,
            end: this.state.giovediEnd,
            start_second: this.state.giovediSecondStart,
            end_second: this.state.giovediSecondEnd,
            second: (this.state.giovediIsSecond) ? 1 : 0,
        })
        orariList.push({
            giorno: "venerdi",
            aperto: (this.state.venerdiOpen) ? 1 : 0,
            start: this.state.venerdiStart,
            end: this.state.venerdiEnd,
            start_second: this.state.venerdiSecondStart,
            end_second: this.state.venerdiSecondEnd,
            second: (this.state.venerdiIsSecond) ? 1 : 0,
        })
        orariList.push({
            giorno: "sabato",
            aperto: (this.state.sabatoOpen) ? 1 : 0,
            start: this.state.sabatoStart,
            end: this.state.sabatoEnd,
            start_second: this.state.sabatoSecondStart,
            end_second: this.state.sabatoSecondEnd,
            second: (this.state.sabatoIsSecond) ? 1 : 0,
        })
        orariList.push({
            giorno: "domenica",
            aperto: (this.state.domenicaOpen) ? 1 : 0,
            start: this.state.domenicaStart,
            end: this.state.domenicaEnd,
            start_second: this.state.domenicaSecondStart,
            end_second: this.state.domenicaSecondEnd,
            second: (this.state.domenicaIsSecond) ? 1 : 0,
        })
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', this.state.operatore.id);
        formData.append('orari', JSON.stringify(orariList));
        formData.append('slot', this.state.lunghezzaSlot);
        axios.post('https://www.visitreporter.com/app/save_orari_operatore.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ loadingActive: false })
                alert(`Orari aggiornati! ${json.message}`)
            } else {
                this.setState({ loadingActive: false })
                alert(`Attenzione! ${json.message}`)
            }
        }).catch((error) => {
            this.setState({ loadingActive: false })
            console.error(error);
        })
    }

    copyHoursToAll = (start, end, startSecond, endSecond, isOpen, isSecond) => {
        this.setState({
            lunediOpen: isOpen,
            lunediStart: start,
            lunediEnd: end,
            lunediIsSecond: isSecond,
            lunediSecondStart: startSecond,
            lunediSecondEnd: endSecond,
            martediOpen: isOpen,
            martediStart: start,
            martediEnd: end,
            martediIsSecond: isSecond,
            martediSecondStart: startSecond,
            martediSecondEnd: endSecond,
            mercolediOpen: isOpen,
            mercolediStart: start,
            mercolediEnd: end,
            mercolediIsSecond: isSecond,
            mercolediSecondStart: startSecond,
            mercolediSecondEnd: endSecond,
            giovediOpen: isOpen,
            giovediStart: start,
            giovediEnd: end,
            giovediIsSecond: isSecond,
            giovediSecondStart: startSecond,
            giovediSecondEnd: endSecond,
            venerdiOpen: isOpen,
            venerdiStart: start,
            venerdiEnd: end,
            venerdiIsSecond: isSecond,
            venerdiSecondStart: startSecond,
            venerdiSecondEnd: endSecond,
            sabatoOpen: isOpen,
            sabatoStart: start,
            sabatoEnd: end,
            sabatoIsSecond: isSecond,
            sabatoSecondStart: startSecond,
            sabatoSecondEnd: endSecond,
            domenicaOpen: isOpen,
            domenicaStart: start,
            domenicaEnd: end,
            domenicaIsSecond: isSecond,
            domenicaSecondStart: startSecond,
            domenicaSecondEnd: endSecond
        })
    }

    getFerie = async () => {
        this.setState({ loadingFerieList: true })
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', this.state.operatore.id);
        axios.post('https://www.visitreporter.com/app/get_ferie.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loading: false })
            if (json.length > 0) {
                this.setState({ listFerie: json, loadingFerieList: false })
            } else {
                this.setState({ loadingFerieList: false })
            }
        }).catch((error) => {
            this.setState({ loadingFerieList: false })
            console.error(error);
        })
    }

    handleDeleteRequest = (ferie, index) => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare l'intervallo di date?") == true) {
            this.handleDelete(ferie, index)
        }
    }

    handleDelete = async (ferie, index) => {
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', ferie);
        axios.post('https://www.visitreporter.com/app/delete_ferie.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                const listFerie = this.state.listFerie;
                listFerie.splice(index, 1)
                this.setState({ listFerie })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    handleSendNewFerie = async () => {
        if (this.state.ferieStart !== '' && this.state.ferieEnd !== '') {
            this.setState({ loadingFerie: true })
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_operatore', this.state.operatore.id);
            formData.append('date_start', moment(new Date(`${this.state.ferieStart.split('/')[2]}-${this.state.ferieStart.split('/')[1]}-${this.state.ferieStart.split('/')[0]}`)).format('YYYY-MM-DD'));
            formData.append('date_end', moment(new Date(`${this.state.ferieEnd.split('/')[2]}-${this.state.ferieEnd.split('/')[1]}-${this.state.ferieEnd.split('/')[0]}`)).format('YYYY-MM-DD'));
            axios.post('https://www.visitreporter.com/app/add_ferie.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.setState({ loadingFerie: false, listFerieActive: true, ferieActive: false, ferieStart: '', ferieEnd: '' })
                    this.getFerie()
                } else {
                    this.setState({ loadingFerie: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loadingFerie: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Inserisci la data di inizio e fine ferie per continuare`)
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Orari</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginBottom: 25, paddingTop: 20, paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={[styles.containerTitle, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%', flexDirection: 'row', alignItems: 'center', backgroundColor: '#0070FF' }]}>
                        {(this.state.operatore.image !== null && this.state.operatore.image !== "")
                            ?
                            <Image source={{ uri: this.state.operatore.image }} resizeMode='cover' style={{ width: 40, height: 40, borderRadius: 20 }} />
                            :
                            <Image source={require('../assets/icon-ios.png')} resizeMode='cover' style={{ width: 40, height: 40, borderRadius: 20 }} />
                        }
                        <View style={{ marginLeft: 12 }}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#fff' }}>OPERATORE</Text>
                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 15, color: '#fff' }}>{this.state.operatore.nome}</Text>
                        </View>
                    </View>
                </View>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    refreshControl={<RefreshControl refreshing={this.state.loadingPage} />}
                >
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 400, paddingBottom: Dimensions.get('screen').height / 4.5 }}>
                        <TouchButton style={[styles.containerTitle, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20, backgroundColor: (this.state.orariActive) ? '#8d4472' : '#fff' }]} onPress={() => this.setState({ orariActive: !this.state.orariActive, ferieActive: false, listFerieActive: false })}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: (this.state.orariActive) ? '#fff' : '#8d4472' }}>ORARI</Text>
                            {(this.state.orariActive)
                                ?
                                <Feather name='chevron-up' size={25} color={'#fff'} />
                                :
                                <Feather name='chevron-down' size={25} color={'#8d4472'} />
                            }
                        </TouchButton>
                        {(this.state.orariActive)
                            &&
                            <>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Lunedì</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <TouchButton style={[styles.circleButton, { backgroundColor: '#23a925' }]} onPress={() => this.copyHoursToAll(this.state.lunediStart, this.state.lunediEnd, this.state.lunediSecondStart, this.state.lunediSecondEnd, this.state.lunediOpen, this.state.lunediIsSecond)}>
                                        <Feather name='copy' size={21} color='#fff' />
                                    </TouchButton>
                                    {(this.state.lunediOpen)
                                        &&
                                        <>
                                        {(this.state.lunediIsSecond)
                                            ?
                                            <TouchButton style={[styles.circleButton, { backgroundColor: '#fc3535' }]} onPress={() => this.setState({ lunediIsSecond: false })}>
                                                <Feather name='x' size={21} color='#fff' />
                                            </TouchButton>
                                            :
                                            <TouchButton style={styles.circleButton} onPress={() => this.setState({ lunediIsSecond: true })}>
                                                <Feather name='plus' size={21} color='#fff' />
                                            </TouchButton>
                                        }
                                        </>
                                    }
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.lunediOpen ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ lunediOpen: !this.state.lunediOpen, lunediStart: '', lunediEnd: '', lunediIsSecond: false, lunediSecondStart: '', lunediSecondEnd: '' })}
                                        value={this.state.lunediOpen}
                                    />
                                </View>
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.lunediStart}
                                    onChangeText={(lunediStart) => this.setState({ lunediStart })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 08:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                    editable={this.state.lunediOpen}
                                    keyboardType='number-pad'
                                />
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.lunediEnd}
                                    onChangeText={(lunediEnd) => this.setState({ lunediEnd })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 20:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                    editable={this.state.lunediOpen}
                                    keyboardType='number-pad'
                                />
                            </View>
                            {(this.state.lunediIsSecond && this.state.lunediOpen)
                                &&
                                <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.lunediSecondStart}
                                        onChangeText={(lunediSecondStart) => this.setState({ lunediSecondStart })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 13:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                        keyboardType='number-pad'
                                    />
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.lunediSecondEnd}
                                        onChangeText={(lunediSecondEnd) => this.setState({ lunediSecondEnd })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 14:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                        keyboardType='number-pad'
                                    />
                                </View>
                            }
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Martedì</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <TouchButton style={[styles.circleButton, { backgroundColor: '#23a925' }]} onPress={() => this.copyHoursToAll(this.state.martediStart, this.state.martediEnd, this.state.martediSecondStart, this.state.martediSecondEnd, this.state.martediOpen, this.state.martediIsSecond)}>
                                        <Feather name='copy' size={21} color='#fff' />
                                    </TouchButton>
                                    {(this.state.martediOpen)
                                        &&
                                        <>
                                        {(this.state.martediIsSecond)
                                            ?
                                            <TouchButton style={[styles.circleButton, { backgroundColor: '#fc3535' }]} onPress={() => this.setState({ martediIsSecond: false })}>
                                                <Feather name='x' size={21} color='#fff' />
                                            </TouchButton>
                                            :
                                            <TouchButton style={styles.circleButton} onPress={() => this.setState({ martediIsSecond: true })}>
                                                <Feather name='plus' size={21} color='#fff' />
                                            </TouchButton>
                                        }
                                        </>
                                    }
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.martediOpen ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ martediOpen: !this.state.martediOpen, martediStart: '', martediEnd: '', martediIsSecond: false, martediSecondStart: '', martediSecondEnd: '' })}
                                        value={this.state.martediOpen}
                                    />
                                </View>
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.martediStart}
                                    onChangeText={(martediStart) => this.setState({ martediStart })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 08:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                    editable={this.state.martediOpen}
                                    keyboardType='number-pad'
                                />
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.martediEnd}
                                    onChangeText={(martediEnd) => this.setState({ martediEnd })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 20:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                    editable={this.state.martediOpen}
                                    keyboardType='number-pad'
                                />
                            </View>
                            {(this.state.martediIsSecond && this.state.martediOpen)
                                &&
                                <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.martediSecondStart}
                                        onChangeText={(martediSecondStart) => this.setState({ martediSecondStart })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 13:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                        keyboardType='number-pad'
                                    />
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.martediSecondEnd}
                                        onChangeText={(martediSecondEnd) => this.setState({ martediSecondEnd })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 14:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                        keyboardType='number-pad'
                                    />
                                </View>
                            }
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Mercoledì</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <TouchButton style={[styles.circleButton, { backgroundColor: '#23a925' }]} onPress={() => this.copyHoursToAll(this.state.mercolediStart, this.state.mercolediEnd, this.state.mercolediSecondStart, this.state.mercolediSecondEnd, this.state.mercolediOpen, this.state.mercolediIsSecond)}>
                                        <Feather name='copy' size={21} color='#fff' />
                                    </TouchButton>
                                    {(this.state.mercolediOpen)
                                        &&
                                        <>
                                        {(this.state.mercolediIsSecond)
                                            ?
                                            <TouchButton style={[styles.circleButton, { backgroundColor: '#fc3535' }]} onPress={() => this.setState({ mercolediIsSecond: false })}>
                                                <Feather name='x' size={21} color='#fff' />
                                            </TouchButton>
                                            :
                                            <TouchButton style={styles.circleButton} onPress={() => this.setState({ mercolediIsSecond: true })}>
                                                <Feather name='plus' size={21} color='#fff' />
                                            </TouchButton>
                                        }
                                        </>
                                    }
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.mercolediOpen ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ mercolediOpen: !this.state.mercolediOpen, mercolediStart: '', mercolediEnd: '', mercolediIsSecond: false, mercolediSecondStart: '', mercolediSecondEnd: '' })}
                                        value={this.state.mercolediOpen}
                                    />
                                </View>
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.mercolediStart}
                                    onChangeText={(mercolediStart) => this.setState({ mercolediStart })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 08:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                    editable={this.state.mercolediOpen}
                                    keyboardType='number-pad'
                                />
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.mercolediEnd}
                                    onChangeText={(mercolediEnd) => this.setState({ mercolediEnd })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 20:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                    editable={this.state.mercolediOpen}
                                    keyboardType='number-pad'
                                />
                            </View>
                            {(this.state.mercolediIsSecond && this.state.mercolediOpen)
                                &&
                                <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.mercolediSecondStart}
                                        onChangeText={(mercolediSecondStart) => this.setState({ mercolediSecondStart })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 13:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                        keyboardType='number-pad'
                                    />
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.mercolediSecondEnd}
                                        onChangeText={(mercolediSecondEnd) => this.setState({ mercolediSecondEnd })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 14:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                        keyboardType='number-pad'
                                    />
                                </View>
                            }
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Giovedì</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <TouchButton style={[styles.circleButton, { backgroundColor: '#23a925' }]} onPress={() => this.copyHoursToAll(this.state.giovediStart, this.state.giovediEnd, this.state.giovediSecondStart, this.state.giovediSecondEnd, this.state.giovediOpen, this.state.giovediIsSecond)}>
                                        <Feather name='copy' size={21} color='#fff' />
                                    </TouchButton>
                                    {(this.state.giovediOpen)
                                        &&
                                        <>
                                        {(this.state.giovediIsSecond)
                                            ?
                                            <TouchButton style={[styles.circleButton, { backgroundColor: '#fc3535' }]} onPress={() => this.setState({ giovediIsSecond: false })}>
                                                <Feather name='x' size={21} color='#fff' />
                                            </TouchButton>
                                            :
                                            <TouchButton style={styles.circleButton} onPress={() => this.setState({ giovediIsSecond: true })}>
                                                <Feather name='plus' size={21} color='#fff' />
                                            </TouchButton>
                                        }
                                        </>
                                    }
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.giovediOpen ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ giovediOpen: !this.state.giovediOpen, giovediStart: '', giovediEnd: '', giovediIsSecond: false, giovediSecondStart: '', giovediSecondEnd: '' })}
                                        value={this.state.giovediOpen}
                                    />
                                </View>
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.giovediStart}
                                    onChangeText={(giovediStart) => this.setState({ giovediStart })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 08:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                    editable={this.state.giovediOpen}
                                    keyboardType='number-pad'
                                />
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.giovediEnd}
                                    onChangeText={(giovediEnd) => this.setState({ giovediEnd })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 20:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                    editable={this.state.giovediOpen}
                                    keyboardType='number-pad'
                                />
                            </View>
                            {(this.state.giovediIsSecond && this.state.giovediOpen)
                                &&
                                <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.giovediSecondStart}
                                        onChangeText={(giovediSecondStart) => this.setState({ giovediSecondStart })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 13:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                        keyboardType='number-pad'
                                    />
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.giovediSecondEnd}
                                        onChangeText={(giovediSecondEnd) => this.setState({ giovediSecondEnd })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 14:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                        keyboardType='number-pad'
                                    />
                                </View>
                            }
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Venerdì</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <TouchButton style={[styles.circleButton, { backgroundColor: '#23a925' }]} onPress={() => this.copyHoursToAll(this.state.venerdiStart, this.state.venerdiEnd, this.state.venerdiSecondStart, this.state.venerdiSecondEnd, this.state.venerdiOpen, this.state.venerdiIsSecond)}>
                                        <Feather name='copy' size={21} color='#fff' />
                                    </TouchButton>
                                    {(this.state.venerdiOpen)
                                        &&
                                        <>
                                        {(this.state.venerdiIsSecond)
                                            ?
                                            <TouchButton style={[styles.circleButton, { backgroundColor: '#fc3535' }]} onPress={() => this.setState({ venerdiIsSecond: false })}>
                                                <Feather name='x' size={21} color='#fff' />
                                            </TouchButton>
                                            :
                                            <TouchButton style={styles.circleButton} onPress={() => this.setState({ venerdiIsSecond: true })}>
                                                <Feather name='plus' size={21} color='#fff' />
                                            </TouchButton>
                                        }
                                        </>
                                    }
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.venerdiOpen ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ venerdiOpen: !this.state.venerdiOpen, venerdiStart: '', venerdiEnd: '', venerdiIsSecond: false, venerdiSecondStart: '', venerdiSecondEnd: '' })}
                                        value={this.state.venerdiOpen}
                                    />
                                </View>
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.venerdiStart}
                                    onChangeText={(venerdiStart) => this.setState({ venerdiStart })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 08:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                    editable={this.state.venerdiOpen}
                                    keyboardType='number-pad'
                                />
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.venerdiEnd}
                                    onChangeText={(venerdiEnd) => this.setState({ venerdiEnd })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 20:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                    editable={this.state.venerdiOpen}
                                    keyboardType='number-pad'
                                />
                            </View>
                            {(this.state.venerdiIsSecond && this.state.venerdiOpen)
                                &&
                                <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.venerdiSecondStart}
                                        onChangeText={(venerdiSecondStart) => this.setState({ venerdiSecondStart })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 13:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                        keyboardType='number-pad'
                                    />
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.venerdiSecondEnd}
                                        onChangeText={(venerdiSecondEnd) => this.setState({ venerdiSecondEnd })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 14:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                        keyboardType='number-pad'
                                    />
                                </View>
                            }
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Sabato</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <TouchButton style={[styles.circleButton, { backgroundColor: '#23a925' }]} onPress={() => this.copyHoursToAll(this.state.sabatoStart, this.state.sabatoEnd, this.state.sabatoSecondStart, this.state.sabatoSecondEnd, this.state.sabatoOpen, this.state.sabatoIsSecond)}>
                                        <Feather name='copy' size={21} color='#fff' />
                                    </TouchButton>
                                    {(this.state.sabatoOpen)
                                        &&
                                        <>
                                        {(this.state.sabatoIsSecond)
                                            ?
                                            <TouchButton style={[styles.circleButton, { backgroundColor: '#fc3535' }]} onPress={() => this.setState({ sabatoIsSecond: false })}>
                                                <Feather name='x' size={21} color='#fff' />
                                            </TouchButton>
                                            :
                                            <TouchButton style={styles.circleButton} onPress={() => this.setState({ sabatoIsSecond: true })}>
                                                <Feather name='plus' size={21} color='#fff' />
                                            </TouchButton>
                                        }
                                        </>
                                    }
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.sabatoOpen ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ sabatoOpen: !this.state.sabatoOpen, sabatoStart: '', sabatoEnd: '', sabatoIsSecond: false, sabatoSecondStart: '', sabatoSecondEnd: '' })}
                                        value={this.state.sabatoOpen}
                                    />
                                </View>
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.sabatoStart}
                                    onChangeText={(sabatoStart) => this.setState({ sabatoStart })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 08:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                    editable={this.state.sabatoOpen}
                                    keyboardType='number-pad'
                                />
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.sabatoEnd}
                                    onChangeText={(sabatoEnd) => this.setState({ sabatoEnd })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 20:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                    editable={this.state.sabatoOpen}
                                    keyboardType='number-pad'
                                />
                            </View>
                            {(this.state.sabatoIsSecond && this.state.sabatoOpen)
                                &&
                                <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.sabatoSecondStart}
                                        onChangeText={(sabatoSecondStart) => this.setState({ sabatoSecondStart })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 13:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                        keyboardType='number-pad'
                                    />
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.sabatoSecondEnd}
                                        onChangeText={(sabatoSecondEnd) => this.setState({ sabatoSecondEnd })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 14:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                        keyboardType='number-pad'
                                    />
                                </View>
                            }
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Domenica</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <TouchButton style={[styles.circleButton, { backgroundColor: '#23a925' }]} onPress={() => this.copyHoursToAll(this.state.domenicaStart, this.state.domenicaEnd, this.state.domenicaSecondStart, this.state.domenicaSecondEnd, this.state.domenicaOpen, this.state.domenicaIsSecond)}>
                                        <Feather name='copy' size={21} color='#fff' />
                                    </TouchButton>
                                    {(this.state.domenicaOpen)
                                        &&
                                        <>
                                        {(this.state.domenicaIsSecond)
                                            ?
                                            <TouchButton style={[styles.circleButton, { backgroundColor: '#fc3535' }]} onPress={() => this.setState({ domenicaIsSecond: false })}>
                                                <Feather name='x' size={21} color='#fff' />
                                            </TouchButton>
                                            :
                                            <TouchButton style={styles.circleButton} onPress={() => this.setState({ domenicaIsSecond: true })}>
                                                <Feather name='plus' size={21} color='#fff' />
                                            </TouchButton>
                                        }
                                        </>
                                    }
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.domenicaOpen ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ domenicaOpen: !this.state.domenicaOpen, domenicaStart: '', domenicaEnd: '', domenicaIsSecond: false, domenicaSecondStart: '', domenicaSecondEnd: '' })}
                                        value={this.state.domenicaOpen}
                                    />
                                </View>
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.domenicaStart}
                                    onChangeText={(domenicaStart) => this.setState({ domenicaStart })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 08:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                    editable={this.state.domenicaOpen}
                                    keyboardType='number-pad'
                                />
                                <MaskedTextInput
                                    mask="99:99"
                                    value={this.state.domenicaEnd}
                                    onChangeText={(domenicaEnd) => this.setState({ domenicaEnd })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Es. 20:00'
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                    editable={this.state.domenicaOpen}
                                    keyboardType='number-pad'
                                />
                            </View>
                            {(this.state.domenicaIsSecond && this.state.domenicaOpen)
                                &&
                                <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.domenicaSecondStart}
                                        onChangeText={(domenicaSecondStart) => this.setState({ domenicaSecondStart })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 13:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                        keyboardType='number-pad'
                                    />
                                    <MaskedTextInput
                                        mask="99:99"
                                        value={this.state.domenicaSecondEnd}
                                        onChangeText={(domenicaSecondEnd) => this.setState({ domenicaSecondEnd })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Es. 14:00'
                                        style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                        keyboardType='number-pad'
                                    />
                                </View>
                            }
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Lunghezza slot (in minuti)</Text>
                            <MaskedTextInput
                                mask="99"
                                value={this.state.lunghezzaSlot}
                                onChangeText={(lunghezzaSlot) => this.setState({ lunghezzaSlot })}
                                placeholderTextColor='#bfbfbf'
                                placeholder='Es. 40'
                                style={styles.input}
                                keyboardType='number-pad'
                            />
                            <View style={{ alignItems: 'center' }}>
                                <TouchButton style={[styles.button, { marginBottom: 20, width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%' }]} onPress={this.handleSaveOrari}>
                                    {(this.state.loadingActive)
                                        ?
                                        <ActivityIndicator color='#fff' size='small' />
                                        :
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva</Text>
                                    }
                                </TouchButton>
                            </View>
                            </>
                        }
                        <TouchButton style={[styles.containerTitle, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20, backgroundColor: (this.state.ferieActive) ? '#8d4472' : '#fff' }]} onPress={() => this.setState({ ferieActive: !this.state.ferieActive, orariActive: false, listFerieActive: false })}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: (this.state.ferieActive) ? '#fff' : '#8d4472' }}>FERIE</Text>
                            {(this.state.ferieActive)
                                ?
                                <Feather name='chevron-up' size={25} color={'#fff'} />
                                :
                                <Feather name='chevron-down' size={25} color={'#8d4472'} />
                            }
                        </TouchButton>
                        {(this.state.ferieActive)
                            &&
                            <>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Inserisci le tue ferie</Text>
                            <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <MaskedTextInput
                                    mask="99/99/9999"
                                    value={this.state.ferieStart}
                                    onChangeText={(ferieStart) => this.setState({ ferieStart })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder={`Es. ${moment(new Date()).format('DD/MM/YYYY')}`}
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30, marginRight: 10 }]}
                                    keyboardType='number-pad'
                                />
                                <MaskedTextInput
                                    mask="99/99/9999"
                                    value={this.state.ferieEnd}
                                    onChangeText={(ferieEnd) => this.setState({ ferieEnd })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder={`Es. ${moment(new Date()).add(1, 'day').format('DD/MM/YYYY')}`}
                                    style={[styles.input, { width: (Dimensions.get('screen').width / 2) - 30 }]}
                                    keyboardType='number-pad'
                                />
                            </View>
                            <View style={{ alignItems: 'center' }}>
                                <TouchButton style={[styles.button, { marginBottom: 20, width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%' }]} onPress={this.handleSendNewFerie}>
                                    {(this.state.loadingFerie)
                                        ?
                                        <ActivityIndicator color='#fff' size='small' />
                                        :
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva</Text>
                                    }
                                </TouchButton>
                            </View>
                            </>
                        }
                        <TouchButton style={[styles.containerTitle, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20, backgroundColor: (this.state.listFerieActive) ? '#8d4472' : '#fff' }]} onPress={() => this.setState({ listFerieActive: !this.state.listFerieActive, orariActive: false, ferieActive: false })}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: (this.state.listFerieActive) ? '#fff' : '#8d4472' }}>FERIE ATTIVE</Text>
                            {(this.state.listFerieActive)
                                ?
                                <Feather name='chevron-up' size={25} color={'#fff'} />
                                :
                                <Feather name='chevron-down' size={25} color={'#8d4472'} />
                            }
                        </TouchButton>
                        {(this.state.listFerieActive)
                            &&
                            <>
                            {(this.state.loadingFerieList)
                                ?
                                <View style={{ paddingBottom: Dimensions.get('screen').height / 4 }}>
                                    <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 800, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'center' }]}>
                                        <ActivityIndicator size='small' color='#0070FF' />
                                    </View>
                                </View>
                                :
                                (this.state.listFerie.length > 0)
                                    ?
                                    this.state.listFerie.map((item, index) => {
                                        return(
                                            <View key={index} style={[styles.containerTitle, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }]}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, color: '#0070FF', marginRight: (Dimensions.get('screen').width <= 500) ? 15 : 30 }}>{index + 1}</Text>
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, color: '#000', marginRight: (Dimensions.get('screen').width <= 500) ? 15 : 45 }}><Text style={{ fontFamily: 'DM-Medium', fontSize: 11, color: '#0070FF' }}>INIZIO</Text>{'\n'}{moment(item.date_start).format('DD/MM/YYYY')}</Text>
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, color: '#000', marginRight: 15 }}><Text style={{ fontFamily: 'DM-Medium', fontSize: 11, color: '#0070FF' }}>FINE</Text>{'\n'}{moment(item.date_end).format('DD/MM/YYYY')}</Text>
                                                </View>
                                                <TouchButton style={[styles.circleButton, { backgroundColor: '#fc3535' }]} onPress={() => this.handleDeleteRequest(item.id, index)}>
                                                    <Feather name='x' size={21} color='#fff' />
                                                </TouchButton>
                                            </View>
                                        )
                                    })
                                    :
                                    <View style={{ paddingBottom: Dimensions.get('screen').height / 4 }}>
                                        <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 800, paddingHorizontal: 20, paddingVertical: 20 }]}>
                                            <Feather name='alert-triangle' color='#fc3535' size={30} />
                                            <View style={{ marginLeft: 15 }}>
                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Non sono presenti ferie</Text>
                                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Aggiungi il tuo primo intervallo di date</Text>
                                            </View>
                                        </View>
                                    </View>
                            }
                            </>
                        }
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: 30,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    inputDate: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerIcon: {
        backgroundColor: '#fff',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        width: 55,
        height: 55,
        alignItems: 'center',
        justifyContent: 'center'
    },
    positionCricle: {
        position: 'absolute',
        bottom: - (Dimensions.get('screen').width + (Dimensions.get('screen').width / 4)),
        right: - (Dimensions.get('screen').width + (Dimensions.get('screen').width / 4)),
        zIndex: -1
    },
    circle: {
        backgroundColor: '#8d4472',
        width: Dimensions.get('screen').width * 2,
        height: Dimensions.get('screen').width * 2,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    containerTitle: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    headImage: {
        paddingBottom: 15,
        paddingHorizontal: 20,
        alignItems: 'center'
    },
    containerImage: {
        width: 100,
        height: 100,
        borderRadius: 15,
        backgroundColor: '#ffffff1c',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 2
    },
    circleButton: {
        backgroundColor: '#0070FF',
        width: 42,
        height: 42,
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
    }
});