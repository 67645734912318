import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { enableScreens, enableFreeze } from 'react-native-screens';
import LoginScreen from '../screens/LoginScreen';
import RegisterScreen from '../screens/RegisterScreen';
import LoadingScreen from '../screens/LoadingScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import PazienteNuovoScreen from '../screens/PazienteNuovoScreen';
import VisitaNuovoScreen from '../screens/VisitaNuovoScreen';
import ProfiloScreen from '../screens/ProfiloScreen';
import EditProfiloScreen from '../screens/EditProfiloScreen';
import EditInformazioniScreen from '../screens/EditInformazioniScreen';
import PazienteModificaScreen from '../screens/PazienteModificaScreen';
import StatisticheScreen from '../screens/StatisticheScreen';
import VisitaModificaScreen from '../screens/VisitaModificaScreen';
import SoobPazientiScreen from '../screens/SoobPazientiScreen';
//import SelectPaymentScreen from '../screens/SelectPaymentScreen';
//import SalePageScreen from '../screens/SalePageScreen';
//import StripePaymentScreen from '../screens/StripePaymentScreen';
//import ThankYouScreen from '../screens/ThankYouScreen';
//import BillingScreen from '../screens/BillingScreen';
//import PayPalPaymentScreen from '../screens/PayPalPaymentScreen';
//import PayPalBillingScreen from '../screens/PayPalBillingScreen';
//import BillingPayPalScreen from '../screens/BillignPayPalScreen';
import MessageScreen from '../screens/MessageScreen';
//import SalePage2Screen from '../screens/subscription/SalePage2Screen';
//import SelectPayment2Screen from '../screens/subscription/SelectPayment2Screen';
//import StripePayment2Screen from '../screens/subscription/StripePayment2Screen';
//import PayPalPayment2Screen from '../screens/subscription/PayPalPayment2Screen';
//import Billing2Screen from '../screens/subscription/Billing2Screen';
//import PayPalBilling2Screen from '../screens/subscription/PayPalBilling2Screen';
//import FatturazioneScreen from '../screens/FatturazioneScreen';
import VisiteUtenteScreen from '../screens/VisiteUtenteScreen';
import UserActiveScreen from '../screens/UserActiveScreen';
import AutomazioniScreen from '../screens/AutomazioniScreen';
import AutomazioniListScreen from '../screens/AutomazioniListScreen';
import AutomazioniCategoryListScreen from '../screens/AutomazioniCategoryListScreen';
import VisiteScreen from '../screens/VisiteScreen';
import LeadModificaScreen from '../screens/LeadModificaScreen';
import PropostaNuovoScreen from '../screens/PropostaNuovoScreen';
import PropostaModificaScreen from '../screens/PropostaModificaScreen';
import CampagneScreen from '../screens/CampagneScreen';
import ImpostazioniScreen from '../screens/ImpostazioniScreen';
import AgendaScreen from '../screens/AgendaScreen';
import StudiScreen from '../screens/StudiScreen';
import OperatoriScreen from '../screens/OperatoriScreen';
import OrariOperatoreScreen from '../screens/OrariOperatoreScreen';
import RichiamiScreen from '../screens/RichiamiScreen';
import AgendaBookScreen from '../screens/AgendaBookScreen';
import AppuntamentiScreen from '../screens/AppuntamentiScreen';
import SelectAutomazioneScreen from '../screens/SelectAutomazioneScreen';
import Tabs from './Tabs';
enableScreens(true);
enableFreeze(true);

export default class Stack extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const Stack = createNativeStackNavigator();
        return(
            <Stack.Navigator initialRouteName='Loading' screenOptions={{ headerShown: false }}>
                <Stack.Screen name="Loading" component={LoadingScreen} />
                <Stack.Screen name="Login" component={LoginScreen} />
                <Stack.Screen name="Register" component={RegisterScreen} />
                <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
                <Stack.Screen name="App" component={Tabs} />
                <Stack.Screen name="PazienteNuovo" component={PazienteNuovoScreen} />
                <Stack.Screen name="VisitaNuovo" component={VisitaNuovoScreen} />
                <Stack.Screen name="Profilo" component={ProfiloScreen} />
                <Stack.Screen name="EditProfilo" component={EditProfiloScreen} />
                <Stack.Screen name="PazienteModifica" component={PazienteModificaScreen} />
                <Stack.Screen name="Statistiche" component={StatisticheScreen} />
                <Stack.Screen name="VisitaModifica" component={VisitaModificaScreen} />
                <Stack.Screen name="SoobPazienti" component={SoobPazientiScreen} />
                <Stack.Screen name="Message" component={MessageScreen} />
                <Stack.Screen name="VisiteUtente" component={VisiteUtenteScreen} />
                <Stack.Screen name="UserActive" component={UserActiveScreen} />
                <Stack.Screen name="Automazioni" component={AutomazioniScreen} />
                <Stack.Screen name="AutomazioniList" component={AutomazioniListScreen} />
                <Stack.Screen name="AutomazioniCategoryList" component={AutomazioniCategoryListScreen} />
                <Stack.Screen name="EditInformazioni" component={EditInformazioniScreen} />
                <Stack.Screen name="Visite" component={VisiteScreen} />
                <Stack.Screen name="LeadModifica" component={LeadModificaScreen} />
                <Stack.Screen name="PropostaNuovo" component={PropostaNuovoScreen} />
                <Stack.Screen name="PropostaModifica" component={PropostaModificaScreen} />
                <Stack.Screen name="Campagne" component={CampagneScreen} />
                <Stack.Screen name="Impostazioni" component={ImpostazioniScreen} />
                <Stack.Screen name="Agenda" component={AgendaScreen} />
                <Stack.Screen name="Studi" component={StudiScreen} />
                <Stack.Screen name="Operatori" component={OperatoriScreen} />
                <Stack.Screen name="OrariOperatore" component={OrariOperatoreScreen} />
                <Stack.Screen name="Richiami" component={RichiamiScreen} />
                <Stack.Screen name="AgendaBook" component={AgendaBookScreen} />
                <Stack.Screen name="Appuntamenti" component={AppuntamentiScreen} />
                <Stack.Screen name="SelectAutomazione" component={SelectAutomazioneScreen} />
            </Stack.Navigator>
        )
    }
}