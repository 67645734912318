import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions, Alert, TouchableOpacity, TextInput, Image } from 'react-native';
import { ActivityIndicator, Switch } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {default as ModalDismiss} from 'expo-react-native-modalbox';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class ImpostazioniScreen extends React.Component {
    constructor(props) {
        super(props)
        this.giornoReminder1 = React.createRef()
        this.giornoReminder2 = React.createRef()
        this.state = {
            item: JSON.parse(this.props.route.params.item),
            loading: false,
            activeAppointment: true,
            messaggioAppuntamento: '',
            appuntamentoTitle: '',
            cursorAppuntamento: 0,
            activeReminder1: true,
            messaggioReminder1: '',
            reminder1Title: '',
            cursorReminder1: 0,
            giornoReminder1: '',
            checkReminder1: false,
            activeReminder2: true,
            messaggioReminder2: '',
            reminder2Title: '',
            cursorReminder2: 0,
            giornoReminder2: '',
            checkReminder2: false,
            activeFollowup1: true,
            messaggioFollowup1: '',
            followup1Title: '',
            cursorFollowup1: 0,
            giornoFollowup1: '',
            activeFollowup2: true,
            messaggioFollowup2: '',
            followup2Title: '',
            cursorFollowup2: 0,
            giornoFollowup2: '',
            activeFollowup3: true,
            messaggioFollowup3: '',
            followup3Title: '',
            cursorFollowup3: 0,
            giornoFollowup3: '',
            activeFollowup4: true,
            messaggioFollowup4: '',
            followup4Title: '',
            cursorFollowup4: 0,
            giornoFollowup4: '',
            showModal: false,
            inputModal: '',
            modalType: 0,
            defaultMessages: {}
        }
    }

    componentDidMount() {
        this.getDataSettings()
    }
    
    getDataSettings = async () => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_automazione', this.state.item.id);
        axios.post('https://www.visitreporter.com/app/get_impostazioni_automazioni.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            let formDataDefault = new FormData();
            formDataDefault.append('token', token);
            formDataDefault.append('id_automazione', this.state.item.id);
            axios.post('https://www.visitreporter.com/app/get_default_messages.php', formDataDefault, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (responsedefault) => {
                const jsonDefault = responsedefault.data;
                this.setState({
                    activeAppointment: json.active_appuntamento,
                    messaggioAppuntamento: (json.message_appuntamento !== null && json.message_appuntamento !== "") ? json.message_appuntamento : jsonDefault.message_appuntamento,
                    appuntamentoTitle: (json.appuntamento_title !== null && json.appuntamento_title !== "") ? json.appuntamento_title : jsonDefault.appuntamento_title,
                    activeReminder1: json.active_reminder_1,
                    messaggioReminder1: (json.message_reminder_1 !== null && json.message_reminder_1 !== "") ? json.message_reminder_1 : jsonDefault.message_reminder_1,
                    reminder1Title: (json.reminder_1_title !== null && json.reminder_1_title !== "") ? json.reminder_1_title : jsonDefault.reminder_1_title,
                    giornoReminder1: (json.giorno_reminder_1 !== null && json.giorno_reminder_1 !== "") ? json.giorno_reminder_1 : jsonDefault.giorno_reminder_1,
                    activeReminder2: json.active_reminder_2,
                    messaggioReminder2: (json.message_reminder_2 !== null && json.message_reminder_2 !== "") ? json.message_reminder_2 : jsonDefault.message_reminder_2,
                    reminder2Title: (json.reminder_2_title !== null && json.reminder_2_title !== "") ? json.reminder_2_title : jsonDefault.reminder_2_title,
                    giornoReminder2: (json.giorno_reminder_2 !== null && json.giorno_reminder_2 !== "") ? json.giorno_reminder_2 : jsonDefault.giorno_reminder_2,
                    activeFollowup1: json.active_followup_1,
                    messaggioFollowup1: (json.message_followup_1 !== null && json.message_followup_1 !== "") ? json.message_followup_1 : jsonDefault.message_followup_1,
                    followup1Title: (json.followup_1_title !== null && json.followup_1_title !== "") ? json.followup_1_title : jsonDefault.followup_1_title,
                    giornoFollowup1: (json.giorno_followup_1 !== null && json.giorno_followup_1 !== "") ? json.giorno_followup_1 : jsonDefault.giorno_followup_1,
                    activeFollowup2: json.active_followup_2,
                    messaggioFollowup2: (json.message_followup_2 !== null && json.message_followup_2 !== "") ? json.message_followup_2 : jsonDefault.message_followup_2,
                    followup2Title: (json.followup_2_title !== null && json.followup_2_title !== "") ? json.followup_2_title : jsonDefault.followup_2_title,
                    giornoFollowup2: (json.giorno_followup_2 !== null && json.giorno_followup_2 !== "") ? json.giorno_followup_2 : jsonDefault.giorno_followup_2,
                    activeFollowup3: json.active_followup_3,
                    messaggioFollowup3: (json.message_followup_3 !== null && json.message_followup_3 !== "") ? json.message_followup_3 : jsonDefault.message_followup_3,
                    followup3Title: (json.followup_3_title !== null && json.followup_3_title !== "") ? json.followup_3_title : jsonDefault.followup_3_title,
                    giornoFollowup3: (json.giorno_followup_3 !== null && json.giorno_followup_3 !== "") ? json.giorno_followup_3 : jsonDefault.giorno_followup_3,
                    activeFollowup4: json.active_followup_4,
                    messaggioFollowup4: (json.message_followup_4 !== null && json.message_followup_4 !== "") ? json.message_followup_4 : jsonDefault.message_followup_4,
                    followup4Title: (json.followup_4_title !== null && json.followup_4_title !== "") ? json.followup_4_title : jsonDefault.followup_4_title,
                    giornoFollowup4: (json.giorno_followup_4 !== null && json.giorno_followup_4 !== "") ? json.giorno_followup_4 : jsonDefault.giorno_followup_4,
                    defaultMessages: jsonDefault
                })
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        })
    }

    updateDataSettings = async () => {
        this.setState({ loading: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_automazione', this.state.item.id);
        formData.append('active_appuntamento', (this.state.activeAppointment == true) ? 1 : 0);
        formData.append('message_appuntamento', this.state.messaggioAppuntamento);
        formData.append('appuntamento_title', this.state.appuntamentoTitle);
        formData.append('active_reminder_1', (this.state.activeReminder1 == true) ? 1 : 0);
        formData.append('message_reminder_1', this.state.messaggioReminder1);
        formData.append('reminder_1_title', this.state.reminder1Title);
        formData.append('giorno_reminder_1', this.state.giornoReminder1);
        formData.append('active_reminder_2', (this.state.activeReminder2 == true) ? 1 : 0);
        formData.append('message_reminder_2', this.state.messaggioReminder2);
        formData.append('reminder_2_title', this.state.reminder2Title);
        formData.append('giorno_reminder_2', this.state.giornoReminder2);
        formData.append('active_followup_1', (this.state.activeFollowup1 == true) ? 1 : 0);
        formData.append('message_followup_1', this.state.messaggioFollowup1);
        formData.append('followup_1_title', this.state.followup1Title);
        formData.append('giorno_followup_1', this.state.giornoFollowup1);
        formData.append('active_followup_2', (this.state.activeFollowup2 == true) ? 1 : 0);
        formData.append('message_followup_2', this.state.messaggioFollowup2);
        formData.append('followup_2_title', this.state.followup2Title);
        formData.append('giorno_followup_2', this.state.giornoFollowup2);
        formData.append('active_followup_3', (this.state.activeFollowup3 == true) ? 1 : 0);
        formData.append('message_followup_3', this.state.messaggioFollowup3);
        formData.append('followup_3_title', this.state.followup3Title);
        formData.append('giorno_followup_3', this.state.giornoFollowup3);
        formData.append('active_followup_4', (this.state.activeFollowup4 == true) ? 1 : 0);
        formData.append('message_followup_4', this.state.messaggioFollowup4);
        formData.append('followup_4_title', this.state.followup4Title);
        formData.append('giorno_followup_4', this.state.giornoFollowup4);
        axios.post('https://www.visitreporter.com/app/update_impostazioni_automazioni.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ loading: false })
                this.props.navigation.goBack()
            } else {
                this.setState({ loading: false })
                Alert.alert(
                    "Attenzione!",
                    "Qualcosa è andato storto. Riprova più tardi",
                    [
                        { text: "Ok" }
                    ]
                )
            }
        }).catch((error) => {
            this.setState({ loading: false })
            Alert.alert(
                "Attenzione!",
                "Qualcosa è andato storto. Riprova più tardi",
                [
                    { text: "Ok" }
                ]
            )
            console.error(error);
        })
    }

    changeGiornoReminder1 = () => {
        if (this.state.giornoReminder2 !== null) {
            const rem1 = parseInt(this.state.giornoReminder1)
            const rem2 = parseInt(this.state.giornoReminder2)
            let sottrazione = 0;
            if (rem1 > rem2) {
                sottrazione = rem1 - rem2;
            } else {
                sottrazione = rem2 - rem1;
            }
            if (rem1 == rem2) {
                Alert.alert(
                    "Attenzione!",
                    "Selezionare i giorni dei Remainder con almeno 9 giorni di ditanza",
                    [
                        { text: 'Ok', onPress: () => this.setState({ giornoReminder1: null }) }
                    ]
                )
            } else if (sottrazione < 9) {
                Alert.alert(
                    "Attenzione!",
                    "Selezionare i giorni dei Remainder con almeno 9 giorni di ditanza",
                    [
                        { text: 'Ok', onPress: () => this.setState({ giornoReminder1: null }) }
                    ]
                )
            }
        }
    }

    changeGiornoReminder2 = () => {
        if (this.state.giornoReminder1 !== null) {
            const rem2 = parseInt(this.state.giornoReminder2)
            const rem1 = parseInt(this.state.giornoReminder1)
            let sottrazione = 0;
            if (rem2 > rem1) {
                sottrazione = rem2 - rem1;
            } else {
                sottrazione = rem1 - rem2;
            }
            if (rem2 == rem1) {
                Alert.alert(
                    "Attenzione!",
                    "Selezionare i giorni dei Remainder con almeno 9 giorni di ditanza",
                    [
                        { text: 'Ok', onPress: () => this.setState({ giornoReminder2: null }) }
                    ]
                )
            } else if (sottrazione < 9) {
                Alert.alert(
                    "Attenzione!",
                    "Selezionare i giorni dei Remainder con almeno 9 giorni di ditanza",
                    [
                        { text: 'Ok', onPress: () => this.setState({ giornoReminder2: null }) }
                    ]
                )
            }
        }
    }

    handleCursorAppuntamento = (event) => {
        this.setState({ cursorAppuntamento: event.nativeEvent.selection.start })
    }

    handleCursorReminder1 = (event) => {
        this.setState({ cursorReminder1: event.nativeEvent.selection.start })
    }

    handleCursorReminder2 = (event) => {
        this.setState({ cursorReminder2: event.nativeEvent.selection.start })
    }

    handleCursorFollowup1 = (event) => {
        this.setState({ cursorFollowup1: event.nativeEvent.selection.start })
    }

    handleCursorFollowup2 = (event) => {
        this.setState({ cursorFollowup2: event.nativeEvent.selection.start })
    }

    handleCursorFollowup3 = (event) => {
        this.setState({ cursorFollowup3: event.nativeEvent.selection.start })
    }

    handleCursorFollowup4 = (event) => {
        this.setState({ cursorFollowup4: event.nativeEvent.selection.start })
    }

    handleButtonClick = (type, text) => {
        if (type == 0) {
            const newText = this.state.messaggioAppuntamento.slice(0, this.state.cursorAppuntamento) + text + this.state.messaggioAppuntamento.slice(this.state.cursorAppuntamento);
            this.setState({ messaggioAppuntamento: newText, cursorAppuntamento: this.state.cursorAppuntamento + text.length })
        } else if (type == 1) {
            const newText = this.state.messaggioReminder1.slice(0, this.state.cursorReminder1) + text + this.state.messaggioReminder1.slice(this.state.cursorReminder1);
            this.setState({ messaggioReminder1: newText, cursorReminder1: this.state.cursorReminder1 + text.length })
        } else if (type == 2) {
            const newText = this.state.messaggioReminder2.slice(0, this.state.cursorReminder2) + text + this.state.messaggioReminder2.slice(this.state.cursorReminder2);
            this.setState({ messaggioReminder2: newText, cursorReminder2: this.state.cursorReminder2 + text.length })
        } else if (type == 3) {
            const newText = this.state.messaggioFollowup1.slice(0, this.state.cursorFollowup1) + text + this.state.messaggioFollowup1.slice(this.state.cursorFollowup1);
            this.setState({ messaggioFollowup1: newText, cursorFollowup1: this.state.cursorFollowup1 + text.length })
        } else if (type == 4) {
            const newText = this.state.messaggioFollowup2.slice(0, this.state.cursorFollowup2) + text + this.state.messaggioFollowup2.slice(this.state.cursorFollowup2);
            this.setState({ messaggioFollowup2: newText, cursorFollowup2: this.state.cursorFollowup2 + text.length })
        } else if (type == 5) {
            const newText = this.state.messaggioFollowup3.slice(0, this.state.cursorFollowup3) + text + this.state.messaggioFollowup3.slice(this.state.cursorFollowup3);
            this.setState({ messaggioFollowup3: newText, cursorFollowup3: this.state.cursorFollowup3 + text.length })
        } else if (type == 6) {
            const newText = this.state.messaggioFollowup4.slice(0, this.state.cursorFollowup4) + text + this.state.messaggioFollowup4.slice(this.state.cursorFollowup4);
            this.setState({ messaggioFollowup4: newText, cursorFollowup4: this.state.cursorFollowup4 + text.length })
        }
    }
    
    saveModalTitle = () => {
        if (this.state.inputModal !== '') {
            const type = this.state.modalType
            switch (type) {
                case 0:
                    this.handleButtonClick(type, '%TITLE%')
                    this.setState({ appuntamentoTitle: this.state.inputModal, showModal: false, inputModal: '', modalType: 0 })
                    break;
                case 1:
                    this.handleButtonClick(type, '%TITLE%')
                    this.setState({ reminder1Title: this.state.inputModal, showModal: false, inputModal: '', modalType: 0 })
                    break;
                case 2:
                    this.handleButtonClick(type, '%TITLE%')
                    this.setState({ reminder2Title: this.state.inputModal, showModal: false, inputModal: '', modalType: 0 })
                    break;
                case 3:
                    this.handleButtonClick(type, '%TITLE%')
                    this.setState({ followup1Title: this.state.inputModal, showModal: false, inputModal: '', modalType: 0 })
                    break;
                case 4:
                    this.handleButtonClick(type, '%TITLE%')
                    this.setState({ followup2Title: this.state.inputModal, showModal: false, inputModal: '', modalType: 0 })
                    break;
                case 5:
                    this.handleButtonClick(type, '%TITLE%')
                    this.setState({ followup3Title: this.state.inputModal, showModal: false, inputModal: '', modalType: 0 })
                    break;
                case 6:
                    this.handleButtonClick(type, '%TITLE%')
                    this.setState({ followup4Title: this.state.inputModal, showModal: false, inputModal: '', modalType: 0 })
                    break;
                default:
                    break;
            }
        } else {
            Alert.alert(
                "Attenzione!",
                "Per continuare inserici un titolo",
                [
                    { text: 'Ok' }
                ]
            )
        }
    }

    resetMessages = (type) => {
        if (this.state.defaultMessages !== null) {
            switch (type) {
                case 1:
                    this.setState({
                        activeAppointment: true,
                        messaggioAppuntamento: this.state.defaultMessages.message_appuntamento,
                        appuntamentoTitle: this.state.defaultMessages.appuntamento_title
                    })
                    break;
                case 2:
                    this.setState({
                        activeReminder1: true,
                        messaggioReminder1: this.state.defaultMessages.message_reminder_1,
                        giornoReminder1: this.state.defaultMessages.giorno_reminder_1,
                        reminder1Title: this.state.defaultMessages.reminder_1_title
                    })
                    break;
                case 3:
                    this.setState({
                        activeReminder2: true,
                        messaggioReminder2: this.state.defaultMessages.message_reminder_2,
                        giornoReminder2: this.state.defaultMessages.giorno_reminder_2,
                        reminder2Title: this.state.defaultMessages.reminder_2_title
                    })
                    break;
                case 4:
                    this.setState({
                        activeFollowup1: true,
                        messaggioFollowup1: this.state.defaultMessages.message_followup_1,
                        giornoFollowup1: this.state.defaultMessages.giorno_followup_1,
                        followup1Title: this.state.defaultMessages.followup_1_title
                    })
                    break;
                case 5:
                    this.setState({
                        activeFollowup2: true,
                        messaggioFollowup2: this.state.defaultMessages.message_followup_2,
                        giornoFollowup2: this.state.defaultMessages.giorno_followup_2,
                        followup2Title: this.state.defaultMessages.followup_2_title
                    })
                    break;
                case 6:
                    this.setState({
                        activeFollowup3: true,
                        messaggioFollowup3: this.state.defaultMessages.message_followup_3,
                        giornoFollowup3: this.state.defaultMessages.giorno_followup_3,
                        followup3Title: this.state.defaultMessages.followup_3_title
                    })
                    break;
                case 7:
                    this.setState({
                        activeFollowup4: true,
                        messaggioFollowup4: this.state.defaultMessages.message_followup_4,
                        giornoFollowup4: this.state.defaultMessages.giorno_followup_4,
                        followup4Title: this.state.defaultMessages.followup_4_title
                    })
                    break;
                default:
                    break;
            }
        } else {
            Alert.alert(
                "Attenzione!",
                "C'è stato un problema con il reset dei messaggi. Riprova più tardi",
                [
                    { text: 'Ok' }
                ]
            )
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Impostazioni</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <View style={{ alignItems: 'center', marginBottom: 15 }}>
                            <View style={styles.containerLogo}>
                                <Image source={require('../assets/impostazioni.png')} resizeMode='contain' style={{ width: 70, height: 70 }} />
                            </View>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 19 }}>Impostazioni Automazioni</Text>
                        </View>
                        <View style={{ alignItems: 'center' }}>
                            <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 60 : Dimensions.get('screen').width - 600, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between' }]}>
                                <View>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{this.state.item.nome}</Text>
                                    {(this.state.item.descrizione != '' && this.state.item.descrizione != null)
                                        &&
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>{this.state.item.descrizione}</Text>
                                    }
                                </View>
                            </View>
                        </View>
                        {(this.state.defaultMessages !== null && this.state.defaultMessages.active_appuntamento == true)
                            &&
                            <>
                            <View style={{ marginBottom: 20, marginTop: 20 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '90%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Automazioni appuntamento</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Inserisci valori dinamici per il messaggio</Text>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginTop: 10, marginBottom: 5, width: '120%' }}>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(0, '%NOME%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%NOME%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(0, '%STUDIO%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%STUDIO%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(0, '%DATA%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%DATA%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.setState({ inputModal: this.state.appuntamentoTitle, modalType: 0, showModal: true })}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%TITLE%'}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <Switch color={'#0070FF'} value={this.state.activeAppointment} onValueChange={() => this.setState({ activeAppointment: !this.state.activeAppointment })} />
                                </View>
                                <TextInput
                                    style={[styles.input, { height: 150 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Messaggio appuntamento'
                                    value={this.state.messaggioAppuntamento}
                                    onChangeText={(messaggioAppuntamento) => this.setState({ messaggioAppuntamento })}
                                    onSelectionChange={this.handleCursorAppuntamento}
                                    multiline={true}
                                    numberOfLines={3}
                                    autoCapitalize='none'
                                />
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity style={[styles.buttonTag, { backgroundColor: '#0070FF' }]} onPress={() => this.resetMessages(1)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 14, color: '#fff' }}>RESETTA</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={styles.lineView}></View>
                            </>
                        }
                        {(this.state.defaultMessages !== null && this.state.defaultMessages.active_reminder_1 == true)
                            &&
                            <>
                            <View style={{ marginBottom: 5 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '90%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Primo Reminder</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Inserisci valori dinamici per il messaggio</Text>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginTop: 10, marginBottom: 5, width: '120%' }}>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(1, '%NOME%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%NOME%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(1, '%STUDIO%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%STUDIO%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(1, '%DATA%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%DATA%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.setState({ inputModal: this.state.reminder1Title, modalType: 1, showModal: true })}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%TITLE%'}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <Switch color={'#0070FF'} value={this.state.activeReminder1} onValueChange={() => this.setState({ activeReminder1: !this.state.activeReminder1 })} />
                                </View>
                                <TextInput
                                    style={[styles.input, { height: 150 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Messaggio reminder'
                                    value={this.state.messaggioReminder1}
                                    onChangeText={(messaggioReminder1) => this.setState({ messaggioReminder1 })}
                                    onSelectionChange={this.handleCursorReminder1}
                                    multiline={true}
                                    numberOfLines={3}
                                    autoCapitalize='none'
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Giorni prima dell'appuntamento</Text>
                                <View>
                                    <TextInput
                                        ref={this.giornoReminder1}
                                        style={styles.input}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Inserire il numero di giorni'
                                        value={this.state.giornoReminder1}
                                        onChangeText={(giornoReminder1) => this.setState({ giornoReminder1: giornoReminder1.replace(/[^0-9]/g, '') })}
                                        keyboardType='numeric'
                                        onFocus={() => this.setState({ checkReminder1: true })}
                                        onBlur={() => { this.setState({ checkReminder1: false }); this.changeGiornoReminder1() }}
                                    />
                                    {(this.state.checkReminder1)
                                        &&
                                        <View style={styles.containerCheck}>
                                            <TouchableOpacity style={styles.buttonCheck} onPress={() => this.giornoReminder1.current.blur()}>
                                                <Feather name='check' size={25} color={'#000'} />
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity style={[styles.buttonTag, { backgroundColor: '#0070FF' }]} onPress={() => this.resetMessages(2)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 14, color: '#fff' }}>RESETTA</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={styles.lineView}></View>
                            </>
                        }
                        {(this.state.defaultMessages !== null && this.state.defaultMessages.active_reminder_2 == true)
                            &&
                            <>
                            <View style={{ marginBottom: 5 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '90%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Secondo Reminder</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Inserisci valori dinamici per il messaggio</Text>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginTop: 10, marginBottom: 5, width: '120%' }}>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(2, '%NOME%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%NOME%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(2, '%STUDIO%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%STUDIO%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(2, '%DATA%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%DATA%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.setState({ inputModal: this.state.reminder2Title, modalType: 2, showModal: true })}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%TITLE%'}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <Switch color={'#0070FF'} value={this.state.activeReminder2} onValueChange={() => this.setState({ activeReminder2: !this.state.activeReminder2 })} />
                                </View>
                                <TextInput
                                    style={[styles.input, { height: 150 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Messaggio reminder'
                                    value={this.state.messaggioReminder2}
                                    onChangeText={(messaggioReminder2) => this.setState({ messaggioReminder2 })}
                                    onSelectionChange={this.handleCursorReminder2}
                                    multiline={true}
                                    numberOfLines={3}
                                    autoCapitalize='none'
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Giorni prima dell'appuntamento</Text>
                                <View>
                                    <TextInput
                                        ref={this.giornoReminder2}
                                        style={styles.input}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Inserire il numero di giorni'
                                        value={this.state.giornoReminder2}
                                        onChangeText={(giornoReminder2) => this.setState({ giornoReminder2: giornoReminder2.replace(/[^0-9]/g, '') })}
                                        keyboardType='numeric'
                                        onFocus={() => this.setState({ checkReminder2: true })}
                                        onBlur={() => { this.setState({ checkReminder2: false }); this.changeGiornoReminder2() }}
                                    />
                                    {(this.state.checkReminder2)
                                        &&
                                        <View style={styles.containerCheck}>
                                            <TouchableOpacity style={styles.buttonCheck} onPress={() => this.giornoReminder2.current.blur()}>
                                                <Feather name='check' size={25} color={'#000'} />
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity style={[styles.buttonTag, { backgroundColor: '#0070FF' }]} onPress={() => this.resetMessages(3)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 14, color: '#fff' }}>RESETTA</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={styles.lineView}></View>
                            </>
                        }
                        {(this.state.defaultMessages !== null && this.state.defaultMessages.active_followup_1 == true)
                            &&
                            <>
                            <View style={{ marginBottom: 5 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '90%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Primo FollowUp</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Inserisci valori dinamici per il messaggio</Text>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginTop: 10, marginBottom: 5, width: '120%' }}>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(3, '%NOME%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%NOME%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(3, '%STUDIO%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%STUDIO%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(3, '%DATA%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%DATA%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.setState({ inputModal: this.state.followup1Title, modalType: 3, showModal: true })}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%TITLE%'}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <Switch color={'#0070FF'} value={this.state.activeFollowup1} onValueChange={() => this.setState({ activeFollowup1: !this.state.activeFollowup1 })} />
                                </View>
                                <TextInput
                                    style={[styles.input, { height: 150 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Messaggio followup'
                                    value={this.state.messaggioFollowup1}
                                    onChangeText={(messaggioFollowup1) => this.setState({ messaggioFollowup1 })}
                                    onSelectionChange={this.handleCursorFollowup1}
                                    multiline={true}
                                    numberOfLines={3}
                                    autoCapitalize='none'
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>N. ore dopo l'appuntamento</Text>
                                <TextInput
                                    style={styles.input}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Inserire il numero delle ore'
                                    value={this.state.giornoFollowup1}
                                    onChangeText={(giornoFollowup1) => this.setState({ giornoFollowup1: giornoFollowup1.replace(/[^0-9]/g, '') })}
                                    keyboardType='numeric'
                                />
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity style={[styles.buttonTag, { backgroundColor: '#0070FF' }]} onPress={() => this.resetMessages(4)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 14, color: '#fff' }}>RESETTA</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={styles.lineView}></View>
                            </>
                        }
                        {(this.state.defaultMessages !== null && this.state.defaultMessages.active_followup_2 == true)
                            &&
                            <>
                            <View style={{ marginBottom: 5 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '90%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Secondo FollowUp</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Inserisci valori dinamici per il messaggio</Text>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginTop: 10, marginBottom: 5, width: '120%' }}>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(4, '%NOME%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%NOME%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(4, '%STUDIO%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%STUDIO%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(4, '%DATA%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%DATA%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.setState({ inputModal: this.state.followup2Title, modalType: 4, showModal: true })}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%TITLE%'}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <Switch color={'#0070FF'} value={this.state.activeFollowup2} onValueChange={() => this.setState({ activeFollowup2: !this.state.activeFollowup2 })} />
                                </View>
                                <TextInput
                                    style={[styles.input, { height: 150 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Messaggio followup'
                                    value={this.state.messaggioFollowup2}
                                    onChangeText={(messaggioFollowup2) => this.setState({ messaggioFollowup2 })}
                                    onSelectionChange={this.handleCursorFollowup2}
                                    multiline={true}
                                    numberOfLines={3}
                                    autoCapitalize='none'
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>N. ore dopo l'appuntamento</Text>
                                <TextInput
                                    style={styles.input}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Inserire il numero delle ore'
                                    value={this.state.giornoFollowup2}
                                    onChangeText={(giornoFollowup2) => this.setState({ giornoFollowup2: giornoFollowup2.replace(/[^0-9]/g, '') })}
                                    keyboardType='numeric'
                                />
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity style={[styles.buttonTag, { backgroundColor: '#0070FF' }]} onPress={() => this.resetMessages(5)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 14, color: '#fff' }}>RESETTA</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={styles.lineView}></View>
                            </>
                        }
                        {(this.state.defaultMessages !== null && this.state.defaultMessages.active_followup_3 == true)
                            &&
                            <>
                            <View style={{ marginBottom: 5 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '90%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Terzo FollowUp</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Inserisci valori dinamici per il messaggio</Text>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginTop: 10, marginBottom: 5, width: '120%' }}>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(5, '%NOME%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%NOME%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(5, '%STUDIO%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%STUDIO%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(5, '%DATA%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%DATA%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.setState({ inputModal: this.state.followup3Title, modalType: 5, showModal: true })}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%TITLE%'}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <Switch color={'#0070FF'} value={this.state.activeFollowup3} onValueChange={() => this.setState({ activeFollowup3: !this.state.activeFollowup3 })} />
                                </View>
                                <TextInput
                                    style={[styles.input, { height: 150 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Messaggio followup'
                                    value={this.state.messaggioFollowup3}
                                    onChangeText={(messaggioFollowup3) => this.setState({ messaggioFollowup3 })}
                                    onSelectionChange={this.handleCursorFollowup3}
                                    multiline={true}
                                    numberOfLines={3}
                                    autoCapitalize='none'
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>N. ore dopo l'appuntamento</Text>
                                <TextInput
                                    style={styles.input}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Inserire il numero delle ore'
                                    value={this.state.giornoFollowup3}
                                    onChangeText={(giornoFollowup3) => this.setState({ giornoFollowup3: giornoFollowup3.replace(/[^0-9]/g, '') })}
                                    keyboardType='numeric'
                                />
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity style={[styles.buttonTag, { backgroundColor: '#0070FF' }]} onPress={() => this.resetMessages(6)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 14, color: '#fff' }}>RESETTA</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={styles.lineView}></View>
                            </>
                        }
                        {(this.state.defaultMessages !== null && this.state.defaultMessages.active_followup_4 == true)
                            &&
                            <>
                            <View style={{ marginBottom: 5 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <View style={{ width: '90%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Quarto FollowUp</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Inserisci valori dinamici per il messaggio</Text>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginTop: 10, marginBottom: 5, width: '120%' }}>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(6, '%NOME%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%NOME%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(6, '%STUDIO%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%STUDIO%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.handleButtonClick(6, '%DATA%')}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%DATA%'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={styles.buttonTag} onPress={() => this.setState({ inputModal: this.state.followup4Title, modalType: 6, showModal: true })}>
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>{'%TITLE%'}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <Switch color={'#0070FF'} value={this.state.activeFollowup4} onValueChange={() => this.setState({ activeFollowup4: !this.state.activeFollowup4 })} />
                                </View>
                                <TextInput
                                    style={[styles.input, { height: 150 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Messaggio followup'
                                    value={this.state.messaggioFollowup4}
                                    onChangeText={(messaggioFollowup4) => this.setState({ messaggioFollowup4 })}
                                    onSelectionChange={this.handleCursorFollowup4}
                                    multiline={true}
                                    numberOfLines={3}
                                    autoCapitalize='none'
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>N. ore dopo l'appuntamento</Text>
                                <TextInput
                                    style={styles.input}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Inserire il numero delle ore'
                                    value={this.state.giornoFollowup4}
                                    onChangeText={(giornoFollowup4) => this.setState({ giornoFollowup4: giornoFollowup4.replace(/[^0-9]/g, '') })}
                                    keyboardType='numeric'
                                />
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity style={[styles.buttonTag, { backgroundColor: '#0070FF' }]} onPress={() => this.resetMessages(7)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 14, color: '#fff' }}>RESETTA</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            </>
                        }
                        {(this.state.defaultMessages !== null && this.state.defaultMessages.list == true)
                            &&
                            <View style={{ alignItems: 'center' }}>
                                <TouchableOpacity style={styles.button} onPress={this.updateDataSettings}>
                                    {(this.state.loading)
                                        ?
                                        <ActivityIndicator color='#fff' size='small' />
                                        :
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva e continua</Text>
                                    }
                                </TouchableOpacity>
                            </View>
                        }
                    </View>
                </ScrollView>
                <ModalDismiss style={styles.modal} backdropOpacity={0.3} swipeToClose={true} backButtonClose={true} isOpen={this.state.showModal} onClosed={() => this.setState({showModal: false})} position={"center"} ref={"modal"}>
                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, textAlign: 'center', marginBottom: 20 }}>Impostazione title</Text>
                    <TextInput
                        style={[styles.input, { marginBottom: 0 }]}
                        placeholderTextColor='#bfbfbf'
                        placeholder='Inserisci il titolo'
                        value={this.state.inputModal}
                        onChangeText={(inputModal) => this.setState({ inputModal })}
                        maxLength={200}
                    />
                    <TouchableOpacity style={styles.button} onPress={this.saveModalTitle}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Inserisci</Text>
                    </TouchableOpacity>
                </ModalDismiss>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 150,
        marginTop: 25,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerLogo: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems:'center',
        width: 85,
        height: 85,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 10
    },
    lineView: {
        backgroundColor: 'lightgrey',
        height: 2,
        borderRadius: 10,
        marginBottom: 30
    },
    buttonTag: {
        backgroundColor: 'lightgrey',
        paddingVertical: 4.5,
        paddingHorizontal: 10,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 7,
        marginBottom: 10
    },
    containerCheck: {
        position: 'absolute',
        height: 55,
        top: 0,
        right: 0,
        bottom: 0,
        marginRight: 10,
        zIndex: 2,
        justifyContent: 'center'
    },
    buttonCheck: {
        backgroundColor: 'lightgrey',
        width: 35,
        height: 35,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modal: {
        width: Dimensions.get('screen').width - 40,
        height: 'auto',
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
        paddingHorizontal: 20,
        paddingVertical: 35,
        justifyContent: 'center'
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
});