import React from 'react';
import { StyleSheet, Text, View, StatusBar, Alert, Platform } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Crypto from 'expo-crypto';
import Moment from 'moment';
import 'moment/locale/it';
import * as Application from 'expo-application';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class LoadingScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    async componentDidMount() {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {
            const email = await AsyncStorage.getItem('email')
            const password = await AsyncStorage.getItem('password')
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('email', email);
            formData.append('password', password);
            axios.post('https://www.visitreporter.com/app/login.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    await AsyncStorage.setItem('id', json.id)
                    await AsyncStorage.setItem('nome', json.nome)
                    await AsyncStorage.setItem('cognome', json.cognome)
                    //await AsyncStorage.setItem('data_scadenza', json.data_scadenza)
                    await AsyncStorage.setItem('email', json.email)
                    await AsyncStorage.setItem('telefono', json.telefono)
                    await AsyncStorage.setItem('country_code', json.country_code)
                    /*await AsyncStorage.setItem('data_inizio', json.data_inizio)
                    await AsyncStorage.setItem('is_prova', json.is_prova)
                    await AsyncStorage.setItem('periodo_prova', json.periodo_prova)
                    await AsyncStorage.setItem('abbonamento_attivo', json.abbonamento_attivo)*/
                    if (json.image != null && json.image != '') {
                        await AsyncStorage.setItem('image', json.image)
                    }
                    const emailTest = json.email.split('@')[1]
                    if (emailTest == 'icloud.com' || json.id == 16 || json.id == '16') {
                        this.props.navigation.replace('App')
                    } else {
                        if (json.via != null && json.via != '') {
                            await AsyncStorage.setItem('via', json.via)
                        }
                        if (json.citta != null && json.citta != '') {
                            await AsyncStorage.setItem('citta', json.citta)
                        }
                        if (json.piva != null && json.piva != '') {
                            await AsyncStorage.setItem('piva', json.piva)
                        }
                        if (json.ragione_sociale != null && json.ragione_sociale != '') {
                            await AsyncStorage.setItem('ragione_sociale', json.ragione_sociale)
                        }
                        if (json.codice_univoco != null && json.codice_univoco != '' && json.nome_studio != null && json.nome_studio != '') {
                            await AsyncStorage.setItem('codice_univoco', json.codice_univoco)
                            await AsyncStorage.setItem('nome_studio', json.nome_studio)
                            
                            this.props.navigation.replace('App')
                        } else {
                            this.props.navigation.replace('App')
                        }
                    }
                } else {
                    this.props.navigation.replace('Login')
                }
            }).catch((error) => {
                console.error(error);
            })
        } else {
            this.props.navigation.replace('Login')
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.button}>
                    <ActivityIndicator color='#fff' size='large' />
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#0070FF',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        backgroundColor: '#2484FF',
        width: 100,
        height: 100,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    }
});