import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { AntDesign, Feather } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class LeadModificaScreen extends React.Component {
    constructor(props) {
        super(props)
        this.phoneRef = React.createRef()
        this.state = {
            loading: false,
            itemPaziente: JSON.parse(this.props.route.params.paziente),
            loadingAutomazioni: true,
            listAutomazioni: [],
            country: {
                "callingCode": [
                    "39",
                ],
                "cca2": "IT",
                "currency": [
                    "EUR",
                ],
                "flag": "flag-it",
                "name": "Italy",
                "region": "Europe",
                "subregion": "Southern Europe"
            }
        }
    }

    componentDidMount() {
        this.setState({ country: JSON.parse(this.state.itemPaziente.country_code) })
        this.getAutomazioni()
    }

    salvaPaziente = async () => {
        this.setState({ loading: true })
        if (this.state.itemPaziente.nome != '' && this.state.itemPaziente.cognome != '' && this.state.itemPaziente.email != '' && this.state.itemPaziente.telefono != '') {
            const isValidaNumber = this.phoneRef.current.isValidNumber(this.state.itemPaziente.telefono)
            if (isValidaNumber == true) {
                const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                let formData = new FormData();
                formData.append('token', token);
                formData.append('id_paziente', this.state.itemPaziente.id);
                formData.append('email', this.state.itemPaziente.email);
                formData.append('nome', this.state.itemPaziente.nome);
                formData.append('cognome', this.state.itemPaziente.cognome);
                formData.append('telefono', this.state.itemPaziente.telefono);
                formData.append('country_code', JSON.stringify(this.state.country))
                formData.append('fase_lead', this.state.itemPaziente.fase_lead);
                formData.append('paziente', this.state.itemPaziente.paziente);
                formData.append('fonte', this.state.itemPaziente.fonte);
                formData.append('sesso', this.state.itemPaziente.sesso);
                formData.append('impiego', this.state.itemPaziente.impiego);
                formData.append('figli', this.state.itemPaziente.figli);
                axios.post('https://www.visitreporter.com/app/modifica_lead.php', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(async (response) => {
                    const json = response.data;
                    if (json.code == true) {
                        this.setState({ loading: false })
                        this.handleGoBack()
                    } else {
                        this.setState({ loading: false })
                        alert(`Attenzione! ${json.message}`)
                    }
                }).catch((error) => {
                    this.setState({ loading: false })
                    console.error(error);
                })
            } else {
                this.setState({ loading: false })
                alert(`Attenzione! Il numero di telefono inserito non è valido`)
            }
        } else {
            this.setState({ loading: false })
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    getAutomazioni = async () => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_paziente', this.state.itemPaziente.id);
        axios.post('https://www.visitreporter.com/app/get_utente_automazioni.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json !== null) {
                this.setState({ listAutomazioni: json })
            } else {
                this.setState({ listAutomazioni: [] })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    deleteConfirm = () => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare il lead?") == true) {
            this.delete()
        }
    }

    delete = async () => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', this.state.itemPaziente.id);
        axios.post('https://www.visitreporter.com/app/delete_paziente.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                alert(`Successo! ${json.message}`)
                this.handleGoBack()
            } else {
                alert(`Attenzione! ${json.message}`)
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App', { screen: 'Lead' }); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Modifica Lead</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backButton} onPress={this.deleteConfirm}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginRight: 6 }}>Elimina</Text>
                        <AntDesign name='closecircleo' color='#fff' size={23} />
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Nome*</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Nome'
                            value={this.state.itemPaziente.nome}
                            onChangeText={(nome) => this.setState({ itemPaziente: { ...this.state.itemPaziente, nome } })}
                        />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Cognome*</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Cognome'
                            value={this.state.itemPaziente.cognome}
                            onChangeText={(cognome) => this.setState({ itemPaziente: { ...this.state.itemPaziente, cognome } })}
                        />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Email*</Text>
                        <TextInput
                            style={styles.input}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Email'
                            value={this.state.itemPaziente.email}
                            onChangeText={(email) => this.setState({ itemPaziente: { ...this.state.itemPaziente, email: email.toLowerCase().trim() } })}
                            keyboardType='email-address'
                            autoCapitalize='none'
                        />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Telefono*</Text>
                        <PhoneInput
                            ref={this.phoneRef}
                            defaultCode={JSON.parse(this.state.itemPaziente.country_code).cca2}
                            layout="first"
                            withDarkTheme={false}
                            withShadow={false}
                            placeholder='Numero di cellulare'
                            containerStyle={{ width: '100%', borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 20 }}
                            textContainerStyle={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, paddingVertical: 10, height: 55, backgroundColor: '#f5f5f5' }}
                            textInputStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                            codeTextStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                            flagButtonStyle={{ backgroundColor: '#e8e8e8', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
                            filterProps={{ placeholder: 'Cerca nazione o prefisso' }}
                            //value={this.state.telefono}
                            defaultValue={this.state.itemPaziente.telefono}
                            onChangeText={(telefono) => this.setState({ itemPaziente: { ...this.state.itemPaziente, telefono } })}
                            onChangeCountry={(country) => this.setState({ country })}
                        />
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Fase</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona la fase', value: null }}
                                doneText='Fine'
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(fase_lead) => this.setState({ itemPaziente: { ...this.state.itemPaziente, fase_lead } })}
                                value={this.state.itemPaziente.fase_lead}
                                items={[
                                    { label: 'Da contattare', value: '0' },
                                    { label: 'Irreperibile', value: '1' },
                                    { label: 'Da richiamare', value: '2' },
                                    { label: 'App. Rifiutato', value: '3' },
                                    { label: 'App. Accettato', value: '4' }
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Paziente</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di Paziente', value: null }}
                                doneText='Fine'
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(paziente) => this.setState({ itemPaziente: { ...this.state.itemPaziente, paziente } })}
                                value={this.state.itemPaziente.paziente}
                                items={[
                                    { label: 'Nuovo', value: 'Nuovo' },
                                    { label: 'Acquisito', value: 'Acquisito' }
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Fonte</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di Fonte', value: null }}
                                doneText='Fine'
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(fonte) => this.setState({ itemPaziente: { ...this.state.itemPaziente, fonte } })}
                                value={this.state.itemPaziente.fonte}
                                items={[
                                    { label: 'Passaparola', value: 'Passaparola' },
                                    { label: 'Google', value: 'Google' },
                                    { label: 'Facebook', value: 'Facebook' },
                                    { label: 'Stampa', value: 'Stampa' },
                                    { label: 'Affisioni', value: 'Affisioni' },
                                    { label: 'Tv', value: 'Tv' },
                                    { label: 'Assicurazioni', value: 'Assicurazioni' },
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Sesso</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di Sesso', value: null }}
                                doneText='Fine'
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(sesso) => this.setState({ itemPaziente: { ...this.state.itemPaziente, sesso } })}
                                value={this.state.itemPaziente.sesso}
                                items={[
                                    { label: 'Uomo', value: 'Uomo' },
                                    { label: 'Donna', value: 'Donna' },
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Figli</Text>
                            <Switch
                                trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                thumbColor={this.state.isFigli ? '#fff' : '#fff'}
                                ios_backgroundColor="#f5f5f5"
                                onValueChange={(value) => this.setState({ itemPaziente: { ...this.state.itemPaziente, figli: (value == true) ? 1 : 0 } })}
                                value={(this.state.itemPaziente.figli == 1) ? true : false}
                            />
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Impiego</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di Impiego', value: null }}
                                doneText='Fine'
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(impiego) => this.setState({ itemPaziente: { ...this.state.itemPaziente, impiego } })}
                                value={this.state.itemPaziente.impiego}
                                items={[
                                    { label: 'Impiegato', value: 'Impiegato' },
                                    { label: 'Libero professionista', value: 'Libero professionista' },
                                    { label: 'Imprenditore', value: 'Imprenditore' },
                                    { label: 'Operaio', value: 'Operaio' },
                                    { label: 'Studente', value: 'Studente' },
                                    { label: 'Altro', value: 'Altro' },
                                ]}
                            />
                        </View>
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={[styles.button, {
                                width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%'
                            }]} onPress={this.salvaPaziente}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator color='#fff' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva lead</Text>
                                }
                            </TouchableOpacity>
                        </View>
                        {(this.state.listAutomazioni.length > 0)
                            ?
                            <>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginTop: 20, marginBottom: 15 }}>Automazioni attive:</Text>
                            {this.state.listAutomazioni.map((item, index) => {
                                return(
                                    <View key={index} style={[styles.buttonLink, { 
                                        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 60 : Dimensions.get('screen').width - 600,
                                        paddingHorizontal: 20,
                                        paddingVertical: 10,
                                        justifyContent: 'space-between',
                                        marginBottom: 15,
                                        backgroundColor: '#0795FF'
                                    }]}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <Feather name='activity' color='#fff' size={30} />
                                            <View style={{ marginLeft: 15 }}>
                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, color: '#fff' }}>{item.nome}</Text>
                                                {(item.date_book !== null && item.date_book !== "")
                                                    &&
                                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#fff' }}>Data appuntamento:{'\n'}{item.date_book}</Text>
                                                }
                                            </View>
                                        </View>
                                    </View>
                                )
                            })}
                            </>
                            :
                            null
                        }
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
});