import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, TextInput, Platform, FlatList, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import 'moment/locale/it';
import DateTimePicker from '@react-native-community/datetimepicker';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class OperatoriScreen extends React.Component {
    constructor(props) {
        super(props)
        this.searchInput = React.createRef()
        this.phoneRef = React.createRef()
        this.serachStudi = React.createRef()
        this.state = {
            loading: true,
            loadingData: false,
            listOperatori: [],
            storyStudi: [],
            showModal: false,
            cerca: '',
            searchResult: [],
            cercaOperatore: '',
            loadingSearch: false,
            page: 1,
            isLoading: false,
            loadingFinish: false,
            isFiltri: false,
            image: '',
            nome: '',
            cognome: '',
            email: '',
            telefono: '',
            country: {
                "callingCode": [
                    "39",
                ],
                "cca2": "IT",
                "currency": [
                    "EUR",
                ],
                "flag": "flag-it",
                "name": "Italy",
                "region": "Europe",
                "subregion": "Southern Europe"
            },
            idOperatore: '',
            idStudio: '',
            nomeStudio: '',
            loadingActive: false,
            isEdit: false,
            cercaStudi: '',
            searchResultStudi: [],
            widthTitle: 0
        }
    }

    componentDidMount() {
        this.getOperatori()
    }

    getOperatori = async () => {
        let listOperatori = this.state.listOperatori;
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', this.state.page)
        axios.post('https://www.visitreporter.com/app/get_operatori.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loading: false })
            if (json.length > 0) {
                listOperatori = [...listOperatori, ...json];
                this.setState({ listOperatori: listOperatori, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    searchOperatori = async (search) => {
        this.setState({ cercaOperatore: search, loadingSearch: true, loading: true, page: 1 })
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('search_item', search);
        formData.append('page', 1);
        axios.post('https://www.visitreporter.com/app/search_operatori.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loadingSearch: false, loading: false, isFiltri: true })
            if (json.length > 0) {
                this.setState({ listOperatori: json })
            } else {
                this.setState({ listOperatori: [] })
            }
        }).catch((error) => {
            this.setState({ loadingSearch: false, loading: false })
            console.error(error);
        })
    }

    searchOperatoriContinue = async () => {
        let listOperatori = this.state.listOperatori;
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('search_item', this.state.cercaOperatore);
        formData.append('page', this.state.page);
        axios.post('https://www.visitreporter.com/app/search_operatori.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                listOperatori = [...listOperatori, ...json];
                this.setState({ listOperatori: listOperatori, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    continueStudiQueryLike = async (searchText) => {
        this.setState({ cercaStudi: searchText, loadingData: true })
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        axios.post('https://www.visitreporter.com/app/search_studi.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ searchResultStudi: json, loadingData: false })
            } else {
                this.setState({ searchResultStudi: [], loadingData: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    handleDeleteRequest = (user, index) => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare l'operatore?") == true) {
            this.handleDelete(user, index)
        }
    }

    handleDelete = async (user, index) => {
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', user);
        axios.post('https://www.visitreporter.com/app/delete_operatore.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                const listOperatori = this.state.listOperatori;
                listOperatori.splice(index, 1)
                this.setState({ listOperatori })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    handleAddOperatore = async () => {
        if (this.state.nome !== '' && this.state.cognome !== '' && this.state.idStudio !== '') {
            if (this.state.telefono !== '' && this.state.telefono !== null) {
                const isValidaNumber = this.phoneRef.current.isValidNumber(this.state.telefono)
                if (isValidaNumber == true) {
                    this.handleSendAddOperatore()
                } else {
                    alert("Attenzione! Il numero inserito non è corretto")
                }
            } else {
                this.handleSendAddOperatore()
            }
        } else {
            alert("Attenzione! Compila tutti i campi obbligatori")
        }
    }

    handleSendAddOperatore = async () => {
        this.setState({ loadingActive: true })
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_studio', this.state.idStudio);
        formData.append('nome', this.state.nome);
        formData.append('cognome', this.state.cognome);
        formData.append('email', this.state.email);
        formData.append('image', this.state.image);
        formData.append('telefono', this.state.telefono);
        formData.append('country_code', JSON.stringify(this.state.country));
        axios.post('https://www.visitreporter.com/app/add_operatore.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.resetListPage()
                this.setState({ loadingActive: false, showModal: false, isEdit: false, image: '', nome: '', cognome: '', email: '', telefono: '', idStudio: '', cercaStudi: '', searchResultStudi: [], country: {
                    "callingCode": [
                        "39",
                    ],
                    "cca2": "IT",
                    "currency": [
                        "EUR",
                    ],
                    "flag": "flag-it",
                    "name": "Italy",
                    "region": "Europe",
                    "subregion": "Southern Europe"
                } })
                setTimeout(() => {
                    this.props.navigation.navigate('OrariOperatore', { operatore: { id: json.operatore, nome: json.name_operatore, image: json.image_operatore } })
                }, 750);
            } else {
                this.setState({ loadingActive: false })
                alert(`Attenzione! ${json.message}`)
            }
        }).catch((error) => {
            this.setState({ loadingActive: false })
            console.error(error);
        })
    }

    handleEditOperatore = async () => {
        if (this.state.idStudio !== '' && this.state.nome !== '' && this.state.cognome !== '' && this.state.idOperatore !== '') {
            if (this.state.telefono !== '' && this.state.telefono !== null) {
                const isValidaNumber = this.phoneRef.current.isValidNumber(this.state.telefono)
                if (isValidaNumber == true) {
                    this.handleSendEditOperatore()
                } else {
                    alert("Attenzione! Il numero inserito non è corretto")
                }
            } else {
                this.handleSendEditOperatore()
            }
        } else {
            alert("Attenzione! Compila tutti i campi obbligatori")
        }
    }

    handleSendEditOperatore = async () => {
        this.setState({ loadingActive: true })
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_studio', this.state.idStudio);
        formData.append('id_operatore', this.state.idOperatore);
        formData.append('nome', this.state.nome);
        formData.append('cognome', this.state.cognome);
        formData.append('email', this.state.email);
        formData.append('telefono', this.state.telefono);
        formData.append('country_code', JSON.stringify(this.state.country));
        formData.append('image', this.state.image);
        axios.post('https://www.visitreporter.com/app/edit_operatore.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.resetListPage()
                this.setState({ loadingActive: false, showModal: false, isEdit: false, image: '', nome: '', cognome: '', email: '', telefono: '', idStudio: '', cercaStudi: '', searchResultStudi: [], country: {
                    "callingCode": [
                        "39",
                    ],
                    "cca2": "IT",
                    "currency": [
                        "EUR",
                    ],
                    "flag": "flag-it",
                    "name": "Italy",
                    "region": "Europe",
                    "subregion": "Southern Europe"
                } })
                alert(`Operatore aggioranto! ${json.message}`)
            } else {
                this.setState({ loadingActive: false })
                alert(`Attenzione! ${json.message}`)
            }
        }).catch((error) => {
            this.setState({ loadingActive: false })
            console.error(error);
        })
    }

    renderItem = ({item, index}) => {
        const isEven = index % 2 === 0;
        const country_code = JSON.parse(item.country_code)
        return(
            <View key={index} style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 10, justifyContent: 'space-between', marginBottom: 15, marginRight: (!isEven) ? 0 : '6%' }]}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {(item.image !== null && item.image !== "")
                        ?
                        <Image source={{ uri: item.image }} resizeMode='cover' style={{ width: 40, height: 40, borderRadius: 20 }} />
                        :
                        <Image source={require('../assets/icon-ios.png')} resizeMode='cover' style={{ width: 40, height: 40, borderRadius: 20 }} />
                    }
                    <View style={{ marginLeft: 15, width: (Dimensions.get('screen').width <= 500) ? '50%' : 'auto' }}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{item.nome} {item.cognome}</Text>
                        {(item.email !== "" && item.email !== null)
                            &&
                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>{item.email}</Text>
                        }
                        {(item.telefono !== "" && item.telefono !== null)
                            &&
                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                        }
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Studio{'\n'}{item.nome_studio}</Text>
                    </View>
                </View>
                <View>
                    <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#23a925', marginBottom: 8 }]} onPress={() => this.props.navigation.navigate('OrariOperatore', { operatore: { id: item.id, nome: `${item.nome} ${item.cognome}`, image: item.image } })}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Orari</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.buttonMore, { marginBottom: 8 }]} onPress={() => this.setState({ isEdit: true, idOperatore: item.id, image: (item.image !== null && item.image !== '') ? item.image : '', nome: item.nome, cognome: item.cognome, email: item.email, telefono: item.telefono, country_code: item.country_code, idStudio: item.id_studio, cercaStudi: item.nome_studio, showModal: true })}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Modifica</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fc3535' }]} onPress={() => this.handleDeleteRequest(item.id, index)}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Elimina</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    renderFooter = () => {
        return(
            this.state.isLoading && !this.state.loadingFinish ?
            <View style={{ marginTop: 10, alignItems: 'center' }}>
                <ActivityIndicator size='small' color='#FFF' />
            </View> : null
        );
    }

    handleMore = () => {
        if (!this.state.isFiltri) {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.getOperatori()
            }, 1000);
        } else {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.searchOperatoriContinue()
            }, 1000);
        }
    }

    resetListPage = () => {
        this.setState({ page: 1, loading: true, listOperatori: [], isFiltri: false, loadingSearch: false, cercaOperatore: '' })
        setTimeout(() => {
            this.getOperatori()
        }, 1000);
    }

    pickImage = async () => {
        let permission = await ImagePicker.getMediaLibraryPermissionsAsync();
        if (permission.status != 'granted') {
            let request = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (request.status != 'granted') {
                alert("Per caricare un'immagine devi acconsentire il permesso per accedere alla tua galleria");
            } else {
                this.imageGallery()
            }
        } else {
            this.imageGallery()
        }
    }

    imageGallery = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 4],
            quality: 0.8
        })
        if (!result.canceled) {
            const manipulateImge = await ImageManipulator.manipulateAsync(result.assets[0].uri, [{ resize: { width: 700 } }], { format: ImageManipulator.SaveFormat.PNG, base64: true })
            const newAvatarImage = `data:image/png;base64,${manipulateImge.base64}`;
            this.setState({ image: newAvatarImage })
        }
    }

    closeModal = () => {
        this.setState({ showModal: false, image: '', nome: '', cognome: '', email: '', telefono: '', idStudio: '', nomeStudio: '', searchResultStudi: [], cercaStudi: '', country: {
            "callingCode": [
                "39",
            ],
            "cca2": "IT",
            "currency": [
                "EUR",
            ],
            "flag": "flag-it",
            "name": "Italy",
            "region": "Europe",
            "subregion": "Southern Europe"
        }  })
    }

    selectStudio = (uid, nome_studio, item) => {
        this.setState({ idStudio: uid, searchResultStudi: [], cercaStudi: nome_studio })
        this.serachStudi.current.blur()
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Operatori</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backButton} onPress={() => this.setState({ showModal: true, isEdit: false })}>
                        <AntDesign name='pluscircleo' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Aggiungi</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginBottom: 25, paddingTop: 20, paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={[styles.containerTitle, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%' }]} onLayout={(event) => {
                        const {x, y, width, height} = event.nativeEvent.layout;
                        this.setState({ widthTitle: width })
                    }}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#0070FF' }}>OPERATORI</Text>
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Gestisci tutti gli operatori</Text>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                        <View style={styles.containerIcon}>
                            {(this.state.loadingSearch)
                                ?
                                <ActivityIndicator size='small' color='#000' />
                                :
                                <AntDesign name='search1' color='#000' size={23} />
                            }
                        </View>
                        <TextInput
                            style={[styles.input, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0, width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 160 : (this.state.widthTitle - 120), marginRight: 10, backgroundColor: '#FFF' }]}
                            placeholderTextColor='#D3D6D7'
                            placeholder="Cerca l'operatore"
                            value={this.state.cercaOperatore}
                            onChangeText={(cerca) => this.searchOperatori(cerca)}
                        />
                        <TouchableOpacity disabled={(this.state.cercaOperatore !== '') ? false : true} style={[styles.backButton, { backgroundColor: (this.state.cercaOperatore !== '') ? '#FFF' : '#D3D6D7', width: 55, height: 55, paddingHorizontal: 0 }]} onPress={this.resetListPage}>
                            <AntDesign name='closecircleo' color={(this.state.cercaOperatore !== '') ? '#0070FF' : 'grey'} size={23} />
                        </TouchableOpacity>
                    </View>
                </View>
                {(this.state.loading)
                    ?
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                        <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'center' }]}>
                            <ActivityIndicator size='small' color='#0070FF' />
                        </View>
                    </View>
                    :
                    (this.state.listOperatori.length > 0)
                        ?
                        <FlatList
                            data={this.state.listOperatori}
                            renderItem={this.renderItem}
                            contentContainerStyle={{ 
                                paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150,
                                paddingTop: 10,
                                paddingBottom: Dimensions.get('screen').height / 4
                            }}
                            numColumns={(Dimensions.get('screen').width <= 500) ? 1 : 2}
                            keyExtractor={(item, index) => index.toString()}
                            showsVerticalScrollIndicator={false}
                            ListFooterComponent={this.renderFooter}
                            onEndReachedThreshold={0.5}
                            onEndReached={this.handleMore}
                        />
                        :
                        <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                            <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20 }]}>
                                <Feather name='alert-triangle' color='#fc3535' size={30} />
                                <View style={{ marginLeft: 15 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Non sono presenti operatori</Text>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Aggiungi il tuo primo operatore</Text>
                                </View>
                            </View>
                        </View>
                }
                <View style={styles.positionCricle}>
                    <View style={styles.circle}></View>
                </View>
                <Modal
                    visible={this.state.showModal}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={this.closeModal}
                >
                    <View style={[styles.container, { backgroundColor: '#FFF' }]}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={this.closeModal}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { marginBottom: Dimensions.get('screen').height / 3 }]}>
                                <View style={styles.headImage}>
                                    <TouchButton onPress={this.pickImage}>
                                        <Image source={(this.state.image != '') ? { uri: this.state.image } : require('../assets/user-default-dentop.png')} resizeMode='cover' style={{ width: 100, height: 100, borderRadius: 15 }} />
                                        <View style={styles.containerImage}>
                                            {(this.state.loading)
                                                ?
                                                <ActivityIndicator size='small' color='#000' />
                                                :
                                                <AntDesign name='edit' color='#000' size={26} />
                                            }
                                        </View>
                                    </TouchButton>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Seleziona lo studio*</Text>
                                    {(this.state.loadingData)
                                        &&
                                        <ActivityIndicator size='small' color='#000' />
                                    }
                                </View>
                                <TextInput
                                    style={[styles.input, { borderBottomLeftRadius: (this.state.searchResultStudi.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResultStudi.length > 0) ? 0 : 10 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Inserisci il nome dello studio'
                                    value={this.state.cercaStudi}
                                    //onChangeText={(cerca) => {this.setState({ cerca }); this.filterResult(cerca)}}
                                    onChangeText={(cercaStudi) => this.continueStudiQueryLike(cercaStudi)}
                                    //editable={(this.state.loadingData) ? false : true}
                                    ref={this.serachStudi}
                                />
                                {(this.state.searchResultStudi.length > 0)
                                    &&
                                    this.state.searchResultStudi.map((item, index) => {
                                        let counter = index + 1;
                                        return(
                                            <TouchButton onPress={() => this.selectStudio(item.id, item.nome_studio.trim(), item)} key={item.id} style={[styles.listUser, {
                                                borderBottomLeftRadius: (counter == this.state.searchResultStudi.length) ? 10 : 0,
                                                borderBottomRightRadius: (counter == this.state.searchResultStudi.length) ? 10 : 0
                                            }]}>
                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>
                                                    {item.nome_studio.trim()}
                                                </Text>
                                            </TouchButton>
                                        )
                                    })
                                }
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, marginTop: 20 }}>Nome*</Text>
                                <TextInput
                                    style={[styles.input, { marginBottom: 20 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Nome operatore'
                                    value={this.state.nome}
                                    onChangeText={(nome) => this.setState({ nome })}
                                    maxLength={100}
                                    autoCapitalize='words'
                                />
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Cognome*</Text>
                                <TextInput
                                    style={[styles.input, { marginBottom: 20 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Cognome operatore'
                                    value={this.state.cognome}
                                    onChangeText={(cognome) => this.setState({ cognome })}
                                    maxLength={150}
                                />
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Email</Text>
                                <TextInput
                                    style={[styles.input, { marginBottom: 20 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Email operatore'
                                    value={this.state.email}
                                    onChangeText={(email) => this.setState({ email: email.toLocaleLowerCase() })}
                                    maxLength={150}
                                    autoCapitalize='none'
                                />
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Telefono</Text>
                                <PhoneInput
                                    ref={this.phoneRef}
                                    defaultCode="IT"
                                    layout="first"
                                    withDarkTheme={false}
                                    withShadow={false}
                                    placeholder='Numero di cellulare'
                                    containerStyle={{ width: '100%', borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 20 }}
                                    textContainerStyle={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, paddingVertical: 10, height: 55, backgroundColor: '#f5f5f5' }}
                                    textInputStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                                    codeTextStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                                    flagButtonStyle={{ backgroundColor: '#e8e8e8', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
                                    filterProps={{ placeholder: 'Cerca nazione o prefisso' }}
                                    value={this.state.telefono}
                                    onChangeText={(telefono) => this.setState({ telefono })}
                                    onChangeCountry={(country) => this.setState({ country })}
                                />
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={(this.state.isEdit) ? this.handleEditOperatore : this.handleAddOperatore}>
                                        {(this.state.loadingActive)
                                            ?
                                            <ActivityIndicator color='#fff' size='small' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva</Text>
                                        }
                                    </TouchButton>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF',
        overflow: 'hidden'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    inputDate: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerIcon: {
        backgroundColor: '#fff',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        width: 55,
        height: 55,
        alignItems: 'center',
        justifyContent: 'center'
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 2,
        right: - Dimensions.get('screen').width / 2,
        zIndex: -1,
        overflow: 'hidden'
    },
    circle: {
        backgroundColor: '#8d4472',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').width,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
    containerTitle: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    headImage: {
        paddingBottom: 15,
        paddingHorizontal: 20,
        alignItems: 'center'
    },
    containerImage: {
        width: 100,
        height: 100,
        borderRadius: 15,
        backgroundColor: '#ffffff1c',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 2
    },
});