import React from 'react';
import { StyleSheet, Text, View, StatusBar, Dimensions, Image, ScrollView, Modal, Alert, TouchableOpacity as TouchButton , Platform, FlatList, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator, Switch } from 'react-native-paper';
import Constants from 'expo-constants';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Table, Row, Rows, Cell, TableWrapper, Col } from 'react-native-table-component-2';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNPickerSelect from 'react-native-picker-select';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DateTimePicker from '@react-native-community/datetimepicker';
import Moment from 'moment';
import 'moment/locale/it';
import * as DocumentPicker from 'expo-document-picker';
import * as Linking from 'expo-linking';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { MaskedTextInput } from "react-native-mask-text";

export default class PazientiScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pazienti: [],
            pazientiHistory: [],
            search: '',
            paziente: null,
            fonte: null,
            figli: false,
            showFilter: false,
            isFiltri: false,
            fromDate: '',
            toDate: '',
            isDateSelect: false,
            showModalFile: false,
            isFileUploaded: false,
            fileUrl: null,
            mimeType: null,
            loadingUpload: false,
            page: 1,
            isLoading: false,
            loadingFinish: false,
            loadingPazienti: false
        }
    }

    componentDidMount() {
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            if (this.state.isFiltri) {
                this.updateData()
                this.addFilters()
            } else {
                this.setState({ page: 1 })
                setTimeout(() => {
                    this.reloadData()
                }, 1000);
            }
        });
        this.getListofData()
    }

    componentWillUnmount() {
        this._unsubscribe
    }

    updateData = async () => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        axios.post('https://www.visitreporter.com/app/pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ pazientiHistory: json })
        }).catch((error) => {
            console.error(error);
        })
    }

    downloadFileDemo = () => {
        Linking.openURL('https://visitreporter.com/app/demo/import-pazienti.xlsx')
    }

    uploadPazientiRequest = async () => {
        const document = await DocumentPicker.getDocumentAsync({ type: ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] })
        if (document.canceled == false) {
            this.setState({ isFileUploaded: true, fileUrl: document.assets[0].uri, mimeType: document.assets[0].mimeType })   
        }
    }

    uploadPazienti = async () => {
        if (this.state.fileUrl !== null) {
            this.setState({ loadingUpload: true })
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            const id = await AsyncStorage.getItem('id')
            const extension = this.state.fileUrl.split('.')[1]
            // Convert the base64 file data to a Blob object
            const byteCharacters = atob(this.state.fileUrl.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: this.state.mimeType });

            const file = new File([blob], `upload_pazienti_${Date.now()}.${extension}`, {
                type: this.state.mimeType
            });

            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('excel', file);
            axios.post('https://www.visitreporter.com/app/upload_excel.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    if (json.email_uso == true) {
                        alert(`Caricato con successo! ${json.message_uso}`)
                        this.setState({ page: 1 })
                        setTimeout(() => {
                            this.reloadData()
                        }, 1000);
                        this.setState({ loadingUpload: false, showModalFile: false, fileUrl: null, mimeType: null, isFileUploaded: false })
                    } else {
                        alert(`Caricato con successo! ${json.message}`)
                        this.setState({ page: 1 })
                        setTimeout(() => {
                            this.reloadData()
                        }, 1000);
                        this.setState({ loadingUpload: false, showModalFile: false, fileUrl: null, mimeType: null, isFileUploaded: false })
                    }
                } else {
                    this.setState({ loadingUpload: false })
                    alert(`Attenzione! ${json.message_uso}`)
                }
            }).catch((error) => {
                this.setState({ loadingUpload: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Devi prima caricare un file excel`)
        }
    }

    reloadData = async () => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1)
        axios.post('https://www.visitreporter.com/app/pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ pazienti: json, pazientiHistory: json })
        }).catch((error) => {
            console.error(error);
        })
    }

    getListofData = async () => {
        let pazienti = this.state.pazienti;
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', this.state.page)
        axios.post('https://www.visitreporter.com/app/pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                pazienti = [...pazienti, ...json];
                this.setState({ pazienti: pazienti, pazientiHistory: pazienti, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    filterResult = (txt) => {
        let text = txt.toUpperCase().trim();
        let space = null;
        space = this.state.pazientiHistory.filter(x => x.nome.toUpperCase().match(text));
        if (space == 0) {
            space = this.state.pazientiHistory.filter(x => x.cognome.toUpperCase().match(text));
            this.setState({ pazienti: space });
        } else {
            this.setState({ pazienti: space });
        }
    }

    addFilters = async () => {
        if (this.state.fonte != null || this.state.paziente != null || this.state.figli != false || (this.state.fromDate !== '' && this.state.toDate !== '')) {
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let id = await AsyncStorage.getItem('id')
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('paziente', this.state.paziente);
            formData.append('fonte', this.state.fonte);
            formData.append('figli', this.state.figli);
            axios.post('https://www.visitreporter.com/app/filtri_pazienti.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                this.setState({ pazienti: json, showFilter: false, isFiltri: true })
                if (this.state.fromDate !== '' && this.state.toDate !== '') {
                    let filterDate = this.state.pazienti.filter(x => Moment(x.data_creazione).format('YYYY-MM-DD') >= Moment(new Date(`${this.state.fromDate.split('/')[2]}-${this.state.fromDate.split('/')[1]}-${this.state.fromDate.split('/')[0]}`)).format('YYYY-MM-DD') && Moment(x.data_creazione).format('YYYY-MM-DD') <= Moment(new Date(`${this.state.toDate.split('/')[2]}-${this.state.toDate.split('/')[1]}-${this.state.toDate.split('/')[0]}`)).format('YYYY-MM-DD'))
                    this.setState({ pazienti: filterDate })
                }
            }).catch((error) => {
                console.error(error);
            })
        } else {
            alert("Attenzione! Per aggiungere un filtro devi prima selezionare uno dei due valori")
        }
    }

    removeFilters = () => {
        this.setState({ pazienti: this.state.pazientiHistory, showFilter: false, paziente: null, fonte: null, figli: false, fromDate: '', toDate: '', isFiltri: false })
    }

    removeFileUploaded = () => {
        this.setState({ fileUrl: null, mimeType: null, isFileUploaded: false })
    }

    renderItem = ({item, index}) => {
        const countIndex = index + 1;
        const country_code = JSON.parse(item.country_code)
        return( 
            <View key={item.id} style={{
                borderBottomWidth: 1,
                borderColor: '#ededed',
                backgroundColor: '#fff',
                flexDirection: 'row',
                alignItems: 'center',
                width: (Dimensions.get('screen').width <= 500) ? '100%' : Dimensions.get('screen').width - 400,
                borderBottomLeftRadius: (Dimensions.get('screen').width <= 500) ? 0 : (countIndex == this.state.pazienti.length) ? 20 : 0,
                borderBottomRightRadius: (Dimensions.get('screen').width <= 500) ? 0 : (countIndex == this.state.pazienti.length) ? 20 : 0,
                paddingLeft: (Dimensions.get('screen').width <= 500) ? 0 : 15,
                paddingRight: (Dimensions.get('screen').width <= 500) ? 0 : 25
            }}>
                <TouchableOpacity style={styles.widthRow} onPress={() => this.props.navigation.navigate('PazienteModifica', { paziente: JSON.stringify(item) })}>
                    <Text style={[styles.text, { fontFamily: 'DM-Regular', marginLeft: 10 }]}>{item.nome} {item.cognome}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.widthRow} onPress={() => Linking.openURL(`tel:+${country_code.callingCode[0]}${item.telefono}`)}>
                    <Text style={[styles.text, { fontFamily: 'DM-Regular', marginRight: 10, textDecorationStyle: 'solid', textDecorationLine: 'underline' }]}>+{country_code.callingCode[0]}{item.telefono}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.widthRow, { flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }]} onPress={() => this.props.navigation.navigate('PazienteModifica', { paziente: JSON.stringify(item) })}>
                    {(item.valutazione != null && item.valutazione != '' && item.valutazione != 0 && item.valutazione != '0')
                        ?
                        <>
                        {(item.valutazione.split('.')[0] >= 1)
                            ?
                            <FontAwesome name='star' color='#e3e31e' size={21} />
                            :
                            (item.valutazione.split('.')[0] == 0)
                                ?
                                <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                :
                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        }
                        {(item.valutazione.split('.')[0] >= 2)
                            ?
                            <FontAwesome name='star' color='#e3e31e' size={21} />
                            :
                            (item.valutazione == 1.5)
                                ?
                                <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                :
                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        }
                        {(item.valutazione.split('.')[0] >= 3)
                            ?
                            <FontAwesome name='star' color='#e3e31e' size={21} />
                            :
                            (item.valutazione == 2.5)
                                ?
                                <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                :
                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        }
                        {(item.valutazione.split('.')[0] >= 4)
                            ?
                            <FontAwesome name='star' color='#e3e31e' size={21} />
                            :
                            (item.valutazione == 3.5)
                                ?
                                <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                :
                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        }
                        {(item.valutazione.split('.')[0] >= 5)
                            ?
                            <FontAwesome name='star' color='#e3e31e' size={21} />
                            :
                            (item.valutazione == 4.5)
                                ?
                                <FontAwesome name='star-half-o' color='#e3e31e' size={21} />
                                :
                                <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        }
                        </>
                        :
                        <>
                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        <FontAwesome name='star-o' color='#e3e31e' size={21} />
                        </>
                    }
                </TouchableOpacity>
            </View>
        );
    }

    renderFooter = () => {
        return(
            this.state.isLoading && !this.state.loadingFinish ?
            <View style={{ marginTop: 10, alignItems: 'center' }}>
                <ActivityIndicator size='small' color='#000' />
            </View> : null
        );
    }

    handleMore = () => {
        if (!this.state.isFiltri) {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                if (this.state.search !== "") {
                    this.continuePatientsQueryLike()
                } else {
                    this.getListofData()
                }
            }, 1000);
        }
    }

    searchPatientsQueryLike = async (search) => {
        this.setState({ search, page: 1, loadingPazienti: true })
        if (search !== "") {
            let pazienti = [];
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let id = await AsyncStorage.getItem('id')
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('page', 1);
            formData.append('search_item', search);
            axios.post('https://www.visitreporter.com/app/search_pazienti.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.length > 0) {
                    pazienti = [...pazienti, ...json];
                    this.setState({ pazienti: pazienti, isLoading: false, loadingPazienti: false })
                } else {
                    this.setState({ pazienti: [], isLoading: false, loadingFinish: true, loadingPazienti: false })
                }
            }).catch((error) => {
                console.error(error);
            })
        } else {
            this.setState({ pazienti: [], loadingPazienti: false })
            setTimeout(() => {
                this.getListofData()
            }, 1000);
        }
    }

    continuePatientsQueryLike = async () => {
        let pazienti = this.state.pazienti;
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', this.state.page);
        formData.append('search_item', this.state.search);
        axios.post('https://www.visitreporter.com/app/search_pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                pazienti = [...pazienti, ...json];
                this.setState({ pazienti: pazienti, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={{ backgroundColor: '#0070FF' }}>
                    <View style={styles.header}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#fff' }}>Anagrafica</Text>
                        <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.navigate('PazienteNuovo')}>
                            <AntDesign name='pluscircleo' color='#fff' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Paziente</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.uploadContainer}>
                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff', marginRight: (Dimensions.get('screen').width <= 500) ? 0 : 25 }}>Carica i pazienti tramite file Excel</Text>
                    <TouchableOpacity style={[styles.backButton, { backgroundColor: '#0070FF' }]} onPress={() => this.setState({ showModalFile: true })}>
                        <AntDesign name='upload' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Carica file</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', paddingHorizontal: 20, paddingVertical: 15, justifyContent: (Dimensions.get('screen').width <= 500) ? 'flex-start' : 'center' }}>
                    <TextInput style={[styles.input, { backgroundColor: '#f5f5f5', color: '#000' }]} value={this.state.search} onChangeText={(search) => this.searchPatientsQueryLike(search)} placeholderTextColor='#bfbfbf' placeholder='Cerca paziente'></TextInput>
                    <TouchableOpacity style={[styles.button, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]} onPress={() => this.setState({ showFilter: true })}>
                        {(this.state.isFiltri)
                            &&
                            <AntDesign name='checkcircle' color='#fff' size={20} style={{ marginRight: 6 }} />
                        }
                        {(this.state.loadingPazienti)
                            &&
                            <ActivityIndicator size='small' color='#fff' style={{ marginRight: 6 }} />
                        }
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Filtri</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.containerLink}>
                    <View style={[styles.head, { 
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingHorizontal: 0,
                        borderTopLeftRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20,
                        borderTopRightRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20
                    }]}>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff', marginLeft: (Dimensions.get('screen').width <= 500) ? 10 : 25 }]}>Nome</Text>
                        </View>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff' }]}>Telefono</Text>
                        </View>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff', marginRight: (Dimensions.get('screen').width <= 500) ? 10 : 25 }]}>Valutazione</Text>
                        </View>
                    </View>
                </View>
                <FlatList
                    data={this.state.pazienti}
                    renderItem={this.renderItem}
                    contentContainerStyle={{ paddingBottom: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').height / 2.2 : Dimensions.get('screen').height / 4, paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 0 : 200 }}
                    numColumns={1}
                    keyExtractor={(item, index) => index.toString()}
                    ListFooterComponent={this.renderFooter}
                    onEndReachedThreshold={0.5}
                    onEndReached={this.handleMore}
                />
                <Modal
                    animationType='slide'
                    presentationStyle='pageSheet'
                    visible={this.state.showModalFile}
                    onRequestClose={() => {
                        this.setState({ showModalFile: false, fileUrl: null, mimeType: null, isFileUploaded: false })
                    }}
                >
                    <View style={{ marginTop: 40, paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300 }}>
                        <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 50 }}>
                            <View style={[styles.backButtonModal, { width: 'auto', paddingHorizontal: 20 }]}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Caricamento file</Text>
                            </View>
                            <TouchButton style={styles.backButtonModal} onPress={() => this.setState({ showModalFile: false, fileUrl: null, mimeType: null, isFileUploaded: false })}>
                                <AntDesign name='closecircleo' color='#000' size={23} />
                            </TouchButton>
                        </View>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>Guida al caricamento</Text>
                    </View>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 0 : 300 }}>
                            <View style={{ paddingHorizontal: 30, backgroundColor: 'lightgrey', marginTop: 15, paddingVertical: 12, borderRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>Il file non deve superare le 250 righe</Text>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>Tutti i campi sono obbligatori e non possono essere lasciati vuoti</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, backgroundColor: 'lightgrey', marginTop: 15, paddingVertical: 12, borderTopLeftRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20, borderTopRightRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Nome</Text>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Cognome</Text>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Email</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, backgroundColor: '#f5f5f5', paddingVertical: 12, marginBottom: 10, borderBottomLeftRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20, borderBottomRightRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}>Mario</Text>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}>Rossi</Text>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}>mario@...</Text>
                            </View>
                            <View style={{ paddingHorizontal: 30 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Inserisci il numero di telefono senza il prefisso</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, backgroundColor: 'lightgrey', marginTop: 15, paddingVertical: 12, borderTopLeftRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20, borderTopRightRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Telefono</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, backgroundColor: '#f5f5f5', paddingVertical: 12, marginBottom: 10, borderBottomLeftRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20, borderBottomRightRadius: (Dimensions.get('screen').width <= 500) ? 0 : 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}>3325898445</Text>
                            </View>
                            <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: Dimensions.get('screen').height / 5 }}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center' }}>Scarica il file Excel demo</Text>
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={[styles.buttonModal, { marginTop: 10, marginBottom: 15, width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={this.downloadFileDemo}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Scarica il file</Text>
                                    </TouchButton>
                                </View>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center' }}>Carica il file Excel</Text>
                                <View>
                                    <TouchButton style={styles.uploadButton} onPress={this.uploadPazientiRequest}>
                                        {(this.state.isFileUploaded)
                                            ?
                                            <>
                                            <AntDesign name='checkcircle' color='green' size={23} />
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: 'grey', marginLeft: 7 }}>File caricato</Text>
                                            </>
                                            :
                                            <>
                                            <AntDesign name='upload' color='#1d1d1b' size={23} />
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: 'grey', marginLeft: 7 }}>Carica file</Text>
                                            </>
                                        }
                                    </TouchButton>
                                    {(this.state.isFileUploaded)
                                        &&
                                        <TouchButton style={styles.buttonRemoveFile} onPress={this.removeFileUploaded}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>Rimuovi</Text>
                                        </TouchButton>
                                    }
                                </View>
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={[styles.buttonModal, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={this.uploadPazienti}>
                                        {(this.state.loadingUpload)
                                            ?
                                            <ActivityIndicator size='small' color='#fff' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Carica Pazienti</Text>
                                        }
                                    </TouchButton>
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </Modal>
                <Modal
                    animationType='slide'
                    presentationStyle='pageSheet'
                    visible={this.state.showFilter}
                    onRequestClose={() => {
                        this.setState({ showFilter: false })
                    }}
                >
                    <View style={{ marginTop: 40, paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300 }}>
                        <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 50 }}>
                            <View style={[styles.backButtonModal, { width: 'auto', paddingHorizontal: 20 }]}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Filtri Pazienti</Text>
                            </View>
                            <TouchButton style={styles.backButtonModal} onPress={() => this.setState({ showFilter: false })}>
                                <AntDesign name='closecircleo' color='#000' size={23} />
                            </TouchButton>
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Intervallo date</Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <View style={{ width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width / 2) - 35 : '45%' }}>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 14, marginBottom: 10 }}>Da</Text>
                                    <MaskedTextInput
                                        mask="99/99/9999"
                                        value={this.state.fromDate}
                                        onChangeText={(fromDate) => this.setState({ fromDate })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder={`Es. ${Moment(new Date()).subtract(2, 'days').format('DD/MM/YYYY')}`}
                                        style={styles.inputModal}
                                    />
                                </View>
                                <View style={{ width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width / 2) - 35 : '45%' }}>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 14, marginBottom: 10 }}>A</Text>
                                    <MaskedTextInput
                                        mask="99/99/9999"
                                        value={this.state.toDate}
                                        onChangeText={(toDate) => this.setState({ toDate })}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder={`Es. ${Moment(new Date()).format('DD/MM/YYYY')}`}
                                        style={styles.inputModal}
                                    />
                                </View>
                            </View>
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Paziente</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di Paziente', value: null }}
                                doneText='Fine'
                                style={{ viewContainer: [styles.inputSelect, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(paziente) => this.setState({ paziente })}
                                value={this.state.paziente}
                                items={[
                                    { label: 'Nuovo', value: 'Nuovo' },
                                    { label: 'Acquisito', value: 'Acquisito' }
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Fonte</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di Fonte', value: null }}
                                doneText='Fine'
                                style={{ viewContainer: [styles.inputSelect, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(fonte) => this.setState({ fonte })}
                                value={this.state.fonte}
                                items={[
                                    { label: 'Passaparola', value: 'Passaparola' },
                                    { label: 'Google', value: 'Google' },
                                    { label: 'Facebook', value: 'Facebook' },
                                    { label: 'Stampa', value: 'Stampa' },
                                    { label: 'Affisioni', value: 'Affisioni' },
                                    { label: 'Tv', value: 'Tv' },
                                    { label: 'Assicurazioni', value: 'Assicurazioni' },
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Pazienti con almeno{'\n'}un Figlio</Text>
                            <Switch color={'#0070FF'} value={this.state.figli} onValueChange={() => this.setState({ figli: !this.state.figli })} />
                        </View>
                        {(this.state.isFiltri)
                            ?
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: (Dimensions.get('screen').width <= 500) ? 'space-between' : 'center' }}>
                                <TouchButton onPress={this.removeFilters} style={[styles.buttonModal, { backgroundColor: '#f5f5f5', paddingVertical: 18, width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width / 3) - 35 : '20%', marginRight: (Dimensions.get('screen').width <= 500) ? 0 : 25 }]}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 15 }}>Rimuovi</Text>
                                </TouchButton>
                                <TouchButton onPress={this.addFilters} style={[styles.buttonModal, { paddingVertical: 16, width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width - 65) - ((Dimensions.get('screen').width / 3) - 35) : '30%' }]}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Aggiungi Filtri</Text>
                                </TouchButton>
                            </View>
                            :
                            <View style={{ alignItems: 'center' }}>
                                <TouchButton style={[styles.buttonModal, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={this.addFilters}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Aggiungi Filtri</Text>
                                </TouchButton>
                            </View>
                        }
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#edf7ff'
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width - 50) - ((Dimensions.get('screen').width / 1.3) - 50) : '8%',
        height: 45,
        paddingHorizontal: 10,
        paddingVertical: 6,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    header: {
        paddingHorizontal: 20,
        paddingTop: 10 + Constants.statusBarHeight,
        paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerLink: {
        paddingTop: (Dimensions.get('screen').width <= 500) ? 0 : 20,
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 0 : 200
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    head: {
        height: 50,
        backgroundColor: '#0070FF',
        paddingHorizontal: 5
    },
    text: { 
        marginVertical: 6 
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width / 1.3) - 50 : '30%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 45,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 15,
        marginRight: 10
    },
    inputSelect: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    buttonDate: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center'
    },
    widthTable: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width / 3 : (Dimensions.get('screen').width / 3) - 10
    },
    widthRow: {
        height: 40,
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width / 3 : (Dimensions.get('screen').width / 3) - 30,
        justifyContent: 'center'
    },
    backButtonModal: {
        backgroundColor: '#f5f5f5',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonModal: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    uploadContainer: {
        backgroundColor: '#2484FF',
        paddingHorizontal: 20,
        paddingVertical: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: (Dimensions.get('screen').width <= 500) ? 'space-between' : 'center'
    },
    uploadButton: {
        backgroundColor: '#f5f5f5',
        width: '100%',
        height: 85,
        borderRadius: 10,
        borderColor: 'grey',
        borderWidth: 3,
        borderStyle: 'dashed',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 15
    },
    buttonRemoveFile: {
        backgroundColor: 'lightgrey',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 4,
        paddingHorizontal: 10,
        borderRadius: 18,
        position: 'absolute',
        top: 18,
        right: 8,
        zIndex: 3
    },
    inputModal: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
});