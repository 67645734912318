import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions, Alert, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Crypto from 'expo-crypto';

export default class UserActiveScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.route.params.message,
            message2: this.props.route.params.message2,
            loading: false
        }
    }

    requestLogout = () => {
        Alert.alert(
            "Attenzione",
            "Sei sicuro di voler uscire dal tuo account?",
            [
              {
                text: "Annulla",
                style: "cancel"
              },
              { text: "Esci", onPress: () => this.logout() }
            ]
        );
    }

    logout = async () => {
        await AsyncStorage.removeItem('id')
        await AsyncStorage.removeItem('nome')
        await AsyncStorage.removeItem('cognome')
        await AsyncStorage.removeItem('data_scadenza')
        await AsyncStorage.removeItem('email')
        await AsyncStorage.removeItem('password')
        await AsyncStorage.removeItem('telefono')
        await AsyncStorage.removeItem('data_inizio')
        await AsyncStorage.removeItem('is_prova')
        await AsyncStorage.removeItem('periodo_prova')
        await AsyncStorage.removeItem('abbonamento_attivo')
        await AsyncStorage.removeItem('ragione_sociale')
        await AsyncStorage.removeItem('nome_studio')
        await AsyncStorage.removeItem('image')
        await AsyncStorage.removeItem('via')
        await AsyncStorage.removeItem('citta')
        await AsyncStorage.removeItem('piva')
        this.props.navigation.navigate('Auth')
    }

    requestDisactivate = () => {
        Alert.alert(
            "Attenzione",
            "Sei sicuro di voler disattivare tutti i profili dell'account?",
            [
              {
                text: "Annulla",
                style: "cancel"
              },
              { text: "Disattiva", onPress: () => this.disactivateProfiles() }
            ]
        );
    }

    disactivateProfiles = async () => {
        this.setState({ loading: true })
        const id = await AsyncStorage.getItem('id')
        let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        fetch(`https://www.visitreporter.com/app/delete_active.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then(async (json) => {
            this.setState({ loading: false })
            if (json.code == true) {
                Alert.alert(
                    json.message,
                    json.message2,
                    [
                        { text: "Ok" }
                    ]
                )
            } else {
                Alert.alert(
                    json.message,
                    json.message2,
                    [
                        { text: "Ok" }
                    ]
                )
            }
        }).catch((error) => {
            this.setState({ loading: false })
            console.error(error);
        })
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <Text style={{ fontFamily: 'DM-Bold', fontSize: 22, textAlign: 'center', color: '#fff', marginBottom: 10 }}>{this.state.message}</Text>
                <Text style={{ fontFamily: 'DM-Medium', fontSize: 18, textAlign: 'center', color: '#fff' }}>{this.state.message2}</Text>
                <TouchableOpacity style={styles.button} onPress={this.requestLogout}>
                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center' }}>Esci dall'account</Text>
                </TouchableOpacity>
                <Text style={{ fontFamily: 'DM-Medium', fontSize: 18, textAlign: 'center', color: '#fff', marginTop: 25 }}>Disattiva tutti i profili dell'account</Text>
                <TouchableOpacity style={[styles.button, { marginTop: 10 }]} onPress={this.requestDisactivate}>
                    {(this.state.loading)
                        ?
                        <ActivityIndicator size='small' color='#000' />
                        :
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center' }}>Disattiva profili</Text>
                    }
                </TouchableOpacity>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#0070FF',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 30
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: 30,
        marginTop: 25,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 25
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#36a9ff',
        width: 'auto',
        paddingHorizontal: 20,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    typeOne: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 25,
        paddingVertical: 20,
        borderTopLeftRadius: 17,
        borderTopRightRadius: 17,
        borderColor: 'lightgrey',
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    typeTwo: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 25,
        paddingVertical: 20,
        borderBottomLeftRadius: 17,
        borderBottomRightRadius: 17,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 25
    },
    backButtonSecond: {
        backgroundColor: '#36a9ff',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    }
});