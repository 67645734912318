import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, Image, TextInput } from 'react-native';
import { ActivityIndicator, Modal as ModalPaper } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WebView } from 'react-native-webview';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import {Calendar, LocaleConfig} from 'react-native-calendars';
import moment from 'moment';
import 'moment/locale/it';
import PhoneInput from "react-native-phone-number-input";

LocaleConfig.locales['it'] = {
    monthNames: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ],
    monthNamesShort: ['Genn.', 'Febb.', 'Marzo', 'Apr.', 'Magg.', 'Giug.', 'Lugl.', 'Agos.', 'Sett.', 'Ott.', 'Nov.', 'Dic.'],
    dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    today: "Oggi"
};

LocaleConfig.defaultLocale = 'it';

let dates = {
    '2012-05-17': { selected: true }
};

export default class AgendaBookScreen extends React.Component {
    constructor(props) {
        super(props)
        this.phoneRef = React.createRef()
        this.state = {
            uid: this.props.route.params.uid,
            isEnabled: true,
            loading: true,
            studio: null,
            dateSelect: null,
            dateSelectString: null,
            dateString: null,
            currentStep: 0,
            boxWidth: 0,
            selectedStudio: null,
            listStudi: [],
            loadingStudi: true,
            listOperatori: [],
            loadingOperatori: true,
            selectedOperatore: null,
            isOrari: false,
            loadingAgenda: true,
            loadingSlot: true,
            slots: [],
            selectedSlot: '',
            email: '',
            nome: '',
            cognome: '',
            telefono: '',
            country: {
                "callingCode": [
                    "39",
                ],
                "cca2": "IT",
                "currency": [
                    "EUR",
                ],
                "flag": "flag-it",
                "name": "Italy",
                "region": "Europe",
                "subregion": "Southern Europe"
            },
            loadingSend: false,
            dateDisabled: null
        }
    }

    componentDidMount() {
        const { uid } = this.props.route.params || {};

        if (!uid) {
            this.setState({ isEnabled: false })
        } else {
            this.setState({ isEnabled: true })
        }
        this.getStudioData()
        this.getStudi()
    }

    onLayout = (event) => {
        const {x, y, height, width} = event.nativeEvent.layout;
        this.setState({ boxWidth: width })
    }

    decryptId = () => {
        const secretKey = 'D4GJR593HG3JKRE93JER';  // La stessa chiave usata per crittare
        const bytes = CryptoJS.AES.decrypt(decodeURIComponent(this.state.uid), secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);
        return originalId;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getStudioData = async () => {
        const idStudio = this.decryptId()
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_studio', idStudio);
        axios.post('https://www.visitreporter.com/app/get_user.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({
                    studio: {
                        nome_studio: json.nome_studio,
                        image: json.image,
                        via: json.via,
                        citta: json.citta
                    },
                    loading: false
                })
            } else {
                alert(`Attenzione! ${json.message}`)
                this.setState({ loading: false })
            }
        }).catch((error) => {
            this.setState({ loading: false })
            console.error(error);
        })
    }

    updateDateInJson(jsonObject, oldDate, newDate) {
        // Copia il contenuto della vecchia data in una nuova chiave (nuova data)
        jsonObject[newDate] = jsonObject[oldDate];
      
        // Elimina la vecchia data
        delete jsonObject[oldDate];
        
        return jsonObject;
    }

    getStudi = async () => {
        this.setState({ loadingStudi: true })
        const idStudio = this.decryptId()
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', idStudio);
        axios.post('https://www.visitreporter.com/app/get_studi_all.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ listStudi: json, loadingStudi: false })
            } else {
                this.setState({ listStudi: [], loadingStudi: false })
            }
        }).catch((error) => {
            this.setState({ listStudi: [], loadingStudi: false })
            console.error(error);
        })
    }
    
    selectStudio = (item) => {
        this.setState({ selectedStudio: item })
        setTimeout(() => {
            this.getOperatori(item.id)
            this.setState({ currentStep: 1 })
        }, 500);
    }

    getOperatori = async (id_studio) => {
        this.setState({ loadingOperatori: true })
        const idStudio = this.decryptId()
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', idStudio);
        formData.append('id_studio', id_studio)
        axios.post('https://www.visitreporter.com/app/get_operatori_all.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ listOperatori: json, loadingOperatori: false })
            } else {
                this.setState({ listOperatori: [], loadingOperatori: false })
            }
        }).catch((error) => {
            this.setState({ listOperatori: [], loadingOperatori: false })
            console.error(error);
        })
    }

    selectOperatore = (item) => {
        this.setState({ selectedOperatore: item })
        setTimeout(() => {
            this.getOrariOperatoreCheck(item.id)
            this.getFerie(item.id)
            this.setState({ currentStep: 2 })
        }, 500);
    }

    getOrariOperatoreCheck = async (id_operatore) => {
        this.setState({ loadingAgenda: true })
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', id_operatore);
        axios.post('https://www.visitreporter.com/app/get_orari_operatore_check.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ isOrari: true, loadingAgenda: false })
            } else {
                this.setState({ isOrari: false, loadingAgenda: false })
            }
        }).catch((error) => {
            this.setState({ isOrari: false, loadingAgenda: false })
            console.error(error);
        })
    }

    getDatesInRange = (startDate, endDate) => {
        // Converte le stringhe in oggetti Date
        const start = new Date(startDate);
        const end = new Date(endDate);
      
        // Array per memorizzare tutte le date
        const dateArray = [];
      
        // Itera attraverso le date, aggiungendo ogni giorno all'array
        while (start <= end) {
          // Clona l'oggetto Date per evitare mutazioni
          dateArray.push(new Date(start));
      
          // Incrementa la data di un giorno
          start.setDate(start.getDate() + 1);
        }
      
        return dateArray;
    }

    formatDate = (date) => {
        return date.toISOString().split('T')[0];
    }

    getFerie = async (id_operatore) => {
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', id_operatore);
        axios.post('https://www.visitreporter.com/app/get_ferie.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                let markedDates = {};
                json.forEach(element => {
                    const arrayDates = this.getDatesInRange(element.date_start, element.date_end)
                    arrayDates.forEach(dates => {
                        const saveDate = this.formatDate(dates)
                        markedDates[saveDate] = { disabled: true, disableTouchEvent: true };
                    });
                });
                this.setState({ dateDisabled: markedDates })
            } else {
                this.setState({ dateDisabled: null })
            }
        }).catch((error) => {
            this.setState({ dateDisabled: null })
            console.error(error);
        })
    }

    removeAccent = (str) => {
        return str.replace(/[ì]/g, 'i');
    }

    getSlots = async (timestamp) => {
        this.setState({ loadingSlot: true })
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', this.state.selectedOperatore.id);
        formData.append('day_name', this.removeAccent(moment(timestamp).format('dddd')))
        formData.append('date_select', moment(timestamp).format('YYYY-MM-DD'))
        axios.post('https://www.visitreporter.com/app/get_operatore_time_slot.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ slots: json, loadingSlot: false })
            } else {
                this.setState({ slots: [], loadingSlot: false })
            }
        }).catch((error) => {
            this.setState({ slots: [], loadingSlot: false })
            console.error(error);
        })
    }

    selectSlot = (time) => {
        this.setState({ selectedSlot: time })
        setTimeout(() => {
            this.setState({ currentStep: 4 })
        }, 500);
    }

    saveAppuntamento = async () => {
        if (this.state.nome !== '' && this.state.cognome !== '' && this.state.email !== '' && this.state.telefono !== '') {
            const isValidaNumber = this.phoneRef.current.isValidNumber(this.state.telefono)
            if (isValidaNumber == true) {
                this.setState({ loadingSend: true })
                const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                let formData = new FormData();
                formData.append('token', token);
                formData.append('id_operatore', this.state.selectedOperatore.id);
                formData.append('date_select', moment(this.state.dateSelect).format('YYYY-MM-DD'))
                formData.append('time_select', this.state.selectedSlot);
                formData.append('nome', this.state.nome)
                formData.append('cognome', this.state.cognome)
                formData.append('telefono', this.state.telefono)
                formData.append('email', this.state.email)
                formData.append('country_code', JSON.stringify(this.state.country))
                axios.post('https://www.visitreporter.com/app/save_appuntamento.php', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(async (response) => {
                    const json = response.data;
                    if (json.code == true) {
                        this.setState({ loadingSend: false, currentStep: 5 })
                    } else {
                        this.setState({ loadingSend: false })
                        alert(`Attenzione! ${json.message}`)
                    }
                }).catch((error) => {
                    this.setState({ loadingSend: false })
                    alert("Attenzione! Qualcosa è andato storto")
                    console.error(error);
                })
            } else {
                alert("Attenzione! Il numero di telefono inserito non è valido");
            }
        } else {
            alert("Attenzione! Compila tutti i campi obbligatori")
        }
    }
 
    render() {
        const { currentStep } = this.state;
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 25, paddingBottom: Dimensions.get('screen').height / 4 }}>
                        <View style={{ alignItems: 'center', marginBottom: 15 }}>
                            <View style={styles.containerLogo}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator size='small' color={'#0070FF'} />
                                    :
                                    (this.state.studio !== null)
                                        ?
                                        <Image source={(this.state.studio.image !== '' && this.state.studio.image !== null) ? { uri: this.state.studio.image } : require('../assets/dental-automation.png')} resizeMode='contain' style={{ width: 60, height: 60 }} />
                                        :
                                        <Image source={require('../assets/dental-automation.png')} resizeMode='contain' style={{ width: 60, height: 60 }} />
                                }
                            </View>
                            {(this.state.studio !== null)
                                &&
                                <>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 19, color: '#FFF' }}>{this.state.studio.nome_studio}</Text>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, marginTop: 3, color: '#FFF' }}>{this.state.studio.via} {this.state.studio.citta}</Text>
                                </>
                            }
                        </View>
                        <View style={{ alignItems: 'center' }}>
                            <View style={styles.containerStep} onLayout={this.onLayout}>
                                {(currentStep == 0)
                                    ?
                                    <>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 19, textAlign: 'center', marginBottom: 15 }}>Seleziona uno studio</Text>
                                    <View style={styles.rowButtons}>
                                        {(this.state.loadingStudi)
                                            ?
                                            <View style={{ alignItems: 'center' }}>
                                                <ActivityIndicator size='large' color={'#0070FF'} />
                                            </View>
                                            :
                                            (this.state.listStudi.length > 0)
                                                ?
                                                this.state.listStudi.map((item, index) => {
                                                    const isEven = index % 2 === 0;
                                                    return(
                                                        <TouchableOpacity key={index} style={[styles.buttonSelect, {
                                                            width: (this.state.boxWidth / 2) - 30,
                                                            marginRight: (isEven) ? 20 : 0,
                                                            borderColor: (this.state.selectedStudio !== null) ? (this.state.selectedStudio.id == item.id) ? '#0070FF' : '#fff' : '#fff'
                                                        }]} onPress={() => this.selectStudio(item)}>
                                                            {(item.image !== '' && item.image !== null)
                                                                ?
                                                                <Image source={{ uri: item.image }} resizeMode='cover' style={{ width: 50, height: 50, borderRadius: 30 }} />
                                                                :
                                                                <Image source={require('../assets/dental-automation.png')} resizeMode='cover' style={{ width: 50, height: 50, borderRadius: 30 }} />
                                                            }
                                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, textAlign: 'center', marginTop: 5 }}>{item.nome_studio}</Text>
                                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginTop: 3, textAlign: 'center' }}>{item.via} {item.citta}</Text>
                                                        </TouchableOpacity>
                                                    )
                                                })
                                                :
                                                <View style={{ alignItems: 'center' }}>
                                                    <Feather name='alert-triangle' color={'red'} size={40} />
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginTop: 10, textAlign: 'center' }}>C'è stato un errore nella ricezione dei dati.{'\n'}Riprova più tardi</Text>
                                                </View>
                                        }
                                    </View>
                                    </>
                                    :
                                    (currentStep == 1)
                                    ?
                                    <>
                                    <View style={[styles.badcrump, { width: this.state.boxWidth - 40 }]}>
                                        <TouchableOpacity style={styles.buttonBack} onPress={() => this.setState({ currentStep: 0 })}>
                                            <Feather name='arrow-left' color={'#0070FF'} size={20} />
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF', marginLeft: 10 }}>Indietro</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{this.state.selectedStudio.nome_studio}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 19, textAlign: 'center', marginBottom: 15 }}>Seleziona un operatore</Text>
                                    <View style={styles.rowButtons}>
                                        {(this.state.loadingOperatori)
                                            ?
                                            <View style={{ alignItems: 'center' }}>
                                                <ActivityIndicator size='large' color={'#0070FF'} />
                                            </View>
                                            :
                                            (this.state.listOperatori.length > 0)
                                                ?
                                                this.state.listOperatori.map((item, index) => {
                                                    const isEven = index % 2 === 0;
                                                    const country_code = JSON.parse(item.country_code)
                                                    return(
                                                        <TouchableOpacity key={index} style={[styles.buttonSelect, {
                                                            width: (this.state.boxWidth / 2) - 30,
                                                            marginRight: (isEven) ? 20 : 0,
                                                            borderColor: (this.state.selectedOperatore !== null) ? (this.state.selectedOperatore.id == item.id) ? '#0070FF' : '#fff' : '#fff'
                                                        }]} onPress={() => this.selectOperatore(item)}>
                                                            {(item.image !== '' && item.image !== null)
                                                                ?
                                                                <Image source={{ uri: item.image }} resizeMode='cover' style={{ width: 50, height: 50, borderRadius: 30 }} />
                                                                :
                                                                <Image source={require('../assets/dental-automation.png')} resizeMode='cover' style={{ width: 50, height: 50, borderRadius: 30 }} />
                                                            }
                                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, textAlign: 'center', marginTop: 5 }}>{item.nome} {item.cognome}</Text>
                                                            {(item.email !== '')
                                                                &&
                                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginTop: 3, textAlign: 'center' }}>{item.email}</Text>
                                                            }
                                                            {(item.telefono !== '')
                                                                &&
                                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginTop: 3, textAlign: 'center' }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                                            }
                                                        </TouchableOpacity>
                                                    )
                                                })
                                                :
                                                <View style={{ alignItems: 'center' }}>
                                                    <Feather name='alert-triangle' color={'red'} size={40} />
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginTop: 10, textAlign: 'center' }}>C'è stato un errore nella ricezione dei dati.{'\n'}Riprova più tardi</Text>
                                                </View>
                                        }
                                    </View>
                                    </>
                                    :
                                    (currentStep == 2)
                                    ?
                                    <>
                                    <View style={[styles.badcrump, { width: this.state.boxWidth - 40 }]}>
                                        <TouchableOpacity style={styles.buttonBack} onPress={() => this.setState({ currentStep: 1 })}>
                                            <Feather name='arrow-left' color={'#0070FF'} size={20} />
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF', marginLeft: 10 }}>Indietro</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{this.state.selectedStudio.nome_studio}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{this.state.selectedOperatore.nome} {this.state.selectedOperatore.cognome}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    {(this.state.loadingAgenda)
                                        ?
                                        <View style={{ alignItems: 'center' }}>
                                            <ActivityIndicator size='large' color={'#0070FF'} />
                                        </View>
                                        :
                                        (this.state.isOrari)
                                        ?
                                        <Calendar
                                            style={{
                                                width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 80 : (Dimensions.get('screen').width / 3) - 20
                                            }}
                                            renderArrow={(direction) => <Feather color={'#0070FF'} size={30} name={(direction == 'left') ? 'chevron-left' : 'chevron-right'} />}
                                            onDayPress={day => {
                                                let oldDate = '2012-05-17'
                                                if (this.state.dateString !== null) {
                                                    oldDate = this.state.dateString
                                                }
                                                const updatedDates = this.updateDateInJson(dates, oldDate, day.dateString);
                                                this.setState({
                                                    dateSelect: day.timestamp,
                                                    dateSelectString: updatedDates,
                                                    dateString: day.dateString
                                                })

                                                setTimeout(() => {
                                                    this.getSlots(day.timestamp)
                                                    this.setState({ currentStep: 3 })
                                                }, 500);
                                            }}
                                            theme={{
                                                selectedDayBackgroundColor: '#0070FF',
                                                selectedDayTextColor: '#fff',
                                                todayTextColor: '#0070FF',
                                                textDayFontFamily: 'DM-Medium',
                                                textMonthFontFamily: 'DM-Bold',
                                                textDayHeaderFontFamily: 'DM-Medium',
                                                textDayFontSize: 16,
                                                textMonthFontSize: 17,
                                                textDayHeaderFontSize: 14
                                            }}
                                            firstDay={1}
                                            minDate={moment(new Date).format('YYYY-MM-DD')}
                                            markedDates={(this.state.dateSelectString !== null) ? {...this.state.dateSelectString, ...this.state.dateDisabled} : this.state.dateDisabled}
                                        />
                                        :
                                        <View style={{ alignItems: 'center' }}>
                                            <Feather name='alert-triangle' color={'red'} size={40} />
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginTop: 10, textAlign: 'center' }}>C'è stato un errore nella ricezione dei dati.{'\n'}Riprova più tardi</Text>
                                        </View>
                                    }
                                    </>
                                    :
                                    (currentStep == 3)
                                    ?
                                    <>
                                    <View style={[styles.badcrump, { width: this.state.boxWidth - 40 }]}>
                                        <TouchableOpacity style={styles.buttonBack} onPress={() => this.setState({ currentStep: 2 })}>
                                            <Feather name='arrow-left' color={'#0070FF'} size={20} />
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF', marginLeft: 10 }}>Indietro</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{this.state.selectedStudio.nome_studio}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{this.state.selectedOperatore.nome} {this.state.selectedOperatore.cognome}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{moment(this.state.dateSelect).format('DD/MM/YYYY')}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    {(this.state.loadingSlot)
                                        ?
                                        <View style={{ alignItems: 'center' }}>
                                            <ActivityIndicator size='large' color={'#0070FF'} />
                                        </View>
                                        :
                                        (this.state.slots.length > 0)
                                            ?
                                            <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                                                {this.state.slots.map((item, index) => {
                                                    return (
                                                        <TouchableOpacity
                                                            key={index}
                                                            disabled={!item.enabled}
                                                            style={[styles.buttonSlot, {
                                                                backgroundColor: (item.enabled) ? (item.slot_start_time == this.state.selectedSlot) ? '#0070FF' : '#fff' : '#ededed',
                                                                width: (this.state.boxWidth - 25) / 4
                                                            }]}
                                                            onPress={() => this.selectSlot(item.slot_start_time)}
                                                        >
                                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, color: (item.enabled) ? (item.slot_start_time == this.state.selectedSlot) ? '#fff' : '#0070FF' : 'lightgrey' }}>{item.slot_start_time}</Text>
                                                        </TouchableOpacity>
                                                    )
                                                })}
                                            </View>
                                            :
                                            <View style={{ alignItems: 'center' }}>
                                                <Feather name='info' color={'orange'} size={40} />
                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginTop: 10, textAlign: 'center' }}>Non ci sono slot disponibili{'\n'}per la data selezionata</Text>
                                            </View>
                                    }
                                    </>
                                    :
                                    (currentStep == 4)
                                    ?
                                    <>
                                    <View style={[styles.badcrump, { width: this.state.boxWidth - 40 }]}>
                                        <TouchableOpacity style={styles.buttonBack} onPress={() => this.setState({ currentStep: 3 })}>
                                            <Feather name='arrow-left' color={'#0070FF'} size={20} />
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF', marginLeft: 10 }}>Indietro</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{this.state.selectedStudio.nome_studio}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{this.state.selectedOperatore.nome} {this.state.selectedOperatore.cognome}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{moment(this.state.dateSelect).format('DD/MM/YYYY')}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonBack}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>{this.state.selectedSlot}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{ width: this.state.boxWidth - 40 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Nome*</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholderTextColor='#bfbfbf'
                                            placeholder='Nome'
                                            value={this.state.nome}
                                            onChangeText={(nome) => this.setState({ nome })}
                                            autoCapitalize='words'
                                        />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Cognome*</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholderTextColor='#bfbfbf'
                                            placeholder='Cognome'
                                            value={this.state.cognome}
                                            onChangeText={(cognome) => this.setState({ cognome })}
                                            autoCapitalize='words'
                                        />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Email*</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholderTextColor='#bfbfbf'
                                            placeholder='Email'
                                            value={this.state.email}
                                            onChangeText={(email) => this.setState({ email: email.toLowerCase().trim() })}
                                            keyboardType='email-address'
                                            autoCapitalize='none'
                                        />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Telefono*</Text>
                                        <PhoneInput
                                            ref={this.phoneRef}
                                            defaultCode="IT"
                                            layout="first"
                                            withDarkTheme={false}
                                            withShadow={false}
                                            placeholder='Numero di cellulare'
                                            containerStyle={{ width: '100%', borderTopRightRadius: 10, borderBottomRightRadius: 10, marginBottom: 20 }}
                                            textContainerStyle={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, paddingVertical: 10, height: 55, backgroundColor: '#f5f5f5' }}
                                            textInputStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                                            codeTextStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                                            flagButtonStyle={{ backgroundColor: '#e8e8e8', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, width: (Dimensions.get('screen').width <= 500) ? '26%' : '20%' }}
                                            filterProps={{ placeholder: 'Cerca nazione o prefisso' }}
                                            value={this.state.telefono}
                                            onChangeText={(telefono) => this.setState({ telefono })}
                                            onChangeCountry={(country) => this.setState({ country })}
                                        />
                                        <View style={{ alignItems: 'center' }}>
                                            <TouchableOpacity style={[styles.button, {
                                                width: (Dimensions.get('screen').width <= 500) ? '100%' : '60%'
                                            }]} onPress={this.saveAppuntamento}>
                                                {(this.state.loadingSend)
                                                    ?
                                                    <ActivityIndicator color='#fff' size='small' />
                                                    :
                                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva e continua</Text>
                                                }
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    </>
                                    :
                                    <View style={{ alignItems: 'center' }}>
                                        <Feather name='check-circle' color={'green'} size={40} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginTop: 10, textAlign: 'center' }}>Grazie per averci scelto{'\n'}L'appuntamento è stato salvato con successo</Text>
                                        <TouchableOpacity style={styles.button} onPress={() => window.location.reload()}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Nuovo appuntamento</Text>
                                        </TouchableOpacity>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF',
        overflow: 'hidden',
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: 30,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    buttonTermini: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    containerLogo: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems:'center',
        width: 85,
        height: 85,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 10
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 2,
        right: - Dimensions.get('screen').width / 2,
        zIndex: -1,
        overflow: 'hidden'
    },
    circle: {
        backgroundColor: '#8d4472',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').width,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
    containerStep: {
        backgroundColor: '#fff',
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width / 3,
        borderRadius: 20,
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonSelect: {
        backgroundColor: '#fff',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        borderRadius: 10,
        marginBottom: 20,
        height: 'auto',
        padding: 15,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2.5
    },
    rowButtons: {
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    badcrump: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    buttonBack: {
        borderColor: '#0070FF',
        borderWidth: 2,
        backgroundColor: '#fff',
        borderRadius: 10,
        paddingHorizontal: 15,
        paddingVertical: 3,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 15,
        marginRight: 15
    },
    buttonSlot: {
        borderRadius: 10,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 15,
        marginRight: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    }
});