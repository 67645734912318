import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, TextInput, Platform, FlatList, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import 'moment/locale/it';
import DateTimePicker from '@react-native-community/datetimepicker';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { MaskedTextInput } from "react-native-mask-text";
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class RichiamiScreen extends React.Component {
    constructor(props) {
        super(props)
        this.searchInput = React.createRef()
        this.searchPaziente = React.createRef()
        this.state = {
            loading: true,
            loadingData: false,
            listRichiami: [],
            storyStudi: [],
            showModal: false,
            cerca: '',
            searchResult: [],
            cercaRichiamo: '',
            loadingSearch: false,
            page: 1,
            isLoading: false,
            loadingFinish: false,
            isFiltri: false,
            image: '',
            idPaziente: '',
            dataIgene: '',
            esito: '',
            cap: '',
            descrizione: '',
            idRichiamo: '',
            loadingActive: false,
            isEdit: false,
            cercaPaziente: '',
            loadingPaziente: false,
            searchResultPaziente: [],
            widthTitle: 0,
            cercaData: false
        }
    }

    componentDidMount() {
        this.getRichiami()
    }

    getRichiami = async () => {
        let listRichiami = this.state.listRichiami;
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', this.state.page)
        axios.post('https://www.visitreporter.com/app/get_richiami.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loading: false })
            if (json.length > 0) {
                listRichiami = [...listRichiami, ...json];
                this.setState({ listRichiami: listRichiami, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    searchRichiami = async (search) => {
        let searchItem = '';
        if (this.state.cercaData) {
            searchItem = `${search.split('/')[2]}-${search.split('/')[1]}-${search.split('/')[0]}`;
        } else {
            searchItem = search;
        }
        this.setState({ cercaRichiamo: search, loadingSearch: true, loading: true, page: 1 })
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('search_item', searchItem);
        formData.append('page', 1);
        axios.post('https://www.visitreporter.com/app/search_richiami.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loadingSearch: false, loading: false, isFiltri: true })
            if (json.length > 0) {
                this.setState({ listRichiami: json })
            } else {
                this.setState({ listRichiami: [] })
            }
        }).catch((error) => {
            this.setState({ loadingSearch: false, loading: false })
            console.error(error);
        })
    }

    searchRichiamiContinue = async () => {
        let listRichiami = this.state.listRichiami;
        let searchItem = '';
        if (this.state.cercaData) {
            searchItem = `${this.state.cercaRichiamo.split('/')[2]}-${this.state.cercaRichiamo.split('/')[1]}-${this.state.cercaRichiamo.split('/')[0]}`;
        } else {
            searchItem = this.state.cercaRichiamo;
        }
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('search_item', searchItem);
        formData.append('page', this.state.page);
        axios.post('https://www.visitreporter.com/app/search_richiami.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                listRichiami = [...listRichiami, ...json];
                this.setState({ listRichiami: listRichiami, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    handleDeleteRequest = (user, index) => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare il richiamo?") == true) {
            this.handleDelete(user, index)
        }
    }

    handleDelete = async (user, index) => {
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', user);
        axios.post('https://www.visitreporter.com/app/delete_richiamo.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                const listRichiami = this.state.listRichiami;
                listRichiami.splice(index, 1)
                this.setState({ listRichiami })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    handleAddRichiamo = async () => {
        if (this.state.idPaziente !== '') {
            this.setState({ loadingActive: true })
            const id = await AsyncStorage.getItem('id')
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('id_paziente', this.state.idPaziente);
            formData.append('data_igene', (this.state.dataIgene !== null && this.state.dataIgene !== '') ? Moment(new Date(`${this.state.dataIgene.split('/')[2]}-${this.state.dataIgene.split('/')[1]}-${this.state.dataIgene.split('/')[0]}`)).format('YYYY-MM-DD') : '');
            axios.post('https://www.visitreporter.com/app/add_richiamo.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.resetListPage()
                    this.setState({ loadingActive: false, showModal: false, isEdit: false, idPaziente: '', dataIgene: '', cercaPaziente: '' })
                } else {
                    this.setState({ loadingActive: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loadingActive: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    handleEditRichiamo = async () => {
        if (this.state.idRichiamo !== '' && this.state.idPaziente !== '' && this.state.esito !== null) {
            this.setState({ loadingActive: true })
            const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_richiamo', this.state.idRichiamo);
            formData.append('id_paziente', this.state.idPaziente);
            formData.append('data_igene', (this.state.dataIgene !== null && this.state.dataIgene !== '') ? Moment(new Date(`${this.state.dataIgene.split('/')[2]}-${this.state.dataIgene.split('/')[1]}-${this.state.dataIgene.split('/')[0]}`)).format('YYYY-MM-DD') : '');
            formData.append('esito', this.state.esito);
            axios.post('https://www.visitreporter.com/app/edit_richiamo.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.resetListPage()
                    this.setState({ loadingActive: false, showModal: false, isEdit: false, idPaziente: '', dataIgene: '', esito: '', cercaPaziente: '' })
                    alert(`Richiamo aggioranto! ${json.message}`)
                } else {
                    this.setState({ loadingActive: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loadingActive: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Compila tutti i campi obbligatori`)
        }
    }

    renderItem = ({item, index}) => {
        const isEven = index % 2 === 0;
        return(
            <View key={index} style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 10, justifyContent: 'space-between', marginBottom: 15, marginRight: (!isEven) ? 0 : '6%' }]}>
                <View style={{ width: (Dimensions.get('screen').width <= 500) ? '65%' : 'auto' }}>
                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{item.nome} {item.cognome}</Text>
                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, color: (item.active == "1" && item.active == 1) ? 'green' : 'red' }}>{(item.active == "1" || item.active == 1) ? 'Attivo' : 'Disattivo'}</Text>
                    {(item.data_igene !== null && item.data_igene !== "")
                        &&
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12 }}>Data prox. appuntamento: {Moment(item.data_igene).format('DD/MM/YYYY')}</Text>
                    }
                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Esito: {(item.esito == 1 || item.esito == "1") ? 'Scaduto' : 'In corso'}</Text>
                    {(item.data_esito !== null && item.data_esito !== "" && (item.esito == 1 || item.esito == "1"))
                        &&
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Scade il {Moment(item.data_esito).format('DD/MM/YYYY')}</Text>
                    }
                </View>
                <View>
                    <TouchableOpacity style={[styles.buttonMore, { marginBottom: 8 }]} onPress={() => this.setState({ isEdit: true, idRichiamo: item.id, idPaziente: item.id_paziente, dataIgene: Moment(item.data_igene).format('DD/MM/YYYY'), esito: item.esito, cercaPaziente: `${item.nome} ${item.cognome}`, showModal: true })}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Modifica</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fc3535' }]} onPress={() => this.handleDeleteRequest(item.id, index)}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Elimina</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    renderFooter = () => {
        return(
            this.state.isLoading && !this.state.loadingFinish ?
            <View style={{ marginTop: 10, alignItems: 'center' }}>
                <ActivityIndicator size='small' color='#FFF' />
            </View> : null
        );
    }

    handleMore = () => {
        if (!this.state.isFiltri) {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.getRichiami()
            }, 1000);
        } else {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.searchRichiamiContinue()
            }, 1000);
        }
    }

    resetListPage = () => {
        this.setState({ page: 1, loading: true, listRichiami: [], isFiltri: false, loadingSearch: false, cercaRichiamo: '' })
        setTimeout(() => {
            this.getRichiami()
        }, 1000);
    }

    continuePatientsQueryLike = async (searchText) => {
        this.setState({ cercaPaziente: searchText, loadingPaziente: true })
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        axios.post('https://www.visitreporter.com/app/search_pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ searchResultPaziente: json, loadingPaziente: false })
            } else {
                this.setState({ searchResultPaziente: [], loadingPaziente: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    selectPaziente = (uid, nome, cognome, item) => {
        this.setState({ idPaziente: uid, searchResultPaziente: [], cercaPaziente: `${nome} ${cognome}` })
        this.searchPaziente.current.blur()
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Richiami</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backButton} onPress={() => this.setState({ showModal: true, isEdit: false })}>
                        <AntDesign name='pluscircleo' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Nuovo richiamo</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginBottom: 25, paddingTop: 20, paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={[styles.containerTitle, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%' }]} onLayout={(event) => {
                        const {x, y, width, height} = event.nativeEvent.layout;
                        this.setState({ widthTitle: width })
                    }}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#0070FF' }}>RICHIAMI IGENE</Text>
                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>Gestisci lo scadenziario dei richiami d'igene</Text>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={{ alignItems: 'flex-end', marginBottom: 10 }}>
                        <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fff' }]} onPress={() => this.setState({ cercaData: !this.state.cercaData })}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>
                                {(this.state.cercaData)
                                    ?
                                    'Cerca per nome'
                                    :
                                    'Cerca per data'
                                }
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                        <View style={styles.containerIcon}>
                            {(this.state.loadingSearch)
                                ?
                                <ActivityIndicator size='small' color='#000' />
                                :
                                <AntDesign name='search1' color='#000' size={23} />
                            }
                        </View>
                        {(this.state.cercaData)
                            ?
                            <MaskedTextInput
                                mask="99/99/9999"
                                value={this.state.cercaRichiamo}
                                onChangeText={(cerca) => this.searchRichiami(cerca)}
                                placeholderTextColor='#bfbfbf'
                                placeholder={`Es. ${Moment(new Date()).format('DD/MM/YYYY')}`}
                                style={[styles.input, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0, width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 160 : (this.state.widthTitle - 120), marginRight: 10, backgroundColor: '#FFF' }]}
                                keyboardType='number-pad'
                            />
                            :
                            <TextInput
                                style={[styles.input, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0, width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 160 : (this.state.widthTitle - 120), marginRight: 10, backgroundColor: '#FFF' }]}
                                placeholderTextColor='#D3D6D7'
                                placeholder='Cerca il richiamo'
                                value={this.state.cercaRichiamo}
                                onChangeText={(cerca) => this.searchRichiami(cerca)}
                            />
                        }
                        <TouchableOpacity disabled={(this.state.cercaRichiamo !== '') ? false : true} style={[styles.backButton, { backgroundColor: (this.state.cercaRichiamo !== '') ? '#FFF' : '#D3D6D7', width: 55, height: 55, paddingHorizontal: 0 }]} onPress={this.resetListPage}>
                            <AntDesign name='closecircleo' color={(this.state.cercaRichiamo !== '') ? '#0070FF' : 'grey'} size={23} />
                        </TouchableOpacity>
                    </View>
                </View>
                {(this.state.loading)
                    ?
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                        <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'center' }]}>
                            <ActivityIndicator size='small' color='#0070FF' />
                        </View>
                    </View>
                    :
                    (this.state.listRichiami.length > 0)
                        ?
                        <FlatList
                            data={this.state.listRichiami}
                            renderItem={this.renderItem}
                            contentContainerStyle={{ 
                                paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150,
                                paddingTop: 10,
                                paddingBottom: Dimensions.get('screen').height / 4
                            }}
                            numColumns={(Dimensions.get('screen').width <= 500) ? 1 : 2}
                            keyExtractor={(item, index) => index.toString()}
                            showsVerticalScrollIndicator={false}
                            ListFooterComponent={this.renderFooter}
                            onEndReachedThreshold={0.5}
                            onEndReached={this.handleMore}
                        />
                        :
                        <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                            <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20 }]}>
                                <Feather name='alert-triangle' color='#fc3535' size={30} />
                                <View style={{ marginLeft: 15 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Non sono presenti richiami</Text>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Aggiungi il tuo primo richiamo</Text>
                                </View>
                            </View>
                        </View>
                }
                <View style={styles.positionCricle}>
                    <View style={styles.circle}></View>
                </View>
                <Modal
                    visible={this.state.showModal}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={() => {
                        this.setState({ showModal: false, idPaziente: '', dataIgene: '', esito: '', cercaPaziente: '' })
                    }}
                >
                    <View style={[styles.container, { backgroundColor: '#FFF' }]}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={() => this.setState({ showModal: false, idPaziente: '', dataIgene: '', esito: '', cercaPaziente: '' })}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { marginBottom: Dimensions.get('screen').height / 3 }]}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Seleziona il paziente*</Text>
                                    {(this.state.loadingPaziente)
                                        &&
                                        <ActivityIndicator size='small' color='#000' />
                                    }
                                </View>
                                <TextInput
                                    style={[styles.input, { borderBottomLeftRadius: (this.state.searchResultPaziente.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResultPaziente.length > 0) ? 0 : 10 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Inserisci il nome del Paziente'c
                                    value={this.state.cercaPaziente}
                                    //onChangeText={(cercaPaziente) => {this.setState({ cercaPaziente }); this.filterResult(cercaPaziente)}}
                                    onChangeText={(cercaPaziente) => this.continuePatientsQueryLike(cercaPaziente)}
                                    ref={this.searchPaziente}
                                    //editable={(this.state.loadingData) ? false : true}
                                />
                                {(this.state.searchResultPaziente.length > 0)
                                    &&
                                    this.state.searchResultPaziente.map((item, index) => {
                                        let counter = index + 1;
                                        const country_code = JSON.parse(item.country_code)
                                        return(
                                            <TouchButton onPress={() => this.selectPaziente(item.id, item.nome.trim(), item.cognome.trim(), item)} key={item.id} style={[styles.listUser, {
                                                borderBottomLeftRadius: (counter == this.state.searchResultPaziente.length) ? 10 : 0,
                                                borderBottomRightRadius: (counter == this.state.searchResultPaziente.length) ? 10 : 0
                                            }]}>
                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>
                                                    {item.nome.trim()} {item.cognome.trim()}{'\n'}
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                                </Text>
                                            </TouchButton>
                                        )
                                    })
                                }
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, marginTop: 20 }}>Data prox. appuntamento</Text>
                                <MaskedTextInput
                                    mask="99/99/9999"
                                    value={this.state.dataIgene}
                                    onChangeText={(dataIgene) => this.setState({ dataIgene })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder={`Es. ${Moment(new Date()).format('DD/MM/YYYY')}`}
                                    style={[styles.input, { marginBottom: 20 }]}
                                    keyboardType='number-pad'
                                />
                                {(this.state.isEdit)
                                    &&
                                    <>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Esito*</Text>
                                    <RNPickerSelect
                                        placeholder={{ label: "Seleziona l'esito", value: null }}
                                        doneText='Fine'
                                        value={this.state.esito}
                                        style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                        onValueChange={(esito) => this.setState({ esito })}
                                        items={[
                                            { label: 'In corso', value: '0' },
                                            { label: 'Scaduto', value: '1' }
                                        ]}
                                    />
                                    </>
                                }
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={(this.state.isEdit) ? this.handleEditRichiamo : this.handleAddRichiamo}>
                                        {(this.state.loadingActive)
                                            ?
                                            <ActivityIndicator color='#fff' size='small' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva</Text>
                                        }
                                    </TouchButton>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF',
        overflow: 'hidden'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    inputDate: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerIcon: {
        backgroundColor: '#fff',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        width: 55,
        height: 55,
        alignItems: 'center',
        justifyContent: 'center'
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 2,
        right: - Dimensions.get('screen').width / 2,
        zIndex: -1,
        overflow: 'hidden'
    },
    circle: {
        backgroundColor: '#8d4472',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').width,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
    containerTitle: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    headImage: {
        paddingBottom: 15,
        paddingHorizontal: 20,
        alignItems: 'center'
    },
    containerImage: {
        width: 100,
        height: 100,
        borderRadius: 15,
        backgroundColor: '#ffffff1c',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 2
    },
});