import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, TextInput, Platform, FlatList, Image } from 'react-native';
import { ActivityIndicator, Dialog, Button } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import 'moment/locale/it';
import DateTimePicker from '@react-native-community/datetimepicker';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import {Calendar, LocaleConfig, Agenda} from 'react-native-calendars';
import AgendaComponent from './AgendaComponent';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { Calendar as CalendarAgenda, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Appuntamenti.css';
import CustomEvent from './CustomEvent';
import {default as ModalDismiss} from 'expo-react-native-modalbox';

const localizer = momentLocalizer(Moment);

const eventStyleGetter = (event) => {
    const backgroundColor = event.color || '#3174ad'; // Imposta un colore di default
    const color = (event.color == '#f8d54c') ? '#000' : '#fff'; 
  
    const style = {
      backgroundColor,
      color
    };
  
    return {
      style,
    };
};

const events = [
    {
      title: 'Meeting',
      start: new Date(2024, 7, 29, 10, 0),  // Note: Months are 0-indexed (July is 6)
      end: new Date(2024, 7, 29, 11, 0),
      color: 'orange'
    },
];

const lang = {
    week: 'Settimana',
    work_week: 'Settimana di lavoro',
    day: 'Giorno',
    month: 'Mese',
    previous: 'Precedente',
    next: 'Prossimo',
    today: 'Oggi',
    agenda: 'Agenda',

    showMore: (total) => `+${total} totale`,
}

LocaleConfig.locales['it'] = {
    monthNames: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ],
    monthNamesShort: ['Genn.', 'Febb.', 'Marzo', 'Apr.', 'Magg.', 'Giug.', 'Lugl.', 'Agos.', 'Sett.', 'Ott.', 'Nov.', 'Dic.'],
    dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    today: "Oggi"
};

LocaleConfig.defaultLocale = 'it';

export default class AppuntamentiScreen extends React.Component {
    constructor(props) {
        super(props);
        this.searchInputUser = React.createRef()
        this.phoneRef = React.createRef()
        this.state = {
            listAppuntamenti: {},
            loading: true,
            selectedDateAgenda: '',
            showModal: false,
            isEdit: false,
            selectedAppuntamento: null,
            isLoading: true,
            loadingData: false,
            loadingFinish: false,
            isFiltri: false,
            loadingActive: false,
            dateSelect: null,
            dateSelectString: null,
            dateString: null,
            selectedStudio: null,
            listStudi: [],
            loadingStudi: true,
            listOperatori: [],
            loadingOperatori: true,
            selectedOperatore: null,
            isOrari: false,
            loadingAgenda: true,
            loadingSlot: true,
            slots: [],
            selectedSlot: '',
            email: '',
            nome: '',
            cognome: '',
            telefono: '',
            country: {
                "callingCode": [
                    "39",
                ],
                "cca2": "IT",
                "currency": [
                    "EUR",
                ],
                "flag": "flag-it",
                "name": "Italy",
                "region": "Europe",
                "subregion": "Southern Europe"
            },
            loadingSend: false,
            dateDisabled: null,
            isUser: true,
            idPaziente: '',
            cercaUser: '',
            pazienteSelect: null,
            idAppuntamento: '',
            searchResult: [],
            promemoria: [],//[{ "id": "56", "url": "https://hkdk.events/cuizz966kwjcjl" }],
            loadingAutomazioni: true,
            listAutomazioni: [],
            fullAutomazioni: [],
            secondAutomazione: null,
            automazioniAttive: [],
            stato: 0,
            showStato: false,
            timestumoMonth: null,
            showDetail: false,
            eventDetail: {}
        }
    }

    componentDidMount() {
        this.getAppuntamenti(new Date())
    }

    getAppuntamenti = async (date) => {
        this.setState({ isLoading: true, listAppuntamenti: [] })
        const inizioMese = Moment(date).startOf('month').format('YYYY-MM-DD');
        const fineMese = Moment(date).endOf('month').format('YYYY-MM-DD');
        let listAppuntamenti = [];
        const id = await AsyncStorage.getItem('id');
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex);
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('data_inizio', inizioMese);
        formData.append('data_fine', fineMese);
        axios.post('https://www.visitreporter.com/app/get_appuntamenti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                /*{
                    title: 'Meeting',
                    start: new Date(2024, 7, 29, 10, 0),  // Note: Months are 0-indexed (July is 6)
                    end: new Date(2024, 7, 29, 11, 0),
                    color: 'orange'
                  },*/
                json.forEach((item) => {
                    const country_code = JSON.parse(item.country_code).callingCode[0]
                    const date = Moment(item.data_appuntamento).format('YYYY-MM-DD');
                    const time_slot = `${item.time_appuntamento.split(':')[0]}:${item.time_appuntamento.split(':')[1]}`;
                    const dateString = `${date} ${item.time_appuntamento}`;

                    const dateAppuntamento = Moment(dateString, "YYYY-MM-DD HH:mm:ss").toDate();
    
                    listAppuntamenti.push({
                        title: `${item.mansione} - ${item.nome} ${item.cognome}`,
                        description: `${item.email}\n+${country_code}${item.telefono}\nStudio: ${item.nome_studio}\nOperatore: ${item.nome_operatore} ${item.cognome_operatore}`,
                        start: dateAppuntamento,
                        end: dateAppuntamento,
                        color: (item.stato == 0) ? '#f8d54c' : (item.stato == 1) ? 'green' : '#fc3535',
                        ...item
                    })
                });
                this.setState({ listAppuntamenti, loading: false, isLoading: false });
            } else {
                this.setState({ loading: false, isLoading: false });
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false });
            console.error(error);
        });
    }

    getAppuntamentiReload = async (date) => {
        this.setState({ isLoading: true, listAppuntamenti: {} })
        const inizioMese = Moment(date).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        const fineMese = Moment(date).add(1, 'month').endOf('month').format('YYYY-MM-DD');
        let listAppuntamenti = {};
        const id = await AsyncStorage.getItem('id');
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex);
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('data_inizio', inizioMese);
        formData.append('data_fine', fineMese);
        axios.post('https://www.visitreporter.com/app/get_appuntamenti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                json.forEach((item) => {
                    const date = Moment(item.data_appuntamento).format('YYYY-MM-DD');
                    const time_slot = `${item.time_appuntamento.split(':')[0]}:${item.time_appuntamento.split(':')[1]}`;
    
                    if (!listAppuntamenti[date]) {
                        listAppuntamenti[date] = [];
                    }
    
                    // Verifica se l'appuntamento è già presente per evitare duplicati
                    const isAlreadyPresent = listAppuntamenti[date].some(app => app.id === item.id);
                    if (!isAlreadyPresent) {
                        listAppuntamenti[date].push({
                            ...item,
                            time_slot,
                        });
                    }
                });
                this.setState({ listAppuntamenti, loading: false, isLoading: false });
            } else {
                this.setState({ loading: false, isLoading: false });
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false });
            console.error(error);
        });
    }

    selectAppuntamento = (item, country_code) => {
        this.getAutomazioniAppuntamento(item.id)
        this.getAutomazioni()
        this.getListAutomazioni()
        this.getFerieEdit(item.id_operatore)
        const timestump = Moment(item.data_appuntamento, 'YYYY-MM-DD').valueOf();
        this.getSlotsEdit(timestump, item.id_operatore)
        const updatedDates = this.createDateSelectionObject(item.data_appuntamento);

        this.setState({
            showDetail: false,
            eventDetail: {},
            selectedOperatore: item.id_operatore,
            dateString: item.data_appuntamento,
            dateSelect: timestump,
            selectedSlot: item.time_slot,
            idAppuntamento: item.id,
            nome: item.nome,
            cognome: item.cognome,
            email: item.email,
            telefono: item.telefono,
            country: country_code,
            isUser: false,
            isEdit: true,
            dateSelectString: updatedDates,
            isOrari: true,
            showModal: true,
            stato: item.stato,
            showStato: (item.stato == 1 || item.stato == "1") ? false : true
        })
    }

    handleDeleteRequest = (user, item) => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare l'appuntamento?") == true) {
            this.handleDelete(user, item)
        }
    }

    handleDelete = async (user, item) => {
        const resultData = await this.getResultAutomazioniApp(user)        
        if (resultData !== null) {
            await Promise.all(resultData.map(async element => {
                const resultFullAutomation = await this.getResultListAutomazioni();
                let secondAutomazioneData = {};
    
                if (element.url_automation !== null && element.url_automation !== '') {
                    const indexSecondAutomazione = resultFullAutomation.findIndex(e => e.url === element.url_automation);
                    if (indexSecondAutomazione !== -1) {
                        secondAutomazioneData = {
                            id: resultFullAutomation[indexSecondAutomazione].id,
                            url: resultFullAutomation[indexSecondAutomazione].url,
                            nome: resultFullAutomation[indexSecondAutomazione].nome
                        };
                    }
                }
    
                const dateToSend = `${Moment(item.data_appuntamento).format('DD-MM-YYYY')} ${item.time_slot}`;
                const country = JSON.parse(item.country_code).callingCode[0];
    
                this.disattivaAutomazioneDate(element.id, dateToSend, item.nome, item.cognome, item.telefono, country, item.email, element.url, element.url_automation, element.nome, secondAutomazioneData)
            }));

            this.deleteAppuntamentoReuqest(user, item)
        } else {
            this.deleteAppuntamentoReuqest(user, item)
        }
    }

    deleteAppuntamentoReuqest = async (user, item) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', user);
        axios.post('https://www.visitreporter.com/app/delete_appuntamento.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({
                showDetail: false,
                eventDetail: {}
            })
            if (this.state.timestumoMonth !== null) {
                this.getAppuntamenti(this.state.timestumoMonth)
            } else {
                this.getAppuntamenti(new Date())
            }
            /*if (json.code == true) {
                this.deleteAppuntamento(user)
            }*/
        }).catch((error) => {
            console.error(error);
        })
    }

    createDateSelectionObject = (dateString) => {
        // Crea un oggetto con la data come chiave e { selected: true } come valore
        const selectionObject = {
            [dateString]: { selected: true }
        };
        
        return selectionObject;
    }

    getStudi = async () => {
        this.setState({ loadingStudi: true })
        const idStudio = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', idStudio);
        axios.post('https://www.visitreporter.com/app/get_studi_all.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ listStudi: json, loadingStudi: false })
            } else {
                this.setState({ listStudi: [], loadingStudi: false })
            }
        }).catch((error) => {
            this.setState({ listStudi: [], loadingStudi: false })
            console.error(error);
        })
    }

    getOperatori = async () => {
        if (this.state.selectedStudio !== null && this.state.selectedStudio !== '') {
            this.setState({ loadingOperatori: true })
            const idStudio = await AsyncStorage.getItem('id')
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', idStudio);
            formData.append('id_studio', this.state.selectedStudio)
            axios.post('https://www.visitreporter.com/app/get_operatori_all.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.length > 0) {
                    this.setState({ listOperatori: json, loadingOperatori: false })
                } else {
                    this.setState({ listOperatori: [], loadingOperatori: false })
                }
            }).catch((error) => {
                this.setState({ listOperatori: [], loadingOperatori: false })
                console.error(error);
            })
        }
    }

    getOrariOperatoreCheck = async () => {
        if (this.state.selectedOperatore !== null && this.state.selectedOperatore !== '') {
            this.setState({ loadingAgenda: true })
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_operatore', this.state.selectedOperatore);
            axios.post('https://www.visitreporter.com/app/get_orari_operatore_check.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    this.setState({ isOrari: true, loadingAgenda: false })
                } else {
                    this.setState({ isOrari: false, loadingAgenda: false })
                }
            }).catch((error) => {
                this.setState({ isOrari: false, loadingAgenda: false })
                console.error(error);
            })
        }
    }

    getFerie = async () => {
        if (this.state.selectedOperatore !== null && this.state.selectedOperatore !== '') {
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_operatore', this.state.selectedOperatore);
            axios.post('https://www.visitreporter.com/app/get_ferie.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.length > 0) {
                    let markedDates = {};
                    json.forEach(element => {
                        const arrayDates = this.getDatesInRange(element.date_start, element.date_end)
                        arrayDates.forEach(dates => {
                            const saveDate = this.formatDate(dates)
                            markedDates[saveDate] = { disabled: true, disableTouchEvent: true };
                        });
                    });
                    this.setState({ dateDisabled: markedDates })
                } else {
                    this.setState({ dateDisabled: null })
                }
            }).catch((error) => {
                this.setState({ dateDisabled: null })
                console.error(error);
            })
        }
    }

    getFerieEdit = async (id_operatore) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', id_operatore);
        axios.post('https://www.visitreporter.com/app/get_ferie.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                let markedDates = {};
                json.forEach(element => {
                    const arrayDates = this.getDatesInRange(element.date_start, element.date_end)
                    arrayDates.forEach(dates => {
                        const saveDate = this.formatDate(dates)
                        markedDates[saveDate] = { disabled: true, disableTouchEvent: true };
                    });
                });
                this.setState({ dateDisabled: markedDates })
            } else {
                this.setState({ dateDisabled: null })
            }
        }).catch((error) => {
            this.setState({ dateDisabled: null })
            console.error(error);
        })
    }

    removeAccent = (str) => {
        return str.replace(/[ì]/g, 'i');
    }

    getSlots = async (timestamp) => {
        this.setState({ loadingSlot: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', this.state.selectedOperatore);
        formData.append('day_name', this.removeAccent(Moment(timestamp).format('dddd')))
        formData.append('date_select', Moment(timestamp).format('YYYY-MM-DD'))
        axios.post('https://www.visitreporter.com/app/get_operatore_time_slot.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                if (this.state.isEdit) {
                    this.setState({ slots: json, loadingSlot: false })
                } else {
                    const filter = json.filter(e => e.enabled == true)
                    this.setState({ slots: filter, loadingSlot: false })
                }
            } else {
                this.setState({ slots: [], loadingSlot: false })
            }
        }).catch((error) => {
            this.setState({ slots: [], loadingSlot: false })
            console.error(error);
        })
    }

    getSlotsEdit = async (timestamp, id_operatore) => {
        this.setState({ loadingSlot: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', id_operatore);
        formData.append('day_name', this.removeAccent(Moment(timestamp).format('dddd')))
        formData.append('date_select', Moment(timestamp).format('YYYY-MM-DD'))
        axios.post('https://www.visitreporter.com/app/get_operatore_time_slot.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ slots: json, loadingSlot: false })
            } else {
                this.setState({ slots: [], loadingSlot: false })
            }
        }).catch((error) => {
            this.setState({ slots: [], loadingSlot: false })
            console.error(error);
        })
    }

    updateDateInJson(jsonObject, oldDate, newDate) {
        // Copia il contenuto della vecchia data in una nuova chiave (nuova data)
        jsonObject[newDate] = jsonObject[oldDate];
      
        // Elimina la vecchia data
        delete jsonObject[oldDate];
        
        return jsonObject;
    }

    getDatesInRange = (startDate, endDate) => {
        // Converte le stringhe in oggetti Date
        const start = new Date(startDate);
        const end = new Date(endDate);
      
        // Array per memorizzare tutte le date
        const dateArray = [];
      
        // Itera attraverso le date, aggiungendo ogni giorno all'array
        while (start <= end) {
          // Clona l'oggetto Date per evitare mutazioni
          dateArray.push(new Date(start));
      
          // Incrementa la data di un giorno
          start.setDate(start.getDate() + 1);
        }
      
        return dateArray;
    }

    formatDate = (date) => {
        return date.toISOString().split('T')[0];
    }

    continuePatientsQueryLike = async (searchText) => {
        this.setState({ cercaUser: searchText, loadingData: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        axios.post('https://www.visitreporter.com/app/search_pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ searchResult: json, loadingData: false })
            } else {
                this.setState({ searchResult: [], loadingData: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    selectPaziente = (uid, nome, cognome, item) => {
        this.setState({ idPaziente: uid, searchResult: [], cercaUser: `${nome} ${cognome}`, pazienteSelect: item })
        this.searchInputUser.current.blur()
    }

    saveAppuntamento = async () => {
        if (this.state.selectedStudio !== null && this.state.selectedOperatore !== null && this.state.dateSelect !== null && this.state.selectedSlot !== '') {
            if (this.state.isUser) {
                if (this.state.pazienteSelect !== null) {
                    this.saveAppuntamentoSend()
                } else {
                    alert("Attenzione! Compila tutti i campi obbligatori")
                }
            } else {
                if (this.state.nome !== '' && this.state.cognome !== '' && this.email !== '' && this.state.telefono !== '') {
                    const isValidaNumber = this.phoneRef.current.isValidNumber(this.state.telefono)
                    if (isValidaNumber == true) {
                        this.saveAppuntamentoSend()
                    } else {
                        alert("Attenzione! Il numero di telefono inserito non è valido")
                    }
                } else {
                    alert("Attenzione! Compila tutti i campi obbligatori")
                }
            }
        } else {
            alert("Attenzione! Compila tutti i campi obbligatori")
        }
    }

    saveAppuntamentoSend = async () => {
        this.setState({ loadingSend: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_operatore', this.state.selectedOperatore);
        formData.append('date_select', Moment(this.state.dateSelect).format('YYYY-MM-DD'))
        formData.append('time_select', this.state.selectedSlot);
        formData.append('nome', (this.state.isUser) ? this.state.pazienteSelect.nome : this.state.nome)
        formData.append('cognome', (this.state.isUser) ? this.state.pazienteSelect.cognome : this.state.cognome)
        formData.append('telefono', (this.state.isUser) ? this.state.pazienteSelect.telefono : this.state.telefono)
        formData.append('email', (this.state.isUser) ? this.state.pazienteSelect.email : this.state.email)
        formData.append('country_code', (this.state.isUser) ? this.state.pazienteSelect.country_code : JSON.stringify(this.state.country))
        formData.append('stato', 1)
        axios.post('https://www.visitreporter.com/app/save_appuntamento.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                if (this.state.timestumoMonth !== null) {
                    this.getAppuntamenti(this.state.timestumoMonth)
                } else {
                    this.getAppuntamenti(new Date())
                }

                const user = JSON.stringify({ nome: this.state.nome, cognome: this.state.cognome, email: this.state.email, telefono: this.state.telefono, country_code: this.state.country })
                let userAutomation = {};
                let nomeCliente = '';
                if (this.state.isUser) {
                    nomeCliente = `${this.state.pazienteSelect.nome} ${this.state.pazienteSelect.cognome}`;
                    userAutomation = {
                        nome: this.state.pazienteSelect.nome,
                        cognome: this.state.pazienteSelect.cognome,
                        email: this.state.pazienteSelect.email,
                        telefono: `${JSON.parse(this.state.pazienteSelect.country_code).callingCode[0]}${this.state.pazienteSelect.telefono}`
                    }
                } else {
                    nomeCliente = `${this.state.nome} ${this.state.cognome}`;
                    userAutomation = {
                        nome: this.state.nome,
                        cognome: this.state.cognome,
                        email: this.state.email,
                        telefono: `${this.state.country.callingCode[0]}${this.state.telefono}`
                    }
                }
                let secondAutomazioneData = {};
                if (this.state.secondAutomazione !== null && this.state.secondAutomazione !== '') {
                    const indexSecondAutomazione = this.state.fullAutomazioni.findIndex(e => e.url == this.state.secondAutomazione)
                    if (indexSecondAutomazione !== -1) {
                        secondAutomazioneData = {
                            id: this.state.fullAutomazioni[indexSecondAutomazione].id,
                            url: this.state.fullAutomazioni[indexSecondAutomazione].url,
                            nome: this.state.fullAutomazioni[indexSecondAutomazione].nome
                        }
                    }
                }
                this.addPromemoriaAndAutomazioni(this.state.promemoria, json.data.id, this.state.isUser, this.state.idPaziente, user, this.state.secondAutomazione, this.state.dateSelect, this.state.selectedSlot, nomeCliente, userAutomation, secondAutomazioneData)
                this.setState({ 
                    loadingSend: false,
                    showModal: false,
                    isEdit: false,
                    dateSelect: null,
                    dateSelectString: null,
                    dateString: null,
                    selectedStudio: null,
                    listStudi: [],
                    loadingStudi: true,
                    listOperatori: [],
                    loadingOperatori: true,
                    selectedOperatore: null,
                    isOrari: false,
                    loadingAgenda: true,
                    loadingSlot: true,
                    slots: [],
                    selectedSlot: '',
                    email: '',
                    nome: '',
                    cognome: '',
                    telefono: '',
                    country: {
                        "callingCode": [
                            "39",
                        ],
                        "cca2": "IT",
                        "currency": [
                            "EUR",
                        ],
                        "flag": "flag-it",
                        "name": "Italy",
                        "region": "Europe",
                        "subregion": "Southern Europe"
                    },
                    loadingSend: false,
                    dateDisabled: null,
                    isUser: true,
                    idPaziente: '',
                    cercaUser: '',
                    pazienteSelect: null,
                    isFiltri: false,
                    loadingSearch: false,
                    promemoria: [],//[{ "id": "56", "url": "https://hkdk.events/cuizz966kwjcjl" }],
                    secondAutomazione: null
                })
                /*if (json.data !== null) {
                    this.addAppuntamento(json.data)
                }*/
                alert("Appuntamento salvato L'appuntamento è stato salvato con successo")
            } else {
                this.setState({ loadingSend: false })
                alert(`Attenzione! ${json.message}`)
            }
        }).catch((error) => {
            this.setState({ loadingSend: false })
            console.error(error);
        })
    }

    getDataMessages = async (id_automazione) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
    
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_automazione', id_automazione);
    
        try {
            let response = await axios.post(
                'https://www.visitreporter.com/app/get_impostazioni_automazioni.php',
                formData,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            
            // I dati JSON sono direttamente accessibili nella proprietà `data` dell'oggetto response
            let json = response.data;
    
            // Chiamata per ottenere i dati di default
            let formDataDefault = new FormData();
            formDataDefault.append('token', token);
            formDataDefault.append('id_automazione', id_automazione);

            let responseDefault = await axios.post(
                'https://www.visitreporter.com/app/get_default_messages.php',
                formDataDefault,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            
            // I dati JSON sono direttamente accessibili nella proprietà `data` dell'oggetto response
            let jsonDefault = responseDefault.data;
    
            // Controlla se list è false
            if (!jsonDefault.list) {
                return {}; // Restituisci un oggetto vuoto se list è false
            }
    
            // Funzione di utilità per popolare i dati
            const populateField = (defaultValue, userValue) => {
                return userValue && userValue !== "" ? userValue : defaultValue;
            };
    
            const result = {
                messaggioAppuntamento: jsonDefault.active_appuntamento == 1 ? populateField(jsonDefault.message_appuntamento, json.active_appuntamento == 1 ? json.message_appuntamento : "") : "",
                appuntamentoTitle: jsonDefault.active_appuntamento == 1 ? populateField(jsonDefault.appuntamento_title, json.active_appuntamento == 1 ? json.appuntamento_title : "") : "",
    
                messaggioReminder1: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.message_reminder_1, json.active_reminder_1 == 1 ? json.message_reminder_1 : "") : "",
                reminder1Title: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.reminder_1_title, json.active_reminder_1 == 1 ? json.reminder_1_title : "") : "",
                giornoReminder1: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.giorno_reminder_1, json.active_reminder_1 == 1 ? json.giorno_reminder_1 : "") : "",
    
                messaggioReminder2: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.message_reminder_2, json.active_reminder_2 == 1 ? json.message_reminder_2 : "") : "",
                reminder2Title: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.reminder_2_title, json.active_reminder_2 == 1 ? json.reminder_2_title : "") : "",
                giornoReminder2: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.giorno_reminder_2, json.active_reminder_2 == 1 ? json.giorno_reminder_2 : "") : "",
    
                messaggioFollowup1: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.message_followup_1, json.active_followup_1 == 1 ? json.message_followup_1 : "") : "",
                followup1Title: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.followup_1_title, json.active_followup_1 == 1 ? json.followup_1_title : "") : "",
                giornoFollowup1: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.giorno_followup_1, json.active_followup_1 == 1 ? json.giorno_followup_1 : "") : "",
    
                messaggioFollowup2: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.message_followup_2, json.active_followup_2 == 1 ? json.message_followup_2 : "") : "",
                followup2Title: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.followup_2_title, json.active_followup_2 == 1 ? json.followup_2_title : "") : "",
                giornoFollowup2: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.giorno_followup_2, json.active_followup_2 == 1 ? json.giorno_followup_2 : "") : "",
    
                messaggioFollowup3: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.message_followup_3, json.active_followup_3 == 1 ? json.message_followup_3 : "") : "",
                followup3Title: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.followup_3_title, json.active_followup_3 == 1 ? json.followup_3_title : "") : "",
                giornoFollowup3: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.giorno_followup_3, json.active_followup_3 == 1 ? json.giorno_followup_3 : "") : "",
    
                messaggioFollowup4: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.message_followup_4, json.active_followup_4 == 1 ? json.message_followup_4 : "") : "",
                followup4Title: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.followup_4_title, json.active_followup_4 == 1 ? json.followup_4_title : "") : "",
                giornoFollowup4: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.giorno_followup_4, json.active_followup_4 == 1 ? json.giorno_followup_4 : "") : "",
            };
    
            return result;
        } catch (error) {
            console.error("Errore nella fetch dei dati:", error);
            return {}; // Restituisci un oggetto vuoto in caso di errore
        }
    }

    getUrlMessages = (jsonData, nomeCliente, nomeStudio, dataAppuntamento) => {
        let urlScheme = "";
    
        if (jsonData.messaggioAppuntamento !== null && jsonData.messaggioAppuntamento !== "") {
            const messaggioAppuntamento = jsonData.messaggioAppuntamento
                .replace('%NOME%', nomeCliente)
                .replace('%STUDIO%', nomeStudio)
                .replace('%DATA%', dataAppuntamento)
                .replace('%TITLE%', jsonData.appuntamentoTitle);
            urlScheme = `${urlScheme}&messaggio-appuntamento=${encodeURIComponent(messaggioAppuntamento)}`;
        }
    
        if (jsonData.messaggioReminder1 !== null && jsonData.messaggioReminder1 !== "") {
            const messaggioReminder1 = jsonData.messaggioReminder1
                .replace('%NOME%', nomeCliente)
                .replace('%STUDIO%', nomeStudio)
                .replace('%DATA%', dataAppuntamento)
                .replace('%TITLE%', jsonData.reminder1Title);
            urlScheme = `${urlScheme}&messaggio-reminder-1=${encodeURIComponent(messaggioReminder1)}&giorno-reminder-1=${encodeURIComponent(jsonData.giornoReminder1)}`;
        }
    
        if (jsonData.messaggioReminder2 !== null && jsonData.messaggioReminder2 !== "") {
            const messaggioReminder2 = jsonData.messaggioReminder2
                .replace('%NOME%', nomeCliente)
                .replace('%STUDIO%', nomeStudio)
                .replace('%DATA%', dataAppuntamento)
                .replace('%TITLE%', jsonData.reminder2Title);
            urlScheme = `${urlScheme}&messaggio-reminder-2=${encodeURIComponent(messaggioReminder2)}&giorno-reminder-2=${encodeURIComponent(jsonData.giornoReminder2)}`;
        }
    
        if (jsonData.messaggioFollowup1 !== null && jsonData.messaggioFollowup1 !== "") {
            const messaggioFollowup1 = jsonData.messaggioFollowup1
                .replace('%NOME%', nomeCliente)
                .replace('%STUDIO%', nomeStudio)
                .replace('%DATA%', dataAppuntamento)
                .replace('%TITLE%', jsonData.followup1Title);
            urlScheme = `${urlScheme}&messaggio-followup-1=${encodeURIComponent(messaggioFollowup1)}&giorno-followup-1=${encodeURIComponent(jsonData.giornoFollowup1)}`;
        }
    
        if (jsonData.messaggioFollowup2 !== null && jsonData.messaggioFollowup2 !== "") {
            const messaggioFollowup2 = jsonData.messaggioFollowup2
                .replace('%NOME%', nomeCliente)
                .replace('%STUDIO%', nomeStudio)
                .replace('%DATA%', dataAppuntamento)
                .replace('%TITLE%', jsonData.followup2Title);
            urlScheme = `${urlScheme}&messaggio-followup-2=${encodeURIComponent(messaggioFollowup2)}&giorno-followup-2=${encodeURIComponent(jsonData.giornoFollowup2)}`;
        }
    
        if (jsonData.messaggioFollowup3 !== null && jsonData.messaggioFollowup3 !== "") {
            const messaggioFollowup3 = jsonData.messaggioFollowup3
                .replace('%NOME%', nomeCliente)
                .replace('%STUDIO%', nomeStudio)
                .replace('%DATA%', dataAppuntamento)
                .replace('%TITLE%', jsonData.followup3Title);
            urlScheme = `${urlScheme}&messaggio-followup-3=${encodeURIComponent(messaggioFollowup3)}&giorno-followup-3=${encodeURIComponent(jsonData.giornoFollowup3)}`;
        }
    
        if (jsonData.messaggioFollowup4 !== null && jsonData.messaggioFollowup4 !== "") {
            const messaggioFollowup4 = jsonData.messaggioFollowup4
                .replace('%NOME%', nomeCliente)
                .replace('%STUDIO%', nomeStudio)
                .replace('%DATA%', dataAppuntamento)
                .replace('%TITLE%', jsonData.followup4Title);
            urlScheme = `${urlScheme}&messaggio-followup-4=${encodeURIComponent(messaggioFollowup4)}&giorno-followup-4=${encodeURIComponent(jsonData.giornoFollowup4)}`;
        }
    
        return urlScheme;
    }

    addPromemoriaAndAutomazioni = async (promemoria, id_appuntamento, is_user, id_paziente, user, second_automazione, date_select, slot_select, nome_cliente, user_automation, second_automazione_data) => {
        if (promemoria.length > 0) {
            promemoria.forEach(async element => {
                const resultMessages = await this.getDataMessages(element.id)
                if (Object.keys(resultMessages).length === 0) {

                } else {
                    const nomeStudio = await AsyncStorage.getItem('nome_studio')
                    const urlScheme = this.getUrlMessages(resultMessages, nome_cliente, nomeStudio, `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`)
                    const id = await AsyncStorage.getItem('id')
                    const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                    let formData = new FormData();
                    formData.append('token', token);
                    formData.append('id_utente', id);
                    formData.append('id_automazione', element.id);
                    formData.append('id_paziente', (is_user) ? id_paziente : '');
                    formData.append('user', (is_user) ? '' : user)
                    formData.append('id_appuntamento', id_appuntamento)
                    formData.append('url_automation', second_automazione);
                    formData.append('date_book', `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`);
                    axios.post('https://www.visitreporter.com/app/add_automazione_data_appuntamento.php', formData, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async (response) => {
                        const json = response.data;
                        if (json.code == true) {
                            const idAutomazioneUtente = json.id_automazione;
                            const telefono = await AsyncStorage.getItem('telefono')
                            const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                            const via = await AsyncStorage.getItem('via')
                            const citta = await AsyncStorage.getItem('citta')
                            const dateToSend = `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`;
                            fetch(`${element.url}?name=${user_automation.nome}&surname=${user_automation.cognome}&phone=${user_automation.telefono}&email=${user_automation.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${id}&data=${dateToSend}&status=CREATO&idautomazione=${idAutomazioneUtente}&nomeautomazione=${element.nome}${urlScheme}`, {
                                method: 'GET',
                                headers: {
                                    Accept: 'application/json'
                                }
                            })
                            .then((response) => response.text())
                            .then(async (element) => {
                                if (second_automazione !== null && second_automazione !== "") {
                                    const resultMessagesSecond = await this.getDataMessages(second_automazione_data.id)
                                    if (Object.keys(resultMessagesSecond).length === 0) {

                                    } else {
                                        const urlSchemeSecond = this.getUrlMessages(resultMessagesSecond, nome_cliente, nomeStudio, `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`)
                                        fetch(`${second_automazione}?name=${user_automation.nome}&surname=${user_automation.cognome}&phone=${user_automation.telefono}&email=${user_automation.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${id}&data=${dateToSend}&status=CREATO&idautomazione=${idAutomazioneUtente}${urlSchemeSecond}`, {
                                            method: 'GET',
                                            headers: {
                                                Accept: 'application/json'
                                            }
                                        })
                                        .then((response) => response.text())
                                        .then(async (element) => {
    
                                        }).catch((error) => {
                                            console.error(error);
                                        })
                                    }
                                }
                            }).catch((error) => {
                                console.error(error);
                            })
                        } else {
                            Alert.alert(
                                "Attenzione",
                                json.message,
                                [
                                    { text: 'Ok' }
                                ]
                            )
                        }
                    }).catch((error) => {
                        console.error(error);
                    })
                }
            });
        }
    }

    editAppuntamento = async () => {
        if (this.state.idAppuntamento !== '' && this.state.dateSelect !== null && this.state.selectedSlot !== '' && this.state.stato !== null && this.state.stato !== '') {
            if (this.state.nome !== '' && this.state.cognome !== '' && this.email !== '' && this.state.telefono !== '') {
                const isValidaNumber = this.phoneRef.current.isValidNumber(this.state.telefono)
                if (isValidaNumber == true) {
                    this.editAppuntamentoSend()
                } else {
                    alert("Attenzione! Il numero di telefono inserito non è valido")
                }
            } else {
                alert("Attenzione! Compila tutti i campi obbligatori")
            }
        } else {
            alert("Attenzione! Compila tutti i campi obbligatori")
        }
    }

    getAutomazioniAppuntamento = async (id_appuntamento) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_appuntamento', id_appuntamento);
        axios.post('https://www.visitreporter.com/app/get_automazioni_appuntamento.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                let promemoria = []
                json.forEach(element => {
                    promemoria.push({
                        id: element.id_automazione,
                        nome: element.nome,
                        url: element.url
                    })
                });
                this.setState({ promemoria, secondAutomazione: json[0].url_automation, automazioniAttive: json })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getResultAutomazioniApp = async (id_appuntamento) => {
        try {
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex);
            let id = await AsyncStorage.getItem('id');
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('id_appuntamento', id_appuntamento);

            const response = await axios.post(
                'https://www.visitreporter.com/app/get_automazioni_appuntamento.php',
                formData,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            
            // I dati JSON sono direttamente accessibili nella proprietà `data` dell'oggetto response
            const json = response.data;
    
            if (json.length > 0) {
                return json;  // Restituisci l'array JSON
            } else {
                return null;  // Se l'array è vuoto, restituisci null
            }
        } catch (error) {
            console.error(error);
            return null;  // Se c'è un errore, restituisci null
        }
    }

    editAutomazioneAppuntamento = async (promemoria, automazioni_attive, id_appuntamento, user, second_automazione, date_select, slot_select, nome_cliente, user_automation, second_automazione_data) => {
        const updatedArray = automazioni_attive.filter((item1) => {
            const foundInArray2 = promemoria.some((item2) => item2.id === item1.id_automazione);
          
            if (!foundInArray2) {
                const dataArray = automazioni_attive.filter(e => e.id === item1.id)
                // Chiama la function per la condizione "rimosso da array2"
                const dateToSend = `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`;
                const userData = JSON.parse(user)
                const country = userData.country_code.callingCode[0]
                this.disattivaAutomazioneDate(dataArray[0].id, dateToSend, userData.nome, userData.cognome, userData.telefono, country, userData.email, dataArray[0].url, dataArray[0].url_automation, dataArray[0].nome, second_automazione_data)
            }
          
            // Mantieni l'elemento in array1 solo se è presente anche in array2
            return foundInArray2;
        });
        if (promemoria.length > 0) {
            promemoria.forEach(async element => {
                const resultMessages = await this.getDataMessages(element.id)
                if (Object.keys(resultMessages).length === 0) {

                } else {
                    const foundInArray1 = updatedArray.some((item1) => item1.id_automazione === element.id);

                    if (foundInArray1) {
                        const automazioneFind = updatedArray.filter((item1) => item1.id_automazione === element.id)
                        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                        const idStudio = await AsyncStorage.getItem('id')
                        let formData = new FormData();
                        formData.append('token', token);
                        formData.append('id', automazioneFind[0].id);
                        formData.append('user', user);
                        formData.append('url_automation', second_automazione);
                        formData.append('date_book', `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`);
                        axios.post('https://www.visitreporter.com/app/update_automazione_appuntamento.php', formData, {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(async (response) => {
                            const json = response.data;
                            if (json.code == true) {
                                const telefono = await AsyncStorage.getItem('telefono')
                                const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                                const via = await AsyncStorage.getItem('via')
                                const citta = await AsyncStorage.getItem('citta')
                                const nomeStudio = await AsyncStorage.getItem('nome_studio')
                                const dateToSend = `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`;
                                const urlScheme = this.getUrlMessages(resultMessages, nome_cliente, nomeStudio, `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`)
                                fetch(`${element.url}?name=${user_automation.nome}&surname=${user_automation.cognome}&phone=${user_automation.telefono}&email=${user_automation.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${idStudio}&data=${dateToSend}&status=MODIFICATO&idautomazione=${automazioneFind[0].id}&nomeautomazione=${element.nome}${urlScheme}`, {
                                    method: 'GET',
                                    headers: {
                                        Accept: 'application/json'
                                    }
                                })
                                .then((response) => response.text())
                                .then(async (element) => {
                                    if (second_automazione !== null && second_automazione !== "null" && second_automazione !== "") {
                                        const resultMessagesSecond = await this.getDataMessages(second_automazione_data.id)
                                        if (Object.keys(resultMessagesSecond).length === 0) {

                                        } else {
                                            const urlSchemeSecond = this.getUrlMessages(resultMessagesSecond, nome_cliente, nomeStudio, `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`)
                                            fetch(`${second_automazione}?name=${user_automation.nome}&surname=${user_automation.cognome}&phone=${user_automation.telefono}&email=${user_automation.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${idStudio}&data=${dateToSend}&status=MODIFICATO&idautomazione=${automazioneFind[0].id}${urlSchemeSecond}`, {
                                                method: 'GET',
                                                headers: {
                                                    Accept: 'application/json'
                                                }
                                            })
                                            .then((response) => response.text())
                                            .then(async (element) => {
    
                                            }).catch((error) => {
                                                console.error(error);
                                            })
                                        }
                                    }
                                }).catch((error) => {
                                    console.error(error);
                                })
                            } else {
                                alert("Errore Non è stato possibile attivare l'automazione. Riprova più tardi")
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                    } else {
                        // Se l'oggetto è presente in array2 ma non in array1
                        const nomeStudio = await AsyncStorage.getItem('nome_studio')
                        const urlScheme = this.getUrlMessages(resultMessages, nome_cliente, nomeStudio, `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`)
                        const id = await AsyncStorage.getItem('id')
                        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                        let formData = new FormData();
                        formData.append('token', token);
                        formData.append('id_utente', id);
                        formData.append('id_automazione', element.id);
                        formData.append('id_paziente', '');
                        formData.append('user', user)
                        formData.append('id_appuntamento', id_appuntamento)
                        formData.append('url_automation', second_automazione);
                        formData.append('date_book', `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`);
                        axios.post('https://www.visitreporter.com/app/add_automazione_data_appuntamento.php', formData, {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(async (response) => {
                            const json = response.data;
                            if (json.code == true) {
                                const idAutomazioneUtente = json.id_automazione;
                                const telefono = await AsyncStorage.getItem('telefono')
                                const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                                const via = await AsyncStorage.getItem('via')
                                const citta = await AsyncStorage.getItem('citta')
                                const dateToSend = `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`;
                                fetch(`${element.url}?name=${user_automation.nome}&surname=${user_automation.cognome}&phone=${user_automation.telefono}&email=${user_automation.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${id}&data=${dateToSend}&status=CREATO&idautomazione=${idAutomazioneUtente}&nomeautomazione=${element.nome}${urlScheme}`, {
                                    method: 'GET',
                                    headers: {
                                        Accept: 'application/json'
                                    }
                                })
                                .then((response) => response.text())
                                .then(async (element) => {
                                    if (second_automazione !== null && second_automazione !== "") {
                                        const resultMessagesSecond = await this.getDataMessages(second_automazione_data.id)
                                        if (Object.keys(resultMessagesSecond).length === 0) {

                                        } else {
                                            const urlSchemeSecond = this.getUrlMessages(resultMessagesSecond, nome_cliente, nomeStudio, `${Moment(date_select).format('DD-MM-YYYY')} ${slot_select}`)
                                            fetch(`${second_automazione}?name=${user_automation.nome}&surname=${user_automation.cognome}&phone=${user_automation.telefono}&email=${user_automation.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${id}&data=${dateToSend}&status=CREATO&idautomazione=${idAutomazioneUtente}${urlSchemeSecond}`, {
                                                method: 'GET',
                                                headers: {
                                                    Accept: 'application/json'
                                                }
                                            })
                                            .then((response) => response.text())
                                            .then(async (element) => {
    
                                            }).catch((error) => {
                                                console.error(error);
                                            })
                                        }
                                    }
                                }).catch((error) => {
                                    console.error(error);
                                })
                            } else {
                                alert(`Attenzione! ${json.message}`)
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                    }
                }
            });
        }
    }

    disattivaAutomazioneDate = async (id, date, nome, cognome, telefono, country, email, url, url_automation, nome_automazione, second_automazione_data) => {
        const resultMessages = await this.getDataMessages(id)
        if (Object.keys(resultMessages).length === 0) {
            this.disattivaAutomazione(id)
        } else {
            const idStudio = await AsyncStorage.getItem('id')
            const telefonoStudio = await AsyncStorage.getItem('telefono')
            const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
            const via = await AsyncStorage.getItem('via')
            const citta = await AsyncStorage.getItem('citta')
            const nomeStudio = await AsyncStorage.getItem('nome_studio')
            const urlScheme = this.getUrlMessages(resultMessages, `${nome} ${cognome}`, nomeStudio, date)
            fetch(`${url}?name=${nome}&surname=${cognome}&phone=${country}${telefono}&email=${email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&data=${date}&status=ELIMINATO&idautomazione=${id}&nomeautomazione=${nome_automazione}${urlScheme}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            })
            .then((response) => response.text())
            .then(async (element) => {
                if (url_automation !== null && url_automation !== "" && url_automation !== "null") {
                    const resultMessagesSecond = await this.getDataMessages(second_automazione_data.id)
                    if (Object.keys(resultMessagesSecond).length === 0) {
                        this.disattivaAutomazione(id)
                    } else {
                        const urlSchemeSecond = this.getUrlMessages(resultMessagesSecond, `${nome} ${cognome}`, nomeStudio, date)
                        fetch(`${url_automation}?name=${nome}&surname=${cognome}&phone=${country}${telefono}&email=${email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&data=${date}&status=ELIMINATO&idautomazione=${id}${urlSchemeSecond}`, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json'
                            }
                        })
                        .then((response) => response.text())
                        .then(async (element) => {
                            this.disattivaAutomazione(id)
                        }).catch((error) => {
                            console.error(error);
                        })
                    }
                } else {
                    this.disattivaAutomazione(id)
                }
            }).catch((error) => {
                console.error(error);
            })
        }
    }

    disattivaAutomazione = async (id) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', id);
        axios.post('https://www.visitreporter.com/app/delete_automazione_appuntamento.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                
            } else {
                alert("Errore Non è stato possibile disattivare l'automazione. Riprova più tardi")
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    editAppuntamentoSend = async () => {
        this.setState({ loadingSend: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_appuntamento', this.state.idAppuntamento);
        formData.append('date_select', Moment(this.state.dateSelect).format('YYYY-MM-DD'))
        formData.append('time_select', this.state.selectedSlot);
        formData.append('nome', this.state.nome)
        formData.append('cognome', this.state.cognome)
        formData.append('telefono', this.state.telefono)
        formData.append('email', this.state.email)
        formData.append('country_code', JSON.stringify(this.state.country))
        formData.append('stato', this.state.stato)
        axios.post('https://www.visitreporter.com/app/edit_appuntamento.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                if (this.state.timestumoMonth !== null) {
                    this.getAppuntamenti(this.state.timestumoMonth)
                } else {
                    this.getAppuntamenti(new Date())
                }
                /*if (json.data !== null) {
                    try {
                        // Aspetta che editAppuntamentoJson termini prima di procedere
                        await this.editAppuntamentoJson(json.data.id, json.data);
                    } catch (error) {
                        console.error("Errore durante l'aggiornamento dell'appuntamento:", error);
                        return;
                    }
                }*/

                if (this.state.stato == 1 || this.state.stato == "1") {
                    const user = JSON.stringify({ nome: this.state.nome, cognome: this.state.cognome, email: this.state.email, telefono: this.state.telefono, country_code: this.state.country })
                    const userAutomation = {
                        nome: this.state.nome,
                        cognome: this.state.cognome,
                        email: this.state.email,
                        telefono: `${this.state.country.callingCode[0]}${this.state.telefono}`
                    };
                    const nomeCliente = `${this.state.nome} ${this.state.cognome}`;
                    let secondAutomazioneData = {};
                    if (this.state.secondAutomazione !== null && this.state.secondAutomazione !== '') {
                        const indexSecondAutomazione = this.state.fullAutomazioni.findIndex(e => e.url == this.state.secondAutomazione)
                        if (indexSecondAutomazione !== -1) {
                            secondAutomazioneData = {
                                id: this.state.fullAutomazioni[indexSecondAutomazione].id,
                                url: this.state.fullAutomazioni[indexSecondAutomazione].url,
                                nome: this.state.fullAutomazioni[indexSecondAutomazione].nome
                            }
                        }
                    }
                    this.editAutomazioneAppuntamento(this.state.promemoria, this.state.automazioniAttive, this.state.idAppuntamento, user, this.state.secondAutomazione, this.state.dateSelect, this.state.selectedSlot, nomeCliente, userAutomation, secondAutomazioneData)
                }

                this.setState({ 
                    loadingSend: false,
                    showModal: false,
                    isEdit: false,
                    dateSelect: null,
                    dateSelectString: null,
                    dateString: null,
                    selectedStudio: null,
                    listStudi: [],
                    loadingStudi: true,
                    listOperatori: [],
                    loadingOperatori: true,
                    selectedOperatore: null,
                    isOrari: false,
                    loadingAgenda: true,
                    loadingSlot: true,
                    slots: [],
                    selectedSlot: '',
                    email: '',
                    nome: '',
                    cognome: '',
                    telefono: '',
                    country: {
                        "callingCode": [
                            "39",
                        ],
                        "cca2": "IT",
                        "currency": [
                            "EUR",
                        ],
                        "flag": "flag-it",
                        "name": "Italy",
                        "region": "Europe",
                        "subregion": "Southern Europe"
                    },
                    loadingSend: false,
                    dateDisabled: null,
                    isUser: true,
                    idPaziente: '',
                    cercaUser: '',
                    pazienteSelect: null,
                    isFiltri: false,
                    loadingSearch: false,
                    promemoria: [],//[{ "id": "56", "url": "https://hkdk.events/cuizz966kwjcjl" }],
                    secondAutomazione: null,
                    automazioniAttive: [],
                    stato: 0,
                    showStato: false
                })
                alert("Appuntamento salvato! L'appuntamento è stato salvato con successo")
            } else {
                this.setState({ loadingSend: false })
                alert(`Attenzione! ${json.message}`)
            }
        }).catch((error) => {
            this.setState({ loadingSend: false })
            console.error(error);
        })
    }

    closeModal = () => {
        this.setState({ 
            showModal: false,
            isEdit: false,
            dateSelect: null,
            dateSelectString: null,
            dateString: null,
            selectedStudio: null,
            listStudi: [],
            loadingStudi: true,
            listOperatori: [],
            loadingOperatori: true,
            selectedOperatore: null,
            isOrari: false,
            loadingAgenda: true,
            loadingSlot: true,
            slots: [],
            selectedSlot: '',
            email: '',
            nome: '',
            cognome: '',
            telefono: '',
            country: {
                "callingCode": [
                    "39",
                ],
                "cca2": "IT",
                "currency": [
                    "EUR",
                ],
                "flag": "flag-it",
                "name": "Italy",
                "region": "Europe",
                "subregion": "Southern Europe"
            },
            loadingSend: false,
            dateDisabled: null,
            isUser: true,
            idPaziente: '',
            cercaUser: '',
            pazienteSelect: null,
            idAppuntamento: '',
            promemoria: [],//[{ "id": "56", "url": "https://hkdk.events/cuizz966kwjcjl" }],
            secondAutomazione: null,
            stato: 0,
            showStato: false
        })
    }

    addAppuntamento = (newAppuntamento) => {
        const { listAppuntamenti } = this.state;
        const date = newAppuntamento.data_appuntamento;
        const time_slot = `${newAppuntamento.time_appuntamento.split(':')[0]}:${newAppuntamento.time_appuntamento.split(':')[1]}`;
    
        // Creare una copia dello stato
        const updatedList = { ...listAppuntamenti };
    
        // Aggiungere la nuova data se non esiste
        if (!updatedList[date]) {
            updatedList[date] = [];
        }
    
        // Aggiungere il nuovo appuntamento alla data specificata
        updatedList[date].push({
            ...newAppuntamento,
            time_slot,
        });
    
        // Aggiornare lo stato
        this.setState({ listAppuntamenti: updatedList });
    }

    editAppuntamentoJson = (id_appuntamento, updatedAppuntamento) => {
        return new Promise((resolve) => {
            const { listAppuntamenti } = this.state;
    
            // Trova la data originale dell'appuntamento
            const oldDate = Object.keys(listAppuntamenti).find(date => 
                listAppuntamenti[date].some(app => app.id === id_appuntamento)
            );
    
            // Ottieni la nuova data e il time_slot dell'appuntamento aggiornato
            const newDate = updatedAppuntamento.data_appuntamento;
            const time_slot = `${updatedAppuntamento.time_appuntamento.split(':')[0]}:${updatedAppuntamento.time_appuntamento.split(':')[1]}`;
    
            // Creare una copia dello stato
            const updatedList = { ...listAppuntamenti };
    
            if (oldDate && oldDate === newDate) {
                // Se la data non è cambiata, aggiorna direttamente l'appuntamento nella stessa data
                updatedList[oldDate] = updatedList[oldDate].map(app => 
                    app.id === id_appuntamento
                        ? { ...updatedAppuntamento, time_slot }
                        : app
                );
            } else {
                // Rimuovi l'appuntamento dalla vecchia data se la data è cambiata
                if (oldDate) {
                    updatedList[oldDate] = updatedList[oldDate].filter(app => app.id !== id_appuntamento);
                    if (updatedList[oldDate].length === 0) {
                        delete updatedList[oldDate]; // Elimina la chiave se non ci sono più appuntamenti
                    }
                }
    
                // Aggiungi l'appuntamento aggiornato alla nuova data
                if (!updatedList[newDate]) {
                    updatedList[newDate] = [];
                }
                updatedList[newDate].push({
                    ...updatedAppuntamento,
                    time_slot,
                });
            }
    
            // Aggiornare lo stato
            this.setState({ listAppuntamenti: updatedList }, () => {
                resolve(); // Risolve la Promise una volta che lo stato è stato aggiornato
            });
        });
    }    

    deleteAppuntamento = (id_appuntamento) => {
        const { listAppuntamenti } = this.state;
    
        // Creare una copia dello stato
        const updatedList = { ...listAppuntamenti };
    
        // Trova la data dell'appuntamento e rimuovilo
        const date = Object.keys(updatedList).find(date =>
            updatedList[date].some(app => app.id === id_appuntamento)
        );
    
        if (date) {
            updatedList[date] = updatedList[date].filter(app => app.id !== id_appuntamento);
            if (updatedList[date].length === 0) {
                delete updatedList[date]; // Elimina la chiave se non ci sono più appuntamenti
            }
        }
    
        // Aggiornare lo stato
        this.setState({ listAppuntamenti: updatedList });
    }

    renderItem = (item) => {
        return(
            <AgendaComponent 
                item={item} 
                onPress={this.selectAppuntamento} 
                onDelete={this.handleDeleteRequest}
            />
        )
    }

    getAutomazioni = async () => {
        this.setState({ loadingAutomazioni: true })
        const idStudio = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', idStudio);
        formData.append('id_category', '11')
        axios.post('https://www.visitreporter.com/app/get_automazioni_category.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ listAutomazioni: json, loadingAutomazioni: false })
            } else {
                this.setState({ listAutomazioni: [], loadingAutomazioni: false })
            }
        }).catch((error) => {
            this.setState({ loadingAutomazioni: false })
            console.error(error);
        })
    }

    togglePromemoria = (id, url, nome) => {
        this.setState((prevState) => {
            // Copia l'array promemoria dallo stato precedente
            let promemoria = [...prevState.promemoria];
    
            // Trova l'indice dell'oggetto che ha l'id specificato
            const index = promemoria.findIndex(item => item.id === id);
    
            if (index !== -1) {
                // Se l'id è già presente, rimuovi l'oggetto dall'array
                promemoria.splice(index, 1);
            } else {
                // Se l'id non è presente, aggiungi un nuovo oggetto all'array
                // Qui assumiamo che tu voglia inserire un oggetto con un'url fittizia
                promemoria.push({ id: id, url: url, nome: nome });
            }
    
            // Restituisci il nuovo stato
            return { promemoria };
        });
    }

    getListAutomazioni = async () => {
        const idStudio = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', idStudio);
        axios.post('https://www.visitreporter.com/app/get_list_automazioni.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ fullAutomazioni: json })
            } else {
                this.setState({ fullAutomazioni: [] })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getResultListAutomazioni = async () => {
        try {
            const idStudio = await AsyncStorage.getItem('id');
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex);
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', idStudio);
    
            const response = await axios.post(
                'https://www.visitreporter.com/app/get_list_automazioni.php',
                formData,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            
            // I dati JSON sono direttamente accessibili nella proprietà `data` dell'oggetto response
            const json = response.data;
    
            if (json.length > 0) {
                return json;  // Restituisci l'array JSON se non è vuoto
            } else {
                return null;  // Se l'array è vuoto, restituisci null
            }
        } catch (error) {
            console.error(error);
            return null;  // In caso di errore, restituisci null
        }
    }

    selectEvent = (event) => {
        const time_slot = `${event.time_appuntamento.split(':')[0]}:${event.time_appuntamento.split(':')[1]}`;

        const eventDetail = {
            title: `${Moment(event.data_appuntamento).format('DD/MM/YYYY')} ${time_slot}\n${event.title}`,
            description: event.description,
            color: event.color,
            time_slot: time_slot,
            id: event.id,
            nome_studio: event.nome_studio,
            id_operatore: event.id_operatore,
            id_studio: event.id_studio,
            data_appuntamento: event.data_appuntamento,
            time_appuntamento: event.time_appuntamento,
            nome: event.nome,
            cognome: event.cognome,
            email: event.email,
            telefono: event.telefono,
            country_code: event.country_code,
            nome_operatore: event.nome_operatore,
            cognome_operatore: event.cognome_operatore,
            mansione: event.mansione,
            stato: event.stato
        }

        this.setState({
            eventDetail,
            showDetail: true
        })
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return (
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Agenda</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backButton} onPress={() => {this.getStudi(); this.getAutomazioni(); this.getListAutomazioni(); this.setState({ showModal: true, isEdit: false })}}>
                        <AntDesign name='pluscircleo' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Aggiungi</Text>
                    </TouchableOpacity>
                </View>
                {(this.state.loading)
                    ? <ActivityIndicator size='small' color='#fff' style={{ marginTop: 20 }} />
                    :
                    <CalendarAgenda
                        localizer={localizer}
                        events={this.state.listAppuntamenti}
                        startAccessor="start"
                        endAccessor="end"
                        messages={lang}
                        defaultView="month"
                        /*components={{
                            event: CustomEvent,
                        }}*/
                        style={{ 
                            height: Dimensions.get('screen').height,
                            paddingTop: 20,
                            overflow: 'hidden',
                            fontFamily: 'DM-Regular'
                        }}
                        onNavigate={(result) => {
                            this.getAppuntamenti(result)
                            this.setState({ timestumoMonth: result })
                        }}
                        eventPropGetter={eventStyleGetter}
                        popup
                        selectable
                        onSelectEvent={(event) => this.selectEvent(event)}
                    />
                    /*<Agenda
                        items={this.state.listAppuntamenti}
                        loadItemsForMonth={(data) => {
                            this.getAppuntamenti(data.timestamp)
                            this.setState({ timestumoMonth: data.timestamp })
                        }}
                        firstDay={1}
                        theme={{
                            selectedDayBackgroundColor: '#0070FF',
                            selectedDayTextColor: '#fff',
                            todayTextColor: '#0070FF',
                            textDayFontFamily: 'DM-Medium',
                            textMonthFontFamily: 'DM-Bold',
                            textDayHeaderFontFamily: 'DM-Medium',
                            textDayFontSize: 16,
                            textMonthFontSize: 17,
                            textDayHeaderFontSize: 14
                        }}
                        renderItem={this.renderItem}
                        refreshing={false}
                        refreshControl={null}
                        selected={this.state.selectedDateAgenda || Moment().format('YYYY-MM-DD')}
                        onDayPress={(day) => {this.setState({ selectedDateAgenda: day.dateString })}}
                        renderEmptyData={() => <View style={styles.emptyDate}>
                            <Feather name='alert-triangle' color={'red'} size={25} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginTop: 10, textAlign: 'center' }}>Nessun appuntamento</Text>
                        </View>}
                    />*/
                }
                <View style={(Dimensions.get('screen').width <= 500) ? styles.positionBottom : styles.positionBottomWeb}>
                    <TouchableOpacity style={[styles.backButton, (Dimensions.get('screen').width <= 500) ? styles.buttonBottom : styles.buttonBottomWeb]} onPress={() => this.props.navigation.navigate('Agenda')}>
                        <AntDesign name='setting' color='#fff' size={26} />
                    </TouchableOpacity>
                </View>
                <Modal
                    visible={this.state.showModal}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={this.closeModal}
                >
                    <View style={[styles.container, { backgroundColor: '#FFF' }]}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={this.closeModal}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { marginBottom: Dimensions.get('screen').height / 3 }]}>
                                {(!this.state.isEdit)
                                    &&
                                    <>
                                    <View style={{ marginBottom: 20 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Seleziona lo studio*</Text>
                                        <RNPickerSelect
                                            placeholder={{ label: 'Seleziona lo studio', value: null }}
                                            doneText='Fine'
                                            style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                            onValueChange={(selectedStudio) => {
                                                this.setState({ selectedStudio })
                                                setTimeout(() => {
                                                    this.getOperatori()
                                                }, 500);
                                            }}
                                            items={this.state.listStudi.map((item, index) => {
                                                return { key: index, value: item.id, label: item.nome_studio };
                                            })}
                                        />
                                    </View>
                                    <View style={{ marginBottom: 20 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Seleziona l'operatore*</Text>
                                        <RNPickerSelect
                                            placeholder={{ label: "Seleziona l'operatore", value: null }}
                                            doneText='Fine'
                                            style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                            onValueChange={(selectedOperatore) => {
                                                this.setState({ selectedOperatore })
                                                setTimeout(() => {
                                                    this.getOrariOperatoreCheck()
                                                    this.getFerie()
                                                }, 500);
                                            }}
                                            items={this.state.listOperatori.map((item, index) => {
                                                return { key: index, value: item.id, label: `${item.nome} ${item.cognome}` };
                                            })}
                                        />
                                    </View>
                                    </>
                                }
                                <View style={{ marginBottom: 20 }}>
                                    {(this.state.isOrari)
                                        ?
                                        <View style={{ alignItems: 'center' }}>
                                            <Calendar
                                                style={{
                                                    width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 80 : (Dimensions.get('screen').width / 3) - 20
                                                }}
                                                renderArrow={(direction) => <Feather color={'#0070FF'} size={30} name={(direction == 'left') ? 'chevron-left' : 'chevron-right'} />}
                                                onDayPress={day => {
                                                    const updatedDates = this.createDateSelectionObject(day.dateString);
                                                    this.setState({
                                                        dateSelect: day.timestamp,
                                                        dateSelectString: updatedDates,
                                                        dateString: day.dateString,
                                                        selectedSlot: null
                                                    })

                                                    this.getSlots(day.timestamp)
                                                }}
                                                theme={{
                                                    selectedDayBackgroundColor: '#0070FF',
                                                    selectedDayTextColor: '#fff',
                                                    todayTextColor: '#0070FF',
                                                    textDayFontFamily: 'DM-Medium',
                                                    textMonthFontFamily: 'DM-Bold',
                                                    textDayHeaderFontFamily: 'DM-Medium',
                                                    textDayFontSize: 16,
                                                    textMonthFontSize: 17,
                                                    textDayHeaderFontSize: 14
                                                }}
                                                firstDay={1}
                                                minDate={Moment(new Date).format('YYYY-MM-DD')}
                                                markedDates={(this.state.dateSelectString !== null) ? {...this.state.dateSelectString, ...this.state.dateDisabled} : this.state.dateDisabled}
                                            />
                                        </View>
                                        :
                                        <View style={{ alignItems: 'center' }}>
                                            <Feather name='alert-triangle' color={'red'} size={35} />
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginTop: 10, textAlign: 'center' }}>Gli orari non sono disponibili{'\n'}per l'operatore selezionato</Text>
                                        </View>
                                    }
                                </View>
                                <View style={{ marginBottom: 20 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Seleziona lo slot*</Text>
                                    {(this.state.isEdit)
                                        ?
                                        (this.state.selectedSlot !== '' && this.state.selectedSlot !== null)
                                            ?
                                            <RNPickerSelect
                                                placeholder={{ label: "Seleziona lo slot", value: null }}
                                                doneText='Fine'
                                                value={this.state.selectedSlot}
                                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                                onValueChange={(selectedSlot) => this.setState({ selectedSlot })}
                                                items={this.state.slots.map((item, index) => {
                                                    if (item.enabled) {
                                                        return { key: index, value: item.slot_start_time, label: item.slot_start_time };
                                                    } else {
                                                        return { key: index, value: item.slot_start_time, label: `${item.slot_start_time} (Prenotato)` };
                                                    }
                                                })}
                                            />
                                            :
                                            <ActivityIndicator size='small' color='#000' />
                                        :
                                        <RNPickerSelect
                                            placeholder={{ label: "Seleziona lo slot", value: null }}
                                            doneText='Fine'
                                            style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                            onValueChange={(selectedSlot) => this.setState({ selectedSlot })}
                                            items={this.state.slots.map((item, index) => {
                                                return { key: index, value: item.slot_start_time, label: item.slot_start_time };
                                            })}
                                        />
                                    }
                                </View>
                                {(!this.state.isEdit)
                                    &&
                                    <View style={{
                                        marginBottom: 20,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Vuoi selezionare il{'\n'}paziente?</Text>
                                        <Switch
                                            trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                            thumbColor={this.state.isUser ? '#fff' : '#fff'}
                                            ios_backgroundColor="#f5f5f5"
                                            onValueChange={() => this.setState({ isUser: !this.state.isUser })}
                                            value={this.state.isUser}
                                        />
                                    </View>
                                }
                                {(this.state.isUser)
                                    ?
                                    <>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Seleziona il paziente</Text>
                                            {(this.state.loadingData)
                                                &&
                                                <ActivityIndicator size='small' color='#000' />
                                            }
                                        </View>
                                        <TextInput
                                            style={[styles.input, { borderBottomLeftRadius: (this.state.searchResult.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResult.length > 0) ? 0 : 10 }]}
                                            placeholderTextColor='#bfbfbf'
                                            placeholder='Inserisci il nome del Paziente'c
                                            value={this.state.cercaUser}
                                            //onChangeText={(cercaUser) => {this.setState({ cercaUser }); this.filterResult(cercaUser)}}
                                            onChangeText={(cercaUser) => this.continuePatientsQueryLike(cercaUser)}
                                            ref={this.searchInputUser}
                                            //editable={(this.state.loadingData) ? false : true}
                                        />
                                        {(this.state.searchResult.length > 0)
                                            &&
                                            this.state.searchResult.map((item, index) => {
                                                let counter = index + 1;
                                                const country_code = JSON.parse(item.country_code)
                                                return(
                                                    <TouchButton onPress={() => this.selectPaziente(item.id, item.nome.trim(), item.cognome.trim(), item)} key={item.id} style={[styles.listUser, {
                                                        borderBottomLeftRadius: (counter == this.state.searchResult.length) ? 10 : 0,
                                                        borderBottomRightRadius: (counter == this.state.searchResult.length) ? 10 : 0
                                                    }]}>
                                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>
                                                            {item.nome.trim()} {item.cognome.trim()}{'\n'}
                                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                                        </Text>
                                                    </TouchButton>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                        <View style={{ marginBottom: 20 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Nome*</Text>
                                            <TextInput
                                                style={styles.input}
                                                placeholderTextColor='#bfbfbf'
                                                placeholder='Nome'
                                                value={this.state.nome}
                                                onChangeText={(nome) => this.setState({ nome })}
                                                autoCapitalize='words'
                                            />
                                        </View>
                                        <View style={{ marginBottom: 20 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Cognome*</Text>
                                            <TextInput
                                                style={styles.input}
                                                placeholderTextColor='#bfbfbf'
                                                placeholder='Cognome'
                                                value={this.state.cognome}
                                                onChangeText={(cognome) => this.setState({ cognome })}
                                                autoCapitalize='words'
                                            />
                                        </View>
                                        <View style={{ marginBottom: 20 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Email*</Text>
                                            <TextInput
                                                style={styles.input}
                                                placeholderTextColor='#bfbfbf'
                                                placeholder='Email'
                                                value={this.state.email}
                                                onChangeText={(email) => this.setState({ email: email.toLowerCase().trim() })}
                                                keyboardType='email-address'
                                                autoCapitalize='none'
                                            />
                                        </View>
                                        <View style={{ marginBottom: 20 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Telefono*</Text>
                                            <PhoneInput
                                                ref={this.phoneRef}
                                                defaultCode="IT"
                                                layout="first"
                                                withDarkTheme={false}
                                                withShadow={false}
                                                placeholder='Numero di cellulare'
                                                containerStyle={{ width: '100%', borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
                                                textContainerStyle={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, paddingVertical: 10, height: 55, backgroundColor: '#f5f5f5' }}
                                                textInputStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                                                codeTextStyle={{ fontFamily: 'DM-Regular', fontSize: 16 }}
                                                flagButtonStyle={{ backgroundColor: '#e8e8e8', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, width: (Dimensions.get('screen').width <= 500) ? '26%' : '20%' }}
                                                filterProps={{ placeholder: 'Cerca nazione o prefisso' }}
                                                value={this.state.telefono}
                                                onChangeText={(telefono) => this.setState({ telefono })}
                                                onChangeCountry={(country) => this.setState({ country })}
                                            />
                                        </View>
                                    </>
                                }
                                {(this.state.isEdit && this.state.showStato)
                                    &&
                                    <View style={{ marginBottom: 20, marginTop: (this.state.isUser) ? 20 : 0 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Seleziona stato*</Text>
                                        <RNPickerSelect
                                            placeholder={{ label: 'Seleziona lo stato', value: null }}
                                            doneText='Fine'
                                            value={this.state.stato}
                                            style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                            onValueChange={(stato) => this.setState({ stato })}
                                            items={[
                                                { value: 0, label: "In attesa" },
                                                { value: 1, label: "Approvato" },
                                                { value: 2, label: "Rifiutato" }
                                            ]}
                                        />
                                    </View>
                                }
                                {(!this.state.isEdit || this.state.stato == "1" || this.state.stato == 1)
                                    &&
                                    <>
                                    <View style={{ marginBottom: 20, marginTop: (this.state.showStato) ? 0 : (this.state.isUser) ? 20 : 0 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Seleziona promemoria</Text>
                                        {(this.state.loadingAutomazioni)
                                            ?
                                            <View style={{ alignItems: 'center', marginTop: 20 }}>
                                                <ActivityIndicator size='small' color='#0070FF' />
                                            </View>
                                            :
                                            (this.state.listAutomazioni.length > 0)
                                                ?
                                                this.state.listAutomazioni.map((item, index) => {
                                                    const indexPromemoria = this.state.promemoria.findIndex(e => e.id == item.id)
                                                    return(
                                                        <TouchableOpacity key={index} style={[styles.appointmentContainer, { backgroundColor: '#f5f5f5' }]} onPress={() => this.togglePromemoria(item.id, item.url, item.nome)}>
                                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                                {(indexPromemoria !== -1)
                                                                    ?
                                                                    <View style={styles.checkSelected}>
                                                                        <AntDesign name='check' color={'#fff'} size={20} />
                                                                    </View>
                                                                    :
                                                                    <View style={styles.chek}></View>
                                                                }
                                                                <View style={{ marginLeft: 15, width: '85%' }}>
                                                                    <Text style={styles.appointmentText}>{item.nome}</Text>
                                                                    {(item.descrizione !== '' && item.descrizione !== null)
                                                                        &&
                                                                        <Text style={styles.appointmentSubText}>{item.descrizione}</Text>
                                                                    }
                                                                </View>
                                                            </View>
                                                        </TouchableOpacity>
                                                    )
                                                })
                                                :
                                                <View style={{ alignItems: 'center', marginTop: 20 }}>
                                                    <Text style={[styles.appointmentSubText, { textAlign: 'center' }]}>Non sono presenti promemoria</Text>
                                                </View>
                                        }
                                    </View>
                                    <View style={{ marginBottom: 20 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Seleziona una seconda automazione</Text>
                                        <RNPickerSelect
                                            placeholder={{ label: 'Seleziona automazione', value: null }}
                                            doneText='Fine'
                                            value={this.state.secondAutomazione}
                                            style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                            onValueChange={(secondAutomazione) => this.setState({ secondAutomazione })}
                                            items={this.state.fullAutomazioni.map((item, index) => {
                                                return { key: index, value: item.url, label: item.nome };
                                            })}
                                        />
                                    </View>
                                    </>
                                }
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={[styles.button, { marginTop: 20, width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={(this.state.isEdit) ? this.editAppuntamento : this.saveAppuntamento}>
                                        {(this.state.loadingSend)
                                            ?
                                            <ActivityIndicator color='#fff' size='small' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva</Text>
                                        }
                                    </TouchButton>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
                <Modal
                    visible={this.state.showDetail}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={() => this.setState({
                        showDetail: false,
                        eventDetail: {}
                    })}
                >
                    <View style={[styles.container, { backgroundColor: '#FFF' }]}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={() => this.setState({
                                showDetail: false,
                                eventDetail: {}
                            })}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { marginBottom: Dimensions.get('screen').height / 3 }]}>
                                <View style={[styles.event, { backgroundColor: this.state.eventDetail.color || '#3174ad' }]}>
                                    <Text style={[styles.text, { color: (this.state.eventDetail.color == "#f8d54c") ? '#000' : '#fff' }]}>{this.state.eventDetail.title}</Text>
                                    <Text style={[styles.subText, { color: (this.state.eventDetail.color == "#f8d54c") ? '#000' : '#fff' }]}>{this.state.eventDetail.description}</Text>
                                </View>
                                <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: (Dimensions.get('screen').width <= 500) ? 'space-between' : 'flex-end',
                                    marginTop: 15
                                }}>
                                    <Button mode="contained" textColor='#fff' labelStyle={{ fontFamily: 'DM-Medium' }} contentStyle={{ paddingHorizontal: 25, backgroundColor: '#fc3535' }} style={{ marginRight: (Dimensions.get('screen').width <= 500) ? 0 : 20 }} onPress={() => this.handleDeleteRequest(this.state.eventDetail.id, this.state.eventDetail)}>Elimina</Button>
                                    <Button mode="contained" textColor='#fff' labelStyle={{ fontFamily: 'DM-Medium' }} contentStyle={{ paddingHorizontal: 25, backgroundColor: '#0070FF' }} onPress={() => this.selectAppuntamento(this.state.eventDetail, JSON.parse(this.state.eventDetail.country_code))}>Modifica</Button>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    inputDate: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerIcon: {
        backgroundColor: '#fff',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        width: 55,
        height: 55,
        alignItems: 'center',
        justifyContent: 'center'
    },
    positionCricle: {
        position: 'absolute',
        bottom: - (Dimensions.get('screen').width + (Dimensions.get('screen').width / 4)),
        right: - (Dimensions.get('screen').width + (Dimensions.get('screen').width / 4)),
        zIndex: -1
    },
    circle: {
        backgroundColor: '#8d4472',
        width: Dimensions.get('screen').width * 2,
        height: Dimensions.get('screen').width * 2,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    containerTitle: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    headImage: {
        paddingBottom: 15,
        paddingHorizontal: 20,
        alignItems: 'center'
    },
    containerImage: {
        width: 100,
        height: 100,
        borderRadius: 15,
        backgroundColor: '#ffffff1c',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 2
    },
    appointmentContainer: {
        backgroundColor: '#fff',
        padding: 15,
        borderRadius: 10,
        marginRight: 10,
        marginTop: 17,
    },
    appointmentText: {
        fontFamily: 'DM-Bold',
        fontSize: 16,
    },
    appointmentSubText: {
        fontFamily: 'DM-Regular',
        fontSize: 14,
        color: '#6d6e72',
    },
    appointmentSubTextTitle: {
        fontFamily: 'DM-Medium',
    },
    emptyDate: {
        paddingHorizontal: 20,
        paddingTop: 30,
        alignItems: 'center'
    },
    positionDelete: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 2,
        paddingTop: 8,
        paddingRight: 8
    },
    buttonDelete: {
        backgroundColor: '#fc3535',
        width: 26,
        height: 26,
        borderRadius: 22.5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonBottom: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        width: 90,
        height: 70,
        borderRadius: 0,
        borderTopLeftRadius: 60,
        backgroundColor: '#0070FF'
    },
    buttonBottomWeb: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        width: 90,
        height: 70,
        borderRadius: 0,
        borderTopLeftRadius: 60,
        backgroundColor: '#0070FF'
    },
    positionBottom: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 2
    },
    positionBottomWeb: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 5
    },
    chek: {
        backgroundColor: '#fff',
        borderColor: '#0070FF',
        borderWidth: 1.5,
        width: 25,
        height: 25,
        borderRadius: 7
    },
    checkSelected: {
        backgroundColor: '#0070FF',
        borderColor: '#0070FF',
        borderWidth: 1.5,
        width: 25,
        height: 25,
        borderRadius: 7,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modal: {
        width: (Dimensions.get('screen').width <= 500) ? '90%' : '50%',
        height: 'auto',
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150,
        paddingVertical: 25,
        justifyContent: 'center',
        zIndex: 9999999
    },
    event: {
        borderRadius: 10,
        padding: 15
    },
    text: {
        color: 'white',
        fontSize: 14,
        fontFamily: 'DM-Bold'
    },
    subText: {
        color: 'white',
        fontSize: 12,
        fontFamily: 'DM-Regular'
    }
});