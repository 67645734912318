import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Image, Platform, Modal, Alert, TouchableOpacity as TouchButton, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign, FontAwesome } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { WebView } from 'react-native-webview';
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Dentatura from '../components/Dentatura';
import DentaturaOld from '../components/DentaturaOld';
import Chart from '../components/Chart';
import ViewShot, { releaseCapture } from 'react-native-view-shot';

const dimensionChart = Dimensions.get('screen').width - 60;

export default class VisitaModificaScreen extends React.Component {
    constructor(props) {
        super(props)
        this.scrollRef = React.createRef()
        this.scrollVert = React.createRef()
        this.webViewRef = React.createRef()
        this.webViewRef2 = React.createRef()
        this.denti1 = React.createRef()
        this.denti2 = React.createRef()
        this.denti3 = React.createRef()
        this.denti4 = React.createRef()
        this.denti5 = React.createRef()
        this.denti6 = React.createRef()
        this.denti7 = React.createRef()
        this.denti8 = React.createRef()
        this.denti9 = React.createRef()
        this.denti10 = React.createRef()
        this.denti11 = React.createRef()
        this.denti12 = React.createRef()
        this.searchInput = React.createRef()
        this.state = {
            loading: false,
            loadShare: false,
            id: this.props.route.params.id,
            user: this.props.route.params.user,
            datiPaziente: {},
            allPazienti: [],
            pazientiList: [],
            showShare: false,
            pageSelect: 1,
            imageSorriso: this.props.route.params.foto_sorriso,
            imageSorrisoNuova: '',
            imageIntraoraleInferiore: this.props.route.params.foto_intraorale_inferiore,
            imageIntraoraleInferioreNuova: '',
            imageIntraoraleSuperiore: this.props.route.params.foto_intraorale_superiore,
            imageIntraoraleSuperioreNuova: '',
            imageSorrisoDestro: this.props.route.params.foto_sorriso_profilo_destro,
            imageSorrisoDestroNuova: '',
            imageSorrisoSinistro: this.props.route.params.foto_sorriso_profilo_sinistro,
            imageSorrisoSinistroNuova: '',
            imagePanoramica: this.props.route.params.panoramica,
            imagePanoramicaNuova: '',
            imageBitewingDestro: this.props.route.params.bitewing_destro,
            imageBitewingDestroNuova: '',
            imageBitewingSinistro: this.props.route.params.bitewing_sinistro,
            imageBitewingSinistroNuova: '',
            edentuliaTotSuperore: (this.props.route.params.edentulia_totale_superiore == 1) ? true : false,
            edentuliaTotInferiore: (this.props.route.params.edentulia_totale_inferiore == 1) ? true : false,
            dentiMancantiSuperiore: JSON.parse(this.props.route.params.denti_mancanti_superiore),
            dentiMancantiInferiore: JSON.parse(this.props.route.params.denti_mancanti_inferiore),
            otturazioniSuperiore: JSON.parse(this.props.route.params.otturazioni_superiore),
            otturazioniInferiore: JSON.parse(this.props.route.params.otturazioni_inferiore),
            carieSuperiore: JSON.parse(this.props.route.params.carie_superiore),
            carieInferiore: JSON.parse(this.props.route.params.carie_inferiore),
            tascheSuperiore: JSON.parse(this.props.route.params.tasche_superiore),
            tascheInferiore: JSON.parse(this.props.route.params.tasche_inferiore),
            forcazioniSuperiore: JSON.parse(this.props.route.params.forcazioni_superiore),
            forcazioniInferiore: JSON.parse(this.props.route.params.forcazioni_inferiore),
            restauriSuperiore: JSON.parse(this.props.route.params.restauri_protesi_superiore),
            restauriInferiore: JSON.parse(this.props.route.params.restauri_protesi_inferiore),
            areaIntervento: JSON.parse(this.props.route.params.aree_intervento),
            igeneOrale: this.props.route.params.igene_orale,
            dentiMobili: (this.props.route.params.denti_mobili == 1) ? true : false,
            protesiMobile: (this.props.route.params.protesi_mobile == 1) ? true : false,
            fumo: this.props.route.params.fuma,
            sanguinamentoGengive: this.props.route.params.sanguinamento_gengive,
            desideriPaziente: this.props.route.params.desideri,
            ipertensione: (this.props.route.params.ipertensione == 1) ? true : false,
            diabete: (this.props.route.params.diabete == 1) ? true : false,
            osteoporosi: (this.props.route.params.osteoporosi == 1) ? true : false,
            tiroidite: (this.props.route.params.tiroidite == 1) ? true : false,
            malattieCardiache: (this.props.route.params.malattie_cardiache == 1) ? true : false,
            allergie: (this.props.route.params.allergie == 1) ? true : false,
            gravidanza: (this.props.route.params.in_gravidanza == 1) ? true : false,
            malattieVirali: (this.props.route.params.malattie_virali == 1) ? true : false,
            malattieSistemiche: parseInt(this.props.route.params.malattie_sistemiche),
            paziente: null,
            placca: this.props.route.params.placca,
            atrofiaOssea: (this.props.route.params.atrofia_ossea == 1) ? true : false,
            paradontite: this.props.route.params.parodontite,
            analisiOrtodonzia: JSON.parse(this.props.route.params.analisi_ortodonzia),
            analisiGnatologica: JSON.parse(this.props.route.params.analisi_gnatologica),
            diarioClinico: this.props.route.params.diario_clinico,
            isPreventivo: (this.props.route.params.preventivo == 1) ? true : false,
            esito: this.props.route.params.esito,
            valore: this.props.route.params.valore,
            altreProposte: (this.props.route.params.altre_proposte == 1) ? true : false,
            risoluzioneProblemi: this.props.route.params.risoluzione_problemi,
            showChart: false,
            imageChart: this.props.route.params.foto_chart,
            imageChartNuova: '',
            showChart2: false,
            isLoaded: false,
            denti1: '',
            denti2: '',
            denti3: '',
            denti4: '',
            denti5: '',
            denti6: '',
            denti7: '',
            denti8: '',
            denti9: '',
            denti10: '',
            denti11: '',
            denti12: '',
            valutazione: 0,
            stileVita: parseInt(this.props.route.params.stile_vita),
            cerca: '',
            searchResult: [],
            loadingData: false,
            isStatisticActive: false
        }
    }

    async componentDidMount() {
        //this.getPazienti()
        this.getPaziente()
        this.checkStatistic()
        //this.setState({ paziente: this.state.user })
    }

    checkStatistic = async () => {
        const statistic = await AsyncStorage.getItem('statistiche')
        if (statistic == 'true') {
            this.setState({ isStatisticActive: true })
        } else {
            this.setState({ isStatisticActive: false })
        }
    }

    getPaziente = async () => {
        this.setState({ loadingData: true })
        let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_paziente', this.state.user);
        fetch(`https://www.visitreporter.com/app/get_paziente.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({ paziente: this.state.user, datiPaziente: json, cerca: `${json.nome} ${json.cognome}`, loadingData: false })
        }).catch((error) => {
            console.error(error);
        })
    }

    getPazienti = async () => {
        let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        fetch(`https://www.visitreporter.com/app/pazienti_list.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            let dataArray = []
            json.forEach(element => {
                dataArray.push({ label: `${element.nome} ${element.cognome}`, value: element.id, key: element.id })
                if (element.id == this.state.user) {
                    this.setState({ datiPaziente: element })
                }
            })
            this.setState({ pazientiList: dataArray, allPazienti: json })
        }).catch((error) => {
            console.error(error);
        })
    }

    continuePatientsQueryLike = async (searchText) => {
        this.setState({ cerca: searchText, loadingData: true })
        let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        fetch(`https://www.visitreporter.com/app/search_pazienti.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            if (json.length > 0) {
                this.setState({ searchResult: json, loadingData: false })
            } else {
                this.setState({ searchResult: [], loadingData: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    saveDatiPaziente = (item) => {
        let countVal = 0;
        switch (item.fonte) {
            case 'Assicurazioni':
                countVal = countVal + 0.5;
                break;
            case 'Passaparola':
                countVal = countVal + 1;
                break;
            default:
                break;
        }
        if (item.figli == 1) {
            countVal = countVal + 0.5;
        }
        switch (item.impiego) {
            case 'Impiegato':
                countVal = countVal + 1;
                break;
            case 'Libero professionista':
                countVal = countVal + 1;
                break;
            case 'Imprenditore':
                countVal = countVal + 2;
                break;
            case 'Operario':
                countVal = countVal + 0.5;
                break;
            default:
                break;
        }
        this.setState({ paziente: item.id, datiPaziente: item, valutazione: countVal, cerca: `${item.nome} ${item.cognome}`, searchResult: [] })
        this.searchInput.current.blur()
    }

    screen = async () => {
        this.webViewRef.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ imageChartNuova: manipulateImge.uri })
        })
    }

    screen2 = async () => {
        this.webViewRef2.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ imageChartNuova: manipulateImge.uri, isLoaded: true })
        })
    }

    getScreenDenti = async () => {
        this.denti1.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti1: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti2.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti2: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti3.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti3: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti4.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti4: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti5.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti5: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti6.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti6: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti7.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti7: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti8.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti8: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        /*this.denti9.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti9: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti10.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti10: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })*/
        this.denti11.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti11: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
        this.denti12.current.capture().then(async uri => {
            const manipulateImge = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1000 } }], { compress: 0.6, base64: true, format: ImageManipulator.SaveFormat.JPEG })
            this.setState({ denti12: `data:image/jpeg;base64,${manipulateImge.base64}` })
        })
    }

    saveVisita = async () => {
        this.setState({ loading: true })
        if (this.state.paziente != null && this.state.areaIntervento.length > 0) {
            let countVal = this.state.valutazione
            if (this.state.altreProposte == true) {
                countVal = countVal + 1;
            } else {
                countVal = countVal + 0.5;
            }
            if (this.state.edentuliaTotInferiore == true || this.state.edentuliaTotSuperore == true) {
                if (this.state.edentuliaTotSuperore == true) {
                    countVal = countVal + 3;
                }
                if (this.state.edentuliaTotInferiore == true) {
                    countVal = countVal + 4;
                }
            } else {
                if ((this.state.dentiMancantiInferiore.length + this.state.dentiMancantiSuperiore.length) == 1) {
                    countVal = countVal + 0.5;
                } else if ((this.state.dentiMancantiInferiore.length + this.state.dentiMancantiSuperiore.length) == 2) {
                    countVal = countVal + 1.5;
                } else if ((this.state.dentiMancantiInferiore.length + this.state.dentiMancantiSuperiore.length) >= 3) {
                    countVal = countVal + 2;
                }
            }
            if (this.state.paradontite != null && this.state.paradontite != '') {
                countVal = countVal + 1.5;
            }
            if ((this.state.restauriInferiore.length + this.state.restauriSuperiore.length) > 0) {
                countVal = countVal + 1;
            }
            if (this.state.dentiMobili == true) {
                countVal = countVal + 0.5;
            }
            if (this.state.analisiOrtodonzia.includes('Seconda classe')) {
                countVal = countVal + 2;
            }
            if (this.state.analisiOrtodonzia.includes('Terza classe')) {
                countVal = countVal + 2;
            }
            if (this.state.analisiOrtodonzia.includes('Affollamento')) {
                countVal = countVal + 1.5;
            }
            if (this.state.analisiGnatologica.length > 0) {
                countVal = countVal + 1.5;
            }
            if (this.state.protesiMobile == true) {
                if ((this.state.dentiMancantiInferiore.length + this.state.dentiMancantiSuperiore.length) > 0) {
                    countVal = countVal + 2;
                }
            } else {
                if ((this.state.dentiMancantiInferiore.length + this.state.dentiMancantiSuperiore.length) > 0) {
                    countVal = countVal + 0.5;
                }
            }
            if(this.state.desideriPaziente == 'Sorriso fisso') {
                countVal = countVal + 1;
            }
            if(this.state.desideriPaziente == 'Masticare') {
                countVal = countVal + 0.5;
            }
            if(this.state.desideriPaziente == 'Denti da star') {
                countVal = countVal + 1;
            }
            if (this.state.stileVita == 2) {
                countVal = countVal + 0.5;
            } else if (this.state.stileVita == 3) {
                countVal = countVal + 1;
            }
            let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
            let id = await AsyncStorage.getItem('id')
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id', this.state.id);
            formData.append('id_user', id);
            formData.append('id_paziente', this.state.paziente);
            formData.append('desideri', this.state.desideriPaziente);
            formData.append('fuma', this.state.fumo);
            formData.append('ipertensione', (this.state.ipertensione) ? 1 : 0);
            formData.append('diabete', (this.state.diabete) ? 1 : 0);
            formData.append('osteoporosi', (this.state.osteoporosi) ? 1 : 0);
            formData.append('tiroidite', (this.state.tiroidite) ? 1 : 0);
            formData.append('malattie_cardiache', (this.state.malattieCardiache) ? 1 : 0);
            formData.append('allergie', (this.state.allergie) ? 1 : 0);
            formData.append('in_gravidanza', (this.state.gravidanza) ? 1 : 0);
            formData.append('malattie_virali', (this.state.malattieVirali) ? 1 : 0);
            formData.append('denti_mancanti_superiore', JSON.stringify(this.state.dentiMancantiSuperiore));
            formData.append('denti_mancanti_inferiore', JSON.stringify(this.state.dentiMancantiInferiore));
            formData.append('edentulia_totale_superiore', (this.state.edentuliaTotSuperore) ? 1 : 0);
            formData.append('edentulia_totale_inferiore', (this.state.edentuliaTotInferiore) ? 1 : 0);
            formData.append('otturazioni_superiore', JSON.stringify(this.state.otturazioniSuperiore));
            formData.append('otturazioni_inferiore', JSON.stringify(this.state.otturazioniInferiore));
            formData.append('carie_superiore', JSON.stringify(this.state.carieSuperiore));
            formData.append('carie_inferiore', JSON.stringify(this.state.carieInferiore));
            formData.append('tasche_superiore', JSON.stringify(this.state.tascheSuperiore));
            formData.append('tasche_inferiore', JSON.stringify(this.state.tascheInferiore));
            formData.append('forcazioni_superiore', JSON.stringify(this.state.forcazioniSuperiore));
            formData.append('forcazioni_inferiore', JSON.stringify(this.state.forcazioniInferiore));
            formData.append('restauri_protesi_superiore', JSON.stringify(this.state.restauriSuperiore));
            formData.append('restauri_protesi_inferiore', JSON.stringify(this.state.restauriInferiore));
            formData.append('igene_orale', this.state.igeneOrale);
            formData.append('denti_mobili', (this.state.dentiMobili) ? 1 : 0);
            formData.append('protesi_mobile', (this.state.protesiMobile) ? 1 : 0);
            formData.append('sanguinamento_gengive', this.state.sanguinamentoGengive);
            formData.append('placca', this.state.placca);
            formData.append('atrofia_ossea', (this.state.atrofiaOssea) ? 1 : 0);
            formData.append('parodontite', this.state.paradontite);
            formData.append('analisi_ortodonzia', JSON.stringify(this.state.analisiOrtodonzia));
            formData.append('analisi_gnatologica', JSON.stringify(this.state.analisiGnatologica));
            formData.append('aree_intervento', JSON.stringify(this.state.areaIntervento));
            formData.append('diario_clinico', this.state.diarioClinico);
            formData.append('stile_vita', this.state.stileVita);
            formData.append('preventivo', (this.state.isPreventivo) ? 1 : 0);
            formData.append('esito', this.state.esito);
            formData.append('valore', this.state.valore);
            formData.append('altre_proposte', (this.state.altreProposte) ? 1 : 0);
            formData.append('risoluzione_problemi', this.state.risoluzioneProblemi);
            formData.append('malattie_sistemiche', this.state.malattieSistemiche);
            formData.append('valutazione', countVal);
            if (this.state.imageSorrisoNuova != '') {
                const foto_sorriso = {
                    uri: this.state.imageSorrisoNuova,
                    type: 'image/jpeg',
                    name: `immagine_sorriso_${Date.now()}.jpg`,
                };
                formData.append('foto_sorriso', foto_sorriso);
            }
            if (this.state.imageIntraoraleInferioreNuova != '') {
                const foto_intraorale_inferiore = {
                    uri: this.state.imageIntraoraleInferioreNuova,
                    type: 'image/jpeg',
                    name: `immagine_intraorale_inferiore_${Date.now()}.jpg`,
                };
                formData.append('foto_intraorale_inferiore', foto_intraorale_inferiore);
            }
            if (this.state.imageIntraoraleSuperioreNuova != '') {
                const foto_intraorale_superiore = {
                    uri: this.state.imageIntraoraleSuperioreNuova,
                    type: 'image/jpeg',
                    name: `immagine_intraorale_superiore_${Date.now()}.jpg`,
                };
                formData.append('foto_intraorale_superiore', foto_intraorale_superiore);
            }
            if (this.state.imageSorrisoDestroNuova != '') {
                const foto_sorriso_profilo_destro = {
                    uri: this.state.imageSorrisoDestroNuova,
                    type: 'image/jpeg',
                    name: `immagine_sorriso_profilo_destroe_${Date.now()}.jpg`,
                };
                formData.append('foto_sorriso_profilo_destro', foto_sorriso_profilo_destro);
            }
            if (this.state.imageSorrisoSinistroNuova != '') {
                const foto_sorriso_profilo_sinistro = {
                    uri: this.state.imageSorrisoSinistroNuova,
                    type: 'image/jpeg',
                    name: `immagine_sorriso_profilo_sinistro_${Date.now()}.jpg`,
                };
                formData.append('foto_sorriso_profilo_sinistro', foto_sorriso_profilo_sinistro);
            }
            if (this.state.imagePanoramicaNuova != '') {
                const panoramica = {
                    uri: this.state.imagePanoramicaNuova,
                    type: 'image/jpeg',
                    name: `immagine_panoramica_${Date.now()}.jpg`,
                };
                formData.append('panoramica', panoramica);
            }
            if (this.state.imageBitewingDestroNuova != '') {
                const bitewing_destro = {
                    uri: this.state.imageBitewingDestroNuova,
                    type: 'image/jpeg',
                    name: `immagine_bitewing_destro_${Date.now()}.jpg`,
                };
                formData.append('bitewing_destro', bitewing_destro);
            }
            if (this.state.imageBitewingSinistroNuova != '') {
                const bitewing_sinistro = {
                    uri: this.state.imageBitewingSinistroNuova,
                    type: 'image/jpeg',
                    name: `immagine_bitewing_sinistro_${Date.now()}.jpg`,
                };
                formData.append('bitewing_sinistro', bitewing_sinistro);
            }
            if (this.state.imageChartNuova != '') {
                const foto_chart = {
                    uri: this.state.imageChartNuova,
                    type: 'image/jpeg',
                    name: `immagine_chart_visita_${Date.now()}.jpg`,
                };
                formData.append('foto_chart', foto_chart);
            }
            fetch(`https://www.visitreporter.com/app/modifica_visita.php`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                body: formData
            })
            .then((response) => response.json())
            .then((json) => {
                if (json.code == true) {
                    this.getScreenDenti()
                    if (json.foto_sorriso != '' && json.foto_sorriso != 'NULL') {
                        this.setState({ imageSorriso: json.foto_sorriso })
                    }
                    if (json.foto_intraorale_superiore != '' && json.foto_intraorale_superiore != 'NULL') {
                        this.setState({ imageIntraoraleSuperiore: json.foto_intraorale_superiore })
                    }
                    if (json.foto_intraorale_inferiore != '' && json.foto_intraorale_inferiore != 'NULL') {
                        this.setState({ imageIntraoraleInferiore: json.foto_intraorale_inferiore })
                    }
                    if (json.foto_sorriso_profilo_destro != '' && json.foto_sorriso_profilo_destro != 'NULL') {
                        this.setState({ imageSorrisoDestro: json.foto_sorriso_profilo_destro })
                    }
                    if (json.foto_sorriso_profilo_sinistro != '' && json.foto_sorriso_profilo_sinistro != 'NULL') {
                        this.setState({ imageSorrisoSinistro: json.foto_sorriso_profilo_sinistro })
                    }
                    if (json.panoramica != '' && json.panoramica != 'NULL') {
                        this.setState({ imagePanoramica: json.panoramica })
                    }
                    if (json.bitewing_destro != '' && json.bitewing_destro != 'NULL') {
                        this.setState({ imageBitewingDestro: json.bitewing_destro })
                    }
                    if (json.bitewing_sinistro != '' && json.bitewing_sinistro != 'NULL') {
                        this.setState({ imageBitewingSinistro: json.bitewing_sinistro })
                    }
                    if (json.foto_chart != '' && json.foto_chart != 'NULL') {
                        this.setState({ imageChart: json.foto_chart })
                    }
                    this.setState({ 
                        loading: false,
                        showShare: true
                    })
                } else {
                    this.setState({ loading: false })
                    Alert.alert(
                        "Attenzione",
                        json.message,
                        [
                            { text: "OK" }
                        ]
                    );
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.error(error);
            })
        } else {
            this.setState({ loading: false })
            Alert.alert(
                "Attenzione",
                "Seleziona il paziente e le aree di intervento.",
                [
                    { text: "OK" }
                ]
            );
        }
    }

    addDentiMancantiSuperiore = (num) => {
        let datas = this.state.dentiMancantiSuperiore;
        datas.push(num)
        this.setState({ dentiMancantiSuperiore: datas })
    }

    removeDentiMancantiSuperiore = (num) => {
        let datas = this.state.dentiMancantiSuperiore;
        datas = datas.filter(e => e !== num)
        this.setState({ dentiMancantiSuperiore: datas })
    }

    addDentiMancantiInferiore = (num) => {
        let datas = this.state.dentiMancantiInferiore;
        datas.push(num)
        this.setState({ dentiMancantiInferiore: datas })
    }

    removeDentiMancantiInferiore = (num) => {
        let datas = this.state.dentiMancantiInferiore;
        datas = datas.filter(e => e !== num)
        this.setState({ dentiMancantiInferiore: datas })
    }

    addotturazioniSuperiore = (num) => {
        let datas = this.state.otturazioniSuperiore;
        datas.push(num)
        this.setState({ otturazioniSuperiore: datas })
    }

    removeotturazioniSuperiore = (num) => {
        let datas = this.state.otturazioniSuperiore;
        datas = datas.filter(e => e !== num)
        this.setState({ otturazioniSuperiore: datas })
    }

    addotturazioniInferiore = (num) => {
        let datas = this.state.otturazioniInferiore;
        datas.push(num)
        this.setState({ otturazioniInferiore: datas })
    }

    removeotturazioniInferiore = (num) => {
        let datas = this.state.otturazioniInferiore;
        datas = datas.filter(e => e !== num)
        this.setState({ otturazioniInferiore: datas })
    }

    addcarieSuperiore = (num) => {
        let datas = this.state.carieSuperiore;
        datas.push(num)
        this.setState({ carieSuperiore: datas })
    }

    removecarieSuperiore = (num) => {
        let datas = this.state.carieSuperiore;
        datas = datas.filter(e => e !== num)
        this.setState({ carieSuperiore: datas })
    }

    addcarieInferiore = (num) => {
        let datas = this.state.carieInferiore;
        datas.push(num)
        this.setState({ carieInferiore: datas })
    }

    removecarieInferiore = (num) => {
        let datas = this.state.carieInferiore;
        datas = datas.filter(e => e !== num)
        this.setState({ carieInferiore: datas })
    }

    addtascheSuperiore = (num) => {
        let datas = this.state.tascheSuperiore;
        datas.push(num)
        this.setState({ tascheSuperiore: datas })
    }

    removetascheSuperiore = (num) => {
        let datas = this.state.tascheSuperiore;
        datas = datas.filter(e => e !== num)
        this.setState({ tascheSuperiore: datas })
    }

    addtascheInferiore = (num) => {
        let datas = this.state.tascheInferiore;
        datas.push(num)
        this.setState({ tascheInferiore: datas })
    }

    removetascheInferiore = (num) => {
        let datas = this.state.tascheInferiore;
        datas = datas.filter(e => e !== num)
        this.setState({ tascheInferiore: datas })
    }

    addforcazioniSuperiore = (num) => {
        let datas = this.state.forcazioniSuperiore;
        datas.push(num)
        this.setState({ forcazioniSuperiore: datas })
    }

    removeforcazioniSuperiore = (num) => {
        let datas = this.state.forcazioniSuperiore;
        datas = datas.filter(e => e !== num)
        this.setState({ forcazioniSuperiore: datas })
    }

    addforcazioniInferiore = (num) => {
        let datas = this.state.forcazioniInferiore;
        datas.push(num)
        this.setState({ forcazioniInferiore: datas })
    }

    removeforcazioniInferiore = (num) => {
        let datas = this.state.forcazioniInferiore;
        datas = datas.filter(e => e !== num)
        this.setState({ forcazioniInferiore: datas })
    }

    addrestauriSuperiore = (num) => {
        let datas = this.state.restauriSuperiore;
        datas.push(num)
        this.setState({ restauriSuperiore: datas })
    }

    removerestauriSuperiore = (num) => {
        let datas = this.state.restauriSuperiore;
        datas = datas.filter(e => e !== num)
        this.setState({ restauriSuperiore: datas })
    }

    addrestauriInferiore = (num) => {
        let datas = this.state.restauriInferiore;
        datas.push(num)
        this.setState({ restauriInferiore: datas })
    }

    removerestauriInferiore = (num) => {
        let datas = this.state.restauriInferiore;
        datas = datas.filter(e => e !== num)
        this.setState({ restauriInferiore: datas })
    }

    addAreaIntervento = (num) => {
        let datas = this.state.areaIntervento;
        datas.push(num)
        this.setState({ areaIntervento: datas })
    }

    removeAreaIntervento = (num) => {
        let datas = this.state.areaIntervento;
        datas = datas.filter(e => e !== num)
        this.setState({ areaIntervento: datas })
    }

    addAnalisiOrtodonzia = (num) => {
        let datas = this.state.analisiOrtodonzia;
        datas.push(num)
        this.setState({ analisiOrtodonzia: datas })
    }

    removeAnalisiOrtodonzia = (num) => {
        let datas = this.state.analisiOrtodonzia;
        datas = datas.filter(e => e !== num)
        this.setState({ analisiOrtodonzia: datas })
    }

    addAnalisiGnatologica = (num) => {
        let datas = this.state.analisiGnatologica;
        datas.push(num)
        this.setState({ analisiGnatologica: datas })
    }

    removeAnalisiGnatologica = (num) => {
        let datas = this.state.analisiGnatologica;
        datas = datas.filter(e => e !== num)
        this.setState({ analisiGnatologica: datas })
    }

    scattaImage = async (type) => {
        let permission = await ImagePicker.getCameraPermissionsAsync();
        if (permission.status != 'granted') {
            let request = await ImagePicker.requestCameraPermissionsAsync();
            if (request.status != 'granted') {
                alert("Per caricare un'immagine devi acconsentire il permesso per accedere alla tua fotocamera");
            } else {
                this.imageCamera(type)
            }
        } else {
            this.imageCamera(type)
        }
    }

    imageCamera = async (type) => {
        let result = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 0.8
        })
        if (!result.cancelled) {
            let manipulateImge = null;
            if (type == 2 || type == 3) {
                manipulateImge = await ImageManipulator.manipulateAsync(result.uri, [{ resize: { width: 700 }},{ flip: ImageManipulator.FlipType.Horizontal }], { format: ImageManipulator.SaveFormat.JPEG })
            } else {
                manipulateImge = await ImageManipulator.manipulateAsync(result.uri, [{ resize: { width: 700 } }], { format: ImageManipulator.SaveFormat.JPEG })
            }
            switch (type) {
                case 1:
                    this.setState({ imageSorrisoNuova: manipulateImge.uri })
                    break;
                case 2:
                    this.setState({ imageIntraoraleInferioreNuova: manipulateImge.uri })
                    break;
                case 3:
                    this.setState({ imageIntraoraleSuperioreNuova: manipulateImge.uri })
                    break;
                case 4:
                    this.setState({ imageSorrisoDestroNuova: manipulateImge.uri })
                    break;
                case 5:
                    this.setState({ imageSorrisoSinistroNuova: manipulateImge.uri })
                    break;
                case 6:
                    this.setState({ imagePanoramicaNuova: manipulateImge.uri })
                    break;
                case 7:
                    this.setState({ imageBitewingDestroNuova: manipulateImge.uri })
                    break;
                case 8:
                    this.setState({ imageBitewingSinistroNuova: manipulateImge.uri })
                    break;
                default:
                    break;
            }
        }
    }

    pickImage = async (type) => {
        let permission = await ImagePicker.getMediaLibraryPermissionsAsync();
        if (permission.status != 'granted') {
            let request = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (request.status != 'granted') {
                alert("Per caricare un'immagine devi acconsentire il permesso per accedere alla tua galleria");
            } else {
                this.imageGallery(type)
            }
        } else {
            this.imageGallery(type)
        }
    }

    imageGallery = async (type) => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 0.8
        })
        if (!result.cancelled) {
            const manipulateImge = await ImageManipulator.manipulateAsync(result.uri, [{ resize: { width: 700 } }], { format: ImageManipulator.SaveFormat.JPEG })
            switch (type) {
                case 1:
                    this.setState({ imageSorrisoNuova: manipulateImge.uri })
                    break;
                case 2:
                    this.setState({ imageIntraoraleInferioreNuova: manipulateImge.uri })
                    break;
                case 3:
                    this.setState({ imageIntraoraleSuperioreNuova: manipulateImge.uri })
                    break;
                case 4:
                    this.setState({ imageSorrisoDestroNuova: manipulateImge.uri })
                    break;
                case 5:
                    this.setState({ imageSorrisoSinistroNuova: manipulateImge.uri })
                    break;
                case 6:
                    this.setState({ imagePanoramicaNuova: manipulateImge.uri })
                    break;
                case 7:
                    this.setState({ imageBitewingDestroNuova: manipulateImge.uri })
                    break;
                case 8:
                    this.setState({ imageBitewingSinistroNuova: manipulateImge.uri })
                    break;
                default:
                    break;
            }
        }
    }

    navForward = () => {
        let numPage = this.state.pageSelect;
        this.scrollRef.current.scrollTo({ x: Dimensions.get('screen').width * numPage, animated: true })
        //this.setState({ pageSelect: numPage + 1 })
    }

    print = async () => {
        let image = await AsyncStorage.getItem('image')
        let nome_studio = await AsyncStorage.getItem('nome_studio')
        let telefono = await AsyncStorage.getItem('telefono')
        let country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
        let via = await AsyncStorage.getItem('via')
        let citta = await AsyncStorage.getItem('citta')
        if (nome_studio == null || via == null || citta == null) {
            this.setState({ showShare: false })
            this.props.navigation.navigate('EditInformazioni')
            return;
        }
        const htmlPrint = `
        <!DOCTYPE html>
<html>
<head>
<script src="https://cdn.anychart.com/releases/8.7.1/js/anychart-core.min.js"></script>
<script src="https://cdn.anychart.com/releases/8.7.1/js/anychart-radar.min.js"></script>
<style>
${(Platform.OS == 'android')
    &&
    '@page { margin: 20px; }'
}

main {
    break-inside: avoid;
}

@font-face {
  font-weight: 400;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2') format('woff2');
}

@font-face {
  font-weight: 500;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2') format('woff2');
}

@font-face {
  font-weight: 700;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
}

@font-face {
  font-weight: 900;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2') format('woff2');
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-family: 'Circular-Loom';
}

tr:nth-child(even) {
  background-color: #dddddd;
}

body {
	font-family: 'Circular-Loom';
}

#container {
  width: 30em;
  height: 20em;
  margin: 0;
  padding: 0;
}
  .anychart-credits {
    display: none !important;
  }
</style>
</head>
<body>
<main style="height: ${(Platform.OS == 'android') ? '285mm' : '272mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="display: flex; justify-content: center; position: absolute; top: 7em; left: 0; right: 0;">
        <img src="https://www.visitreporter.com/app/assets/image/app/visit-reporter-color.png" style="width: 8em; height: 8em;" />
    </div>
    <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;">
        <div style="padding-top: 3em; padding-bottom: 3em; padding-left: 8em; padding-right: 8em; border: 6px solid rgb(7, 149, 255); border-radius: 0.5em;">
            <div style="display: flex; justify-content: center">
                <img src="${image}" style="width: 10em; height: 10em; border-radius: 5em; background-color: rgb(7, 149, 255); object-fit: cover;" />
            </div>
            <div style="padding-top: 2em">
                <h2 style="margin-top: 0; margin-bottom: 0; text-align: center;">REPORT VISITA</h2>
            </div>
            <div style="padding-top: 2em">
                <h2 style="text-align: center;">Paziente</h2>
                <h3 style="text-align: center;">${this.state.datiPaziente.nome} ${this.state.datiPaziente.cognome}</h3>
            </div>
            <div style="padding-top: 1em; border-top: 1px solid #999999">
                <h3 style="text-align: center;margin-bottom: 0; margin-top: 0">${nome_studio}</h3>
                <p style="text-align: center;margin-top: 0.3em; margin-bottom: 0; padding-bottom: 0;">${via}, ${citta}</div>
                <p style="text-align: center;margin-top: 0.3em; margin-bottom: 0; padding-bottom: 0;">+${country_code.callingCode[0]}${telefono}</div>
            </div>
        </div>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
        <div>
            <p style="margin-bottom: 0; margin-top: 0; font-size: 19px;">
            Gentile Paziente,<br>
            Benvenuto presso ${nome_studio}.<br><br>
            Attraverso questo documento di prima visita potrà valutare insieme al dentista
            la situazione della sua bocca e comprendere quali sono le sue criticità e -
            soprattutto - le aree in cui intervenire al fine di tornare ad avere una funzione
            masticatoria ed estetica di primo livello.<br><br>
            Com’è noto, il sorriso è il nostro biglietto da visita, allieta le nostre
            giornate e quelle di chi ci sta attorno, per cui bisogna averne cura.<br><br>
            <strong>Ma cosa succede quando viene perso o compromesso?</strong><br><br>
            La perdita del sorriso causa disagi a più livelli, non solo a livello estetico,
            ma anche su quello funzionale e psicologico.<br><br>
            Se da una parte si perderà una corretta funzione masticatoria,
            influenzando anche le funzionalità fonetiche, a livello psicologico, si
            smetterà di sorridere e si tenderà ad isolarsi dagli altri, rischiando di
            compromettere le relazioni interpersonali.<br><br>
            Quello che in molti non sanno è che la medicina rende disponibili molte
            opzioni al fine di migliorare il proprio sorriso, in maniera rapida, mini
            invasiva e anche nelle situazioni particolarmente difficili.<br><br>
            Con questo Report vogliamo porre l’attenzione sulla situazione della sua
            bocca e indicarle le modalità di risoluzione dei suoi problemi in maniera
            rapida e assolutamente personalizzata.<br><br>
            ${nome_studio}.<br><br>
            ${
                (this.state.areaIntervento.length > 0)
                    ?
                    `
                    Le aree dell’odontoiatria su cui consigliamo di intervenire sono:<br><br>
                    `
                    :
                    ''
            }
            ${
                this.state.areaIntervento.map((item) => {
                    return ` ${item}`;
                })
            }
            </p>
        </div>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="background-color: rgb(7, 149, 255); padding: 1em; border-radius: 1em; display: flex; justify-content: space-between; align-items: center;">
        <img src="https://www.visitreporter.com/app/assets/image/app/visit-reporter-logo.png" style="width: 7em; height: 7em;" />
        <div style="padding-right: 1em; display: flex; justify-content: flex-end; align-items: center;">
            <div style="margin-right: 1em">
                <img src="${image}" style="width: 5em; height: 5em; object-fit: cover; border-radius: 2.5em;" />
            </div>
            <div>
                <h3 style="color: #fff; margin-bottom: 0; margin-top: 0">${nome_studio}</h3>
                <p style="color: #fff; margin-top: 0.3em">${via}, ${citta}<br>+${country_code.callingCode[0]}${telefono}</div>
            </div>
        </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 1.2em;">
        <h2 style="margin-top: 0">Report visita</h2>
    </div>
    <div style="padding-left: 4em; padding-right: 4em; margin-top: 0.5em; display: flex; justify-content: space-between">
        <div>
            <h3 style="margin-top: 0">Paziente:</h3>
            <p style="margin-top: 0em; margin-bottom: 0">${this.state.datiPaziente.nome} ${this.state.datiPaziente.cognome}</p>
            <p style="margin-top: 0.3em; text-decoration: underline">${this.state.datiPaziente.email}</p>
        </div>
        <div>
            <h3 style="margin-top: 0">Desideri del paziente:</h3>
            <p style="margin-top: 0em; margin-bottom: 0">${this.state.desideriPaziente}</p>
        </div>
    </div>
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 1.5em; padding-bottom: 2em">
        <table style="border-radius: 1em">
            <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Esito</th>
            </tr>
            <tr>
            <td>Fuma</td>
            <td>${(this.state.fumo != null) ? this.state.fumo : ''}</td>
            </tr>
            <tr>
            <td>Ipertensione</td>
            <td>${(this.state.ipertensione) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Diabete</td>
            <td>${(this.state.diabete) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Osteoporosi</td>
            <td>${(this.state.osteoporosi) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Tiroidite</td>
            <td>${(this.state.tiroidite) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Malattie cardiache</td>
            <td>${(this.state.malattieCardiache) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Allergie a farmaci o anestetici</td>
            <td>${(this.state.allergie) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>In gravidanza</td>
            <td>${(this.state.gravidanza) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Malattie virali</td>
            <td>${(this.state.malattieVirali) ? 'Si' : 'No'}</td>
            </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 2em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Esito</th>
        </tr>
        <tr>
            <td>Foto intraorale sorriso</td>
            <td>
                ${(this.state.imageSorriso != '')
                    ?
                    `<img src="${this.state.imageSorriso}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Foto occlusale arcata inferiore</td>
            <td>
                ${(this.state.imageIntraoraleInferiore != '')
                    ?
                    `<img src="${this.state.imageIntraoraleInferiore}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Foto occlusale arcata superiore</td>
            <td>
                ${(this.state.imageIntraoraleSuperiore != '')
                    ?
                    `<img src="${this.state.imageIntraoraleSuperiore}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Foto sorriso profilo destro</td>
            <td>
                ${(this.state.imageSorrisoDestro != '')
                    ?
                    `<img src="${this.state.imageSorrisoDestro}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Foto sorriso profilo sinistro</td>
            <td>
                ${(this.state.imageSorrisoSinistro != '')
                    ?
                    `<img src="${this.state.imageSorrisoSinistro}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Panoramica</td>
            <td>
                ${(this.state.imagePanoramica != '')
                    ?
                    `<img src="${this.state.imagePanoramica}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Bitewing destro</td>
            <td>
                ${(this.state.imageBitewingDestro != '')
                    ?
                    `<img src="${this.state.imageBitewingDestro}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Bitewing sinistro</td>
            <td>
                ${(this.state.imageBitewingSinistro != '')
                    ?
                    `<img src="${this.state.imageBitewingSinistro}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 0em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Edentulia totale</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Dente</th>
        </tr>
        <tr>
            <td>Denti mancanti (Arcata Superiore)</td>
            <td>${(this.state.edentuliaTotSuperore) ? 'Si' : 'No'}</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti1 != '')
                    ?
                    `<img src="${this.state.denti1}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Denti mancanti (Arcata Inferiore)</td>
            <td>${(this.state.edentuliaTotInferiore) ? 'Si' : 'No'}</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti2 != '')
                    ?
                    `<img src="${this.state.denti2}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        </table>
    </div>
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 2em; padding-bottom: 2em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Dente</th>
        </tr>
        <tr>
            <td>Otturazioni (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti3 != '')
                    ?
                    `<img src="${this.state.denti3}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Otturazioni (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti4 != '')
                    ?
                    `<img src="${this.state.denti4}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Carie (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti5 != '')
                    ?
                    `<img src="${this.state.denti5}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 0em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Dente</th>
        </tr>
        <tr>
            <td>Carie (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti6 != '')
                    ?
                    `<img src="${this.state.denti6}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Tasche (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti7 != '')
                    ?
                    `<img src="${this.state.denti7}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Tasche (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti8 != '')
                    ?
                    `<img src="${this.state.denti8}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        ${/*<tr>
            <td>Forcazioni (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti9 != '')
                    ?
                    `<img src="${this.state.denti9}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Forcazioni (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti10 != '')
                    ?
                    `<img src="${this.state.denti10}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>*/''}
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 0em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Dente</th>
        </tr>
        <tr>
            <td>Restauri e Protesi (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti11 != '')
                    ?
                    `<img src="${this.state.denti11}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Restauri e Protesi (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti12 != '')
                    ?
                    `<img src="${this.state.denti12}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '272mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 2em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Esito</th>
        </tr>
        <tr>
            <td>Igiene orale</td>
            <td>
                ${(this.state.igeneOrale != null) ? this.state.igeneOrale : ''}
            </td>
        </tr>
        <tr>
            <td>Denti mobili</td>
            <td>
                ${(this.state.dentiMobili) ? 'Si' : 'No'}
            </td>
        </tr>
        <tr>
            <td>Protesi mobile</td>
            <td>
                ${(this.state.protesiMobile) ? 'Si' : 'No'}
            </td>
        </tr>
        <tr>
            <td>Sanguinamento gengive</td>
            <td>
                ${(this.state.sanguinamentoGengive != null) ? this.state.sanguinamentoGengive : ''}
            </td>
        </tr>
        <tr>
            <td>Placca</td>
            <td>
                ${(this.state.placca != null) ? this.state.placca : ''}
            </td>
        </tr>
        <tr>
            <td>Sospetta atrofia ossea</td>
            <td>
                ${(this.state.atrofiaOssea) ? 'Si' : 'No'}
            </td>
        </tr>
        <tr>
            <td>Parodontite</td>
            <td>
                ${(this.state.paradontite != null) ? this.state.paradontite : ''}
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '272mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; display: flex; justify-content: center;">
        <h3>Indice di rischio</h3>
    </div>
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 1em; padding-bottom: 2em; display: flex; justify-content: center;">
        ${(Platform.OS == 'android')
            ?
            `<img src="${this.state.imageChart}" style="width: 35em; height: 25em; object-fit: contain;" />`
            :
            `<div id="container"></div>`
        }
    </div>
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 2em; padding-bottom: 1em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Valutazione</th>
        </tr>
        <tr>
            <td>Analisi ortodonzia</td>
            <td>
                ${
                    this.state.analisiOrtodonzia.map((item) => {
                        return ` ${item}`;
                    })
                }
            </td>
        </tr>
        <tr>
            <td>Analisi gnatologica</td>
            <td>
                ${
                    this.state.analisiGnatologica.map((item) => {
                        return ` ${item}`;
                    })
                }
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? '285mm' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-top: 4em">
        <h2 style="text-align: center;">Osservazioni</h2>
        <p style="text-align: justify; font-size: 19px;">${this.state.diarioClinico}</p>
    </div>
</main>
${
    (this.state.areaIntervento.length > 0)
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? '285mm' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;">
                <div style="padding: 6em; border: 6px solid rgb(7, 149, 255); border-radius: 0.5em; margin-left: 5em; margin-right: 5em;">
                    <div style="display: flex; justify-content: center">
                        <h2 style="margin-top: 0; margin-bottom: 0; text-align: center;">AREA IN CUI BISOGNEREBBE INTERVENIRE</h2>
                    </div>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Conservativa'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Conservativa</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/conservativa.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Conservare i denti è la cosa più importante che un dentista possa fare. Devi sapere che il dente è costituito da uno strato superficiale chiamato smalto, uno strato intermedio chiamato “dentina” e un nucleo centrale chiamato “polpa” costituita da nervi, cellule e vasi sanguigni che assicurano la vitalità del dente
                            In caso di carie dello smalto o della dentina, è necessario intervenire rapidamente  per evitare che questa intacchi la polpa, generando un forte dolore: il classico mal di denti.<br> Molto importante è che tu sappia che la carie va prevenuta poiché dipende da abitudini alimentari e di igiene orale errate che ti aiuteremo ad intercettare.<br>
                            L’otturazione è il modo in cui  si interviene per ripristinare la forma e la funzione  dell’elemento dentario. L’otturazione viene eseguita attraverso resine composite, materiali molto lucidabili e in grado quindi di mimetizzarsi del tutto al fine di assicurare un’estetica perfetta.     
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Endodonzia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Endodonzia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/endodonzia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    L’endodonzia è la disciplina che si occupa delle cosiddette “devitalizzazioni”  ovvero   le terapie canalari, che consistono nella rimozione della polpa dentaria e canalare e la sua sostituzione con materiali in grado di non far scurire il dente.<br>
                    Quando si devitalizza un dente?<br>
                    Se una carie risulta molto aggressiva fino a raggiungere la polpa dentaria è necessario eseguire la terapia canalare come sopra spiegato; l’intervento è minimo e  potrà  consentire il successivo restauro che permetterà al paziente di riottenere la funzionalità sull’elemento dentario.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Implantologia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Implantologia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/implantologia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    L’implantologia si occupa di  sostituire uno o più denti mancanti attraverso l’inserimento nell’osso di vere e proprie radici artificiali in titanio dette appunto impianti dentali. Normalmente l’aspetto esterno è quello di una vite.<br>
                    Nei casi in cui il dente da sostituire è soltanto uno, normalmente si attende da uno a sei mesi(dipende dai casi) affinché le spire della vite siano circondate da nuovo osso, dopodiché si inserisce una “capsula” in ceramica che per forma e colore simula del tutto e per tutto il dente naturale perduto.
                    Se ci sono più denti adiacenti mancanti si inseriscono più impianti che si collegano fra loro in modalità ponte fisso; ovvero si vedranno più denti in ceramica tutti uniti fra loro e anche in questo caso il ripristino della forma, della funzione e dell’estetica saranno perfette.<br>
                    <h4 style="margin-top: 0.5rem; margin-bottom: 0.5rem;">RIABILITAZIONE ARCATA COMPLETA</h4>
                    L’implantologia è in grado di ridare il sorriso anche a chi abbia perduto tutti i denti di un’arcata; in questo caso utilizzando un numero di impianti che solitamente varia da 4 a 6 si potrà ricostruire tutta l’arcata perduta. Talora si potrà effettuare il carico immediato degli impianti, ovvero posizionare al paziente la protesi il giorno stesso in cui sono stati messi gli impianti.<br>
                    L’intervento di implantologia viene solitamente eseguito in anestesia locale.<br>
                    Il periodo postoperatorio è normalmente piuttosto sereno poiché l’implantologo esperto non traumatizza eccessivamente l’osso; ghiaccio, terapie del caso e un po' di riposo permetteranno al paziente di tornare alla vita normale in pochi giorni.
                    L’igienista seguirà nel tempo la salute dei tessuti attorno agli impianti a patto che il paziente si presenti regolarmente alle sedute previste che variano da caso a caso.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Protesi'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Protesi</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/protesi.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    La protesi è la disciplina dell’odontoiatria che si occupa di sostituire i denti mancanti al fine di ripristinare estetica e funzione masticatoria.<br>
                    La protesi può essere fissa o rimovibile; i pilastri che sorreggono la protesi possono essere denti naturali o viti implantari.<br>
                    Denti mancanti, rotti, usurati, scheggiati e cariati in maniera irreversibile possono essere restaurati interamente o ricostruiti parzialmente tramite materiali all’avanguardia in grado di fornire un’estetica perfetta e una resistenza assai elevata.
                    Il restauro di un dente completo si chiama corona o capsula, mentre la ricostruzione parziale si chiama intarsio.<br>
                    Sono diversi i materiali attraverso cui vengono realizzate le protesi, dalla ceramica ed i suoi derivati alla zirconia, fino alla resine per la realizzazione di provvisori o intarsi.
                    Il dentista solitamente, in base alle esigenze, consiglierà il materiale più idoneo per la risoluzione del problema specifico.<br>
                    Esistono ancora le protesi mobili parziali o totali che si confezionano quando subentra una controindicazione, in genere medica, all’utilizzo di impianti dentali.
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Ortodonzia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Ortodonzia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/ortodonzia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    L’ortodonzia è la disciplina dell’odontoiatria che si occupa del trattamento delle malocclusioni ovvero alterazioni della posizione e/o dell’allineamento dei nostri denti; ciò che può impattare negativamente sull'estetica del sorriso e del volto, sulla masticazione, sulla respirazione e piuttosto spesso sulla postura del corpo nel suo insieme.<br>
                    Il trattamento ortodontico  può essere effettuato sia sui bambini che sugli adulti e si avvale di apparecchi di diversa tipologia per raggiungere i suoi obiettivi.<br>
                    Per i bambini più piccoli si parla di ortodonzia intercettiva, ovvero che intercetta la crescita delle basi ossee in modo che la successiva eruzione dei denti definitivi trovi un contenitore adatto ad un buon allineamento, così da prevenire i  problemi che una malocclusione potrebbe portare al piccolo paziente. 
                    Per i soggetti  adolescenti o adulti si può intervenire con l’ortodonzia classica dove gli spostamenti dentali sono assicurati dai fili ortodontici inseriti negli attacchi (o brackets) incollati sui denti.
                    Oggigiorno si può optare anche per l’ortodonzia trasparente,  detta anche “invisibile”.<br>
                    Si tratta di una terapia che utilizza mascherine trasparenti realizzate su misura con materiali plastici particolari, in grado di correggere l’allineamento dei denti in modo discreto e senza che nessuno possa accorgersi della loro presenza in bocca.
                    Questo tipo di dispositivi, per ovvi motivi, risultano molto apprezzati e la durata di questi trattamenti risulta pressoché sovrapponibile a quella con i trattamenti tradizionali.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Gnatologia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Gnatologia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/gnatologia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    La gnatologia è la branca dell’odontoiatria che si occupa del rapporto tra la mandibola e il cranio.<br>
                    Devi sapere che la mandibola è legata al nostro cranio attraverso  l’articolazione  temporo-mandibolare (ATM). Quando l’articolazione non risulta ben posizionata in seguito a malocclusione o ad altri problemi, talora di natura medica,  possono comparire dolori articolari o muscolari, rumori articolari in apertura o in chiusura della bocca e talora persino un blocco dell’articolazione. Tutto ciò impatta in modo rilevante sulla qualità della vita del paziente. La gnatologia  si pone l’obiettivo di curare questo tipo di problemi attraverso dispositivi intraorali o attraverso cure odontoiatriche e ortodontiche studiate ad hoc dopo attenta diagnosi clinica e strumentale.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Estetica dentale'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Estetica dentale</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/estetica-dentale.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Si parla di estetica dentale quando si effettuano delle cure dentali orientate a fini prevalentemente cosmetici.<br>
                    Il classico esempio di terapia estetica è lo <strong>sbiancamento</strong> dentale, in ogni sua forma.<br>
                    Viene applicato sulla superficie dei denti del materiale sbiancante ad alta concentrazione per terapie eseguite in studio (solitamente di breve durata) o a più bassa concentrazione per terapie domiciliari (solitamente di durata maggiore).<br>
                    Questo materiale agisce sullo smalto, sbiancando ove possibile il dente, permettendo al paziente di tornare a sfoggiare un sorriso più luminoso.<br>
                    <strong>Le faccette dentali</strong>
                    <ul>
                    <li>Qualora le terapie sbiancanti non risultassero sufficienti</li>
                    <li>Qualora si volesse ottenere un sorriso esteticamente perfetto</li>
                    <li>Qualora si volesse rivoluzionare la forma, la dimensione ed il colore dei nostri denti</li>
                    </ul>
                    in tutte queste ipotesi esiste una soluzione costituita dalle faccette.<br>
                    Le faccette sono delle lamine di ceramica sottilissime, che vengono applicate sulla superficie del dente, proprio come fa un guanto con la nostra mano.<br>
                    Conferiscono al dente  forma e colore  desiderati, dopo averli pianificati digitalmente attraverso software particolari che acquisiscono dati sulla bocca e se occorre anche del volto del paziente  
                    La loro applicazione si effettua in poche sedute e non comporta nessun tipo di evento traumatico, per cui il paziente non avvertirà il alcun modo dolore.             
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Parodontologia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Parodontologia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/parodontologia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    La parodontologia è la disciplina che si occupa di prevenire e curare le patologie dei tessuti di supporto dei nostri denti, principalmente la parodontite.<br>
                    La parodontite è una malattia batterica, è una delle malattie più diffuse al mondo (colpisce il 60% della popolazione mondiale adulta) ed è la principale causa della perdita  dei denti. E’ inoltre correlata con molte patologie sistemiche, soprattutto il diabete. Il fumo di sigaretta ne è il principale fattore favorente.<br>
                    Dobbiamo immaginare il dente come un albero e la parodontite come una malattia capace di scavare attorno a quest’albero, sottraendo la terra che lo sostiene.<br>
                    Come l’albero cadrà senza il supporto della terra, anche il nostro dente cadrà senza il supporto dei tessuti che lo sostengono (gengiva, osso e legamenti).<br>
                    La terapia per fermare questa patologia può essere sviluppata sia chirurgicamente sia attraverso speciali e particolari sedute di igiene, studiate apposta per il paziente dopo aver raccolto tutti i dati di partenza (lastre, fotografie, cartella parodontale).
                    Solo dopo aver stabilizzato la parodontite il paziente avrà la possibilità di sostituire gli elementi mancanti con degli impianti dentali. Ricordiamo però che per quanto silenziata la parodontite rimarrà sempre sottotraccia, per cui il paziente deve acquisire una tecnica di igiene domiciliare molto precisa, aiutato dall’igienista che fisserà sedute successive di igiene orale al fine di tenere sotto controllo la malattia            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Igiene'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Igiene</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/igene-dentale.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Le sedute di igiene orale vengono svolte solitamente dall’igienista, un dottore laureato in questa importantissima branca dell’odontoiatria,  che lavora a stretto contatto con il dentista.<br>
                    Lo scopo di queste sedute, che in media si svolgono con frequenza semestrale, è duplice: da una parte servono a far rimuovere attivamente placca, patine e tartaro, dall’altra servono a istruire e motivare il paziente portandolo alla miglior tecnica possibile di igiene domiciliare. Quindi viene svolto un grande lavoro di cura e di prevenzione della carie e delle malattie gengivali. Il paziente che aderisce a questo programma vedrà enormi risultati relativi alla propria salute orale, ma anche miglior salute generale, poiché il benessere della bocca influisce profondamente sul benessere di tutto il nostro corpo.<br>
                    In relazione a vari fattori individuali, l’igienista consiglierà sedute di igiene di vario tipo e frequenza.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Bambini'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Odontoiatria pediatrica (Bambini)</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/odontoiatria-pediatrica.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Portare il bambino dal dentista non è sempre facile. La paura è sempre una cattiva consigliera e si tende spesso a rimandare.<br>
                    La verità è che il bambino ha bisogno di regolari visite dal dentista per curare o ancora meglio prevenire carie, gengiviti e malocclusioni che possono influenzare negativamente la vita e la crescita del piccolo paziente.<br>
                    Devi sapere che i denti da latte, come quelli definitivi, si ammalano esattamente con la stessa frequenza, soprattuto se la dieta del bambino è orientata verso cibi zuccherati e/o a base di prodotti da forno.<br>
                    Dolci, bibite gassate o zuccherate (come i succhi) e caramelle, come tutti sanno, non sono amici dei denti; è necessario limitare al massimo l’assunzione di questi cibi e insieme effettuare un’attenta igiene della bocca a casa, necessariamente con l’aiuto dei genitori almeno fino agli 8-10 anni.<br>
                    Nello stesso tempo è fortemente consigliato portare il bambino dal dentista fin dai 3-4 anni allo scopo innanzitutto di prendere confidenza con l’ambiente e con gli strumenti che eventualmente dovranno essere utilizzati, ma anche per essere istruiti alla prevenzione.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Chirurgia orale'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Chirurgia orale</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/chirurgia-orale.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Piuttosto spesso il dentista è chiamato a curare problemi della bocca attraverso un approccio chirurgico.<br>
                    Le estrazioni, ad esempio, appartengono a questa branca, la rimozione di cisti,  di granulomi, così come le estrazioni di denti del giudizio (o ottavi). Anche l’implantologia è una branca della chirurgia orale.<br>
                    Gli interventi si eseguono in anestesia locale e, talvolta, con il supporto di tecniche sedative particolari, in base alla tipologia di trattamento.                  
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
<script>
  anychart.onDocumentReady(function () {
    // our data from bulbapedia
    var data1 = [
        {x: "FORCAZIONI", value: ${
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) == 16)
            ?
            10
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) > 0 && (this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) <= 4)
            ?
            2
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) > 4 && (this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) <= 8)
            ?
            4
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) > 8 && (this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) <= 12)
            ?
            6
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) > 12 && (this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) < 16)
            ?
            8
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) == 0)
            ?
            0
            :
            0
        }},
        {x: "TASCHE", value: ${
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) == 32)
            ?
            10
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) > 0 && (this.state.tascheSuperiore.length + this.state.tascheInferiore.length) <= 8)
            ?
            3
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) > 8 && (this.state.tascheSuperiore.length + this.state.tascheInferiore.length) <= 16)
            ?
            6
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) > 16 && (this.state.tascheSuperiore.length + this.state.tascheInferiore.length) <= 24)
            ?
            8
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) > 24 && (this.state.tascheSuperiore.length + this.state.tascheInferiore.length) < 32)
            ?
            9
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) == 0)
            ?
            0
            :
            0
        }},
        {x: "DENTI MOBILI", value: ${
            (this.state.dentiMobili)
            ?
            9
            :
            0
        }},
        {x: "FUMO", value: ${
            (this.state.fumo == null)
            ?
            0
            :
            (this.state.fumo == 'No')
            ?
            0
            :
            (this.state.fumo == 'Fino a 10')
            ?
            5
            :
            (this.state.fumo == '20')
            ?
            8
            :
            (this.state.fumo == 'Piu')
            ?
            10
            :
            0
        }},
        {x: "MALATTIE SISTEMICHE", value: ${
            (this.state.malattieSistemiche > 10)
            ?
            10
            :
            this.state.malattieSistemiche
        }},
        {x: "DENTI MANCANTI", value: ${
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) == 32)
            ?
            10
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) > 0 && (this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) <= 8)
            ?
            3
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) > 8 && (this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) <= 16)
            ?
            6
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) > 16 && (this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) <= 24)
            ?
            8
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) > 24 && (this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) < 32)
            ?
            9
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) == 0)
            ?
            0
            :
            0
        }},
        {x: "SANGUINAMENTO", value: ${
            (this.state.sanguinamentoGengive == null)
            ?
            0
            :
            (this.state.sanguinamentoGengive == '0')
            ?
            0
            :
            (this.state.sanguinamentoGengive == '1')
            ?
            4
            :
            (this.state.sanguinamentoGengive == '2')
            ?
            7
            :
            (this.state.sanguinamentoGengive == '3')
            ?
            10
            :
            0
        }},
        {x: "IGENE ORALE", value: ${
            (this.state.igeneOrale == null)
            ?
            0
            :
            (this.state.igeneOrale == 'Buona')
            ?
            0
            :
            (this.state.igeneOrale == 'Sufficente')
            ?
            5
            :
            (this.state.igeneOrale == 'Scarsa')
            ?
            10
            :
            0
        }},
    ];

    // create radar chart
    var chart = anychart.radar();
    // set chart yScale settings
    chart.yScale()
      .minimum(0)
      .maximum(10)
      .ticks({'interval':1});

    // create first series
    chart.area(data1).name('Rischio').markers(true).fill("#3782fa", 0.3).stroke("#3782fa")

    // set container id for the chart
    chart.container('container');
    // initiate chart drawing
    chart.draw();
  });
</script>
</body>
</html>
        `;
        await Print.printAsync({
          html: htmlPrint,
          //printerUrl: selectedPrinter?.url,
        });
    }

    printToFile = async (type) => {
        let image = await AsyncStorage.getItem('image')
        let nome_studio = await AsyncStorage.getItem('nome_studio')
        let telefono = await AsyncStorage.getItem('telefono')
        let country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
        let via = await AsyncStorage.getItem('via')
        let citta = await AsyncStorage.getItem('citta')
        if (nome_studio == null || via == null || citta == null) {
            this.setState({ showShare: false })
            this.props.navigation.navigate('EditInformazioni')
            return;
        }
        const htmlPrint = `
        <!DOCTYPE html>
<html>
<head>
<script src="https://cdn.anychart.com/releases/8.7.1/js/anychart-core.min.js"></script>
<script src="https://cdn.anychart.com/releases/8.7.1/js/anychart-radar.min.js"></script>
<style>
${(Platform.OS == 'android')
    &&
    '@page { margin: 20px; }'
}

main {
    break-inside: avoid;
}

@font-face {
  font-weight: 400;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2') format('woff2');
}

@font-face {
  font-weight: 500;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2') format('woff2');
}

@font-face {
  font-weight: 700;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
}

@font-face {
  font-weight: 900;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2') format('woff2');
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-family: 'Circular-Loom';
}

tr:nth-child(even) {
  background-color: #dddddd;
}

body {
	font-family: 'Circular-Loom';
}

#container {
  width: 30em;
  height: 20em;
  margin: 0;
  padding: 0;
}
  .anychart-credits {
    display: none !important;
  }
</style>
</head>
<body>
<main style="height: ${(Platform.OS == 'android') ? '285mm' : '272mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="display: flex; justify-content: center; position: absolute; top: 7em; left: 0; right: 0;">
        <img src="https://www.visitreporter.com/app/assets/image/app/visit-reporter-color.png" style="width: 8em; height: 8em;" />
    </div>
    <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;">
        <div style="padding-top: 3em; padding-bottom: 3em; padding-left: 8em; padding-right: 8em; border: 6px solid rgb(7, 149, 255); border-radius: 0.5em;">
            <div style="display: flex; justify-content: center">
                <img src="${image}" style="width: 10em; height: 10em; border-radius: 5em; background-color: rgb(7, 149, 255); object-fit: cover;" />
            </div>
            <div style="padding-top: 2em">
                <h2 style="margin-top: 0; margin-bottom: 0; text-align: center;">REPORT VISITA</h2>
            </div>
            <div style="padding-top: 2em">
                <h2 style="text-align: center;">Paziente</h2>
                <h3 style="text-align: center;">${this.state.datiPaziente.nome} ${this.state.datiPaziente.cognome}</h3>
            </div>
            <div style="padding-top: 1em; border-top: 1px solid #999999">
                <h3 style="text-align: center;margin-bottom: 0; margin-top: 0">${nome_studio}</h3>
                <p style="text-align: center;margin-top: 0.3em; margin-bottom: 0; padding-bottom: 0;">${via}, ${citta}</div>
                <p style="text-align: center;margin-top: 0.3em; margin-bottom: 0; padding-bottom: 0;">+${country_code.callingCode[0]}${telefono}</div>
            </div>
        </div>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
        <div>
            <p style="margin-bottom: 0; margin-top: 0; font-size: 19px;">
            Gentile Paziente,<br>
            Benvenuto presso ${nome_studio}.<br><br>
            Attraverso questo documento di prima visita potrà valutare insieme al dentista
            la situazione della sua bocca e comprendere quali sono le sue criticità e -
            soprattutto - le aree in cui intervenire al fine di tornare ad avere una funzione
            masticatoria ed estetica di primo livello.<br><br>
            Com’è noto, il sorriso è il nostro biglietto da visita, allieta le nostre
            giornate e quelle di chi ci sta attorno, per cui bisogna averne cura.<br><br>
            <strong>Ma cosa succede quando viene perso o compromesso?</strong><br><br>
            La perdita del sorriso causa disagi a più livelli, non solo a livello estetico,
            ma anche su quello funzionale e psicologico.<br><br>
            Se da una parte si perderà una corretta funzione masticatoria,
            influenzando anche le funzionalità fonetiche, a livello psicologico, si
            smetterà di sorridere e si tenderà ad isolarsi dagli altri, rischiando di
            compromettere le relazioni interpersonali.<br><br>
            Quello che in molti non sanno è che la medicina rende disponibili molte
            opzioni al fine di migliorare il proprio sorriso, in maniera rapida, mini
            invasiva e anche nelle situazioni particolarmente difficili.<br><br>
            Con questo Report vogliamo porre l’attenzione sulla situazione della sua
            bocca e indicarle le modalità di risoluzione dei suoi problemi in maniera
            rapida e assolutamente personalizzata.<br><br>
            ${nome_studio}.<br><br>
            ${
                (this.state.areaIntervento.length > 0)
                    ?
                    `
                    Le aree dell’odontoiatria su cui consigliamo di intervenire sono:<br><br>
                    `
                    :
                    ''
            }
            ${
                this.state.areaIntervento.map((item) => {
                    return ` ${item}`;
                })
            }
            </p>
        </div>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="background-color: rgb(7, 149, 255); padding: 1em; border-radius: 1em; display: flex; justify-content: space-between; align-items: center;">
        <img src="https://www.visitreporter.com/app/assets/image/app/visit-reporter-logo.png" style="width: 7em; height: 7em;" />
        <div style="padding-right: 1em; display: flex; justify-content: flex-end; align-items: center;">
            <div style="margin-right: 1em">
                <img src="${image}" style="width: 5em; height: 5em; object-fit: cover; border-radius: 2.5em;" />
            </div>
            <div>
                <h3 style="color: #fff; margin-bottom: 0; margin-top: 0">${nome_studio}</h3>
                <p style="color: #fff; margin-top: 0.3em">${via}, ${citta}<br>+${country_code.callingCode[0]}${telefono}</div>
            </div>
        </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 1.2em;">
        <h2 style="margin-top: 0">Report visita</h2>
    </div>
    <div style="padding-left: 4em; padding-right: 4em; margin-top: 0.5em; display: flex; justify-content: space-between">
        <div>
            <h3 style="margin-top: 0">Paziente:</h3>
            <p style="margin-top: 0em; margin-bottom: 0">${this.state.datiPaziente.nome} ${this.state.datiPaziente.cognome}</p>
            <p style="margin-top: 0.3em; text-decoration: underline">${this.state.datiPaziente.email}</p>
        </div>
        <div>
            <h3 style="margin-top: 0">Desideri del paziente:</h3>
            <p style="margin-top: 0em; margin-bottom: 0">${this.state.desideriPaziente}</p>
        </div>
    </div>
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 1.5em; padding-bottom: 2em">
        <table style="border-radius: 1em">
            <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Esito</th>
            </tr>
            <tr>
            <td>Fuma</td>
            <td>${(this.state.fumo != null) ? this.state.fumo : ''}</td>
            </tr>
            <tr>
            <td>Ipertensione</td>
            <td>${(this.state.ipertensione) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Diabete</td>
            <td>${(this.state.diabete) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Osteoporosi</td>
            <td>${(this.state.osteoporosi) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Tiroidite</td>
            <td>${(this.state.tiroidite) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Malattie cardiache</td>
            <td>${(this.state.malattieCardiache) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Allergie a farmaci o anestetici</td>
            <td>${(this.state.allergie) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>In gravidanza</td>
            <td>${(this.state.gravidanza) ? 'Si' : 'No'}</td>
            </tr>
            <tr>
            <td>Malattie virali</td>
            <td>${(this.state.malattieVirali) ? 'Si' : 'No'}</td>
            </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 2em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Esito</th>
        </tr>
        <tr>
            <td>Foto intraorale sorriso</td>
            <td>
                ${(this.state.imageSorriso != '')
                    ?
                    `<img src="${this.state.imageSorriso}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Foto occlusale arcata inferiore</td>
            <td>
                ${(this.state.imageIntraoraleInferiore != '')
                    ?
                    `<img src="${this.state.imageIntraoraleInferiore}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Foto occlusale arcata superiore</td>
            <td>
                ${(this.state.imageIntraoraleSuperiore != '')
                    ?
                    `<img src="${this.state.imageIntraoraleSuperiore}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Foto sorriso profilo destro</td>
            <td>
                ${(this.state.imageSorrisoDestro != '')
                    ?
                    `<img src="${this.state.imageSorrisoDestro}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Foto sorriso profilo sinistro</td>
            <td>
                ${(this.state.imageSorrisoSinistro != '')
                    ?
                    `<img src="${this.state.imageSorrisoSinistro}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Panoramica</td>
            <td>
                ${(this.state.imagePanoramica != '')
                    ?
                    `<img src="${this.state.imagePanoramica}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Bitewing destro</td>
            <td>
                ${(this.state.imageBitewingDestro != '')
                    ?
                    `<img src="${this.state.imageBitewingDestro}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Bitewing sinistro</td>
            <td>
                ${(this.state.imageBitewingSinistro != '')
                    ?
                    `<img src="${this.state.imageBitewingSinistro}" style="width: 10em; height: 5em; object-fit: cover; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 0em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Edentulia totale</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Dente</th>
        </tr>
        <tr>
            <td>Denti mancanti (Arcata Superiore)</td>
            <td>${(this.state.edentuliaTotSuperore) ? 'Si' : 'No'}</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti1 != '')
                    ?
                    `<img src="${this.state.denti1}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Denti mancanti (Arcata Inferiore)</td>
            <td>${(this.state.edentuliaTotInferiore) ? 'Si' : 'No'}</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti2 != '')
                    ?
                    `<img src="${this.state.denti2}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        </table>
    </div>
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 2em; padding-bottom: 2em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Dente</th>
        </tr>
        <tr>
            <td>Otturazioni (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti3 != '')
                    ?
                    `<img src="${this.state.denti3}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Otturazioni (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti4 != '')
                    ?
                    `<img src="${this.state.denti4}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Carie (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti5 != '')
                    ?
                    `<img src="${this.state.denti5}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 0em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Dente</th>
        </tr>
        <tr>
            <td>Carie (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti6 != '')
                    ?
                    `<img src="${this.state.denti6}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Tasche (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti7 != '')
                    ?
                    `<img src="${this.state.denti7}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Tasche (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti8 != '')
                    ?
                    `<img src="${this.state.denti8}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        ${/*<tr>
            <td>Forcazioni (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti9 != '')
                    ?
                    `<img src="${this.state.denti9}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Forcazioni (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti10 != '')
                    ?
                    `<img src="${this.state.denti10}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>*/''}
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '274mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 0em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Dente</th>
        </tr>
        <tr>
            <td>Restauri e Protesi (Arcata Superiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti11 != '')
                    ?
                    `<img src="${this.state.denti11}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        <tr>
            <td>Restauri e Protesi (Arcata Inferiore)</td>
            <td style="display: flex; justify-content: center; align-items: center">
                ${(this.state.denti12 != '')
                    ?
                    `<img src="${this.state.denti12}" style="width: 8em; height: 8em; object-fit: contain; border-radius: 0.5em;" />`
                    :
                    ''
                }
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '272mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 2em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Esito</th>
        </tr>
        <tr>
            <td>Igiene orale</td>
            <td>
                ${(this.state.igeneOrale != null) ? this.state.igeneOrale : ''}
            </td>
        </tr>
        <tr>
            <td>Denti mobili</td>
            <td>
                ${(this.state.dentiMobili) ? 'Si' : 'No'}
            </td>
        </tr>
        <tr>
            <td>Protesi mobile</td>
            <td>
                ${(this.state.protesiMobile) ? 'Si' : 'No'}
            </td>
        </tr>
        <tr>
            <td>Sanguinamento gengive</td>
            <td>
                ${(this.state.sanguinamentoGengive != null) ? this.state.sanguinamentoGengive : ''}
            </td>
        </tr>
        <tr>
            <td>Placca</td>
            <td>
                ${(this.state.placca != null) ? this.state.placca : ''}
            </td>
        </tr>
        <tr>
            <td>Sospetta atrofia ossea</td>
            <td>
                ${(this.state.atrofiaOssea) ? 'Si' : 'No'}
            </td>
        </tr>
        <tr>
            <td>Parodontite</td>
            <td>
                ${(this.state.paradontite != null) ? this.state.paradontite : ''}
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? 'auto' : '272mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; display: flex; justify-content: center;">
        <h3>Indice di rischio</h3>
    </div>
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 1em; padding-bottom: 2em; display: flex; justify-content: center;">
        ${(Platform.OS == 'android')
            ?
            `<img src="${this.state.imageChart}" style="width: 35em; height: 25em; object-fit: contain;" />`
            :
            `<div id="container"></div>`
        }
    </div>
    <div style="padding-left: 4em; padding-right: 4em; padding-top: 2em; padding-bottom: 1em">
        <table style="border-radius: 1em">
        <tr style="background-color: rgb(7, 149, 255)">
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Descrizione</th>
            <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Valutazione</th>
        </tr>
        <tr>
            <td>Analisi ortodonzia</td>
            <td>
                ${
                    this.state.analisiOrtodonzia.map((item) => {
                        return ` ${item}`;
                    })
                }
            </td>
        </tr>
        <tr>
            <td>Analisi gnatologica</td>
            <td>
                ${
                    this.state.analisiGnatologica.map((item) => {
                        return ` ${item}`;
                    })
                }
            </td>
        </tr>
        </table>
    </div>
</main>
<main style="height: ${(Platform.OS == 'android') ? '285mm' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
    <div style="padding-top: 4em">
        <h2 style="text-align: center;">Osservazioni</h2>
        <p style="text-align: justify; font-size: 19px;">${this.state.diarioClinico}</p>
    </div>
</main>
${
    (this.state.areaIntervento.length > 0)
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? '285mm' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;">
                <div style="padding: 6em; border: 6px solid rgb(7, 149, 255); border-radius: 0.5em; margin-left: 5em; margin-right: 5em;">
                    <div style="display: flex; justify-content: center">
                        <h2 style="margin-top: 0; margin-bottom: 0; text-align: center;">AREA IN CUI BISOGNEREBBE INTERVENIRE</h2>
                    </div>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Conservativa'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Conservativa</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/conservativa.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Conservare i denti è la cosa più importante che un dentista possa fare. Devi sapere che il dente è costituito da uno strato superficiale chiamato smalto, uno strato intermedio chiamato “dentina” e un nucleo centrale chiamato “polpa” costituita da nervi, cellule e vasi sanguigni che assicurano la vitalità del dente
                            In caso di carie dello smalto o della dentina, è necessario intervenire rapidamente  per evitare che questa intacchi la polpa, generando un forte dolore: il classico mal di denti.<br> Molto importante è che tu sappia che la carie va prevenuta poiché dipende da abitudini alimentari e di igiene orale errate che ti aiuteremo ad intercettare.<br>
                            L’otturazione è il modo in cui  si interviene per ripristinare la forma e la funzione  dell’elemento dentario. L’otturazione viene eseguita attraverso resine composite, materiali molto lucidabili e in grado quindi di mimetizzarsi del tutto al fine di assicurare un’estetica perfetta.     
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Endodonzia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Endodonzia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/endodonzia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    L’endodonzia è la disciplina che si occupa delle cosiddette “devitalizzazioni”  ovvero   le terapie canalari, che consistono nella rimozione della polpa dentaria e canalare e la sua sostituzione con materiali in grado di non far scurire il dente.<br>
                    Quando si devitalizza un dente?<br>
                    Se una carie risulta molto aggressiva fino a raggiungere la polpa dentaria è necessario eseguire la terapia canalare come sopra spiegato; l’intervento è minimo e  potrà  consentire il successivo restauro che permetterà al paziente di riottenere la funzionalità sull’elemento dentario.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Implantologia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Implantologia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/implantologia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    L’implantologia si occupa di  sostituire uno o più denti mancanti attraverso l’inserimento nell’osso di vere e proprie radici artificiali in titanio dette appunto impianti dentali. Normalmente l’aspetto esterno è quello di una vite.<br>
                    Nei casi in cui il dente da sostituire è soltanto uno, normalmente si attende da uno a sei mesi(dipende dai casi) affinché le spire della vite siano circondate da nuovo osso, dopodiché si inserisce una “capsula” in ceramica che per forma e colore simula del tutto e per tutto il dente naturale perduto.
                    Se ci sono più denti adiacenti mancanti si inseriscono più impianti che si collegano fra loro in modalità ponte fisso; ovvero si vedranno più denti in ceramica tutti uniti fra loro e anche in questo caso il ripristino della forma, della funzione e dell’estetica saranno perfette.<br>
                    <h4 style="margin-top: 0.5rem; margin-bottom: 0.5rem;">RIABILITAZIONE ARCATA COMPLETA</h4>
                    L’implantologia è in grado di ridare il sorriso anche a chi abbia perduto tutti i denti di un’arcata; in questo caso utilizzando un numero di impianti che solitamente varia da 4 a 6 si potrà ricostruire tutta l’arcata perduta. Talora si potrà effettuare il carico immediato degli impianti, ovvero posizionare al paziente la protesi il giorno stesso in cui sono stati messi gli impianti.<br>
                    L’intervento di implantologia viene solitamente eseguito in anestesia locale.<br>
                    Il periodo postoperatorio è normalmente piuttosto sereno poiché l’implantologo esperto non traumatizza eccessivamente l’osso; ghiaccio, terapie del caso e un po' di riposo permetteranno al paziente di tornare alla vita normale in pochi giorni.
                    L’igienista seguirà nel tempo la salute dei tessuti attorno agli impianti a patto che il paziente si presenti regolarmente alle sedute previste che variano da caso a caso.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Protesi'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Protesi</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/protesi.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    La protesi è la disciplina dell’odontoiatria che si occupa di sostituire i denti mancanti al fine di ripristinare estetica e funzione masticatoria.<br>
                    La protesi può essere fissa o rimovibile; i pilastri che sorreggono la protesi possono essere denti naturali o viti implantari.<br>
                    Denti mancanti, rotti, usurati, scheggiati e cariati in maniera irreversibile possono essere restaurati interamente o ricostruiti parzialmente tramite materiali all’avanguardia in grado di fornire un’estetica perfetta e una resistenza assai elevata.
                    Il restauro di un dente completo si chiama corona o capsula, mentre la ricostruzione parziale si chiama intarsio.<br>
                    Sono diversi i materiali attraverso cui vengono realizzate le protesi, dalla ceramica ed i suoi derivati alla zirconia, fino alla resine per la realizzazione di provvisori o intarsi.
                    Il dentista solitamente, in base alle esigenze, consiglierà il materiale più idoneo per la risoluzione del problema specifico.<br>
                    Esistono ancora le protesi mobili parziali o totali che si confezionano quando subentra una controindicazione, in genere medica, all’utilizzo di impianti dentali.
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Ortodonzia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Ortodonzia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/ortodonzia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    L’ortodonzia è la disciplina dell’odontoiatria che si occupa del trattamento delle malocclusioni ovvero alterazioni della posizione e/o dell’allineamento dei nostri denti; ciò che può impattare negativamente sull'estetica del sorriso e del volto, sulla masticazione, sulla respirazione e piuttosto spesso sulla postura del corpo nel suo insieme.<br>
                    Il trattamento ortodontico  può essere effettuato sia sui bambini che sugli adulti e si avvale di apparecchi di diversa tipologia per raggiungere i suoi obiettivi.<br>
                    Per i bambini più piccoli si parla di ortodonzia intercettiva, ovvero che intercetta la crescita delle basi ossee in modo che la successiva eruzione dei denti definitivi trovi un contenitore adatto ad un buon allineamento, così da prevenire i  problemi che una malocclusione potrebbe portare al piccolo paziente. 
                    Per i soggetti  adolescenti o adulti si può intervenire con l’ortodonzia classica dove gli spostamenti dentali sono assicurati dai fili ortodontici inseriti negli attacchi (o brackets) incollati sui denti.
                    Oggigiorno si può optare anche per l’ortodonzia trasparente,  detta anche “invisibile”.<br>
                    Si tratta di una terapia che utilizza mascherine trasparenti realizzate su misura con materiali plastici particolari, in grado di correggere l’allineamento dei denti in modo discreto e senza che nessuno possa accorgersi della loro presenza in bocca.
                    Questo tipo di dispositivi, per ovvi motivi, risultano molto apprezzati e la durata di questi trattamenti risulta pressoché sovrapponibile a quella con i trattamenti tradizionali.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Gnatologia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Gnatologia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/gnatologia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    La gnatologia è la branca dell’odontoiatria che si occupa del rapporto tra la mandibola e il cranio.<br>
                    Devi sapere che la mandibola è legata al nostro cranio attraverso  l’articolazione  temporo-mandibolare (ATM). Quando l’articolazione non risulta ben posizionata in seguito a malocclusione o ad altri problemi, talora di natura medica,  possono comparire dolori articolari o muscolari, rumori articolari in apertura o in chiusura della bocca e talora persino un blocco dell’articolazione. Tutto ciò impatta in modo rilevante sulla qualità della vita del paziente. La gnatologia  si pone l’obiettivo di curare questo tipo di problemi attraverso dispositivi intraorali o attraverso cure odontoiatriche e ortodontiche studiate ad hoc dopo attenta diagnosi clinica e strumentale.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Estetica dentale'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Estetica dentale</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/estetica-dentale.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Si parla di estetica dentale quando si effettuano delle cure dentali orientate a fini prevalentemente cosmetici.<br>
                    Il classico esempio di terapia estetica è lo <strong>sbiancamento</strong> dentale, in ogni sua forma.<br>
                    Viene applicato sulla superficie dei denti del materiale sbiancante ad alta concentrazione per terapie eseguite in studio (solitamente di breve durata) o a più bassa concentrazione per terapie domiciliari (solitamente di durata maggiore).<br>
                    Questo materiale agisce sullo smalto, sbiancando ove possibile il dente, permettendo al paziente di tornare a sfoggiare un sorriso più luminoso.<br>
                    <strong>Le faccette dentali</strong>
                    <ul>
                    <li>Qualora le terapie sbiancanti non risultassero sufficienti</li>
                    <li>Qualora si volesse ottenere un sorriso esteticamente perfetto</li>
                    <li>Qualora si volesse rivoluzionare la forma, la dimensione ed il colore dei nostri denti</li>
                    </ul>
                    in tutte queste ipotesi esiste una soluzione costituita dalle faccette.<br>
                    Le faccette sono delle lamine di ceramica sottilissime, che vengono applicate sulla superficie del dente, proprio come fa un guanto con la nostra mano.<br>
                    Conferiscono al dente  forma e colore  desiderati, dopo averli pianificati digitalmente attraverso software particolari che acquisiscono dati sulla bocca e se occorre anche del volto del paziente  
                    La loro applicazione si effettua in poche sedute e non comporta nessun tipo di evento traumatico, per cui il paziente non avvertirà il alcun modo dolore.             
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Parodontologia'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Parodontologia</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/parodontologia.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    La parodontologia è la disciplina che si occupa di prevenire e curare le patologie dei tessuti di supporto dei nostri denti, principalmente la parodontite.<br>
                    La parodontite è una malattia batterica, è una delle malattie più diffuse al mondo (colpisce il 60% della popolazione mondiale adulta) ed è la principale causa della perdita  dei denti. E’ inoltre correlata con molte patologie sistemiche, soprattutto il diabete. Il fumo di sigaretta ne è il principale fattore favorente.<br>
                    Dobbiamo immaginare il dente come un albero e la parodontite come una malattia capace di scavare attorno a quest’albero, sottraendo la terra che lo sostiene.<br>
                    Come l’albero cadrà senza il supporto della terra, anche il nostro dente cadrà senza il supporto dei tessuti che lo sostengono (gengiva, osso e legamenti).<br>
                    La terapia per fermare questa patologia può essere sviluppata sia chirurgicamente sia attraverso speciali e particolari sedute di igiene, studiate apposta per il paziente dopo aver raccolto tutti i dati di partenza (lastre, fotografie, cartella parodontale).
                    Solo dopo aver stabilizzato la parodontite il paziente avrà la possibilità di sostituire gli elementi mancanti con degli impianti dentali. Ricordiamo però che per quanto silenziata la parodontite rimarrà sempre sottotraccia, per cui il paziente deve acquisire una tecnica di igiene domiciliare molto precisa, aiutato dall’igienista che fisserà sedute successive di igiene orale al fine di tenere sotto controllo la malattia            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Igiene'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Igiene</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/igene-dentale.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Le sedute di igiene orale vengono svolte solitamente dall’igienista, un dottore laureato in questa importantissima branca dell’odontoiatria,  che lavora a stretto contatto con il dentista.<br>
                    Lo scopo di queste sedute, che in media si svolgono con frequenza semestrale, è duplice: da una parte servono a far rimuovere attivamente placca, patine e tartaro, dall’altra servono a istruire e motivare il paziente portandolo alla miglior tecnica possibile di igiene domiciliare. Quindi viene svolto un grande lavoro di cura e di prevenzione della carie e delle malattie gengivali. Il paziente che aderisce a questo programma vedrà enormi risultati relativi alla propria salute orale, ma anche miglior salute generale, poiché il benessere della bocca influisce profondamente sul benessere di tutto il nostro corpo.<br>
                    In relazione a vari fattori individuali, l’igienista consiglierà sedute di igiene di vario tipo e frequenza.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Bambini'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Odontoiatria pediatrica (Bambini)</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/odontoiatria-pediatrica.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Portare il bambino dal dentista non è sempre facile. La paura è sempre una cattiva consigliera e si tende spesso a rimandare.<br>
                    La verità è che il bambino ha bisogno di regolari visite dal dentista per curare o ancora meglio prevenire carie, gengiviti e malocclusioni che possono influenzare negativamente la vita e la crescita del piccolo paziente.<br>
                    Devi sapere che i denti da latte, come quelli definitivi, si ammalano esattamente con la stessa frequenza, soprattuto se la dieta del bambino è orientata verso cibi zuccherati e/o a base di prodotti da forno.<br>
                    Dolci, bibite gassate o zuccherate (come i succhi) e caramelle, come tutti sanno, non sono amici dei denti; è necessario limitare al massimo l’assunzione di questi cibi e insieme effettuare un’attenta igiene della bocca a casa, necessariamente con l’aiuto dei genitori almeno fino agli 8-10 anni.<br>
                    Nello stesso tempo è fortemente consigliato portare il bambino dal dentista fin dai 3-4 anni allo scopo innanzitutto di prendere confidenza con l’ambiente e con gli strumenti che eventualmente dovranno essere utilizzati, ma anche per essere istruiti alla prevenzione.            
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
${
    (this.state.areaIntervento.includes('Chirurgia orale'))
        ?
        `
        <main style="height: ${(Platform.OS == 'android') ? 'auto' : '273mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 4em; padding-bottom: 4em;">
                <div style="background-color: rgb(7, 149, 255); padding: 1.5em; border-radius: 0.5em; display: flex; justify-content: center; margin-bottom: 2em; align-items: center">
                    <h2 style="margin-top: 0; margin-bottom: 0; color: #fff">Chirurgia orale</h2>
                </div>
                <img src="https://localidisuccesso.net/wp-content/uploads/2022/03/chirurgia-orale.png" style="width: 100%; height: 15em; object-fit: cover; border-radius: 0.5em;" />
                <div style="margin-top: 3em">
                    <p style="margin-bottom: 0; margin-top: 0.2em; text-align: justify;">
                    Piuttosto spesso il dentista è chiamato a curare problemi della bocca attraverso un approccio chirurgico.<br>
                    Le estrazioni, ad esempio, appartengono a questa branca, la rimozione di cisti,  di granulomi, così come le estrazioni di denti del giudizio (o ottavi). Anche l’implantologia è una branca della chirurgia orale.<br>
                    Gli interventi si eseguono in anestesia locale e, talvolta, con il supporto di tecniche sedative particolari, in base alla tipologia di trattamento.                  
                    </p>
                </div>
            </div>
        </main>
        `
        :
        ''
}
<script>
  anychart.onDocumentReady(function () {
    // our data from bulbapedia
    var data1 = [
        {x: "FORCAZIONI", value: ${
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) == 16)
            ?
            10
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) > 0 && (this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) <= 4)
            ?
            2
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) > 4 && (this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) <= 8)
            ?
            4
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) > 8 && (this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) <= 12)
            ?
            6
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) > 12 && (this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) < 16)
            ?
            8
            :
            ((this.state.forcazioniSuperiore.length + this.state.forcazioniInferiore.length) == 0)
            ?
            0
            :
            0
        }},
        {x: "TASCHE", value: ${
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) == 32)
            ?
            10
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) > 0 && (this.state.tascheSuperiore.length + this.state.tascheInferiore.length) <= 8)
            ?
            3
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) > 8 && (this.state.tascheSuperiore.length + this.state.tascheInferiore.length) <= 16)
            ?
            6
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) > 16 && (this.state.tascheSuperiore.length + this.state.tascheInferiore.length) <= 24)
            ?
            8
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) > 24 && (this.state.tascheSuperiore.length + this.state.tascheInferiore.length) < 32)
            ?
            9
            :
            ((this.state.tascheSuperiore.length + this.state.tascheInferiore.length) == 0)
            ?
            0
            :
            0
        }},
        {x: "DENTI MOBILI", value: ${
            (this.state.dentiMobili)
            ?
            9
            :
            0
        }},
        {x: "FUMO", value: ${
            (this.state.fumo == null)
            ?
            0
            :
            (this.state.fumo == 'No')
            ?
            0
            :
            (this.state.fumo == 'Fino a 10')
            ?
            5
            :
            (this.state.fumo == '20')
            ?
            8
            :
            (this.state.fumo == 'Piu')
            ?
            10
            :
            0
        }},
        {x: "MALATTIE SISTEMICHE", value: ${
            (this.state.malattieSistemiche > 10)
            ?
            10
            :
            this.state.malattieSistemiche
        }},
        {x: "DENTI MANCANTI", value: ${
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) == 32)
            ?
            10
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) > 0 && (this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) <= 8)
            ?
            3
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) > 8 && (this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) <= 16)
            ?
            6
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) > 16 && (this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) <= 24)
            ?
            8
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) > 24 && (this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) < 32)
            ?
            9
            :
            ((this.state.dentiMancantiSuperiore.length + this.state.dentiMancantiInferiore.length) == 0)
            ?
            0
            :
            0
        }},
        {x: "SANGUINAMENTO", value: ${
            (this.state.sanguinamentoGengive == null)
            ?
            0
            :
            (this.state.sanguinamentoGengive == '0')
            ?
            0
            :
            (this.state.sanguinamentoGengive == '1')
            ?
            4
            :
            (this.state.sanguinamentoGengive == '2')
            ?
            7
            :
            (this.state.sanguinamentoGengive == '3')
            ?
            10
            :
            0
        }},
        {x: "IGENE ORALE", value: ${
            (this.state.igeneOrale == null)
            ?
            0
            :
            (this.state.igeneOrale == 'Buona')
            ?
            0
            :
            (this.state.igeneOrale == 'Sufficente')
            ?
            5
            :
            (this.state.igeneOrale == 'Scarsa')
            ?
            10
            :
            0
        }},
    ];

    // create radar chart
    var chart = anychart.radar();
    // set chart yScale settings
    chart.yScale()
      .minimum(0)
      .maximum(10)
      .ticks({'interval':1});

    // create first series
    chart.area(data1).name('Rischio').markers(true).fill("#3782fa", 0.3).stroke("#3782fa")

    // set container id for the chart
    chart.container('container');
    // initiate chart drawing
    chart.draw();
  });
</script>
</body>
</html>
        `;
        const { uri } = await Print.printToFileAsync({
          html: htmlPrint
        })
        if (type == 0) {
            await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
        } else {
            this.setState({ loadShare: true })
            let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
            const file = {
                uri: uri,
                type: 'application/pdf',
                name: `documento_visita_${Date.now()}.pdf`,
            };
            let formData = new FormData();
            formData.append('token', token);
            formData.append('nome', this.state.datiPaziente.nome);
            formData.append('cognome', this.state.datiPaziente.cognome);
            formData.append('email', this.state.datiPaziente.email);
            formData.append('nome_studio', nome_studio);
            formData.append('documento', file);
            fetch(`https://www.visitreporter.com/app/condividi_visita.php`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                body: formData
            })
            .then((response) => response.json())
            .then(async (json) => {
                if (json.code == true) {
                    this.setState({ loadShare: false })
                    Alert.alert(
                        "Successo",
                        json.message,
                        [
                            { text: "OK" }
                        ]
                    );
                } else {
                    this.setState({ loadShare: false })
                    Alert.alert(
                        "Attenzione",
                        json.message,
                        [
                            { text: "OK" }
                        ]
                    );
                }
            }).catch((error) => {
                this.setState({ loadShare: false })
                console.error(error);
            })
        }
    }

    deleteConfirm = () => {
        Alert.alert(
            "Attenzione!",
            "Sei sicuro di voler eliminare la visita?",
            [
                { text: "Elimina", onPress: () => this.delete() },
                { text: "Annulla", style: 'cancel' }
            ]
        )
    }

    delete = async () => {
        let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', this.state.id);
        fetch(`https://www.visitreporter.com/app/delete_visita.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            if (json.code == true) {
                Alert.alert(
                    "Successo",
                    json.message,
                    [
                        { text: "OK", onPress: () => this.props.navigation.goBack() }
                    ]
                )
            } else {
                Alert.alert(
                    "Attenzione",
                    json.message,
                    [
                        { text: "OK" }
                    ]
                );
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.goBack()}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Modifica Visita</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backButton} onPress={this.deleteConfirm}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginRight: 6 }}>Elimina</Text>
                        <AntDesign name='closecircleo' color='#fff' size={23} />
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false} ref={this.scrollVert}>
                    <ScrollView
                        ref={this.scrollRef}
                        onScroll={event => {
                            let screen = event.nativeEvent.contentOffset.x;
                            if (screen == 0) {
                                this.setState({ pageSelect: 1 })
                            } else if (screen <= Dimensions.get('screen').width) {
                                this.setState({ pageSelect: 2 })
                            } else if (screen <= Dimensions.get('screen').width * 2) {
                                this.setState({ pageSelect: 3 })
                            } else if (screen <= Dimensions.get('screen').width * 3) {
                                this.setState({ pageSelect: 4 })
                            } else if (screen <= Dimensions.get('screen').width * 4) {
                                this.setState({ pageSelect: 5 })
                            } else if (screen <= Dimensions.get('screen').width * 5) {
                                this.setState({ pageSelect: 6 })
                            } else if (screen <= Dimensions.get('screen').width * 6) {
                                this.setState({ pageSelect: 7 })
                            } else if (screen <= Dimensions.get('screen').width * 7) {
                                this.setState({ pageSelect: 8 })
                            } else if (screen <= Dimensions.get('screen').width * 8) {
                                this.setState({ pageSelect: 9 })
                            } else if (screen <= Dimensions.get('screen').width * 9) {
                                this.setState({ pageSelect: 10 })
                            } else if (screen <= Dimensions.get('screen').width * 10) {
                                this.setState({ pageSelect: 11 })
                            } else if (screen <= Dimensions.get('screen').width * 11) {
                                this.setState({ pageSelect: 12 })
                            }
                            setTimeout(() => {
                                this.scrollVert.current.scrollTo({ y: 1, animated: true })
                            }, 300)
                        }}
                        style={{ width: Dimensions.get('screen').width }}
                        showsHorizontalScrollIndicator={false}
                        horizontal={true}
                        bounces={true}
                        pagingEnabled={true}
                    >
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>INFORMAZIONI PRELIMINARI</Text>
                            <View style={{ marginBottom: 20 }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Paziente*</Text>
                                    {(this.state.loadingData)
                                        &&
                                        <ActivityIndicator size='small' color='#000' />
                                    }
                                </View>
                                <TextInput
                                    style={[styles.input, { borderBottomLeftRadius: (this.state.searchResult.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResult.length > 0) ? 0 : 10, marginBottom: (this.state.searchResult.length > 0) ? 0 : 20 }]}
                                    placeholderTextColor='#D3D6D7'
                                    placeholder='Inserisci il nome del Paziente'
                                    value={this.state.cerca}
                                    //onChangeText={(cerca) => {this.setState({ cerca }); this.filterResult(cerca)}}
                                    onChangeText={(cerca) => this.continuePatientsQueryLike(cerca)}
                                    ref={this.searchInput}
                                    //editable={(this.state.loadingData) ? false : true}
                                />
                                {(this.state.searchResult.length > 0)
                                    &&
                                    this.state.searchResult.map((item, index) => {
                                        let counter = index + 1;
                                        const country_code = JSON.parse(item.country_code)
                                        return(
                                            <TouchButton onPress={() => this.saveDatiPaziente(item)} key={item.id} style={[styles.listUser, {
                                                borderBottomLeftRadius: (counter == this.state.searchResult.length) ? 10 : 0,
                                                borderBottomRightRadius: (counter == this.state.searchResult.length) ? 10 : 0
                                            }]}>
                                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>
                                                    {item.nome.trim()} {item.cognome.trim()}{'\n'}
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                                </Text>
                                            </TouchButton>
                                        )
                                    })
                                }
                                {/*<RNPickerSelect
                                    placeholder={{ label: 'Seleziona il Paziente', value: null }}
                                    value={this.state.paziente}
                                    doneText='Fine'
                                    style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                    onValueChange={(value) => {this.setState({ paziente: value }); this.saveDatiPaziente(value)}}
                                    items={this.state.pazientiList}
                                />*/}
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Desideri del paziente</Text>
                                <RNPickerSelect
                                    placeholder={{ label: 'Seleziona il valore', value: null }}
                                    doneText='Fine'
                                    value={this.state.desideriPaziente}
                                    style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                    onValueChange={(value) => this.setState({ desideriPaziente: value })}
                                    items={[
                                        { label: 'Sorriso fisso', value: 'Sorriso fisso' },
                                        { label: 'Masticare', value: 'Masticare' },
                                        { label: 'Denti più luminosi', value: 'Denti più luminosi' },
                                        { label: 'Denti da star', value: 'Denti da star' },
                                        { label: 'Non più dolore', value: 'Non più dolore' },
                                    ]}
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Fuma</Text>
                                <RNPickerSelect
                                    placeholder={{ label: 'Seleziona il valore', value: null }}
                                    doneText='Fine'
                                    value={this.state.fumo}
                                    style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                    onValueChange={(value) => this.setState({ fumo: value })}
                                    items={[
                                        { label: 'No', value: 'No' },
                                        { label: 'Fino a 10', value: 'Fino a 10' },
                                        { label: '20', value: '20' },
                                        { label: 'Più', value: 'Piu' },
                                        { label: 'Sigaretta elettronica', value: 'Sigaretta elettronica' }
                                    ]}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Ipertensione</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.ipertensione ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ ipertensione: !this.state.ipertensione, malattieSistemiche: (!this.state.ipertensione == true) ? this.state.malattieSistemiche + 2 : this.state.malattieSistemiche - 2 })}
                                    value={this.state.ipertensione}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Diabete</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.diabete ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ diabete: !this.state.diabete, malattieSistemiche: (!this.state.diabete == true) ? this.state.malattieSistemiche + 7 : this.state.malattieSistemiche - 7 })}
                                    value={this.state.diabete}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Osteoporosi</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.osteoporosi ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ osteoporosi: !this.state.osteoporosi, malattieSistemiche: (!this.state.osteoporosi == true) ? this.state.malattieSistemiche + 7 : this.state.malattieSistemiche - 7 })}
                                    value={this.state.osteoporosi}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Tiroidite</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.tiroidite ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ tiroidite: !this.state.tiroidite, malattieSistemiche: (!this.state.tiroidite == true) ? this.state.malattieSistemiche + 2 : this.state.malattieSistemiche - 2 })}
                                    value={this.state.tiroidite}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Malattie cardiache</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.malattieCardiache ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ malattieCardiache: !this.state.malattieCardiache, malattieSistemiche: (!this.state.malattieCardiache == true) ? this.state.malattieSistemiche + 5 : this.state.malattieSistemiche - 5 })}
                                    value={this.state.malattieCardiache}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Allergie a farmaci o anestetici</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.allergie ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ allergie: !this.state.allergie, malattieSistemiche: (!this.state.allergie == true) ? this.state.malattieSistemiche + 2 : this.state.malattieSistemiche - 2 })}
                                    value={this.state.allergie}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>In gravidanza</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.gravidanza ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ gravidanza: !this.state.gravidanza, malattieSistemiche: (!this.state.gravidanza == true) ? this.state.malattieSistemiche + 5 : this.state.malattieSistemiche - 5 })}
                                    value={this.state.gravidanza}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Malattie virali</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.malattieVirali ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ malattieVirali: !this.state.malattieVirali, malattieSistemiche: (!this.state.malattieVirali == true) ? this.state.malattieSistemiche + 2 : this.state.malattieSistemiche - 2 })}
                                    value={this.state.malattieVirali}
                                />
                            </View>
                        </View>
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>ESAME EXTRA ORALE</Text>
                            <View style={{ marginBottom: 35, marginTop: 15 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 10 }}>Foto intraorale sorriso</Text>
                                <View style={styles.headImage}>
                                    <TouchableOpacity onPress={() => this.scattaImage(1)} style={[styles.button, { backgroundColor: '#0070FF', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Scatta foto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.pickImage(1)} style={[styles.button, { backgroundColor: '#fff', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>Galleria</Text>
                                    </TouchableOpacity>
                                </View>
                                {(this.state.imageSorrisoNuova != '')
                                    ?
                                    <View style={{ marginTop: 10 }}>
                                        <View style={styles.relativeButton}>
                                            <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({ imageSorrisoNuova: '' })}>
                                                <AntDesign name='closecircle' size={20} color='#fff' />
                                            </TouchableOpacity>
                                        </View>
                                        <Image source={{ uri: this.state.imageSorrisoNuova }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                    </View>
                                    :
                                    (this.state.imageSorriso != '')
                                        ?
                                        <View style={{ marginTop: 10 }}>
                                            <Image source={{ uri: this.state.imageSorriso }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                        </View>
                                        :
                                        null
                                }
                            </View>
                            <View style={{ marginBottom: 35 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 10 }}>Foto occlusale inferiore</Text>
                                <View style={styles.headImage}>
                                    <TouchableOpacity onPress={() => this.scattaImage(2)} style={[styles.button, { backgroundColor: '#0070FF', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Scatta foto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.pickImage(2)} style={[styles.button, { backgroundColor: '#fff', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>Galleria</Text>
                                    </TouchableOpacity>
                                </View>
                                {(this.state.imageIntraoraleInferioreNuova != '')
                                    ?
                                    <View style={{ marginTop: 10 }}>
                                        <View style={styles.relativeButton}>
                                            <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({ imageIntraoraleInferioreNuova: '' })}>
                                                <AntDesign name='closecircle' size={20} color='#fff' />
                                            </TouchableOpacity>
                                        </View>
                                        <Image source={{ uri: this.state.imageIntraoraleInferioreNuova }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                    </View>
                                    :
                                    (this.state.imageIntraoraleInferiore != '')
                                        ?
                                        <View style={{ marginTop: 10 }}>
                                            <Image source={{ uri: this.state.imageIntraoraleInferiore }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                        </View>
                                        :
                                        null
                                }
                            </View>
                            <View style={{ marginBottom: 35 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 10 }}>Foto occlusale superiore</Text>
                                <View style={styles.headImage}>
                                    <TouchableOpacity onPress={() => this.scattaImage(3)} style={[styles.button, { backgroundColor: '#0070FF', width: (Dimensions.get('screen').width / 2) - 40 , borderColor: '#0070FF', borderWidth: 2}]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Scatta foto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.pickImage(3)} style={[styles.button, { backgroundColor: '#fff', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>Galleria</Text>
                                    </TouchableOpacity>
                                </View>
                                {(this.state.imageIntraoraleSuperioreNuova != '')
                                    ?
                                    <View style={{ marginTop: 10 }}>
                                        <View style={styles.relativeButton}>
                                            <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({ imageIntraoraleSuperioreNuova: '' })}>
                                                <AntDesign name='closecircle' size={20} color='#fff' />
                                            </TouchableOpacity>
                                        </View>
                                        <Image source={{ uri: this.state.imageIntraoraleSuperioreNuova }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                    </View>
                                    :
                                    (this.state.imageIntraoraleSuperiore != '')
                                        ?
                                        <View style={{ marginTop: 10 }}>
                                            <Image source={{ uri: this.state.imageIntraoraleSuperiore }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                        </View>
                                        :
                                        null
                                }
                            </View>
                            <View style={{ marginBottom: 35 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 10 }}>Foto sorriso profilo destro</Text>
                                <View style={styles.headImage}>
                                    <TouchableOpacity onPress={() => this.scattaImage(4)} style={[styles.button, { backgroundColor: '#0070FF', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2}]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Scatta foto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.pickImage(4)} style={[styles.button, { backgroundColor: '#fff', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>Galleria</Text>
                                    </TouchableOpacity>
                                </View>
                                {(this.state.imageSorrisoDestroNuova != '')
                                    ?
                                    <View style={{ marginTop: 10 }}>
                                        <View style={styles.relativeButton}>
                                            <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({ imageSorrisoDestroNuova: '' })}>
                                                <AntDesign name='closecircle' size={20} color='#fff' />
                                            </TouchableOpacity>
                                        </View>
                                        <Image source={{ uri: this.state.imageSorrisoDestroNuova }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                    </View>
                                    :
                                    (this.state.imageSorrisoDestro != '')
                                        ?
                                        <View style={{ marginTop: 10 }}>
                                            <Image source={{ uri: this.state.imageSorrisoDestro }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                        </View>
                                        :
                                        null
                                }
                            </View>
                            <View style={{ marginBottom: 35 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 10 }}>Foto sorriso profilo sinistro</Text>
                                <View style={styles.headImage}>
                                    <TouchableOpacity onPress={() => this.scattaImage(5)} style={[styles.button, { backgroundColor: '#0070FF', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Scatta foto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.pickImage(5)} style={[styles.button, { backgroundColor: '#fff', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>Galleria</Text>
                                    </TouchableOpacity>
                                </View>
                                {(this.state.imageSorrisoSinistroNuova != '')
                                    ?
                                    <View style={{ marginTop: 10 }}>
                                        <View style={styles.relativeButton}>
                                            <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({ imageSorrisoSinistroNuova: '' })}>
                                                <AntDesign name='closecircle' size={20} color='#fff' />
                                            </TouchableOpacity>
                                        </View>
                                        <Image source={{ uri: this.state.imageSorrisoSinistroNuova }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                    </View>
                                    :
                                    (this.state.imageSorrisoSinistro != '')
                                        ?
                                        <View style={{ marginTop: 10 }}>
                                            <Image source={{ uri: this.state.imageSorrisoSinistro }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                        </View>
                                        :
                                        null
                                }
                            </View>
                        </View>
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>RADIOGRAFIE</Text>
                            <View style={{ marginBottom: 35, marginTop: 15 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 10 }}>Panoramica</Text>
                                <View style={styles.headImage}>
                                    <TouchableOpacity onPress={() => this.scattaImage(6)} style={[styles.button, { backgroundColor: '#0070FF', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Scatta foto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.pickImage(6)} style={[styles.button, { backgroundColor: '#fff', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>Galleria</Text>
                                    </TouchableOpacity>
                                </View>
                                {(this.state.imagePanoramicaNuova != '')
                                    ?
                                    <View style={{ marginTop: 10 }}>
                                        <View style={styles.relativeButton}>
                                            <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({ imagePanoramicaNuova: '' })}>
                                                <AntDesign name='closecircle' size={20} color='#fff' />
                                            </TouchableOpacity>
                                        </View>
                                        <Image source={{ uri: this.state.imagePanoramicaNuova }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                    </View>
                                    :
                                    (this.state.imagePanoramica != '')
                                        ?
                                        <View style={{ marginTop: 10 }}>
                                            <Image source={{ uri: this.state.imagePanoramica }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                        </View>
                                        :
                                        null
                                }
                            </View>
                            <View style={{ marginBottom: 35 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 10 }}>Bitewing destro</Text>
                                <View style={styles.headImage}>
                                    <TouchableOpacity onPress={() => this.scattaImage(7)} style={[styles.button, { backgroundColor: '#0070FF', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Scatta foto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.pickImage(7)} style={[styles.button, { backgroundColor: '#fff', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>Galleria</Text>
                                    </TouchableOpacity>
                                </View>
                                {(this.state.imageBitewingDestroNuova != '')
                                    ?
                                    <View style={{ marginTop: 10 }}>
                                        <View style={styles.relativeButton}>
                                            <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({ imageBitewingDestroNuova: '' })}>
                                                <AntDesign name='closecircle' size={20} color='#fff' />
                                            </TouchableOpacity>
                                        </View>
                                        <Image source={{ uri: this.state.imageBitewingDestroNuova }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                    </View>
                                    :
                                    (this.state.imageBitewingDestro != '')
                                        ?
                                        <View style={{ marginTop: 10 }}>
                                            <Image source={{ uri: this.state.imageBitewingDestro }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                        </View>
                                        :
                                        null
                                }
                            </View>
                            <View style={{ marginBottom: 35 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 10 }}>Bitewing sinistro</Text>
                                <View style={styles.headImage}>
                                    <TouchableOpacity onPress={() => this.scattaImage(8)} style={[styles.button, { backgroundColor: '#0070FF', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Scatta foto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.pickImage(8)} style={[styles.button, { backgroundColor: '#fff', width: (Dimensions.get('screen').width / 2) - 40, borderColor: '#0070FF', borderWidth: 2 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#0070FF' }}>Galleria</Text>
                                    </TouchableOpacity>
                                </View>
                                {(this.state.imageBitewingSinistroNuova != '')
                                    ?
                                    <View style={{ marginTop: 10 }}>
                                        <View style={styles.relativeButton}>
                                            <TouchableOpacity style={styles.closeButton} onPress={() => this.setState({ imageBitewingSinistroNuova: '' })}>
                                                <AntDesign name='closecircle' size={20} color='#fff' />
                                            </TouchableOpacity>
                                        </View>
                                        <Image source={{ uri: this.state.imageBitewingSinistroNuova }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                    </View>
                                    :
                                    (this.state.imageBitewingSinistro != '')
                                        ?
                                        <View style={{ marginTop: 10 }}>
                                            <Image source={{ uri: this.state.imageBitewingSinistro }} resizeMode='cover' style={{ width: Dimensions.get('screen').width - 60, height: Dimensions.get('screen').width / 2, borderRadius: 10 }} />
                                        </View>
                                        :
                                        null
                                }
                            </View>
                        </View>
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>STATO PREGRESSO</Text>
                            <View style={{ marginBottom: 35, marginTop: 15 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 30 }}>Denti mancanti</Text>
                                <Dentatura
                                    stateDataSuperiore={this.state.dentiMancantiSuperiore}
                                    stateDataInferiore={this.state.dentiMancantiInferiore}
                                    removeFunctionSuperiore={this.removeDentiMancantiSuperiore}
                                    removeFunctionInferiore={this.removeDentiMancantiInferiore}
                                    addFunctionSuperiore={this.addDentiMancantiSuperiore}
                                    addFunctionInferiore={this.addDentiMancantiInferiore}
                                    edentuliaTotSuperore={this.state.edentuliaTotSuperore}
                                    edentuliaTotInferiore={this.state.edentuliaTotInferiore}
                                    functionEdentuliaSuperiore={() => this.setState({ edentuliaTotSuperore: !this.state.edentuliaTotSuperore, dentiMancantiSuperiore: (this.state.edentuliaTotSuperore) ? [] : [11,12,13,14,15,16,17,18,21,22,23,24,25,26,27,28] })}
                                    functionEdentuliaInferiore={() => this.setState({ edentuliaTotInferiore: !this.state.edentuliaTotInferiore, dentiMancantiInferiore: (this.state.edentuliaTotInferiore) ? [] : [41,42,43,44,45,46,47,48,31,32,33,34,35,36,37,38] })}
                                    edentulia={true}
                                    forcazioni={false}
                                    refSuperiore={this.denti1}
                                    refInferiore={this.denti2}
                                />
                            </View>
                        </View>
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>STATO PREGRESSO</Text>
                            <View style={{ marginBottom: 35, marginTop: 15 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 30 }}>Otturazioni</Text>
                                <Dentatura
                                    stateDataSuperiore={this.state.otturazioniSuperiore}
                                    stateDataInferiore={this.state.otturazioniInferiore}
                                    removeFunctionSuperiore={this.removeotturazioniSuperiore}
                                    removeFunctionInferiore={this.removeotturazioniInferiore}
                                    addFunctionSuperiore={this.addotturazioniSuperiore}
                                    addFunctionInferiore={this.addotturazioniInferiore}
                                    edentulia={false}
                                    forcazioni={false}
                                    refSuperiore={this.denti3}
                                    refInferiore={this.denti4}
                                />
                            </View>    
                        </View>
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>STATO PREGRESSO</Text>
                            <View style={{ marginBottom: 35, marginTop: 15 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 30 }}>Carie</Text>
                                <Dentatura
                                    stateDataSuperiore={this.state.carieSuperiore}
                                    stateDataInferiore={this.state.carieInferiore}
                                    removeFunctionSuperiore={this.removecarieSuperiore}
                                    removeFunctionInferiore={this.removecarieInferiore}
                                    addFunctionSuperiore={this.addcarieSuperiore}
                                    addFunctionInferiore={this.addcarieInferiore}
                                    edentulia={false}
                                    forcazioni={false}
                                    refSuperiore={this.denti5}
                                    refInferiore={this.denti6}
                                />
                            </View>    
                        </View>
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>STATO PREGRESSO</Text>
                            <View style={{ marginBottom: 35, marginTop: 15 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 30 }}>Tasche</Text>
                                <Dentatura
                                    stateDataSuperiore={this.state.tascheSuperiore}
                                    stateDataInferiore={this.state.tascheInferiore}
                                    removeFunctionSuperiore={this.removetascheSuperiore}
                                    removeFunctionInferiore={this.removetascheInferiore}
                                    addFunctionSuperiore={this.addtascheSuperiore}
                                    addFunctionInferiore={this.addtascheInferiore}
                                    edentulia={false}
                                    forcazioni={false}
                                    refSuperiore={this.denti7}
                                    refInferiore={this.denti8}
                                />
                            </View>    
                        </View>
                        {/*<View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>STATO PREGRESSO</Text>
                            <View style={{ marginBottom: 35, marginTop: 15 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 30 }}>Forcazioni</Text>
                                <Dentatura
                                    stateDataSuperiore={this.state.forcazioniSuperiore}
                                    stateDataInferiore={this.state.forcazioniInferiore}
                                    removeFunctionSuperiore={this.removeforcazioniSuperiore}
                                    removeFunctionInferiore={this.removeforcazioniInferiore}
                                    addFunctionSuperiore={this.addforcazioniSuperiore}
                                    addFunctionInferiore={this.addforcazioniInferiore}
                                    edentulia={false}
                                    forcazioni={true}
                                    refSuperiore={this.denti9}
                                    refInferiore={this.denti10}
                                />
                            </View>    
                        </View>*/}
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>STATO PREGRESSO</Text>
                            <View style={{ marginBottom: 35, marginTop: 15 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 17, marginBottom: 30 }}>Restauri e Protesi</Text>
                                <Dentatura
                                    stateDataSuperiore={this.state.restauriSuperiore}
                                    stateDataInferiore={this.state.restauriInferiore}
                                    removeFunctionSuperiore={this.removerestauriSuperiore}
                                    removeFunctionInferiore={this.removerestauriInferiore}
                                    addFunctionSuperiore={this.addrestauriSuperiore}
                                    addFunctionInferiore={this.addrestauriInferiore}
                                    edentulia={false}
                                    forcazioni={false}
                                    refSuperiore={this.denti11}
                                    refInferiore={this.denti12}
                                />
                            </View>    
                        </View>
                        <View style={[styles.containerCenter, { marginBottom: 30 }]}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>ANALISI PARODONTALE</Text>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Igene orale</Text>
                                <RNPickerSelect
                                    placeholder={{ label: "Seleziona livello d'igene", value: null }}
                                    doneText='Fine'
                                    value={this.state.igeneOrale}
                                    style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                    onValueChange={(value) => this.setState({ igeneOrale: value })}
                                    items={[
                                        { label: 'Buona', value: 'Buona' },
                                        { label: 'Sufficente', value: 'Sufficente' },
                                        { label: 'Scarsa', value: 'Scarsa' }
                                    ]}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Denti mobili</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.dentiMobili ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ dentiMobili: !this.state.dentiMobili })}
                                    value={this.state.dentiMobili}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Protesi mobile</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.protesiMobile ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ protesiMobile: !this.state.protesiMobile })}
                                    value={this.state.protesiMobile}
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Sanguinamento gengive</Text>
                                <RNPickerSelect
                                    placeholder={{ label: 'Seleziona il valore', value: null }}
                                    doneText='Fine'
                                    value={this.state.sanguinamentoGengive}
                                    style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                    onValueChange={(value) => this.setState({ sanguinamentoGengive: value })}
                                    items={[
                                        { label: '0', value: '0' },
                                        { label: '1', value: '1' },
                                        { label: '2', value: '2' },
                                        { label: '3', value: '3' },
                                    ]}
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Placca</Text>
                                <RNPickerSelect
                                    placeholder={{ label: 'Seleziona il valore', value: null }}
                                    doneText='Fine'
                                    value={this.state.placca}
                                    style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                    onValueChange={(value) => this.setState({ placca: value })}
                                    items={[
                                        { label: '0', value: '0' },
                                        { label: '1', value: '1' },
                                        { label: '2', value: '2' },
                                        { label: '3', value: '3' },
                                    ]}
                                />
                            </View>
                            <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Sospetta atrofia ossea</Text>
                                <Switch
                                    trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                    thumbColor={this.state.atrofiaOssea ? '#fff' : '#fff'}
                                    ios_backgroundColor="#f5f5f5"
                                    onValueChange={() => this.setState({ atrofiaOssea: !this.state.atrofiaOssea })}
                                    value={this.state.atrofiaOssea}
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Parodontite</Text>
                                <RNPickerSelect
                                    placeholder={{ label: 'Seleziona il valore', value: null }}
                                    doneText='Fine'
                                    value={this.state.paradontite}
                                    style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                    onValueChange={(value) => this.setState({ paradontite: value })}
                                    items={[
                                        { label: 'No', value: 'No' },
                                        { label: 'Lieve', value: 'Lieve' },
                                        { label: 'Cronica', value: 'Cronica' },
                                        { label: 'Aggressiva', value: 'Aggressiva' },
                                    ]}
                                />
                            </View>
                            {(Platform.OS == 'android')
                                ?
                                <TouchableOpacity style={[styles.button, { backgroundColor: '#0070FF' }]} onPress={() => this.setState({ showChart: true, showChart2: false, isLoaded: false })}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Visualizza Grafico</Text>
                                </TouchableOpacity>
                                :
                                <ViewShot ref={this.webViewRef} options={{ format: 'jpg', quality: 1.0 }} style={{ width: dimensionChart, height: dimensionChart, marginBottom: Dimensions.get('screen').height / 5 }}>
                                    <Chart
                                        width={dimensionChart}
                                        height={dimensionChart}
                                        forcazioniSuperiore={this.state.forcazioniSuperiore}
                                        forcazioniInferiore={this.state.forcazioniInferiore}
                                        tascheSuperiore={this.state.tascheSuperiore}
                                        tascheInferiore={this.state.tascheInferiore}
                                        dentiMobili={this.state.dentiMobili}
                                        fumo={this.state.fumo}
                                        malattieSistemiche={this.state.malattieSistemiche}
                                        dentiMancantiSuperiore={this.state.dentiMancantiSuperiore}
                                        dentiMancantiInferiore={this.state.dentiMancantiInferiore}
                                        sanguinamentoGengive={this.state.sanguinamentoGengive}
                                        igeneOrale={this.state.igeneOrale}
                                        onLoadFunction={() => setTimeout(() => { this.screen() }, 400)}
                                    />
                                </ViewShot>
                            }
                        </View>
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>ANALISI ORTODONZIA</Text>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 20 }}>
                                {(this.state.analisiOrtodonzia.includes('Terza classe'))
                                    ?
                                    <View style={[styles.button, { borderWidth: 1.5, borderColor: '#9c9c9c', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#9c9c9c' }}>Seconda classe</Text>
                                    </View>
                                    :
                                    (this.state.analisiOrtodonzia.includes('Seconda classe'))
                                        ?
                                        <TouchableOpacity onPress={() => this.removeAnalisiOrtodonzia('Seconda classe')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Seconda classe</Text>
                                        </TouchableOpacity>
                                        :
                                        <TouchableOpacity onPress={() => this.addAnalisiOrtodonzia('Seconda classe')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Seconda classe</Text>
                                        </TouchableOpacity>
                                }
                                {(this.state.analisiOrtodonzia.includes('Seconda classe'))
                                    ?
                                    <View style={[styles.button, { borderWidth: 1.5, borderColor: '#9c9c9c', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#9c9c9c' }}>Terza classe</Text>
                                    </View>
                                    :
                                    (this.state.analisiOrtodonzia.includes('Terza classe'))
                                        ?
                                        <TouchableOpacity onPress={() => this.removeAnalisiOrtodonzia('Terza classe')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Terza classe</Text>
                                        </TouchableOpacity>
                                        :
                                        <TouchableOpacity onPress={() => this.addAnalisiOrtodonzia('Terza classe')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Terza classe</Text>
                                        </TouchableOpacity>
                                }
                                {(this.state.analisiOrtodonzia.includes('Affollamento'))
                                    ?
                                    <TouchableOpacity onPress={() => this.removeAnalisiOrtodonzia('Affollamento')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Affollamento</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity onPress={() => this.addAnalisiOrtodonzia('Affollamento')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Affollamento</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.analisiOrtodonzia.includes('Deglutazione atipica'))
                                    ?
                                    <TouchableOpacity onPress={() => this.removeAnalisiOrtodonzia('Deglutazione atipica')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Deglutazione atipica</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity onPress={() => this.addAnalisiOrtodonzia('Deglutazione atipica')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Deglutazione atipica</Text>
                                    </TouchableOpacity>
                                }
                            </View>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>ANALISI GNATOLOGICA</Text>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 20 }}>
                                {(this.state.analisiGnatologica.includes('Affaticamento/Dolori atm'))
                                    ?
                                    <TouchableOpacity onPress={() => this.removeAnalisiGnatologica('Affaticamento/Dolori atm')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Affaticamento/Dolori atm</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity onPress={() => this.addAnalisiGnatologica('Affaticamento/Dolori atm')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Affaticamento/Dolori atm</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.analisiGnatologica.includes('Click mandibolari'))
                                    ?
                                    <TouchableOpacity onPress={() => this.removeAnalisiGnatologica('Click mandibolari')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Click mandibolari</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity onPress={() => this.addAnalisiGnatologica('Click mandibolari')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Click mandibolari</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.analisiGnatologica.includes('Dolori cervicali'))
                                    ?
                                    <TouchableOpacity onPress={() => this.removeAnalisiGnatologica('Dolori cervicali')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Dolori cervicali</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity onPress={() => this.addAnalisiGnatologica('Dolori cervicali')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Dolori cervicali</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.analisiGnatologica.includes('Acufeni'))
                                    ?
                                    <TouchableOpacity onPress={() => this.removeAnalisiGnatologica('Acufeni')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Acufeni</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity onPress={() => this.addAnalisiGnatologica('Acufeni')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginRight: 10, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Acufeni</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.analisiGnatologica.includes('Bruxismo'))
                                    ?
                                    <TouchableOpacity onPress={() => this.removeAnalisiGnatologica('Bruxismo')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginBottom: 10, backgroundColor: '#0070FF' }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Bruxismo</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity onPress={() => this.addAnalisiGnatologica('Bruxismo')} style={[styles.button, { borderWidth: 1.5, borderColor: '#0070FF', width: 'auto', paddingHorizontal: 9, paddingVertical: 7, marginBottom: 10 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>Bruxismo</Text>
                                    </TouchableOpacity>
                                }
                            </View>
                        </View>
                        <View style={styles.containerCenter}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, marginBottom: 20, textAlign: 'center' }}>AREA IN CUI BISOGNEREBBE INTERVENIRE*</Text>
                            <View style={{ alignItems: 'center', marginBottom: 15, justifyContent: 'space-between', flexDirection: 'row' }}>
                                {(this.state.areaIntervento.includes('Conservativa'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Conservativa')}>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Conservativa</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Conservativa')}>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Conservativa</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.areaIntervento.includes('Endodonzia'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Endodonzia')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Endodonzia</Text>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Endodonzia')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Endodonzia</Text>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                    </TouchableOpacity>
                                }
                            </View>
                            <View style={{ alignItems: 'center', marginBottom: 15, justifyContent: 'space-between', flexDirection: 'row' }}>
                                {(this.state.areaIntervento.includes('Implantologia'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Implantologia')}>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Implantologia</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Implantologia')}>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Implantologia</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.areaIntervento.includes('Protesi'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Protesi')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Protesi</Text>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Protesi')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Protesi</Text>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                    </TouchableOpacity>
                                }
                            </View>
                            <View style={{ alignItems: 'center', marginBottom: 15, justifyContent: 'space-between', flexDirection: 'row' }}>
                                {(this.state.areaIntervento.includes('Ortodonzia'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Ortodonzia')}>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Ortodonzia</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Ortodonzia')}>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Ortodonzia</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.areaIntervento.includes('Gnatologia'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Gnatologia')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Gnatologia</Text>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Gnatologia')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Gnatologia</Text>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                    </TouchableOpacity>
                                }
                            </View>
                            <View style={{ alignItems: 'center', marginBottom: 15, justifyContent: 'space-between', flexDirection: 'row' }}>
                                {(this.state.areaIntervento.includes('Estetica dentale'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Estetica dentale')}>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Estetica dentale</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Estetica dentale')}>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Estetica dentale</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.areaIntervento.includes('Parodontologia'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Parodontologia')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Parodontologia</Text>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Parodontologia')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Parodontologia</Text>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                    </TouchableOpacity>
                                }
                            </View>
                            <View style={{ alignItems: 'center', marginBottom: 15, justifyContent: 'space-between', flexDirection: 'row' }}>
                                {(this.state.areaIntervento.includes('Igiene'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Igiene')}>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Igiene</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Igiene')}>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Igiene</Text>
                                    </TouchableOpacity>
                                }
                                {(this.state.areaIntervento.includes('Bambini'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Bambini')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Bambini</Text>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-end', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Bambini')}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginRight: 10 }}>Bambini</Text>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                    </TouchableOpacity>
                                }
                            </View>
                            <View style={{ alignItems: 'center', marginBottom: 25, justifyContent: 'space-between', flexDirection: 'row' }}>
                                {(this.state.areaIntervento.includes('Chirurgia orale'))
                                    ?
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.removeAreaIntervento('Chirurgia orale')}>
                                        <AntDesign name={'checkcircle'} size={25} color={'#ffc354'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Chirurgia orale</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={[styles.headImage, { justifyContent: 'flex-start', width: (Dimensions.get('screen').width / 2) - 35 }]} onPress={() => this.addAreaIntervento('Chirurgia orale')}>
                                        <AntDesign name={'minuscircleo'} size={25} color={'#000'} />
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13, marginLeft: 10 }}>Chirurgia orale</Text>
                                    </TouchableOpacity>
                                }
                            </View>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Osservazioni</Text>
                            <TextInput
                                style={[styles.input, { height: 100 }]}
                                placeholderTextColor='#bfbfbf'
                                placeholder='Osservazioni paziente'
                                maxLength={500}
                                multiline={true}
                                value={this.state.diarioClinico}
                                onChangeText={(diario) => this.setState({ diarioClinico: diario })}
                                numberOfLines={4}>
                            </TextInput>
                        </View>
                        {(this.state.isStatisticActive)
                            &&
                            <View style={styles.containerCenter}>
                                <View style={{ marginBottom: 20 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Stile di vita riscontrato</Text>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <TouchableOpacity onPress={() => this.setState({ stileVita: 1 })} style={{ marginRight: 20 }}>
                                            {(this.state.stileVita > 0)
                                                ?
                                                <FontAwesome name='star' color='#e3e31e' size={34} />
                                                :
                                                <FontAwesome name='star-o' color='#e3e31e' size={34} />
                                            }
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => this.setState({ stileVita: 2 })} style={{ marginRight: 20 }}>
                                            {(this.state.stileVita > 1)
                                                ?
                                                <FontAwesome name='star' color='#e3e31e' size={34} />
                                                :
                                                <FontAwesome name='star-o' color='#e3e31e' size={34} />
                                            }
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => this.setState({ stileVita: 3 })}>
                                            {(this.state.stileVita == 3)
                                                ?
                                                <FontAwesome name='star' color='#e3e31e' size={34} />
                                                :
                                                <FontAwesome name='star-o' color='#e3e31e' size={34} />
                                            }
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Dalla visita è scaturito{'\n'}un preventivo?</Text>
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.isPreventivo ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ isPreventivo: !this.state.isPreventivo })}
                                        value={this.state.isPreventivo}
                                    />
                                </View>
                                <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Proposte di altri dentisti</Text>
                                    <Switch
                                        trackColor={{ false: "#f5f5f5", true: "#0070FF" }}
                                        thumbColor={this.state.altreProposte ? '#fff' : '#fff'}
                                        ios_backgroundColor="#f5f5f5"
                                        onValueChange={() => this.setState({ altreProposte: !this.state.altreProposte })}
                                        value={this.state.altreProposte}
                                    />
                                </View>
                                <View style={{ marginBottom: 20 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Quando pensa di risolvere i problemi riscontrati?</Text>
                                    <RNPickerSelect
                                        placeholder={{ label: 'Seleziona il valore', value: null }}
                                        doneText='Fine'
                                        value={this.state.risoluzioneProblemi}
                                        style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                        onValueChange={(value) => this.setState({ risoluzioneProblemi: value })}
                                        items={[
                                            { label: 'Subito', value: 'Subito' },
                                            { label: 'Appena possibile', value: 'Appena possibile' },
                                            { label: 'Deve pensarci', value: 'Deve pensarci' },
                                        ]}
                                    />
                                </View>
                                <View style={{ marginBottom: 20 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Esito</Text>
                                    <RNPickerSelect
                                        placeholder={{ label: "Seleziona il valore", value: null }}
                                        doneText='Fine'
                                        value={this.state.esito}
                                        style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                        onValueChange={(value) => this.setState({ esito: value })}
                                        items={[
                                            { label: 'Accettato', value: 'Accettato' },
                                            { label: 'Rifiutato', value: 'Rifiutato' },
                                            { label: 'In attesa', value: 'In attesa' }
                                        ]}
                                    />
                                </View>
                                {(this.state.esito == 'Accettato')
                                    &&
                                    <View style={{ marginBottom: 20 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Valore</Text>
                                        <RNPickerSelect
                                            placeholder={{ label: "Seleziona il valore", value: null }}
                                            doneText='Fine'
                                            value={this.state.valore}
                                            style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                            onValueChange={(value) => this.setState({ valore: value })}
                                            items={[
                                                { label: 'Fino a 1k', value: 'Fino a 1k' },
                                                { label: 'Da 1 a 3k', value: 'Da 1 a 3k' },
                                                { label: 'Da 3 a 5k', value: 'Da 3 a 5k' },
                                                { label: 'Oltre i 5k', value: 'Oltre i 5k' }
                                            ]}
                                        />
                                    </View>
                                }
                            </View>
                        }
                    </ScrollView>
                </ScrollView>
                <View style={styles.headerBottom}>
                    {(this.state.isStatisticActive)
                        ?
                        (this.state.pageSelect == 12)
                            ?
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width / 2) - 22.5, backgroundColor: '#2484FF' }]} onPress={() => {this.getScreenDenti(); this.setState({ showShare: true })}}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Condividi</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width / 2) - 22.5 }]} onPress={(Platform.OS == 'android') ? () => this.setState({ showChart: true, showChart2: true }) : this.saveVisita}>
                                    {(this.state.loading)
                                        ?
                                        <ActivityIndicator color='#000' size='small' />
                                        :
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18 }}>Modifica</Text>
                                    }
                                </TouchableOpacity>
                            </View>
                            :
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width / 2) - 22.5, backgroundColor: '#2484FF' }]} onPress={() => {this.getScreenDenti(); this.setState({ showShare: true })}}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Condividi</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width / 2) - 22.5 }]} onPress={this.navForward}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18 }}>Continua</Text>
                                </TouchableOpacity>
                            </View>
                        :
                        (this.state.pageSelect == 11)
                            ?
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width / 2) - 22.5, backgroundColor: '#2484FF' }]} onPress={() => {this.getScreenDenti(); this.setState({ showShare: true })}}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Condividi</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width / 2) - 22.5 }]} onPress={(Platform.OS == 'android') ? () => this.setState({ showChart: true, showChart2: true }) : this.saveVisita}>
                                    {(this.state.loading)
                                        ?
                                        <ActivityIndicator color='#000' size='small' />
                                        :
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 18 }}>Modifica</Text>
                                    }
                                </TouchableOpacity>
                            </View>
                            :
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width / 2) - 22.5, backgroundColor: '#2484FF' }]} onPress={() => {this.getScreenDenti(); this.setState({ showShare: true })}}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Condividi</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.button, { width: (Dimensions.get('screen').width / 2) - 22.5 }]} onPress={this.navForward}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18 }}>Continua</Text>
                                </TouchableOpacity>
                            </View>
                    }
                </View>
                <Modal
                    animationType='slide'
                    presentationStyle='pageSheet'
                    visible={this.state.showShare}
                    onRequestClose={() => {
                        this.setState({ showShare: false })
                    }}
                >
                    <View style={{ marginTop: 40, paddingHorizontal: 30 }}>
                        <View style={{ justifyContent: 'flex-end', flexDirection: 'row', marginBottom: 50 }}>
                            {(Platform.OS == 'android')
                                ?
                                <TouchButton style={styles.backButtonModal} onPress={() => this.setState({ showShare: false })}>
                                    <AntDesign name='closecircleo' color='#000' size={23} />
                                </TouchButton>
                                :
                                <TouchableOpacity style={styles.backButtonModal} onPress={() => this.setState({ showShare: false })}>
                                    <AntDesign name='closecircleo' color='#000' size={23} />
                                </TouchableOpacity>
                            }
                        </View>
                        {(Platform.OS == 'android')
                            ?
                            <TouchButton style={[styles.button, { backgroundColor: '#f5f5f5', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]} onPress={() => this.printToFile(1)}>
                                {(this.state.loadShare)
                                    ?
                                    <ActivityIndicator color='#000' size='small' />
                                    :
                                    <AntDesign name='mail' color='#000' size={25} />
                                }
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#000', marginLeft: 10 }}>Condividi via Email</Text>
                            </TouchButton>
                            :
                            <TouchableOpacity style={[styles.button, { backgroundColor: '#f5f5f5', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]} onPress={() => this.printToFile(1)}>
                                {(this.state.loadShare)
                                    ?
                                    <ActivityIndicator color='#000' size='small' />
                                    :
                                    <AntDesign name='mail' color='#000' size={25} />
                                }
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#000', marginLeft: 10 }}>Condividi via Email</Text>
                            </TouchableOpacity>
                        }
                        {(Platform.OS == 'android')
                            ?
                            <TouchButton style={[styles.button, { backgroundColor: '#f5f5f5', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]} onPress={this.print}>
                                <AntDesign name='printer' color='#000' size={25} />
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#000', marginLeft: 10 }}>Stampa</Text>
                            </TouchButton>
                            :
                            <TouchableOpacity style={[styles.button, { backgroundColor: '#f5f5f5', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]} onPress={this.print}>
                                <AntDesign name='printer' color='#000' size={25} />
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#000', marginLeft: 10 }}>Stampa</Text>
                            </TouchableOpacity>
                        }
                        {(Platform.OS == 'android')
                            ?
                            <TouchButton style={[styles.button, { backgroundColor: '#f5f5f5', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]} onPress={() => this.printToFile(0)}>
                                <AntDesign name='sharealt' color='#000' size={25} />
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#000', marginLeft: 10 }}>Condividi</Text>
                            </TouchButton>
                            :
                            <TouchableOpacity style={[styles.button, { backgroundColor: '#f5f5f5', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]} onPress={() => this.printToFile(0)}>
                                <AntDesign name='sharealt' color='#000' size={25} />
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#000', marginLeft: 10 }}>Condividi</Text>
                            </TouchableOpacity>
                        }
                    </View>
                </Modal>
                <Modal
                    animationType='slide'
                    presentationStyle='pageSheet'
                    visible={this.state.showChart}
                    onRequestClose={() => {
                        this.setState({ showChart: false, showChart2: false, isLoaded: false })
                    }}
                >
                    <View style={{ marginTop: 40, paddingHorizontal: 30 }}>
                        <View style={{ justifyContent: 'flex-end', flexDirection: 'row', marginBottom: 50 }}>
                            {(Platform.OS == 'android')
                                ?
                                <TouchButton style={styles.backButtonModal} onPress={() => this.setState({ showChart: false, showChart2: false, isLoaded: false })}>
                                    <AntDesign name='closecircleo' color='#000' size={23} />
                                </TouchButton>
                                :
                                <TouchableOpacity style={styles.backButtonModal} onPress={() => this.setState({ showChart: false, showChart2: false, isLoaded: false })}>
                                    <AntDesign name='closecircleo' color='#000' size={23} />
                                </TouchableOpacity>
                            }
                        </View>
                        <ViewShot ref={this.webViewRef2} options={{ format: 'jpg', quality: 1.0 }} style={{ width: dimensionChart, height: dimensionChart }}>
                            <Chart
                                width={dimensionChart}
                                height={dimensionChart}
                                forcazioniSuperiore={this.state.forcazioniSuperiore}
                                forcazioniInferiore={this.state.forcazioniInferiore}
                                tascheSuperiore={this.state.tascheSuperiore}
                                tascheInferiore={this.state.tascheInferiore}
                                dentiMobili={this.state.dentiMobili}
                                fumo={this.state.fumo}
                                malattieSistemiche={this.state.malattieSistemiche}
                                dentiMancantiSuperiore={this.state.dentiMancantiSuperiore}
                                dentiMancantiInferiore={this.state.dentiMancantiInferiore}
                                sanguinamentoGengive={this.state.sanguinamentoGengive}
                                igeneOrale={this.state.igeneOrale}
                                onLoadFunction={() => setTimeout(() => {
                                    this.screen2()
                                }, 400)}
                            />
                        </ViewShot>
                        {(this.state.showChart2 == true && this.state.isLoaded == true)
                            ?
                            <TouchButton style={[styles.button, { backgroundColor: '#0070FF' }]} onPress={() => {this.setState({ showChart: false, showChart2: false, isLoaded: false }); this.saveVisita()}}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva e Continua</Text>
                            </TouchButton>
                            :
                            null
                        }
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        paddingHorizontal: 30,
        marginTop: 20,
        width: Dimensions.get('screen').width,
        marginBottom: Dimensions.get('screen').height / 5
    },
    button: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    headerBottom: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        paddingHorizontal: 20,
        paddingTop: 15,
        paddingBottom: Constants.statusBarHeight,
        backgroundColor: '#0070FF'
    },
    headImage: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    relativeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 2
    },
    closeButton: {
        backgroundColor: '#b08d00',
        width: 35,
        height: 35,
        borderRadius: 17.5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    counterTooth: {
        width: 30,
        height: 30,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0070FF'
    },
    counterPosition: {
        position: 'absolute',
        zIndex: 2
    },
    modal: {
        paddingHorizontal: 30,
        paddingBottom: 23,
        paddingTop: 6,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15
    },
    modal4: {
        height: 350
    },
    backButtonModal: {
        backgroundColor: '#f5f5f5',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
});