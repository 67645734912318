import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, Image } from 'react-native';
import { ActivityIndicator, Modal as ModalPaper } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WebView } from 'react-native-webview';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class AutomazioniListScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            listAutomazioni: [],
            item: JSON.parse(this.props.route.params.item),
            showModal: false
        }
    }

    componentDidMount() {
        this.getAccettoCondizioni()
        this.getAutomazioni()
    }

    getAccettoCondizioni = async () => {
        const condizioni_fornitura = await AsyncStorage.getItem('condizioni_fornitura')
        if (condizioni_fornitura === "true") {
            this.setState({ showModal: false })
        } else {
            this.setState({ showModal: true })
        }
    }

    getAutomazioni = async () => {
        this.setState({ loading: true })
        const idStudio = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', idStudio);
        formData.append('id_category', this.state.item.id)
        axios.post('https://www.visitreporter.com/app/get_automazioni_category.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json !== null) {
                this.setState({ listAutomazioni: json, loading: false })
            } else {
                this.setState({ listAutomazioni: [], loading: false })
            }
        }).catch((error) => {
            this.setState({ loading: false })
            console.error(error);
        })
    }

    handleAccettaCondizioni = async () => {
        this.setState({ showModal: false })
        await AsyncStorage.setItem('condizioni_fornitura', 'true')
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Automazioni</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 25, paddingBottom: Dimensions.get('screen').height / 4 }}>
                        <View style={{ alignItems: 'center', marginBottom: 15 }}>
                            <View style={styles.containerLogo}>
                                <Image source={require('../assets/dental-automation.png')} resizeMode='contain' style={{ width: 60, height: 60 }} />
                            </View>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 19, color: '#FFF' }}>{this.state.item.nome_categoria}</Text>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, marginTop: 3, color: '#FFF' }}>Automazioni</Text>
                        </View>
                        {(this.state.loading)
                            ?
                            <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'center' }]}>
                                <ActivityIndicator size='small' color='#0070FF' />
                            </View>
                            :
                            (this.state.listAutomazioni.length > 0)
                                ?
                                <View style={{
                                    flexDirection: (Dimensions.get('screen').width <= 500) ? 'column' : 'row',
                                    alignItems: 'center',
                                    justifyContent: (Dimensions.get('screen').width <= 500) ? 'flex-start' : 'space-between',
                                    flexWrap: (Dimensions.get('screen').width <= 500) ? 'nowrap' : 'wrap'
                                }}>
                                    {this.state.listAutomazioni.map((item, index) => {
                                        return(
                                            <TouchableOpacity onPress={() => this.props.navigation.navigate('Automazioni', { idAutomazione: item.id, nomeAutomazione: item.nome, descrizioneAutomazione: item.descrizione, urlAutomazione: item.url, haveDate: item.have_date_book })} key={index} style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <Feather name='activity' color='#0070FF' size={30} />
                                                    <View style={{ marginLeft: 15, width: (Dimensions.get('screen').width <= 500) ? '77%' : '90%' }}>
                                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{item.nome}</Text>
                                                        {(item.descrizione != '' && item.descrizione != null)
                                                            &&
                                                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>{item.descrizione}</Text>
                                                        }
                                                    </View>
                                                </View>
                                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                                            </TouchableOpacity>
                                        )
                                    })}
                                </View>
                                :
                                <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20 }]}>
                                    <Feather name='alert-triangle' color='#b08d00' size={30} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Non sono presenti automazioni</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Riprova più tardi</Text>
                                    </View>
                                </View>
                        }
                    </View>
                </ScrollView>
                <View style={styles.positionCricle}>
                    <View style={styles.circle}></View>
                </View>
                <ModalPaper visible={this.state.showModal} dismissable={false} contentContainerStyle={{ 
                    marginHorizontal: 20,
                    backgroundColor: '#fff',
                    borderRadius: 8,
                    height: Dimensions.get('screen').height / 1.5,
                    width: (Dimensions.get('screen').width <= 500) ? 'auto' : Dimensions.get('screen').width / 1.5,
                    marginLeft: (Dimensions.get('screen').width <= 500) ? 20 : (Dimensions.get('screen').width / 2) - ((Dimensions.get('screen').width / 1.5) / 2)
                }}>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 20 }}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 17, textAlign: 'center' }}>Accetta condizioni di fornitura</Text>
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <iframe
                            src={'https://visitreporter.com/app/accordo-licenza.php'}
                            style={{
                                width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 80 : Dimensions.get('screen').width / 1.6,
                                height: Dimensions.get('screen').height / 2,
                                borderWidth: 0
                            }}
                            title="Accordo Licenza"
                        />
                    </View>
                    <View style={{ paddingHorizontal: 15, paddingVertical: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <TouchableOpacity style={styles.buttonTermini} onPress={this.handleAccettaCondizioni}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Accetta</Text>
                        </TouchableOpacity>
                    </View>
                </ModalPaper>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF',
        overflow: 'hidden',
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: 30,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    buttonTermini: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    containerLogo: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems:'center',
        width: 85,
        height: 85,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 10
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 2,
        right: - Dimensions.get('screen').width / 2,
        zIndex: -1,
        overflow: 'hidden'
    },
    circle: {
        backgroundColor: '#f8d54c',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').width,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
});