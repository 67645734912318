import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Linking, Modal, TouchableOpacity as TouchButton, TouchableOpacity, Image } from 'react-native';
import { ActivityIndicator, Dialog, Button, Paragraph } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WebView } from 'react-native-webview';
import CryptoJS from 'crypto-js';
import Clipboard from '@react-native-clipboard/clipboard';

export default class AgendaScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDialog: false
        }
    }

    encryptId = async () => {
        const idStudio = await AsyncStorage.getItem('id')
        const secretKey = 'D4GJR593HG3JKRE93JER';  // Definisci una chiave segreta sicura
        const encrypted = CryptoJS.AES.encrypt(idStudio, secretKey).toString();
        return encodeURIComponent(encrypted);  // Assicurati che sia URL-safe
    }

    openLinkBookPage = async () => {
        const pageUid = await this.encryptId()
        Linking.openURL(`https://visitreporter.com/agenda/book/${pageUid}`)
    }

    copyLinkPage = async () => {
        const pageUid = await this.encryptId()
        Clipboard.setString(`https://visitreporter.com/agenda/book/${pageUid}`);
        this.setState({ showDialog: true })
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }
 
    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Agneda</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 25, paddingBottom: Dimensions.get('screen').height / 4 }}>
                        <View style={{ alignItems: 'center', marginBottom: 15 }}>
                            <View style={styles.containerLogo}>
                                <Image source={require('../assets/calendar.png')} resizeMode='contain' style={{ width: 60, height: 60 }} />
                            </View>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 19, color: '#FFF' }}>Agenda Elettronica</Text>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, marginTop: 3, color: '#FFF' }}>Gestione appuntamenti</Text>
                        </View>
                        <View style={{
                            flexDirection: (Dimensions.get('screen').width <= 500) ? 'column' : 'row',
                            alignItems: 'center',
                            justifyContent: (Dimensions.get('screen').width <= 500) ? 'flex-start' : 'space-between',
                            flexWrap: (Dimensions.get('screen').width <= 500) ? 'nowrap' : 'wrap'
                        }}>
                            {/*<TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('Appuntamenti')}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Feather name='calendar' color='#0070FF' size={30} />
                                    <View style={{ marginLeft: 15, width: '77%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Appuntamenti</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>*/}
                            <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('Studi')}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Feather name='map-pin' color='#0070FF' size={30} />
                                    <View style={{ marginLeft: 15, width: '77%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Studi</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('Operatori')}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Feather name='users' color='#0070FF' size={30} />
                                    <View style={{ marginLeft: 15, width: '77%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Operatori</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>
                            <View style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Feather name='link' color='#0070FF' size={30} />
                                    <View style={{ marginLeft: 15, width: '77%' }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Link Appuntamenti</Text>
                                    </View>
                                </View>
                                {(Dimensions.get('screen').width <= 500)
                                    ?
                                    <View>
                                        <TouchableOpacity style={[styles.buttonMore, { backgroundColor: 'green', marginBottom: 8 }]} onPress={this.copyLinkPage}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Copia</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonMore} onPress={this.openLinkBookPage}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Apri</Text>
                                        </TouchableOpacity>
                                    </View>
                                    :
                                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <TouchableOpacity style={[styles.buttonMore, { backgroundColor: 'green', marginRight: 8 }]} onPress={this.copyLinkPage}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Copia</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.buttonMore} onPress={this.openLinkBookPage}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Apri</Text>
                                        </TouchableOpacity>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <View style={styles.positionCricle}>
                    <View style={styles.circle}></View>
                </View>
                <Dialog style={{
                    width: (Dimensions.get('screen').width <= 500) ? '90%' : Dimensions.get('screen').width / 3,
                    marginLeft: (Dimensions.get('screen').width <= 500) ? 20 : (Dimensions.get('screen').width / 2) - ((Dimensions.get('screen').width / 3) / 2)
                }} visible={this.state.showDialog} onDismiss={() => this.setState({ showDialog: false })}>
                    <Dialog.Icon icon="check-circle" size={35} color='green' />
                    <Dialog.Title style={{ fontFamily: 'DM-Medium' }}>Url copiato</Dialog.Title>
                    <Dialog.Content>
                        <Paragraph style={{ fontFamily: 'DM-Regular' }}>Il link è stato copiato con succcesso</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button mode="contained" textColor='#fff' labelStyle={{ fontFamily: 'DM-Medium' }} contentStyle={{ paddingHorizontal: 25, backgroundColor: '#0070FF' }} onPress={() => this.setState({ showDialog: false })}>Ok</Button>
                    </Dialog.Actions>
                </Dialog>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF',
        overflow: 'hidden',
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: 30,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    buttonTermini: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    containerLogo: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems:'center',
        width: 85,
        height: 85,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 10
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 2,
        right: - Dimensions.get('screen').width / 2,
        zIndex: -1,
        overflow: 'hidden'
    },
    circle: {
        backgroundColor: '#8d4472',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').width,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
});