import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, TouchableOpacity, TextInput, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class CampagneScreen extends React.Component {
    constructor(props) {
        super(props)
        this.searchInput = React.createRef()
        this.state = {
            loading: false,
            idPaziente: '',
            cerca: '',
            searchResult: [],
            title: '',
            campagna: null,
            prezzo: '',
            note: '',
            loadingData: false,
            showDataSecond: false,
            usersSelect: []
        }
    }

    continuePatientsQueryLike = async (searchText) => {
        this.setState({ cerca: searchText, loadingData: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        axios.post('https://www.visitreporter.com/app/search_pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ searchResult: json, loadingData: false })
            } else {
                this.setState({ searchResult: [], loadingData: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    selectPaziente = (uid, nome, cognome, item) => {
        const usersSelect = this.state.usersSelect
        const findUser = usersSelect.filter(e => e.uid == uid)
        if (findUser.length > 0) {
            alert("Attenzione! Questo utente è stato già inserito")
        } else {
            if (usersSelect.length < 100) {
                usersSelect.push({
                    name: `${nome} ${cognome}`,
                    uid: uid
                })
                this.setState({ usersSelect, searchResult: [], cerca: '' })
                //this.searchInput.current.blur()
            } else {
                alert("Attenzione! Hai raggiunto il numero massimo di utenti")
            }
        }
    }

    deletePazienteSelectRequest = (index) => {
        if (confirm("Attenzione! Sei sicuro di voler eliminare il paziente?") == true) {
            this.deletePazienteSelect(index)
        }
    }

    deletePazienteSelect = (index) => {
        const usersSelect = this.state.usersSelect
        usersSelect.splice(index, 1)
        this.setState({ usersSelect })
    }

    testSave = () => {
        if (this.state.usersSelect.length > 0 && this.state.campagna !== null) {
            this.setState({ loading: true })
            setTimeout(() => {
                alert("Campagna inviata! La campagna è stata inviata con successo")
                this.setState({ loading: false, usersSelect: [], campagna: null, cerca: '', searchResult: [], showDataSecond: false, title: '', note: '' })
            }, 2500);
        } else {
            alert("Attenzione! Per continuare assicurati di aver selezionato un utente e una campagna")
        }
    }

    changeCampagna = (campagna) => {
        if (campagna == '10') {
            this.setState({ campagna, showDataSecond: true })
        } else {
            this.setState({ campagna, showDataSecond: false })
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Campagne</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerCenter}>
                        <View style={{ alignItems: 'center', marginBottom: 15 }}>
                            <View style={styles.containerLogo}>
                                <Image source={require('../assets/icon-campagne.png')} resizeMode='contain' style={{ width: 70, height: 70 }} />
                            </View>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 19 }}>Campagne Massive</Text>
                        </View>
                        {(this.state.usersSelect.length > 0)
                            &&
                            <>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, marginTop: 10 }}>Pazienti selezionati: {this.state.usersSelect.length}/100</Text>
                            <View style={[styles.input, { height: 'auto', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }]}>
                                {this.state.usersSelect.map((item, index) => {
                                    return(
                                        <TouchableOpacity key={index} onPress={() => this.deletePazienteSelectRequest(index)} style={[styles.buttonTag, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }]}>
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 13, marginRight: 5 }}>{item.name}</Text>
                                            <Feather size={18} name='x' color={'#000'} />
                                        </TouchableOpacity>
                                    )
                                })}
                            </View>
                            </>
                        }
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: (this.state.usersSelect.length > 0) ? 0 : 10 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Seleziona il paziente*</Text>
                            {(this.state.loadingData)
                                &&
                                <ActivityIndicator size='small' color='#000' />
                            }
                        </View>
                        <TextInput
                            style={[styles.input, { borderBottomLeftRadius: (this.state.searchResult.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResult.length > 0) ? 0 : 10, marginBottom: 0 }]}
                            placeholderTextColor='#bfbfbf'
                            placeholder='Inserisci il nome del Paziente'
                            value={this.state.cerca}
                            //onChangeText={(cerca) => {this.setState({ cerca }); this.filterResult(cerca)}}
                            onChangeText={(cerca) => this.continuePatientsQueryLike(cerca)}
                            ref={this.searchInput}
                            //editable={(this.state.loadingData) ? false : true}
                        />
                        {(this.state.searchResult.length > 0)
                            &&
                            this.state.searchResult.map((item, index) => {
                                let counter = index + 1;
                                const country_code = JSON.parse(item.country_code)
                                return(
                                    <TouchableOpacity onPress={() => this.selectPaziente(item.id, item.nome.trim(), item.cognome.trim(), item)} key={item.id} style={[styles.listUser, {
                                        borderBottomLeftRadius: (counter == this.state.searchResult.length) ? 10 : 0,
                                        borderBottomRightRadius: (counter == this.state.searchResult.length) ? 10 : 0
                                    }]}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>
                                            {item.nome.trim()} {item.cognome.trim()}{'\n'}
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                        </Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                        <View style={{ marginBottom: 20, marginTop: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Tipo di campagna</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di campagna', value: null }}
                                doneText='Fine'
                                value={this.state.campagna}
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                onValueChange={(campagna) => this.changeCampagna(campagna)}
                                items={[
                                    { label: 'Auguri natale', value: '0' },
                                    { label: 'Auguri capodanno', value: '1' },
                                    { label: 'Compleanno', value: '2' },
                                    { label: 'San valentino', value: '3' },
                                    { label: 'Auguri pasqua', value: '4' },
                                    { label: 'Primavera', value: '5' },
                                    { label: 'Matrimonio', value: '6' },
                                    { label: 'Ottobre prevenzione', value: '7' },
                                    { label: 'Giugno Pre-estiva', value: '8' },
                                    { label: 'Back to school', value: '9' },
                                    { label: 'Altro', value: '10' },
                                ]}
                            />
                        </View>
                        {(this.state.showDataSecond)
                            &&
                            <>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Titolo</Text>
                            <TextInput
                                style={styles.input}
                                placeholderTextColor='#bfbfbf'
                                placeholder='Titolo'
                                value={this.state.title}
                                onChangeText={(title) => this.setState({ title })}
                                maxLength={200}
                            />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Messaggio</Text>
                            <TextInput
                                style={[styles.input, { height: 85 }]}
                                placeholderTextColor='#bfbfbf'
                                placeholder='Messaggio campagna'
                                value={this.state.note}
                                onChangeText={(note) => this.setState({ note })}
                                multiline={true}
                                numberOfLines={3}
                            />
                            </>
                        }
                        <View style={{ alignItems: 'center' }}>
                            <TouchableOpacity style={styles.button} onPress={this.testSave}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator color='#fff' size='small' />
                                    :
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Salva e continua</Text>
                                }
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 25,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerLogo: {
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems:'center',
        width: 85,
        height: 85,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 10
    },
    buttonTag: {
        backgroundColor: 'lightgrey',
        paddingVertical: 4.5,
        paddingHorizontal: 10,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 7,
        marginBottom: 10
    },
});