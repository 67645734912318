import React from 'react';
import { StyleSheet, Text, View, StatusBar, Dimensions, Image, ScrollView, Platform, Linking, Alert, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { ProgressBar } from 'react-native-paper';
import { AntDesign, FontAwesome, Feather } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Crypto from 'expo-crypto';
import Moment from 'moment';
import 'moment/locale/it';
import RNPickerSelect from 'react-native-picker-select';
import * as Print from 'expo-print';
import { shareAsync } from 'expo-sharing';

export default class StatisticheScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pazientiNuoviAccettati: 0,
            percentualeNuovi: 0,
            nPreventiviNuovi: 0,
            nPreventiviAcquisiti: 0,
            nPreventivi: 0,
            pazientiAcquisitiAccettati: 0,
            percentualeAcquisiti: 0,
            listaPreventivi: [],
            preventiviHistory: [],
            type: 0,
            valore: null,
            preventiviAnno: [],
            preventiviMese: [],
            preventiviSettimana: []
        }
    }

    componentDidMount() {
        this.getPercentuali()
        this.getPreventiviAccettati()
        this.getCountPreventivi()
    }

    getCountPreventivi = async () => {
        const id = await AsyncStorage.getItem('id')
        const token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        fetch(`https://www.visitreporter.com/app/numero_preventivi_accettati.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            if (json.length > 0) {
                let dataOggi = new Date();
                const first = dataOggi.getDate() - dataOggi.getDay() + 1;
                const last = first + 6;
                const firstday = new Date(dataOggi.setDate(first));
                const lastday = new Date(dataOggi.setDate(last));
                let preventiviAnno = null;
                preventiviAnno = json.filter(x => Moment(x.data_creazione).format('YYYY').match(Moment(dataOggi).format('YYYY')));
                let preventiviMese = null;
                preventiviMese = json.filter(x => Moment(x.data_creazione).format('MM').match(Moment(dataOggi).format('MM')));
                let preventiviSettimana = null;
                preventiviSettimana = json.filter(x => Moment(x.data_creazione).format('YYYY-MM-DD') >= Moment(firstday).format('YYYY-MM-DD') && Moment(x.data_creazione).format('YYYY-MM-DD') <= Moment(lastday).format('YYYY-MM-DD'));
                this.setState({ preventiviAnno, preventiviMese, preventiviSettimana })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getPreventiviAccettati = async () => {
        const id = await AsyncStorage.getItem('id')
        const token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        fetch(`https://www.visitreporter.com/app/preventivi_${(this.state.type == 0) ? 'accettati' : (this.state.type == 1) ? 'rifiutati' : 'in_attesa'}.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            if (json != null) {
                this.setState({ listaPreventivi: json, preventiviHistory: json })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getPercentuali = async () => {
        const id = await AsyncStorage.getItem('id')
        const token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        fetch(`https://www.visitreporter.com/app/statistiche_percentuale_${(this.state.type == 0) ? 'accettati' : (this.state.type == 1) ? 'rifiutati' : 'in_attesa'}.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            let percentuale = (json.pazienti_nuovi_accettati / json.pazienti_nuovi) * 100;
            percentuale = percentuale.toString().split('.')[0]
            let percentuale2 = (json.pazienti_acquisiti_accettati / json.pazienti_acquisiti) * 100;
            percentuale2 = percentuale2.toString().split('.')[0]
            let percentualeProgress;
            let percentualeProgress2;
            if (percentuale < 10) {
                percentualeProgress = `0.0${percentuale}`;
            } else if (percentuale >= 100) {
                percentualeProgress = `1`;
            } else {
                percentualeProgress = `0.${percentuale}`;
            }
            if (percentuale2 < 10) {
                percentualeProgress2 = `0.0${percentuale2}`;
            } else if (percentuale2 >= 100) {
                percentualeProgress2 = `1`;
            } else {
                percentualeProgress2 = `0.${percentuale2}`;
            }
            this.setState({ 
                pazientiNuoviAccettati: percentuale,
                pazientiAcquisitiAccettati: percentuale2,
                percentualeNuovi: parseFloat(percentualeProgress),
                percentualeAcquisiti: parseFloat(percentualeProgress2),
                nPreventiviNuovi: json.pazienti_nuovi_accettati,
                nPreventiviAcquisiti: json.pazienti_acquisiti_accettati,
                nPreventivi: json.pazienti_nuovi
            })
        }).catch((error) => {
            console.error(error);
        })
    }

    filterResult = (txt) => {
        let text = txt;
        let space = null;
        if (text == null) {
            this.setState({ listaPreventivi: this.state.preventiviHistory });
        } else {
            space = this.state.preventiviHistory.filter(x => x.valore.match(text));
            this.setState({ listaPreventivi: space });
        }
    }

    exportFile = () => {
        Alert.alert(
            "Esporta Statistiche",
            "Come le desideri esportare?",
            [
                { text: "Stampa", onPress: () => this.print() },
                { text: "Condividi", onPress: () => this.share() },
                { text: "Annulla", style: "cancel" }
            ]
        )
    }

    print = async () => {
        const htmlPrint = `
        <!DOCTYPE html>
        <html>
        <head>
        <style>
        ${(Platform.OS == 'android')
            &&
            '@page { margin: 20px; }'
        }
        
        main {
            break-inside: avoid;
        }
        
        @font-face {
          font-weight: 400;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 500;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 700;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 900;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2') format('woff2');
        }
        
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }
        
        td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
          font-family: 'Circular-Loom';
        }
        
        tr:nth-child(even) {
          background-color: #dddddd;
        }
        
        body {
            font-family: 'Circular-Loom';
        }
        
        #container {
          width: 30em;
          height: 20em;
          margin: 0;
          padding: 0;
        }
          .anychart-credits {
            display: none !important;
          }
        </style>
        </head>
        <body>
        <main style="height: ${(Platform.OS == 'android') ? '285mm' : '270mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="display: flex; justify-content: center; padding-top: 1em;">
                <h3>Statistiche</h3>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; padding-top: 1em;">
                <div style="margin-right: 6em">
                    <h4 style="text-align: center">Preventivi ${(this.state.type == 0) ? 'Accettati' : (this.state.type == 1) ? 'Rifiutati' : 'In attesa'}<br>su Pazienti Nuovi</h4>
                    <h2 style="text-align: center">${(this.state.pazientiNuoviAccettati == 'NaN') ? 0 : this.state.pazientiNuoviAccettati}%</h2>
                    <p style="text-align: center">${this.state.nPreventiviNuovi} su ${this.state.nPreventivi} preventivi</p>
                </div>
                <div>
                    <h4 style="text-align: center">Preventivi ${(this.state.type == 0) ? 'Accettati' : (this.state.type == 1) ? 'Rifiutati' : 'In attesa'}<br>su Pazienti Acquisiti</h4>
                    <h2 style="text-align: center">${(this.state.pazientiAcquisitiAccettati == 'NaN') ? 0 : this.state.pazientiAcquisitiAccettati}%</h2>
                    <p style="text-align: center">${this.state.nPreventiviAcquisiti} su ${this.state.nPreventivi} preventivi</p>
                </div>
            </div>
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 2em; padding-bottom: 2em">
                <table style="border-radius: 1em">
                <tr style="background-color: rgb(7, 149, 255)">
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Nome</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Cognome</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Email</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Telefono</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Valore</th>
                </tr>
                ${this.state.listaPreventivi.map((item) => {
                    const country_code = JSON.parse(item.country_code)
                    return `<tr>
                                <td>${item.nome}</td>
                                <td>${item.cognome}</td>
                                <td>${item.email}</td>
                                <td>+${country_code.callingCode[0]}${item.telefono}</td>
                                <td>${item.valore}</td>
                            </tr>`
                }).join('')}
                </table>
            </div>
        </main>
        </body>
        </html>
        `;
        await Print.printAsync({
          html: htmlPrint
        });
    }

    share = async () => {
        const htmlPrint = `
        <!DOCTYPE html>
        <html>
        <head>
        <style>
        ${(Platform.OS == 'android')
            &&
            '@page { margin: 20px; }'
        }
        
        main {
            break-inside: avoid;
        }
        
        @font-face {
          font-weight: 400;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 500;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 700;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
        }
        
        @font-face {
          font-weight: 900;
          font-style:  normal;
          font-family: 'Circular-Loom';
        
          src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2') format('woff2');
        }
        
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }
        
        td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
          font-family: 'Circular-Loom';
        }
        
        tr:nth-child(even) {
          background-color: #dddddd;
        }
        
        body {
            font-family: 'Circular-Loom';
        }
        
        #container {
          width: 30em;
          height: 20em;
          margin: 0;
          padding: 0;
        }
          .anychart-credits {
            display: none !important;
          }
        </style>
        </head>
        <body>
        <main style="height: ${(Platform.OS == 'android') ? '285mm' : '270mm'}; width: 210mm; margin-left: auto; margin-right: auto;">
            <div style="display: flex; justify-content: center; padding-top: 1em;">
                <h3>Statistiche</h3>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; padding-top: 1em;">
                <div style="margin-right: 6em">
                    <h4 style="text-align: center">Preventivi ${(this.state.type == 0) ? 'Accettati' : (this.state.type == 1) ? 'Rifiutati' : 'In attesa'}<br>su Pazienti Nuovi</h4>
                    <h2 style="text-align: center">${(this.state.pazientiNuoviAccettati == 'NaN') ? 0 : this.state.pazientiNuoviAccettati}%</h2>
                    <p style="text-align: center">${this.state.nPreventiviNuovi} su ${this.state.nPreventivi} preventivi</p>
                </div>
                <div>
                    <h4 style="text-align: center">Preventivi ${(this.state.type == 0) ? 'Accettati' : (this.state.type == 1) ? 'Rifiutati' : 'In attesa'}<br>su Pazienti Acquisiti</h4>
                    <h2 style="text-align: center">${(this.state.pazientiAcquisitiAccettati == 'NaN') ? 0 : this.state.pazientiAcquisitiAccettati}%</h2>
                    <p style="text-align: center">${this.state.nPreventiviAcquisiti} su ${this.state.nPreventivi} preventivi</p>
                </div>
            </div>
            <div style="padding-left: 4em; padding-right: 4em; padding-top: 2em; padding-bottom: 2em">
                <table style="border-radius: 1em">
                <tr style="background-color: rgb(7, 149, 255)">
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Nome</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Cognome</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Email</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Telefono</th>
                    <th style="padding-top: 1em; padding-bottom: 1em; color: #fff">Valore</th>
                </tr>
                ${this.state.listaPreventivi.map((item) => {
                    const country_code = JSON.parse(item.country_code)
                    return `<tr>
                                <td>${item.nome}</td>
                                <td>${item.cognome}</td>
                                <td>${item.email}</td>
                                <td>+${country_code.callingCode[0]}${item.telefono}</td>
                                <td>${item.valore}</td>
                            </tr>`
                }).join('')}
                </table>
            </div>
        </main>
        </body>
        </html>
        `;
        const { uri } = await Print.printToFileAsync({
            html: htmlPrint
        })
        await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={{ backgroundColor: '#0070FF' }}>
                    <View style={styles.header}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#fff' }}>Business Intelligence</Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 20, paddingBottom: 15 }}>
                        <TouchableOpacity style={styles.backButtonNav} onPress={() => this.props.navigation.goBack()}>
                            <Feather name='arrow-left' color='#fff' size={23} />
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.backButton, { width: (Dimensions.get('screen').width / 2) - 30 }]} onPress={() => this.props.navigation.navigate('SoobPazienti')}>
                            <FontAwesome name='star' color='#fff' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Opportunità</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.backButton, { width: (Dimensions.get('screen').width / 2) - 90 }]} onPress={this.exportFile}>
                            <AntDesign name='export' color='#fff' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Esporta</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.containerPercentuale}>
                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, textAlign: 'center', color: '#FFF' }}>Preventivi Accettati</Text>
                    <View style={{ marginTop: 10, marginBottom: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <View style={{
                            width: (Dimensions.get('screen').width / 3) - 22.5,
                            alignItems: 'center'
                        }}>
                            <View style={styles.buttonCount}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 24 }}>{this.state.preventiviSettimana.length}</Text>
                            </View>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, textAlign: 'center', color: '#FFF' }}>Questa{'\n'}Settimana</Text>
                        </View>
                        <View style={{
                            width: (Dimensions.get('screen').width / 3) - 22.5,
                            alignItems: 'center'
                        }}>
                            <View style={styles.buttonCount}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 24 }}>{this.state.preventiviMese.length}</Text>
                            </View>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, textAlign: 'center', color: '#FFF' }}>Questo{'\n'}Mese</Text>
                        </View>
                        <View style={{
                            width: (Dimensions.get('screen').width / 3) - 22.5,
                            alignItems: 'center'
                        }}>
                            <View style={styles.buttonCount}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 24 }}>{this.state.preventiviAnno.length}</Text>
                            </View>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, textAlign: 'center', color: '#FFF' }}>Questo{'\n'}Anno</Text>
                        </View>
                    </View>
                    <View style={{ 
                        marginBottom: 10,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <View style={{ alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, textAlign: 'center', color: '#FFF' }}>Tipo Preventivo</Text>
                            <RNPickerSelect
                                doneText='Fine'
                                value={this.state.type}
                                placeholder={{ label: 'Selziona...', value: null }}
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center', width: (Dimensions.get('screen').width / 2) - 25 }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 13 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 13, color: '#000' } }}
                                onValueChange={
                                    (Platform.OS == 'android')
                                    ?
                                    (type) => { this.getPercentuali(); this.getPreventiviAccettati(); (type == null) ? this.setState({ type: 0, valore: null }) : this.setState({ type, valore: null }) }
                                    :
                                    (type) => { (type == null) ? this.setState({ type: 0, valore: null }) : this.setState({ type, valore: null }) }
                                }
                                onClose={(Platform.OS != 'android') ? () => { this.getPercentuali(); this.getPreventiviAccettati() } : null}
                                items={[
                                    { label: 'Preventivi Accettati', value: 0 },
                                    { label: 'Preventivi Rifiutati', value: 1 },
                                    { label: 'Preventivi In attesa', value: 2 }
                                ]}
                            />
                        </View>
                        <View style={{ alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10, textAlign: 'center', color: '#FFF' }}>Valore Preventivo</Text>
                            <RNPickerSelect
                                placeholder={{ label: "Rimuovi filtro", value: null }}
                                value={this.state.valore}
                                doneText='Fine'
                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center', width: (Dimensions.get('screen').width / 2) - 25 }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 13 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 13, color: '#000' } }}
                                onValueChange={
                                    (Platform.OS == 'android')
                                    ?
                                    (valore) => { this.setState({ valore }); this.filterResult(valore) }
                                    :
                                    (valore) => this.setState({ valore })
                                }
                                onClose={(Platform.OS != 'android') ? () => this.filterResult(this.state.valore) : null}
                                items={[
                                    { label: 'Fino a 1k', value: 'Fino a 1k' },
                                    { label: 'Da 1 a 3k', value: 'Da 1 a 3k' },
                                    { label: 'Da 3 a 5k', value: 'Da 3 a 5k' },
                                    { label: 'Oltre i 5k', value: 'Oltre i 5k' }
                                ]}
                            />
                        </View>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <View style={{ alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#FFF' }}>Preventivi {(this.state.type == 0) ? 'Accettati' : (this.state.type == 1) ? 'Rifiutati' : 'In attesa'}</Text>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#FFF' }}>Pazienti Nuovi</Text>
                            <View style={styles.buttonPercentuale}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18 }}>{(this.state.pazientiNuoviAccettati == 'NaN') ? 0 : this.state.pazientiNuoviAccettati}%</Text>
                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 13, marginBottom: 4 }}><Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>{this.state.nPreventiviNuovi}</Text> su <Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>{this.state.nPreventivi}</Text> {(this.state.nPreventivi == 1) ? 'preventivo' : 'preventivi'}</Text>
                                <ProgressBar color='#0070FF' progress={this.state.percentualeNuovi} style={{ height: 12, width: (Dimensions.get('screen').width / 2) - 75, borderRadius: 30 }} />
                            </View>
                        </View>
                        <View style={{ alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#FFF' }}>Preventivi {(this.state.type == 0) ? 'Accettati' : (this.state.type == 1) ? 'Rifiutati' : 'In attesa'}</Text>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#FFF' }}>Pazienti Acquisiti</Text>
                            <View style={styles.buttonPercentuale}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18 }}>{(this.state.pazientiAcquisitiAccettati == 'NaN') ? 0 : this.state.pazientiAcquisitiAccettati}%</Text>
                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 13, marginBottom: 4 }}><Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>{this.state.nPreventiviAcquisiti}</Text> su <Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>{this.state.nPreventivi}</Text> {(this.state.nPreventivi == 1) ? 'preventivo' : 'preventivi'}</Text>
                                <ProgressBar color='#0070FF' progress={this.state.percentualeAcquisiti} style={{ height: 12, width: (Dimensions.get('screen').width / 2) - 75, borderRadius: 30 }} />
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.containerTable}>
                    <View style={[styles.head, { alignItems: 'center', paddingHorizontal: 0, borderColor: '#edf7ff', borderBottomWidth: 1, justifyContent: 'center', height: 35 }]}>
                        <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff' }]}>Lista Preventivi</Text>
                    </View>
                    <View style={[styles.head, { flexDirection: 'row', alignItems: 'center', paddingHorizontal: 0 }]}>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff', marginLeft: 10 }]}>Nome</Text>
                        </View>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff' }]}>Email</Text>
                        </View>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff', marginRight: 10 }]}>Telefono</Text>
                        </View>
                    </View>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <View style={{ marginBottom: Dimensions.get('screen').height / 3 }}>
                            {this.state.listaPreventivi.map((item) => {
                                const country_code = JSON.parse(item.country_code)
                                return(
                                    <View key={item.id_visita} style={{ borderBottomWidth: 1, borderColor: '#ededed', backgroundColor: '#fff', flexDirection: 'row', alignItems: 'center' }}>
                                        <TouchableOpacity style={styles.widthRow} onPress={() => this.props.navigation.navigate('PazienteModifica', { paziente: item })}>
                                            <Text style={[styles.text, { fontFamily: 'DM-Regular', marginLeft: 10 }]}>{item.nome} {item.cognome}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.widthRow} onPress={() => this.props.navigation.navigate('PazienteModifica', { paziente: item })}>
                                            <Text style={[styles.text, { fontFamily: 'DM-Regular' }]} numberOfLines={1} ellipsizeMode='tail'>{item.email}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.widthRow} onPress={() => Linking.openURL(`tel:+${country_code.callingCode[0]}${item.telefono}`)}>
                                            <Text style={[styles.text, { fontFamily: 'DM-Regular' }]}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                        </TouchableOpacity>
                                    </View>
                                )
                            })}
                        </View>
                    </ScrollView>
                </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#edf7ff'
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 14,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonCount: {
        backgroundColor: '#fff',
        borderRadius: 10,
        minWidth: 80,
        maxWidth: (Dimensions.get('screen').width / 3) - 22.5,
        paddingHorizontal: 5,
        width: 'auto',
        height: 55,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    button: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    containerPercentuale: {
        backgroundColor: '#2484FF',
        paddingHorizontal: 20,
        paddingVertical: 16
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    backButtonNav: {
        backgroundColor: '#2484FF',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonPercentuale: {
        backgroundColor: '#fff',
        width: 'auto',
        borderRadius: 10,
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginTop: 10,
        width: (Dimensions.get('screen').width / 2) - 25
    },
    header: {
        paddingHorizontal: 20,
        paddingTop: 10 + Constants.statusBarHeight,
        paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerLink: {
        paddingHorizontal: 20,
        paddingVertical: 30
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        paddingHorizontal: 20,
        paddingVertical: 15,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    badge: {
        backgroundColor: '#b08d00',
        paddingHorizontal: 15,
        paddingVertical: 3,
        borderRadius: 20,
        alignItems: 'center',
        width: 'auto'
    },
    head: {
        height: 50,
        backgroundColor: '#0070FF',
        paddingHorizontal: 5
    },
    containerTable: {
        paddingBottom: 30
    },
    text: { 
        margin: 6 
    },
    widthTable: {
        width: Dimensions.get('screen').width / 3
    },
    widthRow: {
        height: 40,
        width: Dimensions.get('screen').width / 3,
        justifyContent: 'center'
    }
});